@import "src/assets/scss/mixin.scss";
@import "src/assets/styles/colors";
@import "src/assets/scss/variable.scss";
.help .bg {
  background-color: $color-3;
  background-size: auto;
  min-height: 340px;
  color: $color-1;
  display: flex;
  align-items: center;
  position: relative;
}

.bg-image {
  background-size: auto;
  background-position: bottom;
  mix-blend-mode: overlay;
}

.margin-top {
  margin-top: 35px;
}
.form-media {
  position: relative;
  .right-side {
    position: absolute;
    right: 10px;
    top: 10px;
    .close {
      display: inline-block;
      background: rgba(0, 0, 0, 0.5);
      border-radius: 14px;
      color: #ffffff;
      padding: 5px 12px;
      font-weight: 400;
      font-size: 15px;
      line-height: 22px;
      font-size: 10px;
      cursor: pointer;
      margin-left: 5px;
      @media (max-width: 767px) {
        padding: 2px 9px;
      }
    }
  }
}

.subject {
  span {
    cursor: pointer;
    color: $primary;
    font-size: 19px;
    font-family: Inter;
    font-weight: 600;
    line-height: 24px;
    padding-top: 20px;
    padding-bottom: 20px;
    @media (max-width: 767px) {
      font-size: 16px;
    }
  }
}
.attachment {
  input[type="file"] {
    display: none;
  }
  .custom-file-upload {
    cursor: pointer;
    text-align: end;
    color: $primary;
    font-size: 15px;
    font-family: Inter;
    font-weight: 600;
  }
}

@media (max-width: 1440px) {
  .bg-image {
    background-size: auto;
    width: 78%;
    background-position: bottom;
    mix-blend-mode: overlay;
  }
}

@media (max-width: 1000px) {
  .margin-top {
    margin-top: 0px;
  }
}

.ml-2 {
  margin-left: 2%;
}

.help .main-box {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 50px;
}

.help .wrapper h5 {
  font-weight: 600;
  font-size: 30px;
  line-height: 44px;
  color: $color-2;
}

.help .list ul {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
}

.help .list ul li {
  font-weight: 600;
  font-size: 19px;
  line-height: 46px;
  color: $color-3;
  flex-basis: 30%;
}

.help .wrapper .heading h4 {
  font-weight: 600;
  font-size: 36px;
  line-height: 50px;
  color: $color-2;
  margin-bottom: 30px;
}

.help .main-box {
  margin-top: 33px;
}

.help .main-box .box {
  background: $color-1;
  box-shadow: 0 8px 16px rgb(171 190 209 / 40%);
  width: 357px;
  height: 146px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.help .boxes .search-box input {
  margin: 0 0 5% 0;
  // width: 60vw !important;
  height: 46px;
  padding: 10px 40px 10px 10px;
}

.help .boxes {
  display: flex;
  margin-bottom: 36px;
  margin-top: 30px;
}

.help .title {
  padding: 0;
  font-weight: 600;
  font-size: 30px;
  line-height: 44px;
  color: $color-2;
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 1080px) {
    font-size: 15px !important;
  }
}

.help .title::after {
  content: url(../../assets/img/Down.svg);
  cursor: pointer;
}

.help .accordian {
  margin-top: 50px;
  .accordion {
    --bs-accordion-border-color: none !important;
    --bs-accordion-active-color: none !important;
    --bs-accordion-active-bg: none !important;
    --bs-accordion-btn-focus-border-color: none;
    --bs-accordion-btn-focus-box-shadow: none;
  }
}

.under-prize {
  .accordion-button:after {
    order: -1; //swap order
    margin-left: 0;
    margin-right: 0.5em; // just extra space between text and icon
  }
}

.subToggle {
  display: flex;
  flex-direction: column;
}

.noBorder {
  border: none;
}

.whiteText {
  color: white;
}

.help .subtitle {
  font-weight: 600;
  font-size: 19px;
  line-height: 24px;
  color: $color-2;
}

.help .arrow::before {
  content: "";
  background: url(../../assets/img/Down.svg);
  margin-right: 30px;
  cursor: pointer;
  background-size: 100% 100%;
  display: inline-block;
  height: 10px;
  width: 17px;
}

.help .search-box {
  position: relative;
  width: 100%;
}

.help .search-img {
  background: url(../../assets/img/Search.svg) no-repeat;
  position: absolute;
  top: 11px;
  display: inline-block;
  width: 27px;
  height: 25px;
  right: 11px;
  cursor: pointer;
}

.help .boxes .search select {
  height: 46px;
  padding: 10px 7px;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  color: $color-5;
}

// .help .card {
//   border: none;
// }

// .help .card-body {
//   padding: 0;
// }

// .help .card-body p {
//   font-weight: 400;
//   font-size: 15px;
//   line-height: 22px;
//   color: $color-5;
//   margin-top: 13px;
//   padding: 0;
//   margin-bottom: 0;
// }

.help .bottom ul {
  display: flex;
  flex-direction: row;
  gap: 23px;
}

.help .bottom ul li {
  font-weight: 600;
  font-size: 19px;
  line-height: 24px;
  color: $color-3;
  cursor: pointer;
}

@media (max-width: 991px) {
  .help .boxes .search-box input {
    width: auto;
  }

  .help .wrapper .heading h4 {
    font-size: 23px;
    line-height: 34px;
  }

  .help .wrapper h5 {
    font-size: 19px;
  }

  .help .list ul {
    display: block;
  }

  .help .list ul li {
    font-size: 13px;
  }
}

@media (max-width: 767px) {
  .help .bg .bg-image {
    max-width: 200px;
    width: 100%;
    display: block;
    margin: auto;
  }
}

@media (max-width: 500px) {
  .help .boxes .search select {
    width: 30vw !important;
  }

  .help .boxes {
    justify-content: center;
    flex-wrap: wrap;
    .search {
      width: 40% !important;
      select {
        width: 100% !important;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    .search-box {
      width: 59% !important;
    }
  }

  .help .list ul {
    flex-direction: column;
  }
}

@media (max-width: 1000px) {
  .help .list ul li {
    font-size: 16px !important;
    line-height: 25px !important;
    margin-top: 15px;
  }
}

.ml-2 {
  margin-left: 2%;
}

.help {
  .faq-list {
    display: flex;
    flex-direction: column;
    padding: 20px 0px 0px 0px;
    font-family: "Inter";

    .faq-question {
      font-weight: 600;
      font-size: 18px;
      text-transform: capitalize;
      color: #0432a3;
      cursor: pointer;
    }

    .faq-tag {
      color: grey;
      font-weight: 400;
      font-size: 15px;
      text-transform: capitalize;
      line-height: 25px;
    }

    .faq-answer {
      font-weight: 500;
      font-size: 16px;
      text-transform: capitalize;
      color: #000;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      cursor: pointer;
    }
  }
}

.help-article {
  h5 {
    font-weight: 600;
    font-size: 30px;
    line-height: 44px;
    color: #01052d;
  }
  .card-height-article {
    height: 100%;

    @media (max-width: 767px) {
      height: auto;
    }
  }

  .card-height-page-article {
    height: 450px;
  }

  .card-height-page {
    height: 427px;

    @media (max-width: 767px) {
      height: auto;
    }
  }

  .card-height-event {
    height: 450px;
  }

  .card-height-groups {
    min-height: 405px;

    @media (max-width: 767px) {
      min-height: auto;
    }
  }

  .card {
    border: 1px solid $border;
    margin-bottom: 20px;
    width: 100%;
    border-radius: 5px;

    .followicon {
      margin-right: 3px;
    }

    .groupicon {
      background: url(../../assets/img/groupicon.png) no-repeat center;
      width: 20px;
      height: 14px;
      display: inline-block;
      cursor: pointer;
    }

    .pageicon {
      border-radius: 50%;
      width: 100px;
      height: 100px;
      object-fit: cover;
    }

    .card-image-square {
      height: 200px;
      width: 100%;
      object-fit: cover;
      background: $linear;
    }

    .card-header {
      background: white;
      border: none;

      .name {
        @include small;
        color: $dark-black;
      }

      .name:hover {
        text-decoration: underline;
        color: $primary;
        cursor: pointer;
      }

      .date {
        display: flex;
        margin-top: 10px;
        justify-content: space-between;
        align-items: center;

        .days {
          @include para;
          font-size: 14px;
        }

        .subscribe {
          background: $transparent-blue;
          padding: 2px 12px;
          @include font-14;
          color: $primary;
          border-radius: 5px;
          cursor: pointer;
        }
      }

      .profile-img {
        @include profile-circle;
      }

      h3 {
        @include font-600;
        font-size: 19px;
        margin-top: 16px;
        color: $dark-black;
        text-transform: capitalize;
      }
    }

    .card-text-control {
      display: inline;
      -webkit-line-clamp: 2;
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      word-wrap: break-word;
    }

    .card-page-text-control {
      display: inline;
      -webkit-line-clamp: 4;
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      word-wrap: break-word;
    }

    .card-event-control {
      display: inline;
      -webkit-line-clamp: 1;
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      word-wrap: break-word;
    }

    .card-body {
      p {
        @include para;
        font-size: 14px;
      }

      img {
        display: none;
      }

      .read {
        color: $primary;
        @include font-14;
        margin-top: 9px;
        cursor: pointer;
        display: inline-block;
        font-weight: bold;
      }
    }
  }
}
