.watch_smart_interview {
  // background: #eff2f6;
  padding-top: 30px;
  height: 100vh;
  .interview_container {
    border-radius: 5px;
    background: #fff;
    box-shadow: 0px 2px 4px 0px rgba(171, 190, 209, 0.6);
  }
  .sub_btn {
    border-radius: 5px;
    background: #0432a3;
  }
  .question_container {
    position: absolute;
    width: 50%;
    /* bottom: 0; */
    text-align: left;
    top: 10px;
    /* left: 30px; */
    height: 80px;
    padding: 20px;
    background: linear-gradient(
      90deg,
      rgba(4, 50, 163, 0.2) 38.68%,
      rgba(4, 50, 163, 0) 100%
    );
    max-height: calc(30vh - 150px);
    scroll-behavior: smooth;
    overflow-y: auto;
    overflow-x: hidden;
    p {
      color: #01052d;
      // font-weight: bold;
    }
    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }
    &::-webkit-scrollbar-thumb {
      background-color: #363b99;
      border-radius: 10px;
    }
  }

  .interview-video-player {
    padding: inherit;

    /* Hide the download button */
    &::-webkit-media-controls-enclosure {
      overflow: hidden !important;
    }

    /* Hide the download button in Firefox */
    &::-moz-media-controls-panel {
      display: none !important;
    }

    /* Hide the download button in Chrome */
    &::-webkit-media-controls-enclosure::before {
      content: none !important;
    }

    /* Hide the download button in Safari */
    &::-webkit-media-controls-panel:not(.audio)::before {
      display: none !important;
    }
  }

  .toplist {
    background-color: #0432a3;
    padding: 10px;
    color: #fff;
    border-radius: 25px;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
  }
}
