@import "src/assets/styles/colors";

.info ul li::before {
  content: url("../../../assets/img/Check-pass.svg");
  margin-right: 10px;
  padding-top: 5px;
}

.info {
  background: $color-1;
  padding: 18px;
  position: absolute;
  left: 78px;
  top: -30px;
  border: 1px solid #e8e8e8;
}


.info .box {
  width: 40px;
  height: 40px;
  background: rgb(255 255 255);
  position: absolute;
  transform: rotate(45deg);
  left: 50px;
  border: 1px solid #e8e8e8;
  bottom: -12px;
  z-index: -1;
}

.info .angle {
  width: 40px;
  height: 40px;
  background: rgb(255 255 255);
  position: absolute;
  transform: rotate(45deg);
  left: 50px;
  bottom: -10px;
}
.info ul {
  position: relative;
  z-index: 5;
}

.info ul li {
  font-weight: 400;
  font-size: 10px;
  line-height: 20px;
  display: flex;
  color: $color-5;
}

.info h6 {
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: $color-2;
}
