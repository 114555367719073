@import "src/assets/styles/colors";
@import "src/assets/scss/variable";

.help .help .main-box {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 50px;
}

.help .wrapper h5 {
  font-weight: 600;
  font-size: 30px;
  line-height: 44px;
  color: $color-2;
  @media (max-width: 767px) {
    font-size: 24px;
    line-height: 34px;
  }
}

.bottom ul li a {
  color: $color-3 !important;
}
.imgClass {
  width: 100%;
  margin-bottom: 20px;
}

.help .list ul {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  justify-content: space-between;
}

.help .list ul li a {
  color: $primary;
}

.btn-grey {
  border: 1px solid $color-3;
  color: $color-3;
  margin-left: 20px;
}

.help .wrapper p {
  font-weight: 600;
  font-size: 19px;
  line-height: 24px;
  color: $dark-black;
  margin-top: 10px;
}

.help .wrapper .feedBack {
  display: flex;
  justify-content: space-between;
  margin: 40px auto;
  padding: 20px;
  border: 1px solid $color-6;
  align-items: center;
}

.help .list ul li {
  font-weight: 600;
  font-size: 19px;
  line-height: 46px;
  color: $color-3;
  flex-basis: 30%;
}

.help .wrapper .heading {
  h2 {
    font-weight: 600;
    font-size: 36px;
    line-height: 44px;
    color: $color-2;
    margin-bottom: 30px;
    @media (max-width: 767px) {
      font-size: 18px;
      line-height: 38px;
      margin-bottom: 5px;
    }
  }
  h3 {
    font-size: 30px;
    font-family: Inter;
    font-weight: 600;
    line-height: 44px;
    color: $color-2;
  }
  &.mb-3 {
    margin-bottom: 0 !important;
  }
}

.help .main-box {
  margin-top: 33px;
}

.help .main-box .box {
  background: $color-1;
  box-shadow: 0 8px 16px rgb(171 190 209 / 40%);
  width: 357px;
  height: 146px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.help .boxes .search-box input {
  width: 100%;
  height: 46px;
  padding: 10px 40px 10px 10px;
  margin: 0 0 5% 0;
  color: $parafont;
}

.help .boxes {
  display: flex;
  margin-bottom: 50px;
  margin-top: 30px;
  .search-list-box {
    position: absolute;
    background: white;
    width: 100%;
    top: 50px;
    z-index: 10;
    border: 1px solid #e9e9e9;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    min-height: 100px;
  }
  .list {
    color: black;
    padding: 2px 10px;
    cursor: pointer;
  }
}

.help .title {
  padding: 0;
  font-weight: 600;
  font-size: 30px;
  line-height: 44px;
  color: $color-2;
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;
}

.help .title::after {
  content: url(../../../assets/img/Down.svg);
  cursor: pointer;
}

.help .accordian {
  margin-top: 50px;
}

.help .subtitle {
  font-weight: 600;
  font-size: 19px;
  line-height: 24px;
  color: $color-2;
  @media screen and (max-width: 1080px) {
    font-size: 15px;
    font-weight: 500;
    line-height: 50px;
  }
}

.help .arrow::before {
  content: "";
  background: url(../../../assets/img/Down.svg);
  margin-right: 30px;
  cursor: pointer;
  background-size: 100% 100%;
  display: inline-block;
  height: 10px;
  width: 17px;
}

.help .search-box {
  position: relative;
}

.help .search-img {
  background: url(../../../assets/img/Search.svg) no-repeat;
  position: absolute;
  top: 11px;
  display: inline-block;
  width: 27px;
  height: 25px;
  right: 11px;
  cursor: pointer;
}

.help .boxes .search select {
  height: 46px;
  padding: 10px 7px;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  color: $color-5;
}

.help .card {
  border: none;
}

// .help .card-body {
//   padding: 0;
// }

// .help .card-body p {
//   font-weight: 400;
//   font-size: 15px;
//   line-height: 22px;
//   color: $color-5;
//   margin-top: 13px;
//   padding: 0;
//   margin-bottom: 0;
// }

.help .bottom ul {
  display: flex;
  flex-direction: row;
  gap: 23px;
}

.help .bottom ul li {
  font-weight: 600;
  font-size: 19px;
  line-height: 24px;
  color: $color-3;
  cursor: pointer;
}

.help h5.accordion-header {
  margin-top: 20px;
}
.help .accordion-body {
  padding-top: 5px;
}

.faq-detail {
  // .content * {
  //   all: unset; /* Resets all styles */
  //   display: revert; /* Restores the default browser styles */
  // }
  .content ul {
    list-style-type: disc; /* Example */
    margin: 1rem !important;
    padding-left: 1.5rem !important;
  }

  .content li {
    margin-bottom: 0.5rem !important;
  }
}
@media (max-width: 767px) {
  .help h5.accordion-header .accordion-button {
    font-size: 20px !important;
    padding: 0;
  }
  .help .accordion-body {
    padding-left: 0;
    padding-right: 0;
    margin-top: 2px;
  }
  .help .accordion-item .accordion-item .accordion-body {
    padding-left: 30px;
  }
  .help div.accordion-header .accordion-button {
    font-size: 18px !important;
    padding: 8px 0;
  }
  .under-prize .accordion-button:after {
    background-size: 14px;
    background-position-y: 6px;
  }
  .help .wrapper p {
    font-size: 14px;
    padding-right: 10px;
  }
  .help .wrapper .heading h3 {
    font-size: 18px;
  }
  .help .wrapper .feedBack {
    padding: 10px;
  }
  .help .main-box {
    gap: 12px;
    padding: 0 10px;
    .box {
      width: 47%;
    }
  }
  .select-device {
    flex-wrap: wrap;
    .radio-row {
      display: inline-block;
      vertical-align: top;
      margin-bottom: 3px;
      label {
        margin-right: 15px;
      }
      input[type="radio"] {
        margin-left: 0 !important;
      }
    }
  }
}

@media (max-width: 500px) {
  .help .boxes .search select {
    width: 100% !important;
  }
  .help .boxes {
    justify-content: center;
  }
  .help .bg {
    padding-bottom: 60px;
  }
}
