.join-now-section {
  background: linear-gradient(135deg, #5b2e92 0%, #29409c 50.49%, #0f91d2 100%);
  padding: 34px 26px 50px;
  position: relative;
  // z-index: 1;
  overflow: hidden;
  margin-top: 35px;
  .join-now-outer {
    display: flex;
    justify-content: space-between;
  }
  h2 {
    color: #fff;
    font-size: 36px;
    font-weight: 600;
    line-height: 50px;
    margin-bottom: 25px;
  }
  p {
    color: #fff;
    font-size: 15px;
    font-weight: 400;
    line-height: 22.5px;
  }
  &::before {
    content: "";
    background: url("../../../../assets/img/radiousBg.png") 0 0 no-repeat;
    background-size: 100%;
    width: 156px;
    height: 1566px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
  .join-now-btn {
    display: inline-flex;
    padding: 8.5px 30px;
    justify-content: center;
    align-items: center;
    color: #01052d;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 22.5px;
    background: #fff;
    height: 100%;
    cursor: pointer;
    margin-top: 30px;
    img {
      margin-left: 10px;
    }
  }

  @media screen and (max-width: 767px) {
    h2 {
      font-size: 26px;
      line-height: 34px;
    }
    .join-now-outer {
      flex-direction: column;
    }
    .join-now-btn {
      margin-top: 30px;
    }
  }
}
