.recent-job-search-card-layout {
  .job-heading-box {
    margin-bottom: 30px;
  }
  .job-heading {
    color: #01052D;
    font-size: 19px;
    font-weight: 600;
    line-height: 24px;
  }

  .job-profile {
    color: #676A79;
    font-size: 15px;
    font-weight: 600;
    line-height: 22.5px;
  }
  .location {
    color: #676A79;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin-top: 3px;
  }
  .cross-btn {
    color: rgba(103, 106, 121, 1);
    font-size: 16px;
  }
  .new {
    color: #008C39;
    font-size: 15px;
    font-weight: 400;
    line-height: 22.5px;
    position: relative;
    display: inline-block;
    &::before {
      content: "";
      background: rgb(103, 106, 121);
      width: 5px;
      height: 5px;
      border-radius: 50px;
      display: inline-block;
      vertical-align: middle;
      margin: 0 5px;
    }
  }
  .clear {
    color: #0432A3;
    font-size: 15px;
    font-weight: 400;
    line-height: 22.5px;
  }
  .main-area{
    max-height: 280px;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }
    &::-webkit-scrollbar-thumb {
      background-color: #363b99;
      border-radius: 10px;
    }
  }
  .card-list {
    padding-bottom: 10px;
    border-bottom: 1px #D1DFFF solid;
    margin-top: 20px;
  }
  .view-more-btn {
    margin-top: 10px;
    text-align: center;
    a {
      color: #0432A3;
      font-size: 15px;
      font-weight: 600;
      line-height: 22.5px;
    }
  }

//for new design
/* Styles for the switch */
.switch {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 20px;
  margin-left: 10px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 20px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #008C39;
}

input:checked + .slider:before {
  transform: translateX(14px);
}

/* Optional: Adjust font and alignment */
.job-heading-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.job-heading-box p {
  margin: 0;
  padding: 0;
}




}


.recent-job-search-card-layoutNew::-webkit-scrollbar {
  width: 10px;
}
.recent-job-search-card-layoutNew::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
.recent-job-search-card-layoutNew::-webkit-scrollbar-thumb {
  background-color: #363b99;
  border-radius: 10px;
}