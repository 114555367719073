@import "../../../../assets/scss/mixin.scss";
@import "../../../../assets/scss/variable.scss";

.message-box-main {
  background: $white;
  color: #01052d;
  box-shadow: 0px 2px 4px rgba(171, 190, 209, 0.6);
  border-radius: 5px;

  .profile-img {
    @include profile-40;
    position: relative;

    .status-indicator {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      outline: 2.5px solid $white;
      background-color: $red;
    }
    .status-indicator.offline {
      background-color: $orange;
    }
    .status-indicator.online {
      background-color: $green;
    }
  }

  .details {
    word-break: break-all;
    .peoples {
      background: $primary;
      padding: 5px 10px;
      color: $white;
      border-radius: 25px;
      @include font-14;
      cursor: pointer;
      .close {
        margin-left: 10px;
      }
    }
    h5 {
      @include font-19;
      margin: 0;
      @media (max-width: 767px) {
        font-size: 14px;
      }
    }

    p {
      @include para;
      @media (max-width: 767px) {
        font-size: 12px;
      }
    }
    .online {
      color: #008c39;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
    .away-message {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
    }
  }

  .icon-call {
    background: url(../../../../assets/img/call-grey.svg) no-repeat center;
    display: inline-block;
    width: 20px;
    height: 20px;
    cursor: pointer;
  }

  .icon-video {
    background: url(../../../../assets/img/video-grey.svg) no-repeat center;
    display: inline-block;
    width: 30px;
    height: 20px;
    cursor: pointer;
  }

  .dot {
    display: inline-block;
    color: $grey;
    position: relative;
    cursor: pointer;

    .drops-list {
      position: absolute;
      right: 0;
      z-index: 1;
      background: $white;
      box-shadow: 0px 2px 4px rgba(171, 190, 209, 0.6);
      padding: 10px;
      width: max-content;
      margin-top: 21px;

      ul {
        li {
          line-height: 40px;
        }
      }
    }
  }

  .chatting {
    position: relative;
    height: 80vh;
    .messages-list {
      height: 65vh;
      overflow-y: auto;
      overflow-x: hidden;
      .system-message{
        display: flex;
        justify-content: center;
        margin-top: 10px;
        p{
        padding: 10px;
    background: #eaf0ff;
    color: #70737d;
    width: fit-content;
    // border-radius: 50px;
      }}
      .left-side {
        width: calc(100px + 146px);

        .msg-sender {
          display: flex;
          gap: 5px;
          align-items: center;
          width: 80%;
          img {
            width: 24px;
            height: 24px;
            border-radius: 50%;
            margin: 5px;
          }
          h5 {
            font-size: 15px;
          }
          h6 {
            color: #536985;
            font-size: 10px;
            font-weight: 400;
          }
        }

        .box {
          display: flex;
          gap: 7px;
          align-items: center;

          .dot {
            font-size: 10px;
            position: relative;
            cursor: pointer;

            &:hover {
              .dropdown {
                display: block;
              }
            }

            .dropdown {
              display: none;
              position: absolute;
              top: -12px;
              left: 0px;
              background: $white;
              padding: 10px;
              box-shadow: 0px 2px 4px rgba(171, 190, 209, 0.6);
              z-index: 99;

              // display: none;
              ul {
                @include font-14;
                line-height: 36px;
                cursor: pointer;
              }
            }
          }

          .msg {
            background: $primary;
            // padding: 2px 18px;
            color: $white;
            @include font-14;
            line-height: 25px;
            width: 223.23px;
            word-wrap: break-word;
            // border-radius: 15px;
            position: relative;
            white-space: pre-wrap;

            // bubble right msg
            --r: 25px;
            --t: 10px;
            padding: calc(var(--r) / 2);
            -webkit-mask: radial-gradient(
                  var(--t) at var(--_d) 0,
                  #0000 98%,
                  #000 102%
                )
                var(--_d) 100% / calc(100% - var(--r)) var(--t) no-repeat,
              conic-gradient(at var(--r) var(--r), #000 75%, #0000 0)
                calc(var(--r) / -2) calc(var(--r) / -2) padding-box,
              radial-gradient(50% 50%, #000 98%, #0000 101%) 0 0 / var(--r)
                var(--r) space padding-box;
            --_d: 0%;
            border-left: var(--t) solid #0000;
            place-self: start;

            // &::after {
            //   content: "";
            //   background: url(../../../../assets/img/left-shape.png) no-repeat;
            //   width: 22px;
            //   height: 17px;
            //   display: inline-block;
            //   position: absolute;
            //   bottom: 0;
            //   left: -9px;
            //   background-size: 22px;
            // }
          }
        }

        .time {
          text-align: right;
          color: $grey;
          @include font-14;
          font-size: 10px;
          margin-right: 27px;
          margin-top: 2px;
        }
      }

      .right-side {
        margin-left: auto;
        width: fit-content;
        width: calc(100px + 146px);

        .box {
          display: flex;
          gap: 7px;
          align-items: center;

          .dot {
            font-size: 10px;
            position: relative;
            cursor: pointer;

            &:hover {
              .dropdown {
                display: block;
              }
            }

            .dropdown {
              display: none;
              position: absolute;
              right: 0;
              background: $white;
              padding: 10px;
              box-shadow: 0px 2px 4px rgba(171, 190, 209, 0.6);
              z-index: 2;

              ul {
                @include font-14;
                line-height: 36px;
                cursor: pointer;
              }
            }
          }

          .msg {
            background: $transparent-blue;
            // padding: 2px 18px;
            color: $grey;
            @include font-14;
            line-height: 25px;
            width: 223.23px;
            word-wrap: break-word;
            // border-radius: 15px;
            position: relative;
            white-space: pre-wrap;

            // bubble right msg
            --r: 25px;
            --t: 10px;
            padding: calc(var(--r) / 2);
            -webkit-mask: radial-gradient(
                  var(--t) at var(--_d) 0,
                  #0000 98%,
                  #000 102%
                )
                var(--_d) 100% / calc(100% - var(--r)) var(--t) no-repeat,
              conic-gradient(at var(--r) var(--r), #000 75%, #0000 0)
                calc(var(--r) / -2) calc(var(--r) / -2) padding-box,
              radial-gradient(50% 50%, #000 98%, #0000 101%) 0 0 / var(--r)
                var(--r) space padding-box;
            --_d: 100%;
            border-right: var(--t) solid #0000;
            place-self: end;

            &::after {
              content: "";
              background: url(../../../../assets/img/sentIcon.png) no-repeat
                center/cover;
              width: 12px;
              height: 10px;
              margin: 5px;
              display: inline-block;
              position: absolute;
              bottom: 0px;
              right: 0px;
            }

            &.delivered::after {
              width: 18px;
              background: url(../../../../assets/img/deliveredIcon.png)
                no-repeat center/cover;
            }

            &.displayed::after {
              width: 18px;
              background: url(../../../../assets/img/displayedIcon.png)
                no-repeat center/cover;
            }

            .tick {
              position: absolute;
              right: 10px;
              bottom: 6px;
              z-index: 9;

              &::before {
                content: "";
                display: block;
                width: 6px;
                height: 13px;
                border: solid $grey;
                border-width: 0 2px 2px 0;
                transform: rotate(45deg);
              }
            }

            .active-tick {
              position: absolute;
              right: 15px;
              bottom: 6px;
              z-index: 9;

              &::before {
                content: "";
                display: block;
                width: 6px;
                height: 13px;
                border: solid $primary;
                border-width: 0 2px 2px 0;
                transform: rotate(45deg);
                position: absolute;
                bottom: 0px;
                right: -6px;
              }

              &::after {
                content: "";
                display: block;
                width: 6px;
                height: 13px;
                border: solid $primary;
                border-width: 0 2px 2px 0;
                transform: rotate(45deg);
              }
            }
          }
        }

        .time {
          text-align: left;
          color: $grey;
          @include font-14;
          font-size: 10px;
          margin-left: 27px;
          margin-top: 2px;

          span {
            font-style: italic;
          }
        }
      }
    }
    .addemoji {
      height: 40vh;
      width: 100%;
      .EmojiPickerReact {
        .epr-category-nav {
          padding-top: 0px;
        }
      }
      @media (max-width: 991px) {
        height: 40vh;
      }
    }

    .write {
      // margin-top: 45px;
      position: absolute;
      bottom: 10px;

      .image {
        @include image-icon;
        background-size: 18px;
      }

      .attach {
        @include attach-icon;
        background-size: 18px;
      }

      .icon-video {
        background-size: 18px;
      }

      .gif {
        @include gif-icon;
        background-size: 18px;
      }

      .mic {
        @include mic-icon;
      }

      .stop-mic {
        @include stop-mic-icon;
      }

      .send-icon {
        @include send-icon;
      }

      .bottom-dropdown {
        position: relative;
        .dot {
          transform: rotate(90deg);
          font-size: 10px;
        }
        .drops-list {
          position: absolute;
          bottom: 30px;
          right: -5px;
          z-index: 1;
          background: #ffffff;
          box-shadow: 0px 2px 4px 1px rgba(171, 190, 209, 0.6);
          padding: 10px;
          width: max-content;

          ul {
            li {
              line-height: 40px;
              display: flex;
              align-items: start;
              gap: 10px;
              padding: 10px;
              input[type="radio"] {
                width: 22px;
                height: 22px;
                // margin: 5px 10px;
              }
              label {
                color: #676a79;
                h5 {
                  @include font-600;
                }
                p {
                  @include font-14;
                  margin-top: 5px;
                }
              }
            }
          }
        }
      }

      .reply {
        background: $light-grey;
        border-radius: 25px;
        // padding: 5px 5px 5px 20px;

        input,
        textarea {
          border: none;
          background: transparent;
          max-height: 100px;
        }
      }
    }
  }

  &.new-message {
    .chatting {
      height: 70vh;
      // .messages-list {
      //   height: 55vh;
      // }

      // .write {
      //   margin-top: 75px;
      // }
    }
  }
}
