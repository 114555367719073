// @import "../../assets/scss/mixin.scss";
@import "../../assets/scss/variable.scss";

.notification-main-div {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
 img{
  width: 48px;
  height: 50px;
  border-radius: 50%;
 }
}

@media (max-width:  767px) {
  .notification-main-div {
    img{
     width: 35px;
     height: 37px;
    }

   }
}



.notificationMenuDropdown {
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 5px 24px rgba(0, 0, 0, 0.1);
  padding: 17px 30px;
  position: absolute;
  z-index: 1;
  /* right: -4px; */
  top: 27px;
  right: 0;
}

.notification-dropdown-span {
  padding: 4px 0px;
  font-weight: 500;
  display: flex;
  gap: 12px;
  align-items: center;
  cursor:pointer;
}

.notification-dropdown-span span:hover {
  color: blue;
}

.notificationListMenuDropdown {
  background: #fff;
    border-radius: 5px;
    box-shadow: 0 5px 24px rgba(0, 0, 0, 0.1);
    padding: 17px 30px;
    position: absolute;
    z-index: 1;
    top: 35px;
    right: 23px;
}


.option-custom{
  padding-left: 10px;
  font-size: 15px;
}

.truncate-notification{
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 140px;
}