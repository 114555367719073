@import "../../../assets/scss/mixin.scss";
@import "../../../assets/scss/variable.scss";


.dashboard .center .msg .msg-box-create {
    .hashTagBox {
        h2 {
            font-weight: 600;
            color:$dark-black;

        }

        p {
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            color: $grey;
        }

        .btn {
            padding: 5px 25px;
        }
    }
}
