.webViewCommunity {


    .community .bg {
        // url(../img/about.jpg)

        @media screen and (max-width:1080px) {
            background-image: url(../../assets/img/community.png) !important;
            background-color: #0432A3 !important;
            background-size: 100px !important;
            background-repeat: no-repeat !important;
            background-position-x: 90% !important;
            background-position-y: 20% !important;
            min-height: 300px !important;
        }

        @media (max-width: 760px) {
            background-image: none !important;
        }

    }

    .mobile-res {
        @media (max-width: 760px) {
            font-size: revert !important;
            line-height: normal;
        }
    }

    .contextCommunityWebView {
        padding: 50px 0px;

        // background: url(../../assets/img/bgimage.jpg);
    }
}