
.hiring-pop-up-default {
    background: rgba(0, 0, 0, 0.25);
    padding-top: 10%;
    padding-bottom: 30px;
    @media (max-width:767px) {
        padding-left: 15px;
        padding-right: 15px;
    }
    .hiring-pop-up-default-inner {
        background-color: #fff;
        max-width: 575px;
        width: 100%;
        margin: auto;
        border-radius: 5px;
        padding: 20px;
    }
    .white-btn {
        border-radius: 5px;
        background: #fff;
        color: #0432A3;
        padding: 8px 30px;
        display: inline-block;
        border: 1px #0432A3 solid;
        cursor: pointer;
    }
    .blue-btn {
        border-radius: 5px;
        background: #0432A3;
        color: #fff;
        padding: 8px 30px;
        display: inline-block;
        cursor: pointer;
        outline: 0;
        border: 1px transparent solid;
    }
}

.jobs-posted-modal {
    h3 {
        color: #01052D;
        font-family: Inter;
        font-size: 15px;
        font-style: normal;
        font-weight: 600;
        line-height: 22.5px;
        @media (max-width:767px) {
            font-size: 13px;
        }
        .name {
            margin: 0 10px;
            @media (max-width:767px) {
                margin: 0 5px;
            }
        }
    }
    .jobs-posted-head {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px #D1DFFF solid;
        padding-bottom: 10px;
        margin-top: 10px;
        margin-bottom: 30px;
        .left {
            color: #676A79;
            font-family: Inter;
            font-size: 15px;
            font-style: normal;
            font-weight: 600;
            line-height: 22.5px;
            @media (max-width:767px) {
                font-size: 12px;
            }
        }
        .right {
            color: #0432A3;
            font-family: Inter;
            font-size: 15px;
            font-style: normal;
            font-weight: 600;
            line-height: 22.5px;
            cursor: pointer;
            @media (max-width:767px) {
                font-size: 12px;
            }
        }
    }
    .job-posted-outer {
        border-bottom: 1px #D1DFFF solid;
        padding-bottom: 30px;
        margin-bottom: 20px;
    }
    .job-posted-list {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        h4 {
            color: #000;
            font-family: Inter;
            font-size: 15px;
            font-style: normal;
            font-weight: 600;
            line-height: 22.5px;
            margin-bottom: 5px;
            @media (max-width:767px) {
                font-size: 12px;
                line-height: 16px;
            }
        }
        .org-name {
            color: #717171;
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            position: relative;
            @media (max-width:767px) {
                font-size: 12px;
                line-height: 14px;
            }
            .location {
                display: inline-block;
                @media (max-width:767px) {
                    padding-right: 5px;
                }
                &::before {
                    content: '';
                    width: 4px;
                    height: 4px;
                    background-color: #676A79;
                    display: inline-block;
                    vertical-align: middle;
                    margin: 0 5px;
                    border-radius: 50%;
                }
            }
        }
        .left-list {
            display: flex;
            align-items: center;
            // flex-basis: 60%;
            img {
                margin-right: 15px;
                @media (max-width:767px) {
                    max-width: 40px;
                }
            }
        }
        .right-list {
            align-self: end;
            color: #717171;
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            @media (max-width:767px) {
                font-size: 12px;
            }
        }
    }
    .bottom-text {
        h4 {
            color: #01052D;
            font-family: Inter;
            font-size: 15px;
            font-style: normal;
            font-weight: 600;
            line-height: 22.5px;
            @media (max-width:767px) {
                font-size: 14px;
            }
        }
        p {
            color: #676A79;
            font-family: Inter;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: 22.5px;
            margin-top: 10px;
            @media (max-width:767px) {
                font-size: 14px;
            }
        }
        .message {
            color: #0432A3;
            font-family: Inter;
            font-size: 15px;
            font-style: normal;
            font-weight: 600;
            line-height: 22.5px;
            margin-top: 10px;
            cursor: pointer;
        }
    }
    #scrollableDiv {
        height: 300px;
        overflow: auto;
    
        &::-webkit-scrollbar {
          width: 5px;
        }
    
        &::-webkit-scrollbar-track {
          box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        }
    
        &::-webkit-scrollbar-thumb {
          background-color: #363b99;
          border-radius: 10px;
        }
      }
}

.jobs-messages-modal {
    .hiring-pop-up-default-inner {
        max-width: 1175px;
    }
    h3 {
        color: #01052D;
        font-family: Inter;
        font-size: 30px;
        font-style: normal;
        font-weight: 600;
        line-height: 44px;
        @media (max-width:767px) {
            font-size: 22px;
            line-height: 28px;
        }
    }
    p {
        color: #676A79;
        font-family: Inter;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 22.5px;
        margin-top: 20px;
        @media (max-width:767px) {
            font-size: 14px;
            line-height: 18px;
        }
    }
    h4 {
        color: #01052D;
        font-family: Inter;
        font-size: 19px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        @media (max-width:767px) {
            font-size: 14px;
            line-height: 20px;
        }
    }
    .title-head {
        display: flex;
        justify-content: space-between;
        margin-top: 30px;
        margin-bottom: 20px;
        @media (max-width:767px) {
            margin-top: 20px;
            margin-bottom: 10px;
        }
    }
    .edit,.add {
        color: #0432A3;
        text-align: right;
        font-family: Inter;
        font-size: 19px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        cursor: pointer;
        @media (max-width:767px) {
            font-size: 12px;
        }
    }
    textarea {
        width: 100%;
        border-radius: 5px;
        border: 1px solid #D1DFFF;
        height: 169px;
        @media (max-width:767px) {
            height: 100px;
        }
    }
    .blue-btn-outer {
        text-align: right;
        margin-top: 20px;
        .white-btn {
            margin-right: 20px;
        }
    }

   
}
.job_loc::after{
    content: '';
    display: inline-block;
    width: 10px; 
    height: 10px;
    background-color: black; 
    border-radius: 70%; 
    margin-left: 5px;
}
.modal-size-sm{
   max-width: 600px !important;
}