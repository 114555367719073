@import "../../../assets/scss/variable.scss";
@import "../../../assets/scss/mixin.scss";

.talent-dashboard {
  background-color: $light-blue;
  min-height: 100vh;
  display: flex;
  .left-sidebar {
    background-color: $white;
    height: 100%;
    .tp-logo {
      // padding-top: 30px;
      // margin-left: 75px;
      // margin-bottom: 30px;
      display: flex;
      justify-content: center;
      img {
        max-height: 37px;
        max-width: 143px;
        width: 100%;
        object-fit: cover;
      }
    }
    .left-items {
      display: flex;
      align-items: center;
      margin-left: 30%;
      h4 {
        @include font-19;
        cursor: pointer;
      }
    }
    .nav {
      --bs-nav-link-color: none !important;
      --bs-nav-link-hover-color: none !important;
      .nav-item {
        margin-top: 5px;
        height: 100px !important;
        display: flex;
        align-items: center;
        .menu-icon {
          margin-right: 20px;
          height: 24px;
          width: 24px;
        }
      }
    }
    .nav > .active {
      background-color: $light-blue !important;
      color: $orange !important;
      border-left: 0 !important;
    }
  }
  .noti {
    margin-left: auto;
    display: flex;
    .notification {
      width: 30px;
      height: 30px;
      // margin-top: 30px;
      background-color: rgba(217, 83, 79, 0.15);
      border-radius: 50%;
      img {
        margin-top: 5px;
        margin-left: 5px;
      }
    }
    .search {
      position: relative;
      // margin-top: 30px;
      margin-left: 10px;
      &::before {
        background: url("../../../assets//img/gg_search.svg") no-repeat;
        content: "";
        cursor: pointer;
        height: 22px;
        // right: 232px;
        // top: 56px;
        left: 8px;
        top: 8px;
        position: absolute;
        width: 22px;
        z-index: 5;
      }
      input {
        background: $light-blue;
        border: 1px solid $portalborder;
        padding: 5px 10px 5px 40px;
        width: 100%;
        height: 37px;
      }
    }
  }
  .homepage {
    .card {
      margin-top: 20px;
      height: 140px;
      .card-header {
        border: none;
        background: none;
        h5 {
          margin-top: 10px;
        }
      }
      .card-body {
        // padding-left: 30px;
        background-color: rgba(217, 83, 79, 0.15);
        // border: none;
      }
    }
    .post-new-job {
      .card {
        background-color: $scout !important;
        cursor: pointer;
        .card-body {
          padding: 0px !important;

          .plus {
            height: 40px;
            width: 40px;
            background-color: $white;
            border-radius: 50%;
            margin-top: 45px;
            .plus-icon {
              margin-top: 7px;
              margin-left: 8px;
            }
          }
          h5 {
            margin-top: 50px !important;
          }
        }
      }
    }
    .job-position {
      h5 {
        font-weight: 600 !important;
        font-size: 15px !important;
        color: $parafont !important;
        margin-left: 5rem;
      }
    }
    .job-position-desc {
      // .position-header {
      //   display: flex;
      //   justify-content: space-between;
      //   align-items: center;
      //   width: 100%;
      //   padding: 0px 55px 0px 0px;
      .accordion-button {
        text-align: center !important;
        background-color: inherit;
        color: inherit;
      }
      .bg-red {
        // background-color: rgba(217, 83, 79, 0.15);
        border-radius: 50%;
        width: 50px;
        height: 50px;
        margin-right: 15px;
      }
      .bg-red.job-icon {
        img {
          // width: 100%;
          // height: 100%;
        }
      }
      // img {
      //   margin-top: -4px;
      //   margin-left: 12px;
      // }
      .vacant-no {
        padding-right: 50px;
      }
      .close {
        box-shadow: 0px 2px 4px rgb(171 190 209 / 60%);
        border-radius: 30px;
        padding: 5px 10px;
        display: flex;
        gap: 5px;
        align-items: center;
        width: 113px;
        img {
          margin-top: 2px;
          margin-left: 0px;
        }
        // }
      }
      .jop-applicatns {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 0px 0px 0px 65px;

        .profile {
          border-radius: 50%;
          width: 50px;
          height: 50px;
          margin-right: 15px;
        }
        .b-white.action {
          background-color: $white;
          box-shadow: 0px 2px 4px rgb(171 190 209 / 60%);
          border-radius: 50%;
          // width: 32px;
          // height: 32px;
          margin-right: 15px;
          padding: 5px;
          cursor: pointer;
          // img {
          //   margin-left: 8px;
          //   margin-top: 9px;
          // }
        }
      }
    }
  }
  .settingpage {
    .card-talent {
      .card-body {
        padding: 30px 80px 30px 80px !important;
      }
    }
    .card-active {
      border: 1px solid $orange !important;
    }
    .card {
      margin-top: 20px;
      height: 140px;
      .card-header {
        border: none;
        background: none;
        h5 {
          margin-top: 10px;
        }
      }
      .card-body {
        padding: 30px 60px 30px 60px;
        text-align: center;
      }
    }
    .post-new-job {
      .card {
        background-color: $scout !important;
        cursor: pointer;
        .card-body {
          padding: 0px !important;

          .plus {
            height: 40px;
            width: 40px;
            background-color: $white;
            border-radius: 50%;
            margin-top: 45px;
            .plus-icon {
              margin-top: 7px;
              // margin-left: 8px;
            }
          }
          h5 {
            margin-top: 50px !important;
          }
        }
      }
    }
    .seeting-job.job-messages {
      // margin-top: 30px;
      // padding: 30px;

      h4 {
        color: black;
        font-weight: 600;
        font-size: 19px;
        line-height: 24px;
        margin-bottom: 20px;
      }
      .edit {
        color: $orange;
        cursor: pointer;
        float: right;
      }
      .job-msg {
        p {
          padding: 10px;
        }
      }

      .job-message-buttons {
        margin-top: 25px;
        display: flex;
        justify-content: flex-end;
        gap: 20px;
      }
    }
    .setting-talent {
      // margin-top: 30px;
      // padding: 30px;
      h4 {
        color: black;
        font-weight: 600;
        font-size: 19px;
        line-height: 24px;
        margin-bottom: 20px;
      }
      .talent-url-input {
        display: flex;
        gap: 20px;
        margin-top: 30px;
        small {
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
        }
        .talent-url {
          width: 100%;
        }
        img {
          height: 30px;
          width: 30px;
          padding-bottom: 5px;
        }
        .available {
          color: $green;
        }
        .unavailable {
          color: $red;
        }
        .btn {
          line-height: 25px;
          height: 35px;
          padding: 5px 30px !important;
        }
      }
    }
  }
}

.tp-serch {
  .autocomplete-wrapper .dropdown {
    padding: 10px;
    text-align: left;
    max-height: 300px;
    width: 250px;
    overflow: hidden;
    overflow-y: auto;
    position: absolute;
    z-index: 1;
    background-color: #ffffff;
    border: 1px solid #d1dfff;
    border-radius: 4px;
    // box-shadow: 10px 10px 5px lightblue;
  }
  .autocomplete-wrapper .item {
    display: block;
    cursor: pointer;
    font-size: 14px;
    padding: 0.375rem 0.75rem;
    word-break: break-all;
  }

  .autocomplete-wrapper .item .name-heading-drop {
    line-height: 20px;
    color: $dark-black;
    font-size: 15px;
    font-weight: 500;
  }

  .autocomplete-wrapper .item .sub-heading-drop {
    font-weight: 400;
    color: $grey;
    font-size: 10px;
    margin-top: 0px;
    // line-height: 5px;
  }

  .autocomplete-wrapper .item.selected-item {
    background-color: $light-blue-grey;
    color: $dark-black;
  }
  .autocomplete-wrapper .item:hover {
    background-color: $light-blue-grey;
    color: $dark-black;
  }
}

.post-job-modal {
  // .modal-header{
  //   border: none !important;
  //   padding: 20px 30px !important;
  //   align-items: baseline;
  //   h5{
  //     p{
  //       font-size: 15px!important;
  //       font-weight: 400!important;
  //       line-height: 22px!important;
  //     }
  //   }
  // }
  .modal-header {
    // background: $linear;
    .modal-title {
      // color: $white;
      padding: 0 10px;
      color: #01052d;
      .sub-title {
        color: #676a79;
      }
    }
    .btn-close {
      // color: $white;
      // background: url(../../../assets/img/X.svg) no-repeat center;
      opacity: 5;
    }
  }
  .para-text {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 10px;
    font-size: 15px;
    font-weight: 500;
  }
  .modal-body form label {
    font-size: 16px !important;
    font-weight: 500 !important;
    margin-bottom: 10px;
  }
  .startedbutton {
    display: grid;
    margin-top: 40px;
    margin-bottom: 10px;
  }
}

.add-job-detail {
  .modal-header {
    height: 80px !important;
  }
  .bg-white {
    border-radius: 5px;
  }

  .heading {
    @include font-19;
  }

  .sub-heading {
    @include small;
    font-weight: 600;
    color: $dark-black;
    margin-bottom: 10px;
  }

  label {
    color: $dark-black;
  }

  .screening-box {
    margin: 16px;
    min-height: 140px;
    border: 1px solid $border;
    border-radius: 5px;

    .box-bar {
      min-height: 40px;
      background-color: rgba(238, 240, 242, 0.35);
    }
  }

  .sub-content {
    @include small;
    color: $grey;
  }

  .job-editor {
    padding-left: 16px;
    padding-right: 16px;

    .rdw-editor-toolbar {
      margin-bottom: 8px;
    }

    .editor-class {
      border-radius: 0px;
    }
  }

  .preview {
    color: $orange;
  }
  .receipientInput {
    margin-top: 15px;
    border: 1px solid $orange;
    border-radius: 40px;
    color: $orange;
    font-size: 15px;
    font-weight: 400;
    line-height: 22px;
    padding: 4px 10px;
    max-width: 200px;
    min-width: 60px;
    width: auto;
    height: 33px;
  }
  .selectRecptRow {
    align-items: center;
    background-color: $orange;
    color: $white !important;
  }
  .add-skills {
    background: #fff;
    border: 1px solid $orange;
    border-radius: 40px;
    color: $orange;
    display: flex;
    font-size: 15px;
    font-weight: 400;
    justify-content: space-between;
    line-height: 22px;
    margin-top: 15px;
    // max-width: 85%;
    min-width: 60px;
    padding: 4px 10px;
    margin-right: 10px;
    height: 33px;
    cursor: pointer;
    .plus {
      width: 15px;
      height: 15px;
      margin-top: 4px;
      margin-left: 4px;
    }
  }
}
@media screen and (max-width: 768px) {
  .talent-dashboard .noti {
    display: flex;
    justify-content: flex-start !important;
  }
  .talent-dashboard .noti .search {
    width: 100%;
  }
  //   .talent-dashboard .left-sidebar .left-items{
  //     margin-left: 10px;
  //   }
  //   .talent-dashboard .left-sidebar .nav .nav-item .menu-icon {
  //     margin-right: 8px;

  // }
  .post-job-modal {
    .para-text {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}
@media screen and (max-width: 500px) {
  .tp__name {
    max-width: 200px !important;
  }
}

@media (max-width: 1023px) {
  .talent-dashboard .left-sidebar .left-items {
    margin-left: 10px;
  }
  .talent-dashboard .left-sidebar .nav .nav-item .menu-icon {
    margin-right: 8px;
  }
  .talent-dashboard .homepage .job-position-desc .jop-applicatns {
    .gap-5 {
      gap: 0 !important;
    }
  }
}
