@import "../../../assets/scss/variable.scss";

.login-portal {
  display: flex;
  height: 100vh;
  overflow: hidden;

  .left {
    background: #0432a312;
    height: 100%;
    display: grid;
    align-items: center;
    .center {
      display: flex;
      margin: auto;
      width: 80%;
      position: relative;
    }
  }
  .forgot-left {
    background: #0432a312;
    // height: 100vh;
    display: grid;
    align-items: center;
    .center {
      display: flex;
      margin: auto;
      width: 80%;
      position: relative;
    }
  }
  .logo {
    position: absolute;
    bottom: 5%;
    left: 62%;
  }
}

.welcome {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  .sub-heading {
    h4 {
      font-weight: 600;
      font-size: 19px;
      line-height: 24px;
      color: $primary;
      cursor: pointer;
    }
  }
}
.login-modal {
  .portal-login-form{
  
    input[type=checkbox]:checked {
      background-color: #f68a18;
      border: none;
  }
  h3{
    color: black;
  }
  .form-check-label{
    font-size: 17px;
  }
  }
  .login-form {
    .login-checkbox {
      align-items: flex-start;
      color: $grey;
      display: flex;
      font-size: 13.25px;
      font-weight: 400;
      justify-content: space-between;
      line-height: 20px;
      margin-bottom: 30px;
      margin-top: 30px;
      span {
        cursor: pointer;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: $parafont;
      }
    }
  }
}
.forgot-modal {
  .modal-header {
    border: none;
    padding-bottom: 0px;
    padding: 20px 30px 0px 30px;
    h5 {
      font-size: 22px;
    }
  }
  .modal-body {
    padding-top: 15px;
    .btn {
      margin-top: 30px;
    }
  }
}

@media screen and (max-width: 767px) {
  .login-portal {
    height: auto;
    flex-direction: column;
    .left,
    .right,
    .col-8 {
      width: 100%;
    }
    .right {
      display: block;
      padding: 0;
      .login-form {
        display: block;
      }
    }
    .auth-form {
      float: none;
      width: auto;
      img.mb-3 {
        width: 100%;
      }
    }
    .welcome {
      position: relative;
      top: 0;
      left: 0;
      width: 100%;
      padding: 20px 20px;
    }
    .logo {
      text-align: center;
      position: relative;
      bottom: 0;
      left: 0;
    }
  }
}

// @media screen and (max-width:'720px') {
//   .login-portal {
//     .forgot-left {
//       height: 50%;
//     }
//   }
// }

