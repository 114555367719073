.job-prefrence-modal {
    // position: absolute;
    // top: 100%;
    // left: 0;
    // transform: translateY(-50%);
    // width: 100%;
    // border-radius: 5px;
    // background: #fff;
    // width: 100%;
    // max-width: 574px;
    // margin: auto;
    // box-shadow: 1px 1px 10px #c7c7c7;
    // position: relative;
    // button {
    //   position: absolute;
    //   top: 10px;
    //   right: 20px;
    //   background: transparent;
    //   font-size: 24px;
    // }
    // .modal-content1 {
    //   border-radius: 5px;
    //   background: #FFF;
    //   width: 100%;
    //   max-width: 574px;
    //   margin: auto;
    //   padding: 30px;
    //   box-shadow: 1px 1px 10px #c7c7c7;
    //   position: relative;
    // }
    h3 {
      color: #01052d;
      font-family: Inter;
      font-size: 19px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      // border-bottom: 1px #d1dfff solid;
      // padding-bottom: 20px;
      
    }
    label {
      color: #676a79 !important;
      font-family: Inter !important;
      font-size: 15px !important;
      font-style: normal !important;
      font-weight: 600 !important;
      line-height: 22.5px !important;
    }
    input {
      border-radius: 4px;
      border: 1px solid #d1dfff;
      background: #fff;
      padding: 10px;
      width: 100%;
    }
    label {
      color: #676a79;
      font-family: Inter;
      font-size: 15px;
      font-style: normal;
      font-weight: 600;
      line-height: 22.5px;
      display: block;
      margin-bottom: 10px;
    }
    .input-box {
      margin-top: 20px;
    }
    .radio-box {
      margin-top: 20px;
      .title {
        color: #676a79;
        font-family: Inter;
        font-size: 15px;
        font-style: normal;
        font-weight: 600;
        line-height: 22.5px;
        display: block;
        margin-bottom: 10px;
      }
      // .input-box {
      //   margin-top: 20px;
      // }
      .radio-box {
        // margin-top: 20px;
        // .title {
        //   color: #676a79;
        //   font-family: Inter;
        //   font-size: 15px;
        //   font-style: normal;
        //   font-weight: 600;
        //   line-height: 22.5px;
        //   display: block;
        //   margin-bottom: 15px;
        // }
        input{
          width: auto;
          margin-right: 10px;
        }
        label{
          display: inline-block;
          margin-right: 20px;
          vertical-align: middle;
          // margin-top: 3px;
        }
      }
    }
    .checkbox-box {
      input {
        display: none;
      }
      label {
        padding: 0px 10px;
        color: #0432A3 !important;
        display: inline-block;
        // padding: 5px 10px;
        border-radius: 20px;
        border: 1px solid #0432a3;
        margin-right: 10px;
        cursor: pointer;
        &::after {
          content: "";
          clear: both;
          display: inline-block;
          background: url("../../assets/img/plusIconBlue.png") 0 3px no-repeat;
          background-size: 100%;
          width: 15px;
          height: 15px;
          margin-left: 10px;
        }
      }
      input[type="checkbox"]:checked + label {
        background: #0432a3;
        color: #fff !important;
        &::after {
          background: url("../../assets/img/checkIconBlue.png") 0 3px no-repeat;
        }
      }
    }
    .submit-btn-outer {
      text-align: center;
    }
    .submit-btn {
      border-radius: 5px;
      background: #0432a3;
      padding: 8px 30px;
      display: inline-block;
      color: #fff;
      font-family: Inter;
      font-size: 15px;
      font-style: normal;
      font-weight: 600;
      line-height: 22.5px;
      margin-top: 40px;
      width: auto;
    }
  }
  
