$primary: #0432a3;
$grey: #676a79;
$linear: linear-gradient(120.5deg, #5b2e92 0%, #29409c 50.49%, #0f91d2 100%);
$transparent-linear: linear-gradient(
  120.5deg,
  rgba(91, 46, 146, 0.1) 0%,
  rgba(41, 64, 156, 0.1) 50.49%,
  rgba(15, 145, 210, 0.1) 100%
);
$white: #ffffff;
$blue: #19bcf4;
$transparent-blue: #eaf0ff;
$black: #000;
$pink: #8430c8;
$transparent-black: rgba(0, 0, 0, 0.5);
$light-grey: rgba(238, 240, 242, 0.35);
$border: #d1dfff;
$placeholder: #d4d4d8;
$transparent-grey: rgba(238, 240, 242, 0.35);
$orange: #f68a18;
$dark-black: #01052d;
$green: #149d52;
$bsgreen: rgba(0, 140, 57, 1);
$red: #d9534f;
$light-blue-grey: #b0c4de;
$bsred: rgba(217, 83, 79, 1);
$headfont: rgba(1, 5, 45, 1);
$otherfont: rgba(24, 24, 24, 1);
$portalborder: rgba(209, 223, 255, 1);
$parafont: rgba(103, 106, 121, 1);
$light-blue: rgba(248, 250, 255, 1);
$starter-color: #eff2f6;
$starter: rgba(239, 242, 246, 1);
$scout: rgba(246, 138, 24, 1);
