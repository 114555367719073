@import "../../../assets/scss/mixin.scss";
@import "src/assets/styles/colors";

.tags-input-container {
  // border: 2px solid #000;
  // padding: .5em;
  // border-radius: 3px;
  // width: min(80vw, 600px);
  // margin-top: 1em;
  // margin-top: 1.5rem;
  // border: 1px solid #D1DFFF;
  // border-radius: 0.375rem;
  // display: flex;
  // align-items: center;
  // flex-wrap: wrap;
  // gap: .5em;

  input::placeholder {
    color: $color-3;
    background-color: $color-6;
    border-radius: 20px;
    padding: 3px;
    width: 50px;
  }

  input:focus::placeholder {
    color: transparent;
    background-color: transparent;
    width: 80px;
  }
}

.tag-item {
  display: inline-block;
  padding: 3px 10px;
  border-radius: 20px;
  background-color: $color-3;
  color: #ffffff;
  font-size: 14px;
}
.tag-item .close {
  height: 14px;
  width: 14px;
  background-color: $color-1;
  padding-bottom: 3px;
  color: $color-3;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-left: 0.5em;
  font-size: 18px;
  cursor: pointer;
}

.tags-input {
  // flex-grow: 1;
  padding: 0.5em 0;
  border: none;
  outline: none;
}

.autocomplete-wrapper1 {
  position: relative;
  width: 100%;
}

.autocomplete-wrapper1 input {
  // margin-top: 1.5rem;
  border: none;
  width: 100%;
  padding: 0.375rem 0.75rem;
  border-radius: 0.375rem;
}
// .autocomplete-wrapper input:focus {
//   border-color: #0F67FF;
//   box-shadow: none;
//   outline: none;
// }
.autocomplete-wrapper1 .dropdown {
  width: 100%;
  padding: 0;
  text-align: left;
  max-height: 150px;
  overflow: hidden;
  overflow-y: auto;
  position: absolute;
  z-index: 1;
  background-color: #ffffff;
  border: 1px solid #d1dfff;
  border-radius: 4px;
  // box-shadow: 10px 10px 5px lightblue;
}
.autocomplete-wrapper1 .item {
  display: block;
  cursor: pointer;
  font-size: 14px;
  padding: 0.375rem 0.75rem;
}
.autocomplete-wrapper1 .item.selected-item {
  background-color: $color-3;
  color: #fafbfc;
}
.autocomplete-wrapper1 .item:hover {
  background-color: $color-3;
  color: #fafbfc;
}
