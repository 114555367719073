@import "../../assets/scss/mixin.scss";
@import "../../assets/scss/variable.scss";
.intro {
  h1 {
    font-weight: 600;
    font-size: 30px;
    color: $dark-black;
    @media (max-width:767px) {
      font-size: 26px;
      line-height: 32px;
      margin-bottom: 10px;
    }
  }
  p {
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    color: #676a79;
  }
  .addButton:hover{
    cursor: pointer;
        }
  form {
    .form-select {
      background-image: url(../../assets/img/edit.svg);
      background-size: auto;
      cursor: pointer;
  }
    width: 555px;
    margin: auto;
    label {
      font-weight: 600;
      font-size: 15px;
      line-height: 22px;
      color: #01052d;
      margin-bottom: 15px;
      display: block;
    }

    span {
      font-weight: 600;
      font-size: 15px;
      line-height: 22px;
      display: flex;
      align-items: center;
      color: #0432a3;
      margin: 10px 0px;
      cursor: pointer;
      display: inline-block;
    }
    .headline {
      input {
        position: relative;
        border: 1px solid #d1dfff;
        border-radius: 4px;
        width: 100%;
        height: 40px;
        padding: 20px;
      }
    }
   
    .banner {
      background: $linear;
      padding:0;
      border-radius: 5px;
      position: relative;
      height: 140px;
      margin-bottom: 20px;
      @media (max-width:767px) {
        margin-bottom: 60px;
      }
      .edit {
        position: absolute;
        top: 4px;
        right: 6px;
        cursor: pointer;
      }
      .user-image {
        padding: 1.8rem 1.8rem 1.8rem 1rem;
      }
    }
    .profiles {
      margin-bottom: 55px;
      .img-circle {
        position: relative;
        width: 100px;
        .img-profile {
          background: #eef0f2;
          height: 100px;
          width: 100px;
          border-radius: 50%;
          object-fit: cover;
        }
        .edit {
          position: absolute;
          bottom: 5px;
          right: 5px;
          filter: drop-shadow(0px 6px 12px rgba(171, 190, 209, 0.3));
          cursor: pointer;
        }
      }
    }
    .exper {
      .box {
        display: flex;
        background: rgba(238, 240, 242, 0.35);
        border-radius: 4px;
     //   height: 40px;
        align-items: center;
        position: relative;
        margin: 5px 0px;
        .text {
          // .experienceClass{
            padding: 6px 36px 6px 12px;
          //   width: 40%;
          //   white-space: nowrap;
          //   display: -webkit-box;
          //   overflow: hidden;
          // }
          span {
            font-weight: 400;
            font-size: 15px;
            line-height: 22px;
            color: #676a79;
            display: inline;
            word-break: break-word;
          }
        }
        .right {
          position: absolute;
          right: 15px;
          cursor: pointer;
        }
      }
      .check {
        display: flex;
        gap: 10px;
        input {
          @include tick;
        }
        label {
          font-weight: 400;
          font-size: 15px;
          line-height: 22px;
          align-items: center;
          color: #676a79;
          display: inline;
        }
      }
    }
    .buttons {
      display: flex;
      justify-content: space-between;
      align-items: center;
      a {
        font-weight: 600;
        font-size: 15px;
        line-height: 22px;
        color: #0432a3;
      }
      .blue {
        background: #0432a3;
        border-radius: 5px;
        color: white;
        padding: 10px 25px;
      }
    }
  }
}

@media (max-width:991px) {
  .intro {
    form {
      width: auto;
    }
  }
}
