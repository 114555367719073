@import "src/assets/styles/colors";

.signup .text-center p {
  font-size: 14px;
  margin-top: 10px;
  font-weight: 500;
}

.signup .text-center span {
  color: $color-3;
}

.signup .text-center .font-700 {
  font-weight: 700;
}

.signup {
  .login-info {
    color: #01052d;
    text-align: right;
    font-size: 16px;
    font-family: Inter;
    font-weight: 500;
    line-height: 20px;
  }
}

@media (max-width: 991px) {
  .banner {
    .signup {
      width: fit-content;
    }
  }
}
