@import "../../../../assets/scss/mixin.scss";
@import "../../../../assets/scss/variable.scss";

// .topDiv .modal_head{
//     padding: 16px 30px !important;
// }

.selectDiv small {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: $grey;
}

.buttonCol {
  display: flex;
  flex-direction: row;
  gap: 15%;
}

.buttonRow {
  justify-content: end;
  padding: 20px;
  width: 90%;
}

.btn.disabled,
.btn:disabled,
fieldset:disabled .btn {
  color: $white;
  pointer-events: none;
  background-color: $primary;
  border-color: $primary;
  opacity: 0.2;
}

.selectedReceipientSearched {
  position: relative;
  min-height: 100%;
}

.searchedName {
  overflow: hidden;
}

// .reactionNavLink {
//   width: 4.8rem;
//   text-align: center;
// }

.selectDiv h5 {
  cursor: pointer;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  margin: 0;
  color: $grey;
}

.topDiv .modal-body {
  padding: 20px 30px;
}

.selectReceipentBody {
  height: 600px;
  overflow: scroll;
  overflow-x: hidden;
}

.selectDiv {
  margin-bottom: 20px;
}

.receipientInput {
  margin-bottom: 5px;
  color: $grey;
}

.receipientInput ::placeholder {
  color: $grey;
}
.selectRecpt {
  .selectRecptRow {
    --bs-gutter-x: 0 !important;
  }
}

.selectedReceipient {
  display: flex;
  justify-content: space-between;
  background: #ffffff;
  border: 1px solid #0432a3;
  border-radius: 40px;
  padding: 2px 6px;
  max-width: 85%;
  line-height: 22px;
  font-weight: 400;
  font-size: 15px;
  margin-top: 5px;
  align-items: center;
}

.selectedReceipient p {
  color: $primary;
}

.contain {
  background: rgba(238, 240, 242, 0.35);
  padding: 10px;
  margin-left: 10px;
}

.listImg {
  height: 40px;
  width: 40px;
  border-radius: 50%;
}

.pollModal label {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  color: $grey !important;
  margin-bottom: 10px;
}

.pollParaTag {
  text-align: end !important;
  color: red;
  font-size: 12px;
  align-self: self-end;
  cursor: pointer;
}

// .nav-item{
//     padding: 0 2.5% !important;
// }

.viewReactionRow {
}

.pollModal h5 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  color: #0432a3 !important;
  margin-bottom: 20px;
}

.navLinks {
  --bs-nav-link-padding-x: 1.9rem !important;
}

.pollModal select {
  margin-bottom: 10px;
}

.pollModal .paraClass {
  text-align: right;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #676a79;
  // margin-bottom: 20px;
  .textFeildP {
    margin: 0 0 15px 0;
  }
}

@media (max-width: 600px) {
  .buttonRow {
    justify-content: flex-start !important;
  }
}

// @media (max-width: 425px) {
//   .reactionNavLink {
//     width: 3rem !important;
//     text-align: center;
//   }
// }
