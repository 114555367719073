@import "../../../../../assets/scss/mixin.scss";
@import "../../../../../assets/scss/variable.scss";

.verification {
  .text-box {
    border: 1px solid #d1dfff;
    border-radius: 5px;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 14px;
    .link {
      @include small;
    }
    .status {
      font-family: Inter;
      font-size: 15px;
      font-weight: 400;
      line-height: 23px;
      letter-spacing: 0em;
    }
    .avilable {
      @include small;
      color: #008c39;
      font-size: 10px;
      cursor: pointer;
    }
    .unAvilable {
      @include small;
      color: #b62727;
      font-size: 10px;
      cursor: pointer;
    }
  }
  .verify {
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 23px;
    letter-spacing: 0.0168em;
    text-align: right;
    color: $primary;
    right: 50px;
    margin-right: 20px;
    cursor: pointer;
  }
  .badgesBtn {
    padding: 8.5px 30px;
  }
}

.verify-popup {
  .modal-header {
    height: 90px;
    border: none;
    padding-left: 30px;
    h5 {
      font-family: "Inter";
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 138%;
      color: #000;
      @media (max-width: 767px) {
        font-size: 16px;
        line-height: 100%;
      }
    }
    p {
      @include para;
      font-size: 14px !important;
    }
  }
}

.varificationInfo {
  border-top: 1px solid #f0f0f0;
  padding: 20px;
  margin-block: 20px 0px;
  text-align: center;
  .recommendations {
    margin-bottom: 10px;
    .btn {
      cursor: auto;
      padding: 4px 10px;
      font-size: 12px;
      &.btn-outline-success {
        border-color: #008c39;
        color: #008c39;
      }
      &:hover {
        background-color: transparent;
      }
    }
  }
  .subHeading {
    color: $dark-black;
    margin-bottom: 10px;
  }
  p {
    @include para;
    margin-bottom: 10px;
  }
}

.verificationStepBox {
  width: 100%;
  max-width: 415px;
  margin-inline: auto;
  margin-block: 15px 0px;
  border: 2px solid #f0f0f0;
  @include borderRadius(8px);
  padding: 12px 35px;
  .verificationStep {
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    align-items: center;
    .verificationStepIcon {
      margin-bottom: 10px;
    }
    .verificationStepText {
      h6 {
        font-size: 10px;
        line-height: 2;
        font-weight: 400;
      }
    }
  }
}
