@import "../../assets/scss/variable.scss";
.my-jobs-list-section {
    border-radius: 5px;
    background: #FFF;
    box-shadow: 0px 2px 4px 0px rgba(171, 190, 209, 0.60);
    padding: 30px;
    svg {
        path {
            fill: rgba(103, 106, 121, 1);
        }
        margin-right: 15px;
    }
    .active {
        svg {
            path {
                fill: rgba(4, 50, 163, 1);
            }
        }
        .title, .number {
            color: #0432A3;
        }
    }
    .hidden {
        svg {
            path {
                fill: rgba(103, 106, 121, 0.3);
            }
        }
        .title, .number{
            color: rgb(103 106 121 / 30%);
        }
    }
    li {
        margin-top: 30px;
        &:first-child {
            margin-top: 0;
        }
        .title, .number {
            color: #676A79;
            font-size: 15px;
            font-style: normal;
            font-weight: 600;
            line-height: 22.5px;
        }
    }
    .job-list {
        display: flex;
        justify-content: space-between;
        cursor: pointer;
    }
}

.my-jobs-section {
    border-radius: 5px;
    background: #FFF;
    box-shadow: 0px 2px 4px 0px rgba(171, 190, 209, 0.60);
    padding: 30px;
    .head {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
    }
    select {
        flex-basis: 20%;
        border: 0;
        padding: 0;
        color: #676A79;
        text-align: center;
        font-size: 15px;
        font-weight: 600;
        line-height: 22.5px;
    }
    h4 {
        margin-bottom: 20px;
        color: #01052D;
        font-size: 19px;
        font-weight: 600;
        line-height: 24px;
    }
    .nav {
        border-bottom: 1px #D1DFFF solid;
    }
    li {
        margin-right: 18px;
        &:last-child{
            margin-right: 0;
        }
    }
    .nav-link {
        color: #676A79;
        font-size: 15px;
        font-weight: 600;
        line-height: 22.5px;
        border: 0;
        outline: 0;
        padding: 0 0 10px;
        cursor: pointer;
        &.active {
            color: #0432A3;
            font-size: 15px;
            font-weight: 600;
            line-height: 22.5px;
            border-radius: 2px;
            border-bottom: 3px #0432A3 solid;        
        }
    }
    .my-job-nav-list {
        margin-top: 30px;
        display: flex;
        justify-content: space-between;
        .profile-detail-outer {
            display: flex;
        }
        .profile-detail {
            margin-left: 22px;
        }
        h5 {
            color: #000;
            font-size: 15px;
            font-weight: 600;
            line-height: 22.5px;
            cursor: pointer;
        }
        h6 {
            color: #717171;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
        }
        .close {
            display: grid;
            justify-items: flex-end;
            padding-bottom: 8px;
            cursor: pointer;
            img {
                filter: brightness(0);
            }
            .time {
                align-self: flex-end;
                color: #717171;
                font-size: 10px;
                font-weight: 400;
                line-height: 20px;
            }
        }
        .create{
            color: $primary;
        }
    }

    .subTabs{
        display: flex;
        justify-content: space-around;
    }

}

.see-more-featured{
    padding: 15px 0px 0px 15px;
    min-height: 55px;
    color: $primary;
    font-size: 15px;
    font-weight: 600;
    background-color: white;
    border-radius: 0px 0px 5px 5px;
    cursor: pointer;
  }
@media(max-width:767px){
    .my-jobs-list-section{
        padding:15px;
    }
    .my-jobs-section{
        padding:15px;
        .my-job-nav-list .profile-detail-outer {
            img{
                min-width:50px;
            }
        }
        .head {
            select {
                flex-basis: 100px;
            }
        }
    }
}