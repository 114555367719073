.create_order {
    header{
    height: 150px;
    width: 100%;
    background:  url("../../../assets/img/backgroundHeader.svg");
    background-size: cover;
}
select {
    display: flex;
    flex-direction:column;
    justify-content: flex-end;
    align-items: center;
    border: 1px solid #d1dfff;
}
}
.stepper{
    background : #676A7933;
    border-radius: 50px;
}
.package_form{
    background: #F8F8FA;
    ul{
    list-style-type: disc;
}

.form-control{
  &.main_control{
    background-color: transparent !important;
    border: none;
    padding: 0 !important;
  }
}
.form-input{
  .form-control{
    padding: .375rem .75rem !important;
  }
}
.image-upload{
  border : 1px dashed #D1DFFF;
}
.auto_input{
  div{
    width: 100% !important;
  }
  input{
    width: 100% !important;
    padding: .375rem .75rem !important;
    border: 1px solid #d1dfff;
    border-radius: 4px;

  }
}
.additional_name{
    background: #0432A31A;
    color :#0432A3;
    width : fit-content;
    border-radius: 5px;
    cursor: pointer;
}
.switch-box {
    position: relative;
    display: inline-block;
    max-width: 27px;
    height: 17px;
    flex-basis: 5%;
    input {
      opacity: 0;
      width: 0;
      height: 0;
      
      &:checked ~ .slider {
        // background-color: #0432a3;
        &:before {
          transform: translateX(12px);
        }
      }
    }
    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #ffffff;
      border: 1px solid #676a79;
      transition: 0.4s;
      &.round {
        border-radius: 34px;
        background-color: rgba(4, 50, 163, 0.2);
        border: 0;
      }
      &.round:before {
        border-radius: 50%;
        background-color:#0432A3;
      }
      &:before {
        position: absolute;
    content: "";
    height: 12px;
    width: 12px;
    left: 0;
    bottom: 2px;
    background-color: #676a79;
    transition: 0.4s;
      }
    }
  }

  .authorization_input{
    .form-control{
      background-color: transparent !important;
    }
  }
}

.background-drag-and-drop{
  .dropzone{
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}

.packageForm{
  &.round{
    padding: 5px 14px !important;
  }
  &.round:hover{
    padding: 5px 14px !important;
  }
}