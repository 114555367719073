.modal-view-statistics-title {
    background: linear-gradient(120.5deg, #5B2E92 0%, #29409C 50.49%, #0F91D2 100%);
    color: #FFF;
}

.model-view-statistics-body-title {
    font-size: 15px;
    font-weight: 600;
    line-height: 22px;
    color: #676A79;
}

.tab-list-item {
    font-size: 15px;
    font-weight: 400;
    line-height: 22px;
    cursor: pointer;
}
.active-tab {
    border-bottom: 3px solid #0432A3;
    padding-bottom: 8px;
}

.text-primary {
    color: #0432A3;
}

.text-secondary {
    color: #676A79;
    padding-bottom: 8px;
}

.tab-border {
    border-bottom: 1px solid #D1DFFF;
}
