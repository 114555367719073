@import "../../assets/scss/mixin.scss";
@import "../../assets/scss/variable.scss";

.error {
    p {
        @include para
    }
    .go{
        text-decoration: underline !important;
    }

}