@import "../../../assets/scss/mixin.scss";
@import "../../../assets/scss/variable.scss";

.unitUserImg {
    width: 50px;
    height: 50px;
    border-radius: 50px;
    object-fit: cover;
    cursor: pointer;
}

.borderSolidBlack {
    border: 1px solid black;
}

.unitUserRow {
    margin-top: 20px !important;
    padding: 0px 10px;
    gap: 20px;
}

.unitUserCol {
    width: 12% !important;
}

.unitUserName {
    font-family: 'Inter';
    font-style: normal;
    font-size: 14px !important;
    word-break: break-all;
    cursor: pointer;
    // font-weight: 600;
    // line-height: 22px;
    // color: $black;
}

.unitUserSub {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 13.25px;
    line-height: 20px;
    color: $grey;
}

@media (max-width: 500px) {
    .unitUserImg {
        width: 30px;
        height: 30px;
    }
    .sm-sx-p-0{
        padding: 0;
    }
}