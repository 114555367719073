@import "src/assets/styles/colors";
@import "../../assets/scss/mixin.scss";
@import "../../assets/scss/variable.scss";

.directory .browse ul {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  margin-top: 15px;
}

.directory .community ul {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

.directory h1 {
  font-weight: 600;
  font-size: 30px;
  line-height: 44px;
  color: $color-2;
  @media (max-width: 767px) {
    font-size: 24px;
    line-height: 32px;
  }
}

.directory p {
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  color: $color-5;
  margin-bottom: 38px;
}

.directory {
  .list {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    .list-name {
      font-weight: 400;
      font-size: 15px;
      line-height: 22px;
      color: $primary;
      flex: 0 0 23%;
      cursor: pointer;
    }
  }

  .browse ul li {
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;
    color: $color-2;
    cursor: pointer;
  }
  .browse ul li :hover {
    text-decoration: underline;
  }

  .search input {
    width: 100%;
    height: 40px;
    background: rgb(238 240 242 / 35%);
    border: 1px solid $color-6;
    border-radius: 5px;
    padding: 18px 55px 18px 14px;
  }
  .search {
    position: relative;
  }

  .search-img {
    background: url("../../assets/img/Search.svg") no-repeat;
    position: absolute;
    top: 8px;
    display: inline-block;
    width: 27px;
    height: 25px;
    right: 5px;
    cursor: pointer;
  }

  .browse h3 {
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    color: #01052d;
  }
  .community ul li {
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    color: $color-3;
    flex: 0 0 23%;
  }
}

.for-login-signup {
  #collapsibleNavbar {
    flex-basis: 70%;
    display: block;
    .navbar-nav {
      align-items: flex-end;
    }
  }
}

.company-details {
  .wrapper {
    background: $white;
    border-radius: 4.88927px;
    // margin-top: 26px;

    .nav-tabs {
      border: none;
      .nav-insight-item {
        padding: 0 30px 0 30px !important;
      }
      .nav-item {
        padding: 0 28px 0 0px;

        .nav-link {
          border: none;
          padding: 0 0 9px;
          @include font-600;
          color: $grey;
        }

        .active {
          border-bottom: 4px solid $primary;
          color: $primary;
          // border-radius: 4px;
        }
      }
    }
    .btn {
      @media (max-width: 767px) {
        padding: 10px 0px;
      }
    }

    .big-img {
      height: 213px;
      background: $linear;
      border-radius: 4.88927px;
      overflow: hidden;
      position: relative;
      .edit {
        position: absolute;
        right: 20px;
        top: 10px;
      }
      .edit:hover {
        cursor: pointer;
      }
    }

    .img-cover {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }

    .left {
      .profile-details {
        .img {
          @include profile-100;
          //position: relative;
          top: -15px;
          .edit {
            position: absolute;
            right: 5px;
            bottom: 0px;
          }
          .edit:hover {
            cursor: pointer;
          }
        }

        .details {
          word-break: break-word;
          width: 53%;
          display: grid;
          @media (max-width: 767px) {
            width: 100%;
            padding-right: 20px;
          }
          h3 {
            @media (max-width: 767px) {
              font-size: 20px;
              line-height: 26px;
            }
          }
          h1 {
            margin-bottom: 20px;
            @include font-600;
            font-size: 30px;
            line-height: 30px;
          }

          p {
            @include font-600;
            color: $primary;
          }

          small {
            @include font-14;
            color: $grey;
          }
        }

        .list {
          margin-top: 23px;

          ul {
            display: flex;
            gap: 50px;
            flex-wrap: wrap;

            li {
              @include font-600;
              color: $grey;
              cursor: pointer;
            }
          }
        }
      }
    }

    .right {
      position: relative;
      .btn-light {
        color: $primary;
      }
      .dropdown-toggle {
        &::after {
          margin-left: 10px;
        }
      }
      .dropdown-item {
        @include para;
        font-weight: 600;
        line-height: 33px;
        &:hover {
          background: transparent;
          cursor: pointer;
        }
      }
      .dropdown-menu {
        padding: 10px !important;
      }
      .list {
        display: flex;
        align-items: flex-end;
        // height: 97px;

        ul {
          display: flex;
          gap: 30px;

          li {
            @include font-600;
            color: $primary;
            cursor: pointer;
          }
        }
      }
    }
  }

  .middle {
    .b-5 {
      border-radius: 5px;
    }
    .thoughts {
      display: flex;
      gap: 23px;

      .pro-img {
        width: 35px;
        height: 35px;
        border-radius: 55%;

        img {
          width: 35px;
          height: 35px;
          border-radius: 55%;
        }
      }

      .type {
        width: 100%;

        input {
          border: none;
          border-bottom: 1px solid #d1dfff;
          border-radius: 0px;
          width: 100%;
        }
      }
    }

    .read {
      .pro-img {
        width: 100px;
        height: 35px;
        border-radius: 55%;

        img {
          width: 35px;
          height: 35px;
          border-radius: 55%;
        }
      }

      .msg-box {
        background: rgba(238, 240, 242, 0.35);
        border-radius: 0px 5px 5px 5px;
        position: relative;

        h5 {
          @include h1;
          font-size: 15px;
          line-height: 14px;
        }

        p {
          @include para;
          font-size: 14px;
        }

        span {
          color: #19bcf4;
          font-size: 10px;
          font-weight: 400;
          cursor: pointer;
        }

        .time {
          position: absolute;
          top: 22px;
          right: 15px;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: $grey;

          span {
            color: $grey;
            font-size: 14px;
          }
        }
      }

      .flex {
        justify-content: normal;

        span {
          font-size: 14px;
        }
      }
    }

    .about {
      h2 {
        @include font-19;
      }

      p {
        @include para;
        color: $grey;
        word-wrap: break-word;
      }

      .see {
        color: $primary;
        &:hover {
          cursor: pointer;
        }
      }
    }

    .msg-box {
      background: $white;
      border-radius: 5px;
      padding: 20px;

      .type {
        display: flex;
        gap: 20px;

        textarea {
          width: 100%;
          resize: none;
          border: none;

          :focus-visible {
            border: none;
          }
        }
      }

      .profile {
        width: 40px;
        height: 35px;
        background: $grey;
        border-radius: 50%;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          border-radius: 50%;
          width: 100%;
          height: 100%;
        }
      }

      .social {
        display: flex;
        gap: 13px;
        flex-wrap: wrap;

        .box {
          display: flex;
          gap: 10px;
          background: rgba(238, 240, 242, 0.35);
          border-radius: 5px;
          padding: 5px 12px;
          cursor: pointer;

          p {
            margin: 0;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: $grey;
          }
        }
      }
    }

    .section {
      gap: 10px;

      .img {
        @include profile-circle;
      }

      .info-details {
        width: 150px;

        h6 {
          @include font-600;
        }
      }

      .verify {
        @include verify-profile;
      }

      .post {
        p {
          @include font-14;
          color: $grey;
        }
      }
    }

    .profile-details {
      .img {
        @include profile-48;
      }

      .verify {
        @include verify-profile-big;
      }

      .hiring {
        @include hiring;
      }
      .seeking {
        @include seeking;
      }

      .info-details {
        width: 160px;

        h6 {
          @include font-600;
        }

        p {
          @include para;
          color: $grey;
        }
      }

      .context {
        p {
          @include para;
          color: $grey;
        }

        .translate {
          margin-top: 10px;

          p {
            font-size: 10px;
            color: $black;
          }
        }
      }

      .box-img {
        height: 262px;
        position: relative;
        .others {
          display: flex;
          gap: 10px;
          align-items: center;
          position: absolute;
          bottom: 10px;
          left: 10px;
          background: $white;
          border-radius: 25px;
          padding: 5px 10px;
          .hand {
            width: 15px;
            height: 15px;
          }
        }
      }
      .like {
        span {
          small {
            margin-left: 8px;
            @include font-14;
            font-size: 10px;
            color: $primary;
          }
        }
      }
      .comment {
        span {
          @include font-14;
          color: $grey;
        }
      }
      .share {
        span {
          @include font-14;
          color: $grey;
        }
      }
    }
    .people-admin {
      .thoughts {
        align-items: flex-end;
        gap: 15px;
      }
      .read {
        .pro-img {
          width: 35px;
        }
        .msg-box {
          position: relative;
          padding-right: 45px;
          h6 {
            @include font-600;
          }
          p {
            @include para;
          }
          .hour {
            @include font-14;
            color: $grey;
            position: absolute;
            right: 15px;
            top: 15px;
          }
        }
        .likes {
          span {
            @include para;
            margin-right: 20px;
          }
        }
      }
    }
  }

  .brief {
    .website {
     
      color: $primary;
      cursor: pointer;
    }
    .website :hover {
      text-decoration: underline;
    }
  }
}

.talent_link{
  color : $primary;
  font-weight: 700;
}
