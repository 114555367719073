@import "../../assets/scss/mixin.scss";
@import "../../assets/scss/variable.scss";

.jobs {
  min-height: 100vh;
  // background-color: #e5e5e5;
  .left-sidebar {
    box-shadow: 0px 2px 4px 0px rgba(171, 190, 209, 0.6);
    border-radius: 5px;
    padding: 0;

    .leftTop-sidebar {
      padding: 10px 30px;
      // box-shadow: 0px 2px 4px 0px rgba(171, 190, 209, 0.6);
      // border-radius: 5px;
      position: relative;
      .top-buttons {
        margin-bottom: 15px;
        margin-top: 10px;
        padding-bottom: 13px;
        border-bottom: 0.1px solid $border;
        .box {
          position: absolute;
          right: 10px;
          top: 20px;
          background: #ffffff;
          border-radius: 5px;
          padding: 17px 20px;
          z-index: 9;
          box-shadow: 0 5px 24px rgb(0 0 0 / 10%);

          ul {
            li {
              @include para;
              display: flex;
              align-items: baseline;
              gap: 15px;
              line-height: 34px;
              cursor: pointer;
              align-items: center;
            }
          }
        }
      }

      .sub-text {
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        color: $grey;
        cursor: pointer;
      }
    }
    .job-recommend {
      color: #01052d;
      font-size: 19px;
      font-weight: 600;
      line-height: 24px;
      padding: 10px 30px;
    }
  }

  .main-content {
    border-radius: 5px;

    .main-header {
      @include font-19;
      color: $dark-black;
    }

    .sub-header {
      color: $grey;
    }

    .btn-job {
      position: relative;
      margin-top: 40px;
      right: 0px;
      float: right;
    }

    .job-profile-box {
      padding: 20px;
      margin-top: 40px;
      border: 1px solid $border;
      min-height: 110px;

      .btn {
        padding: 10px 20px;

        .icon-small {
          height: 14px;
          width: 14px;
        }
      }

      .user-post {
        display: flex;
        flex-direction: row;
        gap: 10px;

        .poster-name {
          @include small;
          color: $dark-black;
          font-weight: 600;
        }

        .designation-name {
          @include font-14;
        }

        .sub-title {
          font-size: 10px;
        }
      }

      .prof-img {
        width: 48px;
        height: 48px;
        border-radius: 55%;

        img {
          border-radius: 50%;
          width: 100%;
          height: 100%;
          box-shadow: 0 0 0px 2px #ffffff;
          cursor: pointer;
        }
      }
    }

    .job-about-comapny {
      padding: 20px;
      margin-top: 40px;
      border: 1px solid $border;
      min-height: 110px;
      .follow-btn {
        color: $primary;
      }
      .btn {
        padding: 10px 20px;

        .icon-small {
          height: 14px;
          width: 14px;
        }
      }

      .comapny-details {
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: space-between;

        .poster-name {
          @include small;
          color: $dark-black;
          font-weight: 600;
        }

        .designation-name {
          @include font-14;
        }

        .sub-title {
          font-size: 10px;
        }
      }

      .prof-img {
        width: 50px;
        height: 50px;
        border-radius: 55%;
        margin-right: 10px;

        img {
          border-radius: 50%;
          width: 100%;
          height: 100%;
          box-shadow: 0 0 0px 2px #ffffff;
          cursor: pointer;
        }
      }
    }

    .description {
      .sub-header {
        @include small;
        font-weight: 600;
      }

      .details-job {
        @include small;
        color: $grey;
      }
    }
  }

  .bottom-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
  }
  .job-helpful {
    border: 1px solid $border;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 10px;
    h5 {
      font-weight: 600;
      font-size: 16px;
      color: $parafont;
    }
  }
  .about-company {
    border: 1px solid $border;
  }

  .job-alert {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .search-value {
      font-size: 15px;
      font-weight: 400;
      color: $parafont;
    }
    .search-num {
      font-size: 10px;
      font-weight: 400;
      color: $parafont;
    }
    input[type="checkbox"]:checked {
      background-color: $bsgreen !important;
    }
    .form-check-input {
      cursor: pointer;
      height: 20px;
      width: 40px;
    }
  }

  .job-search-bar {
    border-radius: 5px;
    box-shadow: 0px 2px 4px 0px rgba(171, 190, 209, 0.6);
    margin: 0px 0px 20px 0px;
    padding: 10px;

    .filter-option {
      display: inline-flex;
      justify-content: space-between;
      /* align-content: center; */
      align-items: baseline;
      width: 100%;
      // padding-left: 30px;
      // padding-right: 30px;
      // border-radius: 5px;
      // box-shadow: 0px 2px 4px rgba(171, 190, 209, 0.6);
      // background: $white;

      .search {
        position: relative;
        &::before {
          content: "";
          background: url(../../assets/img/location-job.svg) no-repeat;
          position: absolute;
          top: 7px;
          left: -10px;
          width: 22px;
          height: 22px;
          z-index: 5;
          cursor: pointer;
        }
        &::after {
          content: "";
          background: none;
          position: absolute;
        }
        // input {
        //   background: rgba(238, 240, 242, 0.35);
        //   border: none;
        //   width: 100%;
        //   padding: 10px 10px 10px 40px;
        //   &::placeholder {
        //     color: $grey;
        //     @include para;
        //   }
        // }
      }

      ul {
        .active {
          .form-select {
            color: $black;
            &::after {
              background: black;
              // width: 1px;
              height: 100%;
              position: absolute;
              right: 0;
              top: 0;
            }
          }
        }
        .smart-apply {
          width: 12%;
          font-size: 14px;
          font-weight: 500;
          padding-top: 7px;
          .form-check-label {
            color: $grey !important;
          }
        }
        li {
          display: inline;
          input[type="number"]::-webkit-inner-spin-button,
          input[type="number"]::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
        }
        .upper {
          z-index: 99;
          position: relative;
        }
        .form-select {
          @include para;
          border: none;
          display: inline;
          position: relative;
          background-image: url(../../assets/img/drop-down.svg);
          background-size: auto;
          width: 100%;
          font-size: 14px;
          font-weight: 500;
        }
      }

      .location {
        .autocomplete-wrapper {
          .dropdown {
            width: 100% !important;
          }
        }
      }

      .autocomplete-wrapper {
        input {
          border: none;
          // background-image: url(../../../assets/img/drop-down.svg);
          // background-size: auto;
          // background-repeat: no-repeat;
          // background-position: right 0.75rem center;
          // max-width: 130px;
          align-items: center;
          display: flex;
          padding: 0 25px;
        }
      }

      .autocomplete-wrapper div {
        // width: 190px;
        z-index: 1;
      }
      .autocomplete-wrapper input {
        width: 100%;
        // padding: .375rem .75rem;
        top: 7px;
        left: -8px;
        // padding: 0px 1.5rem 0 1rem;
        border-bottom: 1px solid $border;
        padding-bottom: 10px;
        // min-width: 190px;
      }
      .autocomplete-wrapper input::placeholder {
        text-align: center;
      }
      // .autocomplete-wrapper input:focus {
      //   border-color: #0F67FF;
      //   box-shadow: none;
      //   outline: none;
      // }

      .autocomplete-wrapper .dropdown-location {
        width: 100% !important;
      }

      .autocomplete-wrapper .dropdown {
        width: 20%;
        padding: 0;
        text-align: left;
        max-height: 100px;
        overflow: hidden;
        overflow-y: auto;
        position: absolute;
        z-index: 1;
        background-color: #ffffff;
        border: 1px solid #d1dfff;
        border-radius: 4px;
        // box-shadow: 10px 10px 5px lightblue;
      }

      .autocomplete-wrapper .item {
        display: block;
        cursor: pointer;
        font-size: 14px;
        padding: 0.375rem 0.75rem;
      }

      .autocomplete-wrapper .item.selected-item {
        background-color: #0432a3;
        color: #fafbfc;
      }

      .autocomplete-wrapper .item:hover {
        background-color: #0432a3;
        color: #fafbfc;
      }
      // .autocomplete-wrapper .dropdown {
      //   width: 100%;
      //   padding: 0;
      //   text-align: left;
      //   max-height: 100px;
      //   overflow: hidden;
      //   overflow-y: auto;
      //   position: absolute;
      //   z-index: 15;
      //   background-color: #ffffff;
      //   border: 1px solid #d1dfff;
      //   border-radius: 4px;
      //   box-shadow: 10px 10px 5px lightblue;
      // }
      // .autocomplete-wrapper .item {
      //   display: block;
      //   cursor: pointer;
      //   font-size: 14px;
      //   padding: 0.375rem 0.75rem;
      // }
      // .autocomplete-wrapper .item.selected-item {
      //   background-color: #0432a3;
      //   color: #fafbfc;
      // }
      // .autocomplete-wrapper .item:hover {
      //   background-color: #0432a3;
      //   color: #fafbfc;
      //   width: 100%;
      // }
    }
  }
}

@media (max-width: 767px) {
  .jobs {
    .leftTop-sidebar {
      padding: 10px 10px;
      .sub-text {
        font-size: 12px;
      }
    }
    .main-content {
      .job-profile-box {
        .btn {
          padding: 10px 10px;
          font-size: 12px;
          margin-top: 20px;
        }
      }
      img {
        max-height: 50px;
      }
      .btn.btn-blue.ml-20 {
        margin-left: 10px;
      }
    }
    .left-sidebar {
      .top-buttons {
        padding: 0 10px 13px 10px !important;
        border-bottom: 10px solid #f8f8f8 !important;
        margin-bottom: 0 !important;
        .job-list-items {
          padding-right: 7px;
          .sub-text {
            font-size: 13px;
            margin-left: 5px;
          }
        }
      }
      .leftTop-sidebar .sub-text {
        font-size: 13px;
      }
      .recent-job-search-card-layout > .bg-white {
        padding: 0 10px !important;
      }
      .job-recommend {
        padding: 10px 10px;
        width: 100%;
        display: block;
        border-top: 10px solid #f8f8f8;
        font-size: 18px;
      }
      .job-card {
        padding-bottom: 25px !important;
      }
    }
    .recent-job-search-card-layout .job-heading {
      font-size: 18px;
    }
    .jobs-container {
      margin-top: 0 !important;
    }
    .jobs-right {
      padding: 0;
      .main-content {
        padding: 1rem !important;
        .job-profile-box {
          margin-top: 20px;
        }
      }
    }
    .bottom-card .smart-button {
      min-width: 80px;
      align-items: center;
      justify-content: flex-end;
    }
    .main-content .main-header {
      font-size: 16px;
    }
  }
}
@media (max-width: 359px) {
  .jobs {
    .left-sidebar {
      .top-buttons {
        .job-list-items {
          padding-right: 7px;
          .sub-text {
            font-size: 11px;
            line-height: 18px;
          }
        }
      }
      .leftTop-sidebar .sub-text {
        font-size: 11px;
        line-height: 18px;
      }
    }
  }
}
@media (min-width: 768px) {
  .col-md-2 {
    flex: 0 0 auto;
    width: 13.666667%;
  }
  .col-md-1 {
    flex: 0 0 auto;
    width: 10.666667%;
  }
}
