@import "../../../assets/scss/mixin.scss";
@import "../../../assets/scss/variable.scss";

.share-user-post {
  .modal-header {
    background: $linear;
    position: relative;
    .modal-title {
      color: $white;
      .icon-expand {
        position: absolute;
        right: 50px;
        top: 19px;
      }
    }
    .btn-close {
      color: $white;
      background: url(../../../assets/img/X.svg) center no-repeat;
      opacity: 5;
    }
  }
  span {
    margin: 0px;
    font-size: 15px;
    color: #01052d;
  }
  textarea {
    resize: none;
  }

  .react-autocomplete-input {
    margin-top: 18px;
  }

  .react-autocomplete-input > li.active {
    background-color: $primary;
    color: #fff;
  }
  .title {
    @include font-600;
    color: $primary;
    font-size: 18.75px;
  }

  .shareRepost {
    border: 1px solid $border;
    border-radius: 5px;
    padding: 20px;

    .main-Post-div {
      display: flex;
      flex-direction: row;
      gap: 10px;
      margin-bottom: 20px;

      .prof-img {
        width: 40px;
        height: 40px;
        border-radius: 55%;
        box-shadow: 0 0 0px 4px #008c39;

        img {
          border-radius: 50%;
          width: 100%;
          height: 100%;
          box-shadow: 0 0 0px 2px #ffffff;
          cursor: pointer;
        }
      }

      .details {
        p {
          font-weight: 400;
          font-size: 15px;
          line-height: 22px;
          color: #676a79;
          font-size: 10px;
          margin: 0px;
          line-height: 14px;
        }

        span {
          font-weight: 400;
          font-size: 15px;
          line-height: 22px;
          color: #676a79;
          font-size: 10px;
          margin: 0px;
          line-height: 14px;
        }

        h4 {
          font-weight: 600;
          font-size: 30px;
          line-height: 44px;
          color: #01052d;
          font-size: 15px;
          line-height: 18px;
          margin: 0px;
          overflow: hidden;
          max-width: 10rem;
          cursor: pointer;
        }

        .userNameTextWrap {
          max-height: 3em;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          width: 100%;
        }
      }
    }

    .descriptionOverFlowSpan {
      font-weight: 400;
      font-size: 15px;
      line-height: 22px;
      color: $grey;
      white-space: pre-line;
    }
  }
  .box {
    background: $transparent-blue;
    border-radius: 5px;
    overflow: hidden;
    .boxe-img {
      height: 228px;
      background: $linear;
    }
    .text {
      h6 {
        @include font-600;
        color: $grey;
        word-break: break-all;
      }
      p {
        @include font-14;
        color: $grey;
      }
      span {
        @include font-14;
        font-size: 10px;
        color: $grey;
        display: block;
      }
      .btn-blue {
        background: #008c39;
        padding: 5px 15px;
      }
    }
    .btn-light {
      border: 1px solid $primary;
      color: $primary;
    }
  }
  .read {
    .pro-img {
      @include profile-circle;
      flex: 0 0 9%;
      margin-right: 15px;
    }
    .likes {
      @include para;
      @include center-text;
    }
    .msg-box {
      background: $light-grey;
      padding: 20px;
      position: relative;
      border-radius: 0px 5px 5px 5px;
      .hour {
        position: absolute;
        top: 18px;
        right: 22px;
        @include para;
      }
      .translate {
        color: $blue;
        font-size: 10px;
        margin-top: 10px;
      }
    }
  }
  .thoughts {
    @include center-text;
    .pro-img {
      @include profile-circle;
    }
    .type {
      flex: 0 0 80%;
    }
  }
  .reaction {
    h6 {
      color: $black;
      @include font-600;
      margin: 0;
    }
    p {
      @include para;
    }
    ul {
      li {
        @include font-600;
        color: $primary;
        span {
          margin-left: 10px;
        }
      }
    }
  }
  .box-img {
    position: relative;
    margin-top: 20px;
    .click-anywhere {
      display: flex;
      justify-content: center;
      color: white;
      top: 10px;
    }
    .right-side {
      position: absolute;
      right: 10px;
      top: 10px;
      .tag {
        display: inline-block;
        background: $transparent-black;
        border-radius: 14px;
        color: $white;
        padding: 5px 15px;
        @include small;
        font-size: 10px;
        cursor: pointer;
      }
      .close {
        display: inline-block;
        background: $transparent-black;
        border-radius: 14px;
        color: $white;
        padding: 5px 12px;
        @include small;
        font-size: 10px;
        cursor: pointer;
        margin-left: 5px;
      }
    }
    .left-side {
      position: absolute;
      left: 30px;
      top: 20px;
    }
  }
  .dots {
    position: relative;
    height: 28px;
    .drop-down {
      position: absolute;
      background: $white;
      @include small;
      color: $grey;
      border-radius: 5px;
      padding: 10px 23px;
      top: 30px;
      box-shadow: 1px 2px 4px #d7d7da;
      width: 116px;
      right: 0px;
    }
  }
  .pro-img {
    @include profile-64;
  }
  .add {
    border-radius: 20px;
    @include small;
    color: $primary;
    padding: 2px 13px;
    cursor: pointer;
  }
  .post {
    margin-top: 0px;
    @include para;
    text-decoration: underline;
    margin-right: 10px;
  }
  .tooltip {
    position: absolute;
    background: rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    opacity: 1;
    width: 213px;
    top: -60px;
    &::after {
      content: "";
      position: absolute;
      left: 15px;
      width: 0px;
      height: 0px;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 15px solid #e5e5e5;
    }
    p {
      @include para;
      font-size: 10px;
      line-height: 19px;
    }
  }
  h5 {
    @include para;
    margin: 0;
    font-size: 19px;
  }
  .shedule {
    h6 {
      @include font-600;
      margin-top: 15px;
      margin-bottom: 15px;
      color: $grey;
    }
    p {
      @include font-600;
      color: $grey;
    }
    .input-group {
      border: 1px solid $border;
      border-radius: 5px;
      padding-left: 5px;
      .input-group-text {
        background: none;
        border: none;

        .calendar {
          @include calender-icon;
        }
        .clock {
          @include clock-icon;
        }
      }
      input {
        border: none;
      }
    }
    input[type="date"]::-webkit-calendar-picker-indicator {
      position: absolute;
      left: 0;
    }
    input[type="time"]::-webkit-calendar-picker-indicator {
      position: absolute;
      left: 0;
    }

    input::-webkit-datetime-edit {
      position: relative;
      left: 15px;
    }

    input::-webkit-datetime-edit-fields-wrapper {
      position: relative;
      left: 15px;
    }
  }
  .flex {
    span {
      @include small;
      color: $primary;
    }
    .others {
      .love {
        background-size: 54%;
      }
      .hand {
        background-size: 54%;
      }
      .lamp {
        background-size: 54%;
      }
      span {
        @include para;
        font-size: 10px;
      }
    }
  }
  .form-select {
    border: 1px solid $grey;
    padding: 5px 10px;
    display: flex;
    span {
      color: $grey;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100px;
    }
  }

  .no-dropdown {
    border: none !important;
    background-image: none !important;
    span {
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 22px;
    }
  }

  .details {
    p {
      @include para;
    }
    h6 {
      @include font-600;
      margin: 0;
      color: $grey;
    }
    .pro-img {
      @include profile-48;
    }
    input {
      width: 20px;
      height: 20px;
      accent-color: $primary;
    }
  }
  .input {
    display: flex;
    input {
      width: 15px;
      height: 15px;
      accent-color: $primary;
    }
    .facebook {
      display: flex;
      gap: 5px;
      align-items: center;
      margin-right: 12px;
      .icon {
        @include facebook;
      }
    }
    .twitter {
      display: flex;
      gap: 5px;
      align-items: center;
      margin-right: 12px;
      .icon {
        @include twitter;
      }
    }
    .insta {
      display: flex;
      gap: 5px;
      align-items: center;
      margin-right: 12px;
      .icon {
        @include instagram;
      }
    }
  }
}
