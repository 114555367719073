@import "src/assets/styles/colors";
.use-phone-text a {
  justify-content:center;
  margin-bottom:5px;
}

.minHeight {
  min-height:25vh;
}
.auth-form.tp-forgot{
  .form-input {
    .form-control{
      padding-left: 10px !important;
    }
  }
}