@import "./variable.scss";
@mixin h1 {
  font-weight: 600;
  font-size: 30px;
  line-height: 1.5;
  color: #01052d;
}

@mixin h2 {
  font-weight: 600;
  font-size: 30px;
  line-height: 1.5;
  color: #01052d;
}

@mixin para {
  font-weight: 400;
  font-size: 15px;
  line-height: 1.46;
  color: $grey;
}
@mixin font-19 {
  font-weight: 600;
  font-size: 19px;
  line-height: 1.25;
}
@mixin font-14 {
  font-weight: 400;
  font-size: 14px;
  line-height: 1.28;
}
@mixin small {
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
}
@mixin show {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
}
@mixin font-600 {
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
}
@mixin large {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  line-height: 24px;
}
@mixin edit-font {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  position: absolute;
  right: 5px;
  top: 5px;
  color: $primary;
  cursor: pointer;
}
@mixin explore-font {
  font-weight: 500;
  font-size: 20px;
  line-height: 22px;
}
@mixin border {
  border-top: 5px solid $grey;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid transparent;
  cursor: pointer;
}
@mixin border-btn {
  border-radius: 20px;
  color: $black;
  border: 1px solid $primary;
  cursor: pointer;
  background: transparent;
  @include small;
  padding: 8px 20px;
}
@mixin primary-btn {
  background: $primary;
  padding: 8px 20px;
  @include small;
  border-radius: 20px;
  color: $white;
  cursor: pointer;
}
@mixin tick {
  width: 20px;
  height: 20px;
  accent-color: #0432a3;
}

@mixin delete {
  background: url(../img/delete.svg) no-repeat center;
  height: 20px;
  width: 20px;
  cursor: pointer;
}
@mixin trash {
  background: url(../img/Trash.svg) no-repeat center;
  height: 20px;
  width: 20px;
  cursor: pointer;
}
@mixin edit-pencil {
  background: url(../img/edit-pencil.svg) no-repeat center;
  height: 20px;
  width: 20px;
  cursor: pointer;
}
@mixin share {
  background: url(../img/ellipse.svg) no-repeat center;
  height: 40px;
  width: 36px;
  cursor: pointer;
}
@mixin reply {
  background: url(../img/share.svg) no-repeat center;
  height: 40px;
  width: 36px;
  cursor: pointer;
}
@mixin reply-border {
  background: url(../img/share-border.svg) no-repeat center;
  height: 40px;
  background-size: 15px 16px;
  width: 25px;
  cursor: pointer;
  display: inline-block;
}
@mixin comment-border {
  background: url(../img/comment-border.svg) no-repeat center;
  height: 40px;
  background-size: 15px 16px;
  width: 25px;
  cursor: pointer;
  display: inline-block;
}
@mixin like-border {
  background: url(../img/like-border.svg) no-repeat center;
  height: 40px;
  background-size: 15px 16px;
  width: 25px;
  cursor: pointer;
  display: inline-block;
}

@mixin like-border-blue {
  background: url(../img/like-border-blue.svg) no-repeat center;
  height: 40px;
  background-size: 15px 16px;
  width: 25px;
  cursor: pointer;
  display: inline-block;
}

@mixin share-blue {
  background: url(../img/share-blue.svg) no-repeat center;
  height: 40px;
  width: 36px;
  cursor: pointer;
}
@mixin hash-blue {
  background: url(../img/hash-blue.svg) no-repeat center;
  height: 40px;
  width: 36px;
  cursor: pointer;
}
@mixin location-icon {
  background: url(../img/location.svg) no-repeat center;
  height: 40px;
  width: 36px;
  cursor: pointer;
}
@mixin bell-icon {
  background: url(../img/bell-icon.svg) no-repeat center;
  height: 40px;
  width: 36px;
  cursor: pointer;
}
@mixin copy-icon {
  background: url(../img/Copy.svg) no-repeat center;
  height: 15px;
  width: 36px;
  cursor: pointer;
  display: inline-block;
}
@mixin task-icon {
  background: url(../img/task-square.svg) no-repeat center;
  height: 15px;
  width: 36px;
  cursor: pointer;
  display: inline-block;
}
@mixin image-icon {
  background: url(../img/bx_image.svg) no-repeat center;
  height: 15px;
  width: 36px;
  cursor: pointer;
  display: inline-block;
}
@mixin attach-icon {
  background: url(../img/attachment-line.svg) no-repeat center;
  height: 20px;
  width: 36px;
  cursor: pointer;
  display: inline-block;
}
@mixin gif-icon {
  background: url(../img/gif.svg) no-repeat center;
  height: 20px;
  width: 36px;
  cursor: pointer;
  display: inline-block;
}
@mixin mic-icon {
  background: url(../img/mic.svg) no-repeat center;
  height: 20px;
  width: 36px;
  cursor: pointer;
  display: inline-block;
}
@mixin stop-mic-icon {
  background: url(https://upload.wikimedia.org/wikipedia/commons/c/cc/Stopped_icon.svg)
    no-repeat center;
  height: 20px;
  width: 36px;
  cursor: pointer;
  display: inline-block;
}
@mixin send-icon {
  background: url(../img/sendIcon.svg) no-repeat center;
  height: 20px;
  width: 36px;
  cursor: pointer;
  display: inline-block;
}

@mixin eye {
  background: url(../../assets/img/eye.svg), $white;
  width: 33px;
  height: 34px;
  position: absolute;
  right: 1px;
  top: 1px;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 5px;
  cursor: pointer;
}

@mixin calender-icon {
  background: url(../../assets/img/calenda.svg) no-repeat center;
  width: 23px;
  height: 22px;
  cursor: pointer;
}
@mixin pin-icon {
  background: url(../../assets/img/bi_pin-angle-fill.svg) no-repeat center;
  width: 40px;
  height: 40px;
  cursor: pointer;
}

@mixin unpin-icon {
  background: url(../../assets/img/unpin-fill.svg) no-repeat center;
  width: 35px;
  height: 35px;
  cursor: pointer;
}
@mixin clock-icon {
  background: url(../../assets/img/clock.svg) no-repeat center;
  width: 23px;
  height: 22px;
  cursor: pointer;
}
@mixin blue-calender {
  background: url(../../assets/img/calendars.svg) no-repeat;
  width: 23px;
  height: 22px;
  cursor: pointer;
  position: absolute;
  right: 5px;
  top: 40px;
}
@mixin search-icon {
  background: url(../../assets/img/bluesearch.svg) no-repeat;
  width: 23px;
  height: 22px;
  cursor: pointer;
  position: absolute;
  right: 5px;
  top: 40px;
}

@mixin pencil {
  background: url(../img/edit.svg) no-repeat;
  width: 23px;
  height: 22px;
  cursor: pointer;
}
@mixin edit-black {
  background: url(../img/edit-black.svg) no-repeat;
  width: 23px;
  height: 22px;
  cursor: pointer;
}

// tick
@mixin tick-varify {
  position: relative;
  background: $blue;
  width: 15px;
  height: 15px;
  border-radius: 55%;
}
@mixin checkmark {
  position: absolute;
  transform: rotate(50deg) translate(-50%, -50%);
  left: 24%;
  top: 47%;
  height: 9px;
  width: 5px;
  border-bottom: 2px solid #fff;
  border-right: 2px solid #fff;
}
@mixin verify-profile {
  background: url(../img/verify-tick.svg) no-repeat center;
  width: 23px;
  height: 22px;
  cursor: pointer;
  display: inline-block;
}
@mixin verify-profile-big {
  background: url(../img/big-verify.svg) no-repeat center;
  width: 23px;
  height: 22px;
  cursor: pointer;
  display: inline-block;
}
@mixin ci-share {
  background: url(../img/ci_share.svg) no-repeat center;
  width: 23px;
  height: 17px;
  cursor: pointer;
  display: inline-block;
}
@mixin add-icon {
  background: url(../img/add.svg) no-repeat center;
  width: 35px;
  height: 35px;
  cursor: pointer;
  display: inline-block;
}
// tick

// hiring
@mixin hiring {
  border: 1px solid #8430c8;
  border-radius: 5px;
  text-align: center;
  width: 70px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 10px;
  line-height: 27px;
  color: #8430c8;
}
// hiring

// seeking
@mixin seeking {
  @include hiring;
  color: #f68a18;
  border: 1px solid #f68a18;
}
// seeking

@mixin profile-circle {
  width: 36px;
  height: 36px;
  border-radius: 55%;
}
@mixin profile-48 {
  width: 48px;
  height: 48px;
  border-radius: 55%;
}
@mixin profile-64 {
  width: 64px;
  height: 64px;
  border-radius: 55%;
}

@mixin profile-50 {
  height: 50px;
  width: 50px;
  object-fit: cover;
  border-radius: 55%;
}
@mixin profile-40 {
  height: 40px;
  width: 40px;
  object-fit: cover;
  border-radius: 55%;
}
@mixin profile-80 {
  height: 80px;
  width: 80px;
  object-fit: cover;
  border-radius: 55%;
}
@mixin profile-100 {
  height: 100px;
  width: 100px;
  object-fit: cover;
  border-radius: 55%;
}
@mixin profile-125 {
  height: 125px;
  width: 125px;
  object-fit: cover;
  border-radius: 55%;
}

@mixin center-text {
  display: flex;
  align-items: center;
  gap: 12px;
}

@mixin facebook {
  background: url(../img/Facebook.svg);
  height: 20px;
  width: 20px;
  cursor: pointer;
}
@mixin instagram {
  background: url(../img/Instagram.svg);
  height: 20px;
  width: 20px;
  cursor: pointer;
}
@mixin linkdin {
  background: url(../img/Linkedin.svg);
  height: 20px;
  width: 20px;
  cursor: pointer;
}
@mixin twitter {
  background: url(../img/TwitterX.svg);
  height: 20px;
  width: 20px;
  cursor: pointer;
}

@mixin dropdown {
  position: absolute;
  background: $white;
  border-radius: 5px;
  padding: 17px 15px;
  z-index: 50;
  right: 0rem;
  top: 32px;
  border: 1px solid $border;

  ul {
    li {
      font-weight: 400;
      font-size: 15px;
      line-height: 22px;
      color: $grey;
      display: flex;
      align-items: baseline;
      gap: 15px;
      line-height: 34px;
      cursor: pointer;
    }

    li:hover {
      color: $primary;
    }
  }
}

@mixin borderRadius($radius) {
  border-radius: $radius;
  -moz-border-radius: $radius;
  -webkit-border-radius: $radius;
}
