.background_screen_header{
    height: 150px;
    width: 100%;
    background: url("../../assets/img/backgroundHeader.svg");
    background-size: cover;
    img{
        width: 35%;
        padding: 20px;
    }
    h3{
        width: 40%;
    }

}
.background_service_screen{
    background: white !important;
   
    ul{
        list-style-type: disc;
       padding-left: 10px;
    }
   
}
.background-screen{
    height: 50vh;
    overflow: scroll;
    overflow-x: hidden;
    padding: 20px;
    background-color: #F8F8FA;
}

.back_ground_screening_model{
    .modal-header{

        .btn-close {
            background: transparent url("../../assets/img/whiteCrossIcon.svg") center / contain no-repeat !important;
            width: 15px; 
            height: 15px;
        }
    }
}