@import "../../../../assets/scss/variable.scss";

.default-recruiter-modal {
  max-width: 544px;

  .modal-header {
    padding: 20px 30px;

    .modal-title {
      color: #01052d;
      font-family: Inter;
      font-size: 19px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
    }
  }

  .gray-blue-btn {
    border-radius: 5px;
    background: rgba(4, 50, 163, 0.05);
    color: #0432a3;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 22.5px;
    border: 0;
  }
}

.company-custom-dropdown {
  .autocomplete-wrapper {
    position: relative;
  }
  .search {
    &::before {
      content: "";
      background: url(../../../../assets/img/location-job.svg) no-repeat;
      position: absolute;
      top: 7px;
      left: 7px;
      width: 22px;
      height: 22px;
      z-index: 5;
      cursor: pointer;
    }
    &::after {
      content: "";
      background: none;
      position: absolute;
    }
  }

  .autocomplete-wrapper input {
    // margin-top: 1.5rem;
    border: 1px solid #d1dfff;
    width: 100%;
    // padding: 0.375rem 0.75rem;
    padding: 5px 35px;
    border-radius: 4px;
    padding-right: 50px;
  }

  // .autocomplete-wrapper input:focus {
  //   border-color: #0F67FF;
  //   box-shadow: none;
  //   outline: none;
  // }
  .autocomplete-wrapper .dropdown {
    width: 100%;
    padding: 0;
    text-align: left;
    max-height: 200px;
    overflow: hidden;
    overflow-y: auto;
    position: absolute;
    z-index: 1;
    background-color: #ffffff;
    border: 1px solid #d1dfff;
    border-radius: 4px;
    // box-shadow: 10px 10px 5px lightblue;
  }

  .autocomplete-wrapper .item .name-heading-drop {
    line-height: 20px;
    color: #01052d;
    font-size: 15px;
    font-weight: 400;
  }

  .autocomplete-wrapper .item .sub-heading-drop {
    font-weight: 400;
    color: #676a79;
    font-size: 11px;
    margin-top: 0px;
    // line-height: 5px;
  }

  .autocomplete-wrapper .item {
    display: block;
    cursor: pointer;
    font-size: 14px;
    padding: 0.375rem 0.75rem;
  }

  .autocomplete-wrapper .item.selected-item {
    background-color: $light-blue-grey;
    color: #01052d;
  }

  .autocomplete-wrapper .item:hover {
    background-color: $light-blue-grey;
    color: #01052d;
  }

  .fake-input {
    position: relative;
    // width: 240px;
    border: 1px solid #d1dfff;
    border-radius: 4px;
    color: #676a79;
    font-size: 1rem;
  }

  .fake-input input {
    border: none;
    background-color: #fff;
    display: block;
    width: 100%;
    box-sizing: border-box;
  }

  .fake-input img {
    position: absolute;
    top: 0.5px;
    height: 30px;
    width: 30px;
    object-fit: cover;
    padding: 3px;
    border-radius: 50%;
    // right: 5px;
  }
}

.modal-fast-hire-job {
  .modal-header {
    .para-text {
      color: #676a79;
      font-family: Inter;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 22.5px;
      margin-top: 5px;
      display: block;
    }
  }

  .bottom-txt {
    margin-top: 10px;

    input[type="checkbox"] {
      margin-right: 5px;
    }
  }
}

.modal-shortlist-checklist {
  .clear-btn {
    color: #0432a3;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 22.5px;
  }

  label {
    color: #000;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 22.5px;
    display: flex;
    align-items: center;
    margin-top: 30px;

    img {
      max-width: 50px;
      margin: 0 15px;
    }
  }

  input {
    vertical-align: middle;
  }

  .org-name {
    display: block;
    color: #717171;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-top: 2px;

    .location {
      position: relative;

      &::before {
        content: "";
        width: 4px;
        height: 4px;
        background-color: #676a79;
        display: inline-block;
        vertical-align: middle;
        margin: 0 5px 0 2px;
      }
    }
  }
}

.modal-radio-payment {
  .modal-header {
    background: $linear;

    .modal-title {
      color: $white;
    }
    .btn-close {
      color: $white;
      background: url(../../../../assets/img/X.svg) no-repeat center;
      opacity: 5;
    }
  }
  .modal-body {
    p {
      color: #676a79;
      font-family: Inter;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 22.5px;

      span {
        color: #01052d;
      }
    }

    h5 {
      color: #676a79;
      font-family: Inter;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 22.5px;
      border-radius: 5px 5px 0px 0px;
      background: rgba(238, 240, 242, 0.35);
      padding: 8px 20px;
      border: 1px solid #d1dfff;
      border-bottom: 0;
    }

    h3 {
      color: #01052d;
      font-family: Inter;
      font-size: 19px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
    }

    label {
      color: #000;
      font-family: Inter;
      font-size: 15px;
      font-style: normal;
      font-weight: 600;
      line-height: 22.5px;
      display: flex;
      align-items: center;
      margin-top: 30px;
      width: 100%;
      padding-bottom: 20px;

      &:not(:last-of-type) {
        border-bottom: 1px #d1dfff solid;
      }
    }

    .form-box {
      border-radius: 0px 0px 5px 5px;
      border: 1px solid #d1dfff;
      padding: 8px 20px;
    }

    .radio-inner {
      width: 100%;
    }

    .end-code {
      margin-left: 10px;
      color: #676a79;
      font-family: Inter;
      font-size: 15px;
      font-style: normal;
      font-weight: 600;
      line-height: 22.5px;
    }

    .exp-date {
      display: block;
      color: #676a79;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  }
}

.modal-radio-view-edit {
  // .modal-header {
  //   background: $linear;

  //   .modal-title {
  //     color: $white;
  //   }
  //   .btn-close {
  //     color: $white;
  //     background: url(../../../../assets/img/X.svg) no-repeat center;
  //     opacity: 5;
  //   }
  // }
  .modal-header {
    // background: $linear;
    background: linear-gradient(117.14deg, #652D92 0%, #2E3192 31.21%, #2353A8 66.87%, #0D95D5 93.36%);

    .modal-title {
      color: $white;
    }
    .btn-close {
      color: $white;
      background: url(../../../../assets/img/X.svg) no-repeat center;
      opacity: 5;
    }
  }
  .modal-body {
    p {
      color: #676a79;
      font-family: Inter;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 22.5px;
    }

    label {
      color: #000;
      font-family: Inter;
      font-size: 15px;
      font-style: normal;
      font-weight: 600;
      line-height: 22.5px;
     .set-name{
        font-size: 15px;
        font-weight: 600;
        width: 200px;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }

    input[type="radio"] {
      margin-right: 10px;
      accent-color: $primary;
      border: 1px solid $primary;
      background: none;
      padding: 8px;
    }
    input[type="radio"]:checked {
      //   background: $white;
      background: url("../../../../assets/img/blue-circle.svg") no-repeat center;
    }

    input[type="date"] {
      max-width: 200px;
    }

    input[type="time"] {
      max-width: 135px;
    }

    .date-time-box {
      gap: 20px;
    }

    .view-edit-box {
      cursor: pointer;
      .view-btn {
        margin-right: 10px;
      }
    }
  }
}

.modal-delete-topics {
  .modal-header {
    background: $linear;

    .modal-title {
      color: $white;
    }
    .btn-close {
      color: $white;
      background: url(../../../../assets/img/X.svg) no-repeat center;
      opacity: 5;
    }
  }
  p {
    color: #01052d;
    font-family: Inter;
    font-size: 19px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    margin-top: 15px;
  }

  .title {
    color: #000;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 22.5px;
  }

  .title-list-item {
    padding-top: 12px;
    cursor: pointer;

    &:not(:first-of-type) {
      border-top: 1px #d1dfff solid;
    }
  }

  .create-btn {
    color: #0432a3;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 22.5px;
    cursor: pointer;
  }
}

.modal-input-questionnair {
  .modal-header {
    background: $linear;

    .modal-title {
      color: $white;
    }
    .btn-close {
      color: $white;
      background: url(../../../../assets/img/X.svg) no-repeat center;
      opacity: 5;
    }
  }
  .title-list-item {
    img {
      cursor: pointer;
    }
  }
  .title {
    color: #01052d;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 22.5px;
  }

  .modal-body form label {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }

  p {
    color: #676a79;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 22.5px;
    padding-right: 50px;
  }

  .question-box {
    background: #fff;
    box-shadow: 0px 2px 4px 0px rgba(171, 190, 209, 0.6);
    padding: 15px;
  }

  .text-box {
    background: rgba(238, 240, 242, 0.35);
    border: 0;
    border-bottom: 1px #d1dfff solid;
    border-radius: 0;
  }

  .add-btn {
    color: #0432a3;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    cursor: pointer;
  }
}


//Css for increasing the Modal Width
.custom-recruiter-modal {
  max-width: 574px;

  .modal-header {
    padding: 20px 30px;
     height:74px;
    .modal-title {
      //color: #01052d;
      color: white;
      font-family: Inter;
      font-size: 19px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
    }
  }

  .gray-blue-btn {
    border-radius: 5px;
    background: rgba(4, 50, 163, 0.05);
    color: #0432a3;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 22.5px;
    border: 0;
  }
}