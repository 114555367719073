@import "../../../assets/scss/mixin.scss";
@import "../../../assets/scss/variable.scss";

.campaign {
  .container {
    .main-box {
      h2 {
        @include h2;
        @media (max-width: 767px) {
          font-size: 22px;
        }
      }

      .heading-row {
        justify-content: space-between;
        display: flex;
        padding: 30px;
        @media (max-width: 767px) {
          padding: 20px 15px;
          .btn-blue {
            flex-basis: 52%;
            padding: 10px;
            font-size: 12px;
          }
        }
      }

      .boxes {
        h4 {
          @include font-19;
        }

        p {
          @include font-14;
          color: $grey;

          .d-grow {
            background: #d9534f;
          }

          span {
            background: #008c39;
            border-radius: 5px;
            @include font-14;
            color: $white;
            padding: 3px 5px;
            margin-left: 10px;
          }
        }
      }

      .active-box{
        cursor: pointer;
                          // border: 1px solid #0432a3;
                          box-shadow: 2px 2px 5px rgba(0, 0, 0, .3);
                          padding: 10px;
                          // display: flex;
                          // flex-direction: column;
                          // align-items: center;

                          h4 {
                            @include font-19;
                          }
                  
                          p {
                            @include font-14;
                            color: $grey;
                  
                            .d-grow {
                              background: #d9534f;
                            }
                  
                            span {
                              background: #008c39;
                              border-radius: 5px;
                              @include font-14;
                              color: $white;
                              padding: 3px 5px;
                              margin-left: 10px;
                            }
                          }
      }

      .graph-area {
        // height:350px;
        width: 100%;
        padding: 30px;
        @media (max-width: 767px) {
          padding: 20px 10px;
        }
      }

      .tabs-area {
        margin-left: 20px;
        margin-right: 20px;

        .nav-tabs {
          @media (max-width: 767px) {
            white-space: nowrap;
            display: inline-block;
            width: 100%;
            overflow: auto hidden;
          }
          .nav-item {
            padding: 0 28px 0 0px;
            @media (max-width: 767px) {
              display: inline-block;
            }

            .nav-link {
              border: none;
              padding: 0 0 9px;
              font-weight: 600;
              font-size: 15px;
              line-height: 22px;
              color: $grey;
            }

            .active {
              border-bottom: 4px solid $primary;
              color: $primary;
              border-radius: 4px;
            }
          }
        }

        .listing {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 15px;

          .card-left {
            display: flex;

            img {
              height: 60px;
              width: 100px;
              border-radius: 5px;
              padding: 0px;
              margin: 10px 0px 10px 0px;
            }

            .heading {
              padding: 20px;

              p {
                @include font-600;
                color: $dark-black;
              }

              .para {
                @include para;
              }
            }
          }

          .card-right {
            display: flex;

            .btn {
              color: $primary;
              padding: 5px;
            }

            .btn:first-child:active {
              border: none;
            }

            .published {
              color: #008c39;
              background-color: rgba(0, 140, 57, 0.1);
              border-radius: 18px;
              padding: 5px 20px 5px 20px;
              margin-right: 40px;
              text-transform: capitalize;
            }

            .draft {
              color: $primary;
              background-color: #e6ebf6;
              border-radius: 18px;
              padding: 5px 20px 5px 20px;
              margin-right: 40px;
              text-transform: capitalize;
            }

            .completed {
              color: #19bcf4;
              background-color: rgba(0, 140, 57, 0.1);
              border-radius: 18px;
              padding: 5px 20px 5px 20px;
              margin-right: 40px;
              text-transform: capitalize;
            }

            .scheduled {
              color: #f68a18;
              background-color: #fef3e8;
              border-radius: 18px;
              padding: 5px 20px 5px 20px;
              margin-right: 40px;
              text-transform: capitalize;
            }
          }
        }

        .listing:hover {
          background-color: $light-grey;
        }
      }
    }

    .article {
      h4 {
        @include h1;
        font-size: 14px;
        line-height: 10px;
        margin-bottom: 10px;
      }

      .box {
        display: flex;
        gap: 15px;
        margin-top: 20px;

        .img {
          height: 50px;
          width: 84px;
          border-radius: 5px;
          background: $linear;

          img {
            height: 50px;
            width: 84px;
            border-radius: 5px;
          }
        }

        .text {
          h6 {
            @include h1;
            font-size: 14px;
            line-height: 18px;
            text-transform: capitalize;
          }

          h6:hover {
            text-decoration: underline;
          }

          span {
            font-weight: 400;
            font-size: 10px;
          }
        }
      }

      span {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: $grey;
        cursor: pointer;
      }
      span:hover {
        color: $primary;
      }
    }

    .bg-white {
      background-color: $white;
    }
  }
  .graph-area{
    canvas{
      width: 100% !important;
    }
  }
}
