@import "../../../assets/scss/mixin.scss";
@import "../../../assets/scss/variable.scss";

.create-group {
  .modal-header {
    background: $linear;
    position: relative;

    .modal-title {
      color: $white;

      .icon-expand {
        position: absolute;
        right: 50px;
        top: 19px;
      }
    }

    .btn-close {
      color: $white;
      background: url(../../../assets/img/X.svg) no-repeat center;
      opacity: 5;
    }
  }

  .btn-width {
    width: "20%"
  }

  .modal-body {

    .box {
      .tags-input-container {
        margin-top: 1.5rem;
        border: 1px solid #d1dfff;
        border-radius: 0.375rem;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 0.5em;
      }

      .title-count {
        text-align: right;
        font-weight: 400;
        font-size: 15px;
        line-height: 22px;
        color: #676a79;
        font-size: 12px;
      }

      .input-type {
        margin-top: 1.5rem;
        border: 1px solid #d1dfff;
      }

      .checkbox-type-margin {
        margin-top: 1.5rem;
      }

      .input-type-nopad {
        border: 1px solid #d1dfff;
      }

      .form-check-input[type="radio"] {
        border-radius: 0px;
      }

      [type="radio"] {
        background-color: transparent;
      }

      .form-check-input:checked {
        background-color: $primary;
        border-color: $primary;
      }

      .form-check-input:checked[type="radio"] {
        background-image: url(../../../assets/img/tickWhite.svg);
      }

      .autocomplete-wrapper {
        position: relative;
      }

      .autocomplete-wrapper input {
        margin-top: 1.5rem;
        border: 1px solid #d1dfff;
        width: 100%;
        padding: 0.375rem 0.75rem;
        border-radius: 4px;
        padding-right: 50px;
      }

      // .autocomplete-wrapper input:focus {
      //   border-color: #0F67FF;
      //   box-shadow: none;
      //   outline: none;
      // }
      .autocomplete-wrapper .dropdown {
        width: 100%;
        padding: 0;
        text-align: left;
        max-height: 100px;
        overflow: hidden;
        overflow-y: auto;
        position: absolute;
        z-index: 1;
        background-color: #ffffff;
        border: 1px solid #d1dfff;
        border-radius: 4px;
        // box-shadow: 10px 10px 5px lightblue;
      }

      .autocomplete-wrapper .item .name-heading-drop {
        line-height: 20px;
        color: $dark-black;
        font-size: 15px;
        font-weight: 400;
      }

      .autocomplete-wrapper .item .sub-heading-drop {
        font-weight: 400;
        color: $grey;
        font-size: 11px;
        margin-top: 0px;
        // line-height: 5px;
      }

      .autocomplete-wrapper .item {
        display: block;
        cursor: pointer;
        font-size: 14px;
        padding: 0.375rem 0.75rem;
      }

      .autocomplete-wrapper .item.selected-item {
        background-color: $light-blue-grey;
        color: $dark-black;
      }

      .autocomplete-wrapper .item:hover {
        background-color: $light-blue-grey;
        color: $dark-black;
      }

      .speaker {
        position: relative;

        .add {
          @include add-icon;
          position: absolute;
          top: 25px;
          right: 5px;
          background-size: 24px;
        }
      }

      .images {
        display: flex;
        gap: 20px;
        position: relative;

        .preview-img {
          @include profile-circle;
          position: relative;
          cursor: pointer;

          .close {
            position: absolute;
            background: $grey;
            font-size: 8px;
            border-radius: 50%;
            padding: 1px 4px;
            color: $white;
            top: -3px;
            right: -6px;
            box-shadow: 0px 1px 0px 3px #fff;
            cursor: pointer;
          }

          .count-img {
            display: inline-block;
            width: 36px;
            height: 36px;
            border-radius: 55%;
            background: #0432a3;
            color: #ffffff;
            text-align: center;
            line-height: 34px !important;
            font-weight: 400;
            font-size: 14px;
          }
        }
      }

      .main-drag {
        position: relative;

        .drag-img {
          background: url(../../../assets/img/box.png) no-repeat center;
          background-size: cover;
          height: 133px;
          position: relative;
          border-radius: 5px;
          overflow: hidden;

          .text-center {
            position: absolute;
            transform: translate(-50%, -50%);
            top: 50%;
            left: 50%;

            .img {
              background: url(../../../assets/img/gallery-add.png) no-repeat center;
              height: 60px;
              background-size: 50px;
            }

            .text {
              p {
                font-size: 13px;
                color: $grey;

                span {
                  color: $primary;
                  text-decoration: underline;
                }
              }
            }
          }
        }

        .image_drag_drop {
          width: 100%;
          height: 133px;
        }

        .blue-txt {
          color: #0432a3;
        }

        .group-icon-image-fit {
          object-fit: cover;
          height: 100%;
          border-radius: 55%;
          width: 100%;
          height: 100px;
        }

        .add-icon {
          display: grid;
          place-content: center;

          .img {
            background: url(../../../assets/img/gallery-add-dark.png) no-repeat center;
            height: 30px;
          }

          background: #d9d9d9;
          width: 100px;
          height: 100px;
          border-radius: 50%;
          @include font-14;
          color: $primary;
          text-decoration: underline;
          text-align: center;
          position: absolute;
          bottom: -75px;
          box-shadow: 1px 0px 0px 5px #fff;
          left: 20px;
        }
      }

      .mt-7 {
        margin-top: 7rem;
      }
    }

    label {
      @include font-14;
      color: $grey;
    }

    .switch {
      position: relative;
      display: block;
      width: 37px;
      height: 10px;

      input {
        opacity: 0;
        width: 0;
        height: 0;
      }
    }

    .slider {
      position: absolute;
      cursor: pointer;
      height: 16px;
      width: 32px;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: $grey;
      -webkit-transition: 0.4s;
      transition: 0.4s;

      &:before {
        position: absolute;
        content: "";
        height: 10px;
        width: 10px;
        left: 3px;
        bottom: 3px;
        background-color: $white;
        -webkit-transition: 0.4s;
        transition: 0.4s;
      }
    }

    input {
      &:checked {
        + {
          .slider {
            background-color: $primary;

            &:before {
              -webkit-transform: translateX(16px);
              -ms-transform: translateX(26px);
              transform: translateX(16px);
            }
          }
        }
      }

      &:focus {
        + {
          .slider {
            box-shadow: 0 0 1px #2196f3;
          }
        }
      }
    }

    .slider.round {
      border-radius: 34px;

      &:before {
        border-radius: 50%;
      }
    }
  }
}

.modal-group-title {
  background: linear-gradient(120.5deg, #5B2E92 0%, #29409C 50.49%, #0F91D2 100%);
  color: #FFF;
}

.group-details-sidebar {
  .sidebar {
    aside {
      background: $white;
      box-shadow: 0px 2px 4px rgba(171, 190, 209, 0.6);
      border-radius: 0px 0px 5px 5px;

      .view_profile_network {
        height: 41.58px;
        background: $primary;
        color: $white;
        border-radius: 5px;
        text-align: center;
        line-height: 42px;

        a {
          color: $white;
          font-weight: 600;
          font-size: 15px;
        }
      }

      .bg {
        height: 132.02px;
        background: $linear;
        border-radius: 5px;
        position: relative;

        .profile-img {
          border: 2px solid #ffffff;
          border-radius: 5px;
          height: 72px;
          width: 72px;
          position: absolute;
          margin: 20px;
        }

        .back-image-fluid {
          border-radius: 5px;
          height: 132.02px;
          width: 100%;
          object-fit: cover;
        }
      }

      .nobel {
        color: $primary;
        @include show;
        text-align: end;
        text-transform: uppercase;
        font-size: 10px;
        margin-right: 7px;
        cursor: pointer;
      }

      .profile-details {
        h6 {
          @include font-19;
          word-break: break-all;
          color: $dark-black;
          text-transform: capitalize;
        }

        p {
          @include font-14;
          color: $dark-black;
        }
      }

      .follower {
        display: flex;
        justify-content: space-around;
        margin-top: 20px;
        margin-bottom: 15px;

        .box {
          text-align: center;

          h6 {
            @include font-600;
            color: $dark-black;
          }

          p {
            @include font-14;
            color: $grey;
          }
        }

        .h-border {
          background: #d1dfff;
          width: 1px;
        }
      }

      .social {
        margin: 20px;

        ul {
          li {
            @include center-text;
            @include font-600;
            position: relative;
            border-radius: 5px;
            padding: 20px;
            cursor: pointer;

            .count {
              position: absolute;
              right: 10px;
              // background: $blue;
              // color: $white;
              font-weight: bolder;
              font-size: large !important;
              color: #0432A3;
              border-radius: 50%;
              padding: 3px 8px;
              width: 23px;
              height: 23px;
              font-size: 14px;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }

          .active {
            background: $transparent-blue;
          }
        }
      }
    }

    .bottom-custom {
      position: unset;
      margin: 43px;

      .links {
        margin-bottom: 10px;

        ul {
          display: flex;
          flex-wrap: wrap;
          gap: 5px;

          li {
            @include para;
            font-size: 10px;
          }
        }
      }

      .copy-right {
        p {
          @include para;
          font-size: 10px;
        }
      }
    }

    @media (max-width: 991px) {
      .bottom-custom {
        position: unset;
        margin: 43px;
        display: none;
      }
    }
  }

  @media (max-width: 992px) {
    .web-footer {
      display: none;
    }
  }
}

.side-admin {
  .group-admin-name {
    margin-bottom: 0px;
    cursor: pointer;
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    word-wrap: break-word;
  }
}

.view_member_model #account-modal{
background: linear-gradient(
    120.5deg,
    #5b2e92 0%,
    #29409c 50.49%,
    #0f91d2 100%
  );
  color: $white
}

.group-drag {
  .dropzone{
    padding-left: 0 !important; 
    padding-right: 0 !important;
  }
}