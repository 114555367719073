@import "../../../assets/scss/mixin.scss";
@import "../../../assets/scss/variable.scss";

.create-newsletter {
  .autocomplete-wrapper {
    position: relative;
  }
  .autocomplete-wrapper input {
    margin-top: 1.5rem;
    border: 1px solid #d1dfff;
    width: 100%;
    padding: 0.375rem 0.75rem;
    border-radius: 4px;
  }
  // .autocomplete-wrapper input:focus {
  //   border-color: #0F67FF;
  //   box-shadow: none;
  //   outline: none;
  // }
  .autocomplete-wrapper .dropdown {
    width: 100%;
    padding: 0;
    text-align: left;
    max-height: 100px;
    overflow: hidden;
    overflow-y: auto;
    position: absolute;
    z-index: 1;
    background-color: #ffffff;
    border: 1px solid #d1dfff;
    border-radius: 4px;
    // box-shadow: 10px 10px 5px lightblue;
  }
  .autocomplete-wrapper .item {
    display: block;
    cursor: pointer;
    font-size: 14px;
    padding: 0.375rem 0.75rem;
  }
  .autocomplete-wrapper .item.selected-item {
    background-color: #0432a3;
    color: #fafbfc;
  }
  .autocomplete-wrapper .item:hover {
    background-color: #0432a3;
    color: #fafbfc;
  }

  .wrapper-class {
    padding: 1rem 1.5rem;
    border: none;
    .image-upload {
      height: 225px;
      background: rgba(238, 240, 242, 0.35);
      border: 1px dashed #d1dfff;
      position: relative;
      .img-fluid {
        object-fit: cover;
        height: 100%;
        width: 100%;
      }
      .center {
        text-align: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        @include font-14;
        color: $grey;
      }
    }

    .image-upload:hover .image_drag_drop {
      opacity: 0.3;
      
    }

    .image-upload:hover .del_button {
      display: block;

    }

    .image-upload .del_button {
      cursor: pointer;
      position: absolute;
      display: none;
      top: 50%;
      left: 50%;
      z-index: 1;
    }
  }
  .preview {
    font-weight: normal;
    color: #0432a3;
    margin-top: 0px;
    cursor: pointer;
  }

  .input-type {
    margin-top: 2rem;
    border: 1px solid #d1dfff;
  }
  .text-editor-header{
    background-color: #ffff;
font-family: Inter;
font-size: 36px;
font-weight: 600;
line-height: 50px;
color: #797474;

    &::placeholder{
font-size: 36px;
font-weight: 600;
    }
  }

  .preview-button {
    margin-top: 1rem;
    text-align: right;
    color: #0432a3;
  }

  .title-count {
    text-align: right;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    color: #676a79;
    font-size: 12px;
  }

  .editor-class {
    padding: 0rem 1rem;
    border: 1px solid #d1dfff;
    min-height: 300px;
    border-radius: 0.375rem;
  }
  .article-paragraph-editor{
    color: #797474 !important;
font-family: Inter;
font-size: 15px;
font-weight: 400;
line-height: 22.5px;
border-radius: 0px;
    background-color: #ffffff !important;
  }

  .toolbar-class {
    border: 1px solid #ccc;
  }

  .rdw-editor-toolbar {
    border: none;
    margin-bottom: 1.5rem;
  }
  .article-editor{
    .rdw-editor-toolbar {
      // margin-bottom: 0 !important;
      margin-top: 12px !important;
      background-color: #F3F3F3;
      padding: 16px 25px;
      width: max-content;
      .rdw-option-wrapper{
          background-color: transparent !important;
          &:hover{
            box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.72);
            -webkit-box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.72);
            -moz-box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.72);

          }
      }
      .rdw-option-active{
        background-color:red;
        box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.72);
-webkit-box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.72);
-moz-box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.72);
      }
    }
  }

  .rdw-option-wrapper {
    border: none;
  }

  .modal-header {
    background: $linear;
    position: relative;

    .modal-title {
      color: $white;

      .icon-expand {
        position: absolute;
        right: 50px;
        top: 19px;
      }
    }

    .btn-close {
      color: $white;
      background: url(../../../assets/img/X.svg) no-repeat center;
      opacity: 5;
    }
  }

  span {
    cursor: pointer;
    font-size: 15px;
  }

  .title {
    @include font-600;
    color: $primary;
    font-size: 18.75px;
  }

  h5 {
    font-weight: 400;
    font-size: 19px;
    line-height: 22px;
    color: #676a79;
    margin: 0;
  }
}

.wrappers {
  box-shadow: 0px 2px 4px rgba(171, 190, 209, 0.6);
  border-radius: 5px 5px 0px 0px;

  .profiles {
    display: flex;
    gap: 15px;

    .img {
      @include profile-circle;
    }

    .details {
      h6 {
        @include small;
      }

      p {
        @include para;
      }
    }
  }

  .hrs {
    @include small;

    .text-blue {
      color: $primary;
      display: flex;
    }
  }

  .text {
    h4 {
      @include h1;
    }

    p {
      @include para;
    }
  }

  .translate {
    color: $blue;
    text-transform: capitalize;
    @include small;
    margin-top: 10px;
  }

  .comments {
    .profile-img {
      @include profile-circle;
    }
  }
}

.RichEditor--hide-placeholder .public-DraftEditorPlaceholder-root {
  display: none;
}
.create-newsLetter{
  .rdw-editor-toolbar {
    position: absolute;
    top: 0;
    width: 25%;
  }
  .rdw-editor-wrapper{
    display: flex;
    justify-content: center;
  }
  .rdw-editor-main {
    width: 100%;
    border: none !important;
  }
  }