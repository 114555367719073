@import "../../assets/scss/mixin.scss";
@import "../../assets/scss/variable.scss";

.message-boxes {
  background: $transparent-blue;
}

.set-away-messages-popup {
  @media (max-width: 767px) {
    padding: 0 15px;
  }
  h5 {
    @include font-600;
  }
  p {
    @include para;
  }
  label {
    @include font-600;
    color: $grey;
    @media (max-width: 767px) {
      display: block;
    }
  }
  .buttons {
    justify-content: center;
    gap: 20px;
  }
  textarea {
    height: 183px;
  }
  .inputs {
    input {
      border-top: none;
      border-left: none;
      border-right: none;
      border-radius: 0;
    }
    .box {
      background: $light-grey;
      padding: 20px;
      @media (max-width: 767px) {
        padding: 10px;
      }
      p {
        @include font-600;
        @media (max-width: 767px) {
          font-size: 12px;
        }
      }
    }
  }
  .set-away-messages-popup-outer {
    border-radius: 5px;
    background: #fff;
    max-width: 594px;
    width: 100%;
    margin: auto;
    // padding: 30px 20px;
  }
}
