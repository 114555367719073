.setting-modal-default-modal {
    max-width: 575px;
    .modal-header {
        padding: 30px;
        .modal-title {
            color: #01052D;
            font-family: Inter;
            font-size: 19px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
            margin-top: 0;
            @media (max-width:767px) {
                font-size: 16px;
                line-height: 22px;
            }
        }
    }
    .modal-body {
        h3 {
            color: #01052D;
            font-family: Inter;
            font-size: 19px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
            @media (max-width:767px) {
                font-size: 16px;
                line-height: 22px;
            }
        }
        h5 {
            color: #000;
            font-family: Inter;
            font-size: 15px;
            font-style: normal;
            font-weight: 600;
            line-height: 22.5px;
            display: block;
        }
        h6 {
            color: #01052D;
            font-family: Inter;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: 22.5px;
            display: block;
            margin-top: 10px;
        }
        .gray-blue-btn {
            border-radius: 5px;
            background: rgba(4, 50, 163, 0.05);
            color: #0432A3;
            font-family: Inter;
            font-size: 15px;
            font-style: normal;
            font-weight: 600;
            line-height: 22.5px;
            padding: 8px 30px;
            cursor: pointer;
            outline: 0;
            &.with-border {
                border: 1px solid #0432A3;
            }
        }
    }
    .modal-footer{
        .gray-blue-btn {
            border-radius: 5px;
            background: rgba(4, 50, 163, 0.05);
            color: #0432A3;
            font-family: Inter;
            font-size: 15px;
            font-style: normal;
            font-weight: 600;
            line-height: 22.5px;
            padding: 8px 30px;
            cursor: pointer;
            outline: 0;
            &.with-border {
                border: 1px solid #0432A3;
            }
        }

    }
    p {
        color: #01052D;
        font-family: Inter;
        font-size: 15px;
        font-style: normal;
        font-weight: 600;
        line-height: 22.5px;
        margin-top: 8px;
    }
    .gray-light400 {
        color: #676A79 !important;
        font-weight: 400 !important;
    }
    .gray-light600 {
        color: #676A79 !important;
        font-weight: 600 !important;
    }
    .footer-txt {
        display: block;
        padding: 24px 30px;
    }
    label {
        color: #676A79;
        font-family: Inter;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 22.5px;
        margin-bottom: 5px;
    }
    input[type="checkbox"] {
        margin-right: 5px;
        vertical-align: middle;
    }
    .button-outer {
        margin-top: 40px;
    }
    .add-btn {
        color: #0432A3;
        font-family: Inter;
        font-size: 15px;
        font-style: normal;
        font-weight: 600;
        line-height: 22.5px;
        background: transparent;
    }
    .input-box {
        margin-top: 18px;
    }
    .text-light-blue {
        color: #19BCF4;
    }
    .profile-box {
        display: flex;
        flex-wrap: wrap;
        border-bottom: 1px #D1DFFF solid;
        padding-bottom: 20px;
        margin-bottom: 20px;
        @media (max-width:767px) {
            flex-direction: row;
            justify-content: center;
            text-align: center;
        }
        img {
            max-width: 64px;
            border-radius: 50%;
            height: 64px;
        }
        .title-box {
            padding-left: 20px;
            h5{
                width: 400px;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            @media (max-width:767px) {
                padding-left: 0;
                margin-top: 10px;
            }
        }
    }
    .accordion {
        .accordion-content {
            label {
                display: block;
                margin-top: 12px;
            }
        }
        .accordion-item {
            border: 0;
            margin-top: 15px;
            cursor: pointer;
            .accordion-title {
                position: relative;
                &::after {
                    content: '';
                    background: url(../../../assets/img/drop-down.svg) 0 0 no-repeat;
                    background-size: 100%;
                    width: 10px;
                    height: 8px;
                    display: inline-block;
                    position: absolute;
                    right: 0;
                    top: 7px;
                }
            }
            &.open {
                .accordion-title {
                    &::after {
                        transform: rotate(180deg);
                    }
                }
            }
        }
    }
    .checkbox-box {
        .title-list {
            flex-basis: 95%;
            padding-right: 10px;
        }
        li {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin-top: 10px;
            color: #676A79;
            font-family: Inter;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: 22.5px;
            &:first-child:not(.custom-check){
                margin-bottom: 20px;
            }
        }
    }
    .switch-box {
        position: relative;
        display: inline-block;
        max-width: 24px;
        height: 12px;
        flex-basis: 5%;
        input {
            opacity: 0;
            width: 0;
            height: 0;
            &:checked ~ .slider {
                background-color: #0432a3;
                &:before {
                    transform: translateX(12px);
                }
            }
        }
        .slider {
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: #ffffff;
            border: 1px solid #676a79;
            transition: 0.4s;
            &.round {
                border-radius: 34px;
                background-color: #676a79;
            }
            &.round:before {
                border-radius: 50%;
                background-color: #ffffff;
            }
            &:before {
                position: absolute;
                content: "";
                height: 10px;
                width: 10px;
                left: 0;
                bottom: 0;
                background-color: #676a79;
                transition: 0.4s;
            }
        }
    }
}

.nobel-plus-account-modal {
    .modal-header {
        border: 0;
        align-items: flex-start;
    }
    .modal-body {
        padding-top: 0;
        p {
            margin-top: 2px;
        }
    }
}

.add-email-account-modal {
    .modal-body {
        .gray-blue-btn {
            margin-top: 30px;
            display: inline-block;
        }
    }
}

.compare-plans-modal {
    &.setting-modal-default-modal {
        max-width: 1174px;
        .modal-header {
            .modal-title {
                color: #000;
                font-family: Inter;
                font-style: normal;
                font-weight: 600;
                font-size: 30px;
                line-height: 44px;
                @media (max-width:767px) {
                    font-size: 26px;
                    line-height: 30px;
                }                
            }
        }
    }
    img {
        margin-right: 5px;
    }
    table {
        margin-top: 10px;
        width: 100%;
        tr:not(:first-child) {
            border-bottom: 1px #D1DFFF solid;
        }
        th {
            color: #000;
            font-family: Inter;
            font-size: 19px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
        }
        td {
            color: #676A79;
            font-family: Inter;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: 22.5px;
            &:not(:first-child) {
                font-size: 14px;
                @media (max-width:767px) {
                    font-size: 12px;
                }
            }
        }
        th,td {
            padding: 20px 5px;
            @media (max-width:767px) {
                font-size: 12px;
                line-height: 18px;
                padding: 12px 2px;
            }
            &:not(:first-child) {
                text-align: center;
            }
            .para-text {
                max-width: 288px;
                width: 100%;
                display: block;
            }
        }
        img {
            display: block;
            margin: 0 auto 5px;
        }
    }
}




.add-ons-packages-modal {
    .left-content {
        margin-top: 20px;
        color: #676A79;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
    }
    .right-content {
        text-align: right;
        margin-top: 20px;
        color: #676A79;
        font-family: Inter;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
    }
    .modal-footer {
        display: block;
    }
    .transactions-item {
        border-bottom: 1px #D1DFFF solid;
        padding-bottom: 20px;
        &:last-child {
            border-bottom: 0;
        }
        .right-content {
            font-size: 15px;
            line-height: 22px;
        }
    }
    .form-group-box {
        label {
            width: 100%;
            margin-bottom: 0;
            color: #676A79;
            font-family: Inter;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: 22.5px;
        }
        select {
            color: #676A79;
            font-family: Inter;
            font-size: 15px;
            font-style: normal;
            font-weight: 600;
            line-height: 22.5px;
            padding: 0 12px 0 5px;
            background-position: 100%;
            width: auto;
            border: 0;
            outline: 0;
        }
    }
}

.logged-location-modal {
    h5 {
        margin-bottom: 10px;
        margin-top: 30px;
    }
    img {
        margin-right: 20px;
        height: 100%;
        @media (max-width:767px) {
            margin-right: 10px;
        }
    }
    .list-item {
        margin-top: 30px;
    }
    .system-location {
        h6 {
            margin-top: 0;
        }
        .os-system {
            margin-top: 10px;
        }
        .ip-address {
            display: block;
        }
    }
}

.saved-cards-modal {
    img {
        height: 100%;
    }
    .card-desc {
        margin-left: 10px;
    }
    label {
        margin-top: 15px;
    }
    .list-item {
        margin-top: 34px;
        &:first-child {
            margin-top: 0;
        }
        .btn-close {
            cursor: pointer;
            color: #676A79;
            width: 3px;
            opacity: 0.4;
        }
    }
}