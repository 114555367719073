@import "../../../assets/scss/variable.scss";
@import "../../../assets/scss/mixin.scss";

.viewReactionsView {
  .reaction-icon {
    height: 18px;
  }

  .nav-item {
    border: none;

    .active {
      border-bottom: 3px solid #0432a3;
      border-bottom-color: #0432a3 !important;
      border-color: transparent;
    }

    a:hover {
      color: #01052d !important;
    }
  }

  .navlink {
    display: flex;
    height: 41px;
    color: #01052d;
    font-weight: 600;
    padding: 10px;
    align-items: center;
    cursor: pointer;
    gap: 4px;
  }
  .nav-link.active {
    color: #01052d;
  }

  .align-items-center {
    align-items: center !important;
  }

  .reactionNavLink {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;
    color: #01052d;
    cursor: pointer;
  }

  .viewReactionsType {
    display: flex;
  }

  .selectDiv small {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $grey;
  }

  .buttonCol {
    display: flex;
    flex-direction: row;
    gap: 15%;
  }

  .buttonRow {
    justify-content: end;
    margin: 40px 12% 0 0;
    position: sticky;
    left: 0;
    bottom: 0;
    width: 90%;
  }

  .btn.disabled,
  .btn:disabled,
  fieldset:disabled .btn {
    color: $white;
    pointer-events: none;
    background-color: $primary;
    border-color: $primary;
    opacity: 0.2;
  }

  .selectedReceipientSearched {
    position: relative;
    min-height: 100%;
  }

  .searchedName {
    overflow: hidden;
  }

  .selectDiv h5 {
    cursor: pointer;
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;
    margin: 0;
    color: $grey;
  }

  .topDiv .modal-body {
    padding: 20px 30px;
  }

  .selectReceipentBody {
    height: 600px;
    overflow: scroll;
    overflow-x: hidden;
  }

  .selectDiv {
    margin-bottom: 20px;
  }

  .receipientInput {
    margin-bottom: 10px;
    color: $grey;
  }

  .receipientInput ::placeholder {
    color: $grey;
  }

  .selectRecpt {
    .selectRecptRow {
      --bs-gutter-x: 0 !important;
    }
  }

  .selectedReceipient {
    display: flex;
    justify-content: space-between;
    background: #ffffff;
    border: 1px solid #0432a3;
    border-radius: 40px;
    padding: 2px 6px;
    max-width: 85%;
    margin-bottom: 10%;
  }

  .selectedReceipient p {
    color: $primary;
  }

  .contain {
    background: rgba(238, 240, 242, 0.35);
    padding: 20px;
  }

  .listImg {
    height: 64px;
    width: 64px;
    border-radius: 50px;
  }

  .pollModal label {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;
    color: $grey !important;
    margin-bottom: 10px;
  }

  .pollModal h5 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;
    color: #0432a3 !important;
    margin-bottom: 20px;
  }

  .navLinks {
    --bs-nav-link-padding-x: 1.9rem !important;
  }

  .pollModal select {
    margin-bottom: 10px;
  }

  .pollModal .paraClass {
    text-align: right;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: #676a79;

    // margin-bottom: 20px;
    .textFeildP {
      margin: 0 0 15px 0;
    }
  }

  @media (max-width: 425px) {
    .reaction-icon {
      height: 12px;
      margin-right: 3px;
    }

    .navlink {
      padding: 10px 5px;
    }
  }
}

@media (max-width: 600px) {
  .viewReactionModalWebView {
    .buttonRow {
      justify-content: flex-start !important;
    }
  }
}
