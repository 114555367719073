@import "../../../../../assets/scss/mixin.scss";
@import "../../../../../assets/scss/variable.scss";

.chat-people {
  overflow: auto;
  height: 74vh;

  .active {
    background: $light-grey;
  }

  .box {
    padding: 20px 15px;
    cursor: pointer;
    .profile-img {
      @include profile-40;
      position: relative;

      .status-indicator {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        outline: 2.5px solid $white;
        background-color: $red;
      }
      .status-indicator.away {
        background-color: $orange;
      }
      .status-indicator.online {
        background-color: $green;
      }
    }

    .active {
      background: #008c39;
      border: 2px solid #ffffff;
      height: 10px;
      width: 10px;
      border-radius: 25px;
      position: absolute;
      right: 0px;
      bottom: 0px;
    }

    h5 {
      @include font-600;
      margin: 0;
    }

    p,
    h6 {
      @include para;
    }

    .minutes {
      span {
        @include para;
        font-size: 10px;
      }
    }

    .unread {
      span {
        display: flex;
        height: 20px;
        width: 20px;
        justify-content: center;
        align-items: center;
        font-weight: 600;
        border-radius: 50%;
        background-color: #d9534f;
        color: #fff;
        margin: auto;
      }
    }
  }
}
