.giphy-picker-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
  }
  
  .giphy-picker-input {
    width: 100%;
    margin-bottom: 10px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .giphy-picker-button {
    padding: 8px 16px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .giphy-picker-results {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    grid-gap: 10px;
overflow: auto;
  }
  
  .giphy-picker-image {
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
  