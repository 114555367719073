@import "../../../assets/scss/variable.scss";
.job-applicant {
  background-color: #fff;
  min-height: 100vh;
  position: relative;
  .m-top {
    margin-top: 10px;
  }
  .back {
    cursor: pointer;
  }
  .header-row {
    margin-top: 44px;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    .user-profile {
      display: flex;
      align-items: center;
      gap: 15px;
    }
  }
  .main-desc {
    border: 1px solid $portalborder;
    height: 820px;
    margin-bottom: 10px;
    overflow-y: auto;
  }
  .job-applicant-details {
    padding: 30px;
    .profile-image {
      background: #fff;
      border: 2px solid #fff;
      border-radius: 55%;
      flex: 0 0 14%;
      height: 100px;
      position: relative;
      top: 3px;
      left: 5px;
      width: 100px;
      .pro-img {
        border-radius: 55%;
        height: 100px;
        object-fit: cover;
        width: 100px;
      }
    }
    .job-applicant-details-left {
      display: flex;
      justify-content: space-around;
      .job-applicant-details-left-content {
        p {
          margin-top: 10px;
        }
        span {
          margin-top: 15px;
          color: $primary;
        }
        .buttons {
          margin-top: 25px;
          display: flex;
          justify-content: center;
        }
      }
    }
    .verlical-line {
      border-left: 1px solid $portalborder;
      height: 65%;
      margin-left: 20px;
    }

    .job-applicant-details-right {
      padding: 0px 20px 30px 30px;
      width: 100%;
      .job-applicant-details-right-content-row {
        display: flex;
        justify-content: space-between;
      }
      .resume-icons {
        display: flex;
        gap: 20px;
        float: right;
        margin-top: 30px;
        .b-white {
          background-color: $white;
          box-shadow: 0px 2px 4px rgb(171 190 209 / 60%);
          border-radius: 50%;
          width: 32px;
          height: 32px;
          cursor: pointer;
          .close {
            margin-left: 8px;
            margin-top: 9px;
          }
        }
      }
    }
  }
  .experience {
    margin-top: 20px;
    margin-bottom: 20px;
    padding-left: 20px;
    .cover-letter {
      color: $primary;
    }
  }

  .close_button {
    background-color: #ffffff;
    box-shadow: 0px 2px 4px rgba(171, 190, 209, 0.6);
    border-radius: 50%;
    margin-right: 15px;
    padding: 5px;
    cursor: pointer;
    height: 30px;
    width: 30px;
    text-align: center;
  }
}

@media screen and (max-width: "980px") {
  .job-applicant-details-left {
    margin-bottom: 40px;
  }
}
@media screen and (max-width: "500px") {
  .job-applicant-details-left {
    flex-direction: column;
  }
  .job-applicant .job-applicant-details {
    padding: 30px 10px;
  }
  .job-applicant .job-applicant-details .job-applicant-details-right {
    padding-left: 0px;
  }
  .job-applicant .job-applicant-details .verlical-line {
    display: none;
  }
}
