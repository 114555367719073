@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&family=Permanent+Marker&display=swap");
@import "colors";
@import "../scss/variable.scss";

* {
  padding: 0;
  margin: 0;
  font-family: Inter, sans-serif;
}
html,
body,
#root {
  min-height: 100%;
}
:root {
  /* Make the mention background blue. */
  --ck-color-mention-background: #d6e7f6 !important;

  /* Make the mention text dark grey. */
  --ck-color-mention-text: hsl(0, 0%, 15%) !important;
}
.App {
  background: white center/cover no-repeat fixed;
  height: 100%;
  min-height: 100vh;
}
body {
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
  &::-webkit-scrollbar-thumb {
    background-color: #363b99;
    border-radius: 10px;
  }
}
.bg-starter {
  background: $starter;
}

textarea,
.modal-body,
.modal {
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
  &::-webkit-scrollbar-thumb {
    background-color: #363b99;
    border-radius: 10px;
  }
}
/* For Firefox */
textarea,
.modal-body,
.modal {
  scrollbar-width: thin; /* Width of scrollbar */
  scrollbar-color: #363b99 transparent; /* Color of thumb and track */
  border-radius: 10px;
}

textarea,
.modal-body,
.modal::-webkit-scrollbar-thumb {
  border-radius: 10px; /* Border radius of scrollbar thumb for WebKit browsers */
}

a {
  text-decoration: none !important;
}
h6,
h3,
h2,
h4 {
  margin: 0;
}
.form-select:focus {
  border-color: $border;
  outline: 0;
  box-shadow: none;
}
//button common
.btn {
  font-weight: 600;
  font-size: 14px;
  padding: 10px 30px;
}

.select-box {
  select {
    background-image: url(../../assets/img/drop-down.svg) !important;
    background-repeat: no-repeat;
    background-position-x: 100%;
    background-position-y: 7px;
    -webkit-appearance: none;
  }
}

.btn-outline {
  font-weight: 600;
  font-size: 14px;
  padding: 10px 30px;
  color: $primary;
  border: 1px solid $primary;
}

.btn-outline:hover {
  color: $primary;
  border: 1px solid $primary;
}
.btnWebView {
  font-weight: 600;
  font-size: 14px;
  padding: 10px 30px;
  width: 100%;
  border-radius: 8px;
}
.bg-grey {
  // background-color: #e5e5e5;
  min-height: 100vh;
}

.bg-campaign {
  //background: url(../img/background-campaign.jpg);
  min-height: 100%;
  background-size: cover;
}
.btn-light {
  background: $transparent-blue;
}
.btn-light:hover {
  background: $transparent-blue;
  color: $primary;
}
.btn-blue {
  background: $color-3;
  color: $color-1;
}

.btn-blue:hover {
  background: $primary;
  color: $white;
}
.btn-orange {
  background: $orange;
  color: $color-1;
}
.btn-orange:hover {
  background: $orange;
  color: $white;
}
.btn-org-outline {
  font-weight: 600;
  font-size: 14px;
  padding: 10px 30px;
  color: $orange;
  border: 1px solid $orange;
}

.btn-org-outlinee:hover {
  color: $orange;
  border: 1px solid $orange;
}
.btn-org-light {
  background: #fbcb9999;
}
.btn-org-light:hover {
  background: #fbcb9999;
  color: $orange;
}

.disabled-btn-blue {
  background: $color-5;
  color: $color-1;
}

.btn-blue-color {
  color: $color-3;
}

.disabled-btn-blue:hover {
  background: $color-5;
  color: $color-1;
  cursor: not-allowed;
}

.m-0 {
  margin: 0px;
}

.mr-10 {
  margin-right: 10px;
}
.mr-3 {
  margin-right: 3px;
}

.mr-20 {
  margin-right: 20px;
}

.mr-30 {
  margin-right: 30px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-26 {
  margin-top: 26px;
}
.mt-40 {
  margin-top: 40px;
}
.pt-26 {
  padding-top: 26px;
}
.pl-20 {
  padding-left: 20px;
}
.p-20 {
  padding: 20px;
}
.p-30 {
  padding: 30px;
}
.mt-20 {
  margin-top: 20px;
}
.my-10 {
  margin-top: 10px;
  margin-bottom: 10px;
}
.my-20 {
  margin-top: 20px;
  margin-bottom: 20px;
}
.my-30 {
  margin-top: 30px;
  margin-bottom: 30px;
}
.mb-10 {
  margin-bottom: 10px;
}
.mb-20 {
  margin-bottom: 20px;
}
.ml-5 {
  margin-left: 5px !important;
}
.mr-5 {
  margin-right: 5px !important;
}
.ml-10 {
  margin-left: 10px;
}
.ml-20 {
  margin-left: 20px;
}
.ml-30 {
  margin-left: 30px;
}
.mt-30 {
  margin-top: 30px;
}
.mt-32 {
  margin-top: 32px;
}
.mt-35 {
  margin-top: 35px;
}
.mt-rem {
  margin-top: 1.7rem !important;
}
.mb-30 {
  margin-bottom: 30px;
}
.mb-80 {
  margin-bottom: 80px;
}
.mt-100 {
  margin-top: 100px;
}
//margin-common

.w-80 {
  width: 80%;
}
.word-break {
  word-break: break-word;
}
.text-danger {
  --bs-text-opacity: 1;
  color: $red !important;
}

.discardModalHeader {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  line-height: 24px !important;
}

.logout-btn {
  color: #0432a3 !important;
}

.logout-btn:hover {
  cursor: pointer;
}

.border-none {
  border: none;
}
.love {
  background: url(../img/heart.svg) no-repeat center;
  width: 15px;
  height: 15px;
  display: inline-block;
}
.icon-like {
  background: url(../img/like.svg) no-repeat center;
  width: 15px;
  height: 15px;
  display: inline-block;
}
.lamp {
  background: url(../img/lamp.svg) no-repeat center;
  width: 15px;
  height: 15px;
  display: inline-block;
}
.sad-emojee {
  background: url(../img/sad-emoj.svg) no-repeat center;
  width: 15px;
  height: 15px;
  display: inline-block;
}
.emojee {
  background: url(../img/emojee.svg) no-repeat center;
  width: 30px;
  height: 30px;
  display: inline-block;
  cursor: pointer;
}
.hand {
  background: url(../img/hand.svg) no-repeat center;
  width: 55px;
  height: 55px;
  display: inline-block;
}
.icon-comment {
  background: url(../img/comment.svg) no-repeat center;
  width: 15px;
  height: 15px;
  display: inline-block;
}

.icon-expand {
  background: url(../img/expand.svg) no-repeat center;
  width: 20px;
  height: 20px;
  display: inline-block;
  position: absolute;
  right: 50px;
  top: 27px;
}

.icon-collapse {
  background: url(../img/collapse.svg) no-repeat center;
  width: 20px;
  height: 20px;
  display: inline-block;
  position: absolute;
  right: 50px;
  top: 27px;
}

.icon-close {
  background: url(../img/X.svg) no-repeat center;
  width: 20px;
  height: 22px;
  display: inline-block;
}
.user-icon {
  background: url(../img/user-icon.svg) no-repeat center;
  width: 20px;
  height: 22px;
  display: inline-block;
}
.paper-plane {
  background: url(../img/paper-plane.svg) no-repeat center;
  width: 20px;
  height: 22px;
  display: inline-block;
}
.icons-share {
  background: url(../img/icon-share.svg) no-repeat center;
  width: 20px;
  height: 22px;
  display: inline-block;
}
.event-icon {
  background: url(../img/event.svg) no-repeat center;
  width: 20px;
  height: 22px;
  display: inline-block;
}
.page-icon {
  background: url(../img/page.svg) no-repeat center;
  width: 20px;
  height: 22px;
  display: inline-block;
}
.artical-icon {
  background: url(../img/article-icon.svg) no-repeat center;
  width: 20px;
  height: 22px;
  display: inline-block;
}
.news-icon {
  background: url(../img/news-icon.svg) no-repeat center;
  width: 20px;
  height: 22px;
  display: inline-block;
}
.hashtag-icon {
  background: url(../img/hastag.svg) no-repeat center;
  width: 20px;
  height: 22px;
  display: inline-block;
}
.calender-icon {
  background: url(../img/calenda.svg) no-repeat center;
  width: 20px;
  height: 20px;
  display: inline-block;
}
.edit-icon {
  background: url(../img/editing.svg) no-repeat center;
  width: 20px;
  height: 22px;
  display: inline-block;
  cursor: pointer;
}
.static-icon {
  background: url(../img/static.svg) no-repeat center;
  width: 20px;
  height: 22px;
  display: inline-block;
  cursor: pointer;
}
.people-icon {
  position: relative;
  top: 2px;
  background: url(../img/fluent_people.svg) no-repeat center;
  width: 20px;
  height: 14px;
  display: inline-block;
  cursor: pointer;
}
.globe {
  background: url(../img/bi_globe.svg) no-repeat center;
  width: 20px;
  height: 14px;
  display: inline-block;
  cursor: pointer;
  background-size: contain;
}

.privateIcon {
  background: url(../img/privateIcon.svg) no-repeat center;
  width: 20px;
  height: 14px;
  display: inline-block;
  cursor: pointer;
  background-size: contain;
}
input::placeholder {
  color: $grey;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  color: rgba(0, 0, 0, 0);
  opacity: 1;
  display: block;
  background: url(../img/calendars.svg) no-repeat;
  width: 20px;
  height: 20px;
  border-width: thin;
  cursor: pointer;
}
input[type="time"]::-webkit-calendar-picker-indicator {
  color: rgba(0, 0, 0, 0);
  opacity: 1;
  display: block;
  background: url(../img/bytesize_clock.svg) no-repeat;
  width: 20px;
  height: 20px;
  border-width: thin;
  cursor: pointer;
}
input[type="time"] {
  color: transparent;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
}
input[type="time"]:invalid::before {
  color: #808080;
  content: attr(placeholder);
  padding-left: 10px;
  pointer-events: none;
  position: absolute;
  left: 2px;
}
input[type="time"]:valid,
input[type="time"]:focus,
input[type="time"]:active {
  color: #000;
}
input[type="time"]:valid:before,
input[type="time"]:focus:before,
input[type="time"]:active:before {
  display: none;
}

input[type="checkbox"]:checked {
  background-color: $primary;
}

.form-check-input:focus {
  outline: 0;
  box-shadow: none;
}

[type="radio"] {
  // background-color: $primary;
  accent-color: $primary;
}

.main-bg {
  // background: #eff2f6 url(../img/background.png) no-repeat;
  background-size: cover;
}
.admin-bg {
  // background: #eff2f6 url(../img/admin-bg.png) no-repeat;
  // background-color: #e5e5e5;
  background-size: cover;
  min-height: 100vh;
}
.form-select {
  background-image: url(../img/drop-down.svg);
  background-size: auto;
}
.featurecamp-form .form-select {
  background-image: url(../img/DownArrowNew.png);
  background-size: auto;
}
.dots {
  background: rgba(4, 50, 163, 0.05);
  display: flex;
  font-size: small;
  background-color: $white;
  // align-items: center;
  gap: 12px;
  gap: 1px;
  justify-content: center;
  // height: 33px;
  width: 33px;
  border-radius: 5px;
  cursor: pointer;
}
.modal-body a:hover {
  color: $primary !important;
}
.image-fit {
  object-fit: cover;
  height: 100%;
  border-radius: 55%;
  width: 100%;
}
.fit-213 {
  width: 100%;
  height: auto;
  object-fit: cover;
}
.img-fluid {
  object-fit: cover;
  height: 100%;
  width: 100%;
}
.img-fit {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.img-fluid-banner {
  object-fit: cover;
  height: 100%;
  width: 100%;
  border-radius: 5px;
}

input:focus-visible {
  outline: none;
}
.form-control:focus {
  box-shadow: none;
}
p {
  margin: 0;
}
textarea:focus-visible {
  outline: none;
}

ul {
  list-style: none;
  padding: 0 !important;
  margin: 0 !important;
}

// .form-check-input:checked {
//   background: $color-3;
// }

.bg-blue {
  background: $color-3;
}
.circle_profile {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.n-logo {
  position: absolute;
  transform: translate(-50%, 50%);
  left: 50%;
  bottom: 0;
  background: $color-1;
  text-align: center;
  padding: 20px;
  border-radius: 50%;
}

.main {
  padding: 100px 0;
}
.forgot .blue-text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  /* primarycolor */
  margin-top: 15px;
  color: #0432a3;
  margin-bottom: 20px;
}
.forgot .undefined .form-label {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #18181b;
}

.inform {
  max-width: 1920px;
  margin-right: auto;
  margin-left: auto;
  background: url(../img/home-circle.png) no-repeat 103%;
  background-size: 46%;
  // padding: 260px 0;
  padding: 200px 0px 210px 0px;
}
.inform .information,
.main .information {
  margin-top: 50px;
}

.main h2,
.inform h2,
.mob h2 {
  font-weight: 600;
  font-size: 36px;
  line-height: 50px;
  color: $color-2;
}

.main p,
.inform p,
.mob p {
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  color: $color-5;
  margin-top: 10px;
}

.mob .display {
  display: flex;
  flex-wrap: wrap;
  direction: rtl;
  //margin-top: 160px;
}

.mob2 .display1 {
  display: flex;
  flex-wrap: wrap;
  direction: ltr;
  //margin-top: 160px;
}

// .mob4 .display1 {
//   display: flex;
//   flex-wrap: wrap;
//   direction: ltr;
//   //margin-top: 160px;
// }

.mob .apps {
  gap: 20px;
  display: flex;

  margin-top: 25px;
  flex-wrap: wrap;
  cursor: pointer;
}

.mob2 .apps {
  gap: 20px;
  display: flex;
  flex-direction: column;
  margin-top: 25px;
  flex-wrap: wrap;
  cursor: pointer;
}

.nobel-page h4 {
  font-weight: 600;
  font-size: 18.75px;
  line-height: 24px;
  color: $color-2;
}
.nobel-page ul li a {
  color: $grey;
}

.line {
  border-top: 1px solid $color-6;
}
.vr-line {
  border-right: 1px solid $black;
}

.nobel-page ul li {
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  color: $color-5;
}

.nobel-page ul {
  .lead-manage {
    cursor: pointer;
  }
  .lead-manage:hover {
    color: $color-3;
  }
}

.nobel-page ul li a:hover {
  color: $color-3;
}

.main,
.inform .information span {
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  color: $color-3;
  cursor: pointer;
}

.main .information::after,
.inform .information::after {
  content: url("../img/arrow.svg");
  margin-left: 12px;
}

.main ul {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  justify-content: flex-end;
  width: 426px;
  float: right;
}

.mob {
  max-width: 1920px;
  margin-right: auto;
  margin-left: auto;
  background: url(../img/mobile.png) no-repeat left;
  //height: 150vh;
  //background-size: 70%;
  padding: 390px 0;
}

.mob4 {
  max-width: 1920px;
  margin-right: auto;
  margin-left: auto;
  background: url(../img/mob3.png) no-repeat right;
  //height: 150vh;
  //background-size: 70%;
  padding: 390px 0;
}

.mob4 .apps {
  gap: 20px;
  //display: flex;
  flex-direction: column;
  margin-top: 25px;
  flex-wrap: wrap;
  cursor: pointer;
}

// .mob2 {
//   max-width: 1920px;
//   margin-right: auto;
//   margin-left: auto;
//   background: url(../img/mob3.png) no-repeat;
//   background-position: right;
//   padding: 390px 0;
//   display: flex;
//   flex-direction: row-reverse;
// }

.main ul li {
  border: 1px solid $color-5;
  border-radius: 5px;
  padding: 10px;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  text-align: right;
  color: $color-5;
}

.forgot span a {
  font-weight: 600;
  text-decoration: none;
  font-size: 15px;
  line-height: 22px;
  color: $color-3;
  margin-top: 15px;
  margin-bottom: 10px;
  display: block;
}

.about .bg {
  background-color: $color-3;
  background-size: auto;
  min-height: 340px;
  color: $color-1;
  display: flex;
  align-items: center;
  position: relative;
}

.about .bg-image {
  background-size: auto;
  background-position: bottom;
  mix-blend-mode: overlay;
}

.about .margin-top {
  margin-top: 35px;
}

.rdw-emoji-modal {
  left: auto;
  right: 0;
}

@media (max-width: 1440px) {
  .about .bg-image {
    background-size: auto;
    width: 100%;
    background-position: bottom;
    mix-blend-mode: overlay;
  }
}

@media (max-width: 1000px) {
  .about .margin-top {
    margin-top: 0px;
  }
}
.gap-2_5 {
  gap: 0.75rem;
}
.bg h1 {
  font-weight: 700;
  font-size: 48px;
  line-height: 70px;
  color: $white;
}

.bg p {
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  color: $white;
}

.bg .n-logo {
  bottom: 0px;
}
.container {
  max-width: 1220px;
  padding-right: 22px;
}

.community .bg {
  //background: url("../../assets/img/legal.png") no-repeat;
  background-color: $color-3;
  background-size: auto;
  min-height: 340px;
  //background-position: bottom;
  color: $color-1;
  display: flex;
  align-items: center;
  position: relative;
}

.community .bg-image {
  background-size: auto;
  background-position: bottom;
  mix-blend-mode: overlay;
}

.community .margin-top {
  margin-top: 35px;
}
.pagination {
  --bs-pagination-bg: none;
  --bs-pagination-border-color: none;
  border: none;
  --bs-pagination-focus-color: none;
  --bs-pagination-hover-color: none;
  --bs-pagination-active-bg: none;
  --bs-pagination-active-border-color: none;
  --bs-pagination-focus-box-shadow: none;
  --bs-pagination-hover-bg: none;
  --bs-pagination-color: none;
  --bs-pagination-focus-bg: none;
  --bs-pagination-padding-x: 0.3rem !important;

  span {
    cursor: pointer;
  }
}

.landing-page-slider {
  .pagination {
    --bs-pagination-disabled-bg: none;
  }
}

.react-tel-input .form-control {
  height: 37.6px !important;
}
.react-tel-input .country-list {
  max-width: 220px !important;
}
.bottomSpaceFromChatBox {
  margin-bottom: 80px;
  @media screen and (max-width: 767px) {
    margin-bottom: 30px;
  }
}
@media (max-width: 1440px) {
  .community .bg-image {
    background-size: auto;
    width: 78%;
    background-position: bottom;
    mix-blend-mode: overlay;
  }
}

@media (max-width: 1000px) {
  .community .margin-top {
    margin-top: 0px;
  }
}

.modal-body form .radios label {
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  color: $color-5;
}

.modal {
  --bs-modal-width: 831px !important;
}

.modal-body {
  padding: 25px;
  // background: $white;
  max-height: calc(100vh - 200px);
  overflow-y: auto;
}

.modal-fullscreen .modal-content {
  background-color: transparent;
}

.modal-body form label {
  font-weight: 600;
  font-size: 19px;
  line-height: 24px;
  color: $color-2;
}

::-webkit-file-upload-button {
  display: none;
}

::file-selector-button {
  display: none;
}
select {
  text-wrap: wrap;
}

.custom::after {
  content: "Upload";
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  color: $color-3;
  position: absolute;
  right: 20px;
  top: 10px;
}

.custom::before {
  content: url(../img/Logout.svg);
  position: absolute;
  right: 82px;
  top: 8px;
}

input {
  position: relative;
}

select {
  color: #212529 !important;
}

.form-select {
  color: $grey !important;
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

.blue {
  background: $color-3;
  display: inline-block;
  padding: 10px 35px;
  border-radius: 5px;
}

.blue a {
  color: $color-1;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
}

.feedback .helpfull {
  display: flex;
  justify-content: space-between;
  border: 1px solid $color-6;
  border-radius: 5px;
  padding: 20px;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  color: $color-5;
  align-items: center;
  margin-top: 50px;
}

.feedback .helpfull p {
  margin-bottom: 0;
}

.feedback .buttons a {
  width: 87px;
  height: 40px;
  border: 1px solid $color-3;
  color: $color-3;
  border-radius: 5px;
  display: inline-block;
  line-height: 40px;
  text-align: center;
  margin-left: 20px;
}

.feedback .buttons a .active {
  background: $color-3;
  color: $color-1;
}

.context .background {
  background: url(../img/bgimage-smaller.jpg) no-repeat center;
  padding: 100px 0;
  background-size: contain;
  @media (max-width: 767px) {
    padding: 50px 0;
  }
}

.context .padding {
  padding: 100px 0;
}

.context .text h6 {
  font-weight: 600;
  font-size: 19px;
  line-height: 24px;
  color: $color-2;
  margin-bottom: 20px;
}

.context .text p {
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  color: $color-5;
}

.loginfooter ul {
  display: flex;
  gap: 30px;
  justify-content: flex-end;
}

.loginfooter ul li a,
.footer p {
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
  letter-spacing: 0.1px;
}

.loginfooter ul .inactive a {
  color: $grey;
}

.loginfooter ul .active a {
  color: $primary;
}

.loginfooter ul li a:hover {
  color: $color-3;
}

footer {
  margin-top: 30px;
}

footer .text-center ul {
  display: flex;
  justify-content: center;
  column-gap: 15px;
  align-items: center;
  // list-style: disc;
  flex-wrap: wrap;
  padding: 40px 0;
  //  width: 923px;
  margin: 55px auto;
  .hide {
    list-style: none;
  }
}
footer .text-center ul li {
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
  text-align: center;
  letter-spacing: 0.1px;
  color: $color-5;
  margin-left: 10px;
  margin-right: 10px;
  span {
    position: relative;
    @media (max-width: 767px) {
      right: 0;
    }
  }
}
// footer .text-center ul ::marker {
//   font-size: 15px;
//   content:"&#x2022";
// }
footer .text-center ul .disc-size {
  font-size: 8px;
}

footer .text-center ul li a {
  color: $grey;
}

footer .text-center ul li a:hover {
  color: $color-3;
}

@media (max-width: 1600px) {
  .mob {
    background-size: 60%;
    padding: 320px 0;
  }
}
@media (max-width: 1300px) {
  .container {
    padding-right: calc(var(--bs-gutter-x) * 0.5);
  }
}
@media (max-width: 1092px) {
  .about .bg {
    background-size: cover;
  }
  .main {
    padding: 60px 0;
  }
  .mob,
  .inform,
  .mob4 {
    background-size: 300px !important;
    padding: 40px 20px 50px 0;
  }
  .mob4 {
    background-position: center right 20%;
  }
  .mob {
    background-position: center left 20%;
  }
  .community .bg {
    background-size: cover;
  }

  .legal .bg {
    background-size: cover;
  }

  .help .bg {
    background-size: cover;
  }
}

@media (max-width: 991px) {
  .mob,
  .inform,
  .mob4 {
    padding: 30px 220px 30px 0 !important;
    background-size: 220px !important;
    background-position: center right;
  }
  footer .text-center ul {
    width: auto;
    // display: block;
    margin-left: 20px;
  }
  footer .text-center ul li {
    text-align: left;
    line-height: 1.4;
  }
  .main {
    padding-top: 20px;
    padding-bottom: 35px;
  }
  .main h2,
  .inform h2,
  .mob h2,
  .mob4 h2 {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 15px;
  }
  .main p,
  .inform p,
  .mob p,
  .mob4 p {
    margin: 0 auto 10px;
  }
  .mob .apps {
    justify-content: center;
  }
  .mob {
    height: auto;
  }
  .banner .heading {
    padding-top: 15px !important;
  }
  .banner form {
    width: auto;
  }
  .main ul {
    margin-top: 25px !important;
    width: auto;
    flex-direction: inherit;
    justify-content: start;
  }
  .mob .display {
    direction: ltr;
    margin: 0;
  }
  .mob2 .display1 {
    direction: rtl;
    margin: 0;
  }

  .banner .heading h1 {
    font-weight: 700;
    font-size: 25px;
    line-height: 38px;
    color: $color-1;
  }

  .login .navbar-brand {
    width: 55%;
  }

  .login .navbar-brand img {
    width: 150px;
  }

  .bg h1 {
    font-weight: 700;
    font-size: 45px;
    line-height: 53px;
  }

  .loginfooter ul {
    display: block;
    padding: 0;
  }

  .help .boxes .search-box input {
    width: auto;
  }
  .category-footers {
    padding-bottom: 0px !important;
  }
  .footer-logo {
    margin-bottom: 30px;
    text-align: center;
  }
}

@media (max-width: 767px) {
  .mob,
  .inform,
  .mob4 {
    padding: 30px 220px 30px 0 !important;
    background-size: 220px !important;
    background-position: center right;
  }
}
@media screen and (max-width: 600px) {
  .banner .heading h1 {
    font-size: 22px !important;
    line-height: 1.4 !important;
  }
  .main h2,
  .inform h2,
  .mob h2,
  .mob4 h2 {
    font-size: 20px;
    line-height: 1.4;
  }
  .mob,
  .inform,
  .mob4 {
    padding: 40px 10px 230px !important;
    background-size: 200px !important;
    background-position: center bottom !important;
  }
  .mob .apps,
  .mob4 .apps {
    display: flex;
    flex-flow: row nowrap;
    gap: 10px;
    margin-top: 15px;
    img {
      width: auto !important;
      max-width: 100px !important;
      margin: 0 !important;
    }
  }
}
@media (max-width: 450px) {
  .login .navbar-brand img {
    width: 80%;
  }

  .help .boxes {
    flex-wrap: wrap;
  }

  .help .wrapper .heading h4 {
    font-size: 22px;
  }
}

.auth-form {
  background: $color-1;
  padding: 24px;
  backdrop-filter: blur(5px);
  border-radius: 5px;
  position: relative;
  width: 454px;
  margin-bottom: 55px;
  float: right;

  h2 {
    font-weight: 600;
    padding-block: 0px 10px;
    font-size: 30px;
    line-height: 1.5;
    // margin-left: 5px;
  }
  .checkbox {
    font-weight: 400;
    font-size: 13.25px;
    line-height: 20px;
    display: flex;
    align-items: flex-start;
    color: $color-5;
    justify-content: space-between;
    margin-top: 20px;
    margin-block: 30px;
    @media screen and (max-width: 767px) {
      margin-block: 20px;
    }
  }

  .checkbox a {
    color: $color-5;
    .forgot {
      font-size: 13.25px;
      font-family: Inter;
      line-height: 20px;
    }
  }

  .banner form .checkbox label {
    color: $color-4;
    font-weight: 500;
  }

  .banner .btn:hover {
    color: $color-1;
    background-color: $color-3;
  }

  .label-input100 {
    font-family: Inter, sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    text-align: left;
  }

  .form-check-input[type="checkbox"] {
    border-radius: 4px;
    height: 16px;
    width: 16px;
  }
  .form-check-input[type="checkbox"]:active {
    background-color: #adbadb;
    border-radius: 4px;
    height: 16px;
    width: 16px;
  }
}
.text-blue {
  color: $color-3;
}
.text-orange {
  color: $orange;
}
.overflow-x-scroll {
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}

.create-account {
  font-size: 14px;
  margin-top: 20px;
  font-weight: 500;
}

.homepage-inform {
  padding-right: 62px;
}

.category-footers {
  padding-bottom: 64px;
}

.category-title {
  padding-bottom: 20px;
}

body:not(.modal-open) {
  padding-right: 0px !important;
}

.card-control-text-twoline {
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
}
.card-control-text-fourline {
  -webkit-line-clamp: 4;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
}

.card-control-text-oneline {
  display: inline;
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
}
.Toastify__toast--success {
  .Toastify__toast-icon {
    svg {
      fill: $primary !important;
    }
  }
  .Toastify__progress-bar {
    background-color: $primary !important;
  }
}

.Toastify__toast--warning {
  .Toastify__toast-icon {
    svg {
      fill: $primary !important;
    }
  }
  .Toastify__progress-bar--warning {
    background-color: $primary !important;
  }
}

// .Toastify__toast--error{
//   .Toastify__progress-bar--error {
//     background-color: $orange !important;
//   }
// }

@media (max-width: 580px) {
  .card-control-text-oneline {
    display: inline;
    -webkit-line-clamp: 1;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    word-break: break-all;
    padding-right: 5px;
    // display: inline-block;
    width: 100%;
    &.text-secondary {
      padding-bottom: 0;
      margin-bottom: 5px;
    }
  }
}

.round-image {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  object-fit: cover;
}

.card-control-text-fourline {
  display: inline;
  -webkit-line-clamp: 4;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
}

.advertisement_sidebar {
  background: linear-gradient(
    120.5deg,
    #5b2e92 0%,
    #29409c 50.49%,
    #0f91d2 100%
  );
  padding: 10px;
  color: #ffffff;
  border-radius: 5px;
  cursor: pointer;
}

.advertisement_sidebar h3 {
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  margin: 0px;
  display: flex;
  align-items: center;
}

.height-400px {
  min-height: 400px;
}

.curserPointer {
  cursor: pointer;
}

.underlineOnHover:hover {
  text-decoration: underline;
  cursor: pointer;
}

.rdw-link-modal {
  height: auto !important;
}

.text-container-120 {
  max-width: 140px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.secondarybtn {
  background: rgba(4, 50, 163, 0.05);
  border-radius: 5px;
  color: $primary;
}

.secondarybtn:hover {
  color: $primary !important;
}

.reactEasyCrop_Cover_Horizontal {
  height: 100% !important;
}

.hash {
  background: url(../img/hash-blue.svg) no-repeat center;
  height: 30px;
  width: 30px;
  cursor: pointer;
  background-color: $border;
  border-radius: 50%;
}

.blue-color {
  color: $primary !important;
}

.red-color {
  color: $red;
}

.green-color {
  color: $green;
}

.grey-color {
  color: $grey;
}

.black-color {
  color: $black;
}

.dark-blue-color {
  color: $dark-black;
}
.dark-black {
  color: rgba(24, 24, 27, 1);
}

.fw-600 {
  font-weight: 600;
}

.btn-close:focus {
  box-shadow: none;
  outline: 0;
}

.rs-slider-handle:before {
  top: -4px;
  background-color: $primary !important;
}

.rs-slider-bar {
  height: 1px !important;
}

.rs-slider-progress-bar {
  height: 1px !important;
}

.rs-slider-graduator > ol > li:before {
  display: none !important;
}

.resend-btn:hover {
  cursor: pointer;
}

// .sidebar-sticky {
//   position: sticky;
//   top: 10px;
// }
// .sidebar-sticky-left {
//   position: sticky;
//   // top: -400px;
// }

@media (max-width: 767px) {
  .btn {
    padding: 10px 19px;
  }
  .modal-body span {
    word-break: break-word;
  }
  .event-create-popup .ml-30 {
    margin-left: 10px;
  }
  .auth-form h2 {
    font-size: 28px;
  }
  .bg h1 {
    font-size: 28px;
    line-height: 36px;
    margin-top: 20px;
  }
  .bg p {
    margin-top: 10px;
  }
  .inform .information,
  .main .information {
    margin-block: 10px;
  }
  .pagination-slider {
    padding-top: 20px !important;
  }
  .homepage-inform {
    padding-right: 0;
  }
  .nobel-page .footer-logo {
    text-align: center;
  }
  .about .bg-image {
    max-width: 200px;
    margin: auto;
    display: block;
  }
  .community .bg-image {
    max-width: 200px;
    margin: auto;
    display: block;
  }
  .context .padding {
    padding: 60px 0 20px;
  }
  .modal-body {
    padding: 20px;
  }
  .modal-body form label {
    font-size: 16px;
  }
}

@media (min-width: 576px) {
  .btn {
    padding: 10px 19px;
  }
}
@media (min-width: 1500px) {
  .inform {
    background-size: 39%;
  }
}

.border-radius-5 {
  border-radius: 5px;
}

.user-circular-img {
  width: 30px;
  height: 30px;
  border-radius: 55%;
  object-fit: cover;
}

.caret {
  display: inline-block;
  margin-left: 5px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 7px 7px 0 7px;
  border-color: #999 transparent transparent transparent;
}

.photo-profile-modal-circleCrop {
  .reactEasyCrop_CropArea {
    border-radius: 55%;
  }
}
.infinite-scroll-component {
  overflow: hidden !important;
}

// .modal-header {
//   align-items: baseline !important;
// }

//Applied custom timepicker css by Suraj
.customTimePicker input {
  font-size: 14px;
  height: 37px;
}

.customTimePicker a {
  top: 7px;
}

.scheduleFeatureCustomTimePicker input {
  // font-size: 14px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  height: 35px !important;
  width: 100% !important;
}
//Applied custom timepicker css by Suraj End

//Applied custom scrollbar for school dropdown in connections by Surajm (Start)
.custom-scrollbar {
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
  &::-webkit-scrollbar-thumb {
    background-color: #363b99;
    border-radius: 10px;
  }
}

.gradient-background {
  .modal-header {
    padding: 30px 30px 20px;
    background: linear-gradient(
      120.5deg,
      #5b2e92 0%,
      #29409c 50.49%,
      #0f91d2 100%
    );
    // height: 70px;
    h4,
    h5 {
      color: white !important;
    }
    .btn-close {
      --bs-btn-close-color: white !important;
      background: url(../../assets/img/close-white.svg) no-repeat !important ;
      opacity: 1.5;
    }
  }
}

@media (max-width: 767px) {
  .community {
    .content {
      .text-big {
        font-size: 18px;
      }
    }
  }
  .post-campaign-details {
    padding: 0 !important;
  }
  .intro.edit-profile {
    .popup {
      padding-left: 0;
      padding-right: 0;
    }
  }
}
@media (max-width: 359px) {
  .auth-form {
    .checkbox {
      a .forgot {
        font-size: 11px;
      }
      .form-check-label {
        font-size: 11px;
      }
    }
  }
}
