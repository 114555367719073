.social-login-button {
  display:flex;
  padding: 8px 10px;
  background: transparent;
  align-items: center;
  border:1px solid #d1dfff;
  border-radius:5px;
}

.social-login-button-text{
  font-size: 14px;
}
