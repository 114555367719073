@import "../../../assets/scss/mixin.scss";

.controls {
  height: 40px;
  display: flex;
  align-items: center;
}

.zoom-range {
  height: 1px;
 // border-top: 5px solid #fff;
 // border-bottom: 5px solid #fff;
  background: #0432a3;
  width: 100%;
 // border: 1px solid #D1DFFF;
}

.zoom-range::-moz-range-thumb {
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 1px solid #3f51b5;
  background: #3f51b5;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.zoom-range::-webkit-slider-thumb {
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 1px solid #3f51b5;
  background: #3f51b5;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.controls:hover input[type="range"]::-webkit-slider-thumb {
  box-shadow: 0px 0px 0px 8px rgba(63, 81, 181, 0.16);
  border-radius: 50%;
}

.controls:hover input[type="range"]::-moz-range-thumb {
  box-shadow: 0px 0px 0px 8px rgba(63, 81, 181, 0.16);
}

.sub-heading-slider {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  a {
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;
    color: #0432a3;
  }
  .blue {
    background: #0432a3;
    border-radius: 5px;
    color: white;
    padding: 10px 25px;
  }
}
.image-cropper {
  .modal-title {
    @include font-600;
    font-size: 19px;
    color:$dark-black;
  }
  .sub-heading-slider {
    span {
      @include font-600;
      font-size: 14px;
      line-height: 24px;
    }
  }
  .modal-body {
    span {
      margin: 0;
      font-weight: 600;
      font-size: 15px;
      line-height: 22px;
      color: #0432a3;
      cursor: pointer;
    }
    .buttons {
      .transparent {
        background: rgba(4, 50, 163, 0.05);
        border-radius: 5px;
        @include font-600;
        color: $primary;
        margin-right: 30px;
        @media (max-width:767px) {
          margin-right: 10px;
        }
      }
      .btn {
        padding: 8px 30px;

      }
    }
  }
}

@media(max-width:991px) {
  .image-cropper{
    .buttons {
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      gap:25px;
    }
  }
}