@import "../../assets/scss/mixin.scss";
@import "../../assets/scss/variable.scss";

.pull-right {
  // display: flex;
  // justify-content: right;
  .chat-box {
    background: $linear;
    padding: 10px 30px;
    width: 274px;
    height: 68px;
    border-radius: 5px 5px 0px 0px;
    position: fixed;
    bottom: 0px;
    right: 0px;
    @media (max-width: 450px) {
      display: none;
    }
    .chat {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .wrapper {
        display: flex;
        align-items: center;
        gap: 12px;
        .prof-img {
          width: 48px;
          height: 48px;
          position: relative;
          img {
            width: 48px;
            height: 48px;
            border-radius: 55%;
          }
          .active {
            background: green;
            width: 10px;
            box-shadow: 0px 0px 1px 3px white;
            height: 10px;
            border-radius: 55%;
            position: absolute;
            right: 0px;
            bottom: 5px;
          }
        }
        .message {
          span {
            font-weight: 600;
            font-size: 15px;
            line-height: 22px;
            color: #ffffff;
          }
        }
        .online {
          background: #d9534f;
          border-radius: 50%;
          width: 23px;
          height: 23px;
          padding: 2px 7px;
          span {
            color: $white;
          }
        }
      }
      .search {
        .icon {
          box-sizing: border-box;
          position: relative;
          display: block;
          transform: scale(var(--ggs, 1));
          width: 16px;
          height: 16px;
          border: 2px solid $white;
          border-radius: 100%;
          margin-left: -4px;
          margin-top: -4px;
          cursor: pointer;
          &::before {
            content: "";
            display: block;
            box-sizing: border-box;
            position: absolute;
            border-radius: 3px;
            width: 2px;
            height: 8px;
            background: $white;
            transform: rotate(-45deg);
            top: 10px;
            left: 12px;
          }
        }
      }
    }
  }
}
