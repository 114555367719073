@import "../../../../assets/scss/variable.scss";

.rich-editor-share {
  padding: 0px;

  .ql-container.ql-snow {
    border: none;
}

.ql-editor {
  padding: 0px 6px;
  margin: 25px 0px;
  font-size: 16px;
  color:$grey;
}

.ql-editor.ql-blank::before {
  color: rgba(40, 40, 40, 0.5);
  content: attr(data-placeholder);
  left: 6px;
  pointer-events: none;
  position: absolute;
  right: 0px;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
}

  .ql-mention-list {
    list-style: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
  }
  
  .ql-mention-list-container {
    width: 270px;
    height: 200px;
    overflow-y: auto;
    border: 1px solid #f0f0f0;
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 0 2px 12px 0 rgba(30, 30, 30, 0.08);
    z-index: 9001;
  }
  
  .ql-mention-list-item {
    cursor: pointer;
    height: 44px;
    line-height: 44px;
    font-size: 16px;
    padding: 0 20px;
    vertical-align: middle;
  }
  
  .ql-mention-list-item.selected {
    background-color: #d3e1eb;
    text-decoration: none;
  }
  
  .mention {
    span {
      color:$primary
    }
  }
  
  .ql-editor {
    height: 100%;
    max-height: 300px;
    overflow-y: auto;
  }

}
  