.media-view {
  position: relative;
  .media-box {
    padding: 20px;
    // width: 100px;
    // height: 100px;
  }
  .cross {
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    padding: 5px;
  }
}

.msg {
  .media-view {
    .media-box {
      padding: unset;
    }
  }
}

.media-doc {
  display: flex;
  align-items: center;
  gap: 10px;
}
