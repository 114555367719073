.interview-preperation-section {
  height: 100vh;
  //   background-color: #eff2f6;
  padding-top: 26px;
  .accordion-button:not(.collapsed)::after {
    background-image: none !important;
  }
  .wrappper {
    padding: 30px;
  }
  .container {
    background-color: #fff;
    height: 100%;
  }
  h2 {
    color: #01052d;
    font-size: 30px;
    font-weight: 600;
    line-height: 44px;
  }
  .head {
    p {
      color: #676a79;
      font-size: 15px;
      font-weight: 400;
      line-height: 22.5px;
    }
  }
  .tabs-section {
    margin-top: 40px;
    .accordion-item {
      border: 0;
      margin-top: 24px;
    }
    .accordion-content {
      padding-right: 30px;
    }
    h3 {
      color: #676a79;
      font-size: 19px;
      font-weight: 600;
      line-height: 24px;
      margin-bottom: 27px;
      background-color: transparent;
      box-shadow: none;
      padding: 0;
      cursor: pointer;
    }
    .tab-contents {
      margin-top: 10px;
    }
    .tabs {
      display: flex;
      flex-wrap: wrap;
      gap: 58px;
      .tab {
        position: relative;
        padding-left: 30px;
        color: #01052d;
        font-size: 19px;
        font-weight: 600;
        line-height: 24px;
        cursor: pointer;
        &.active {
          padding-bottom: 15px;
          position: relative;
          &::after {
            content: "";
            clear: both;
            display: block;
            width: 100%;
            height: 1px;
            background-color: #0432a3;
            position: absolute;
            left: 0;
            bottom: 0;
          }
        }
        &::before {
          content: "";
          background-size: 100%;
          width: 24px;
          height: 24px;
          position: absolute;
          top: 0;
          left: 0;
        }
        &:nth-child(1) {
          &::before {
            background: url("../../assets/img/processIcon1.svg") 0 0 no-repeat;
          }
        }
        &:nth-child(2) {
          &::before {
            background: url("../../assets/img/processIcon2.svg") 0 0 no-repeat;
          }
        }
        &:nth-child(3) {
          &::before {
            background: url("../../assets/img/processIcon3.svg") 0 0 no-repeat;
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .interview-preperation-section {
    .wrappper {
      padding: 20px 0;
    }
    h2 {
      font-size: 18px;
    }
    .tabs-section {
      .tabs {
        gap: 10px;
        .tab {
          font-size: 12px;
        }
      }
      h3 {
        font-size: 15px;
        margin-bottom: 10px;
      }
      .accordion-item{
        margin-top: 10px;
      }
    }
    .filter-row-res{
      width:100%;
    }
  }
}

.interview-prepare-accordion {
  .accordion-button:not(.collapsed)::after {
    background-image: none !important;
  }
  .accordion-button::after {
    background-image: none !important;
  }
}
