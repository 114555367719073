@import "../../../../assets/scss/mixin.scss";
@import "../../../../assets/scss/variable.scss";

.comments-box-main {
  .thoughts {
    display: flex;
    gap: 23px;

    .pro-img {
      width: 35px;
      height: 35px;
      border-radius: 55%;

      img {
        width: 35px;
        height: 35px;
        border-radius: 55%;
        object-fit: cover;
      }
    }

    .type {
      width: 100%;

      input {
        border: none;
        border-bottom: 1px solid #d1dfff;
        border-radius: 0px;
        width: 100%;
        padding-right: 30px;
      }
    }
  }

  .read {
    .commentDiv {
      // justify-content: space-around;
      // flex-wrap: nowrap;

      .prof-img {
        max-width: 60px !important;
      }

      // .reply-prof-img{
      //   max-width: 0px !important;
      // }
    }

    .width_main_box {
      width: 87%;
      padding: 0px;
    }

    @media (max-width: 500px) {
      .width_main_box {
        width: 100%;
        padding: 0px;
      }
    }

    .likes_comment {
      display: none;
    }

    .likes_comment {
      background: #ffffff;
      border-radius: 13px;
      bottom: 36px;
      left: 2px;
      width: 190px;
      height: 40px;
      position: absolute;
      align-items: center;
      justify-content: space-around;
      border: 1px solid #e5e5e5;
      box-shadow: 0 5px 24px rgba(0, 0, 0, 0.1);
      img {
        cursor: pointer;
      }
      img:hover {
        transform: scale(1.7);
      }
    }

    // .like_main_comment {

    //   transition: opacity  1s ease;
    // }

    .like_main_comment:hover .likes_comment {
      display: flex;
    }

    .likeShareDiv {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    .prof-img {
      width: 100px;
      height: 35px;
      border-radius: 55%;

      img {
        width: 35px;
        height: 35px;
        border-radius: 55%;
        object-fit: cover;
      }
    }

    .msg-box {
      // display: flex;
      flex-direction: row;
      // justify-content: space-between;
      background: rgba(238, 240, 242, 0.35);
      border-radius: 0px 5px 5px 5px;
      position: relative;
      padding: 20px 20px 10px 20px;

      .commentNameDiv {
        display: flex;
        flex-direction: column;
        .namewidth {
          width: 64%;
          display: flex;
          align-items: center;
        }
        .networkNameWidth {
          width: 60%;
          display: flex;
          align-items: center;
        }
      }
      .pin-text {
        color: $grey !important;
        font-size: small;
      }

      h5 {
        @include h1;
        font-size: 15px;
        line-height: 20px;
      }

      p {
        @include para;
        font-size: 14px;
        outline: none;
      }
      .threedots {
        display: flex;
        font-size: small;
        gap: 12px;
        gap: 1px;
        justify-content: center;
        width: 33px;
        border-radius: 5px;
        cursor: pointer;
        align-items: center;
        span {
          color: $dark-black;
        }
      }
      .drop {
        .box {
          position: absolute;
          background: #ffffff;
          border-radius: 5px;
          padding: 17px 15px;
          z-index: 50;
          right: 2.1rem;
          top: 40px;
          border: 1px solid #e5e5e5;
        }
        ul {
          li {
            @include para;
            display: flex;
            align-items: center;
            gap: 15px;
            line-height: 34px;
            cursor: pointer;
          }
          li:hover {
            color: $primary;
          }
        }
      }

      span {
        color: #19bcf4;
        font-size: 10px;
        font-weight: 400;
        cursor: pointer;
      }

      .time {
        // position: absolute;
        // top: 22px;
        // right: 15px;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: $grey;

        span {
          color: $grey;
          font-size: 14px;
        }
      }
    }

    .flex {
      display: flex;
      gap: 16px;
      justify-content: normal;
      position: relative;
      padding: 1px 0px 15px 0px;
      // span {
      // font-size: 14px;
      // }
    }
  }

  .short {
    .form-select {
      border: none;
      padding: 5px 16px 5px 10px;
      display: flex;
      width: 25%;
      margin-bottom: 13px;
      font-size: 15px;

      span {
        color: $grey;
        white-space: nowrap;
        width: 170px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .box {
      position: absolute;
      background: #ffffff;
      border-radius: 5px;
      padding: 17px 15px;
      z-index: 50;
      left: 1.2rem;
      margin-top: 30px;
      border: 1px solid #e5e5e5;
      width: 180px;
      @media (max-width: 767px) {
        width: auto;
      }
    }
    .box-network {
      position: absolute;
      background: #ffffff;
      border-radius: 5px;
      padding: 17px 15px;
      z-index: 50;
      left: 0.2rem;
      margin-top: 30px;
      border: 1px solid #e5e5e5;
      width: 32%;
      @media (max-width: 767px) {
        width: auto;
      }
    }
    ul {
      li {
        @include para;
        display: flex;
        align-items: center;
        gap: 15px;
        line-height: 34px;
        cursor: pointer;
      }
      li:hover {
        color: $primary;
      }
    }
    // .no-dropdown {
    //   border: none !important;
    //   background-image: none !important;
    // }
  }
}
