@import "../../../../assets/scss/mixin.scss";
@import "../../../../assets/scss/variable.scss";

.upgrade-sidebar {
  min-height: 268px;
  border-radius: 5px;
  background: linear-gradient(
    135deg,
    rgba(91, 46, 146, 0.1) 0%,
    rgba(41, 64, 156, 0.1) 50.49%,
    rgba(15, 145, 210, 0.1) 100%
  );

  .upgrade_header {
    color: $dark-black;
    font-size: 19px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  }
}
