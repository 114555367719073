@import "../../../assets/scss/mixin.scss";
@import "../../../assets/scss/variable.scss";

.explore-details {
  h2 {
    @include h1;
  }
  .img {
    img {
      height: 415px;
    }
    .d-flex {
      margin-top: 30px;
      margin-bottom: 30px;
      justify-content: space-between;
      span {
        @include para;
      }
      .publish {
        span {
          &::before {
            content: url("../../../assets/img/calenda.svg");
            position: relative;
            top: 2px;
            margin-right: 9px;
          }
        }
      }
      .follow {
        span {
          border: 1px solid $grey;
          border-radius: 25px;
          padding: 5px 10px;
          cursor: pointer;
        }
      }
    }
    .content {
      margin-top: 30px;
      p {
        @include para;
      }
    }
  }
  h4 {
    margin-top: 30px;
    @include h1;
  }
  .comments {
    margin-top: 60px;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    span {
      @include para;
    }
  }
  .likes {
    display: flex;
    gap: 20px;
    margin-top: 25px;
    span {
      @include para;
      cursor: pointer;
      @include center-text;
      gap: 5px;
    }
  }
  .pro {
    margin-top: 60px;
    margin-bottom: 15px;
    justify-content: space-between;
    .d-flex {
      display: flex;
      gap: 15px;
      align-items: center;

      .profile-img {
        width: 46px;
        height: 46px;
      }
      .name {
        h6 {
          margin: 0;
          font-weight: 500;
          font-size: 18px;
          line-height: 22px;
          text-transform: uppercase;
        }
      }
    }
    .month {
      span {
        @include para;
      }
    }
  }
  .content {
    margin-top: 30px;
    .like {
      display: flex;
      gap: 25px;
      margin: 20px 0;
      span {
        @include para;
      }
    }
  }
  .article {
    margin: 55px 0;
    @media (max-width: 767px) {
      margin: 30px 0;
    }
    h5 {
      @include font-600;
      font-size: 30px;
      line-height: 44px;
      color: $dark-black;
      @media (max-width: 767px) {
        font-size: 24px;
        line-height: 34px;
      }
    }
    .thumbnail {
      border: 0.2px solid $border;
      border-radius: 5px;
      margin-bottom: 19px;
      min-height: 420px;
      .text {
        color: $dark-black;
        cursor: pointer;
      }
      .main-texts p {
        color: grey;
      }
      .img {
        img {
          height: 283.68px;
        }
      }
    }
  }
  .other-viewed {
    h5 {
      @include font-600;
      font-size: 30px;
      margin-bottom: 35px;
      margin-top: 10px;
      color: $dark-black;
      @media (max-width: 767px) {
        font-size: 24px;
        margin-bottom: 32px;
      }
    }
  }
  .box-1 {
    margin-bottom: 25px;

    h6 {
      @include font-600;
      font-size: 20px;
      margin-top: 20px;
      width: 283px;
      color: $dark-black;
      cursor: pointer;
      @media (max-width: 767px) {
        width: auto;
        font-size: 18px;
      }
    }
    .img {
      // height: 80px;
      width: 128px;
      @media (max-width: 767px) {
        height: auto;
        width: 100%;
      }
      img {
        height: auto;
      }
    }
    p {
      @include para;
      margin-top: 10px;
    }
  }
  .explore {
    h6 {
      @include font-600;
      font-size: 20px;
      color: $dark-black;
      @media (max-width: 767px) {
        font-size: 18px;
      }
    }
    ul {
      li {
        @include para;
        line-height: 40px;
        @media (max-width: 767px) {
          display: inline-block;
          margin: 0 2px;
        }
        span {
          border: 1px solid $grey;
          border-radius: 25px;
          padding: 3px 18px;
          cursor: pointer;
        }
      }
    }
  }
}

.img-fluid2 {
  max-width: 100%;
  height: 100%;
}
