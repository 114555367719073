@import "../../../assets/scss/mixin.scss";
@import "../../../assets/scss/variable.scss";

.signup-portal {
  display: flex;
  height: 100vh;
  overflow: hidden;
  .form-input {
    .form-control {
      padding-left: 10px !important;
    }
  }
  .left {
    background: #0432a312;
    display: grid;
    align-items: center;
    .center {
      display: flex;
      margin: auto;
      width: 80%;
      position: relative;
    }
  }
  .right {
    background: white;
    .signup-form {
      display: flex;
      justify-content: center;
      min-height: 100vh;
      overflow: hidden;
      align-items: center;
      .auth-form {
        p {
          font-family: Inter;
          font-size: 15px;
          font-weight: 400;
          line-height: 23px;
          letter-spacing: 0em;
          color: #757575;
        }
        .form-heading {
          font-size: 30px;
          font-weight: 600;
          line-height: 44px;
          letter-spacing: 0em;
          color: #000;
          margin-bottom: 10px;
        }
        .sub-heading {
          color: $primary;
        }
        .nobalaccount {
          cursor: pointer;
        }
      }
    }
  }
  .privacy-policy {
    a {
      color: $primary !important;
    }
  }
}

.verify-email {
  .modal-header {
    padding-left: 38%;
    color: #000;
  }

  .inputs input {
    width: 40px;
    height: 40px;
    margin-right: 3px;
    text-align: center;
    padding: 0 !important;
  }
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
  }
  // .card-2 {
  //   background-color: #fff;
  //   padding: 10px;
  //   width: 350px;
  //   height: 100px;
  //   bottom: -50px;
  //   left: 20px;
  //   position: absolute;
  //   border-radius: 5px;
  // }
  // .card-2 .content {
  //   margin-top: 50px;
  // }
  // .card-2 .content a {
  //   color: red;
  // }
  .form-control:focus {
    box-shadow: none;
    border: 2px solid $primary;
  }
  // .validate {
  //   border-radius: 20px;
  //   height: 40px;
  //   background-color: red;
  //   border: 1px solid red;
  //   width: 140px;
  // }
}

@media screen and (max-width: 767px) {
  .signup-portal {
    height: auto;
    flex-direction: column;
    .left,
    .right {
      width: 100%;
    }
    .right {
      display: block;
      padding: 0;
      .login-form {
        display: block;
      }
    }
    .auth-form {
      float: none;
      width: auto;
      img.mb-3 {
        width: 100%;
      }
    }
  }
}

.img-prop {
  right: 10px !important;
  left: unset !important;
}
