@import "../../assets/scss/mixin.scss";
@import "../../assets/scss/variable.scss";

.slick-dots li.slick-active button:before,
.slick-dots li button:before {
  color: transparent !important;
}

.resume {
  margin-top: 10px;
  .default {
    .form-check {
    }
    .lock {
      background: rgba(0, 0, 0, 0.25);
      width: 100%;
      height: 100%;
      z-index: 9;
      position: absolute;
      top: 0;
      left: 0;
      display: grid;
      place-content: center;
      .lock-img {
        background: url("../../assets/img/lock.svg") no-repeat center;
        background-size: 80%;
        height: 60px;
        width: 80px;
        margin: 0 auto;
      }
      p {
        @include font-600;
        color: $white;
      }
    }
  }
  .main-box {
    box-shadow: 0px 2px 4px rgba(171, 190, 209, 0.6);
    border-radius: 5px;
    @media (max-width: 767px) {
      padding: 20px 10px;
    }
    .top {
      margin-bottom: 30px;

      h1 {
        @include font-600;
        font-size: 30px;
        @media (max-width: 767px) {
          font-size: 18px;
        }
      }

      p {
        @include para;
        margin-top: 10px;
        @media (max-width: 767px) {
          font-size: 14px;
        }
      }
    }

    .header {
      .form-check {
        @media (max-width: 767px) {
          padding-left: 0;
        }
      }

      h2 {
        @include font-19;
        @media (max-width: 767px) {
          font-size: 14px;
          line-height: 20px;
        }
        color: #000;
      }

      label {
        @include para;
        margin-left: 10px;
        @media (max-width: 767px) {
          font-size: 14px;
        }
      }

      input {
        margin-left: 10px;
        position: relative;
        top: 3px;
        width: 20px;
        height: 20px;
        @media (max-width: 767px) {
          width: 15px;
          height: 15px;
        }
      }
    }

    .resume-1 {
      margin-top: 16px;
      padding: 30px 20px;
      background: url(../../assets/img/resume1.png) no-repeat center;
      background-size: cover;
      position: relative;
      // height: 550px;
      // overflow: auto;
      @media (max-width: 767px) {
        padding: 20px 10px;
      }
      .profile-img {
        img {
          @media (max-width: 767px) {
            width: 60px;
            height: 60px;
          }
        }
      }
      .skills {
        .heading {
          h3 {
            @include font-14;
            font-weight: 700;
            font-size: 7.33967px;
            line-height: 9px;
            color: #000000;
            margin: 20px 0;

            &::before {
              content: url("../../assets/img/bullet.svg");
              position: relative;
              top: 2px;
            }
          }
        }

        ul {
          display: flex;
          flex-wrap: wrap;
          gap: 8px;

          li {
            @include font-14;
            font-size: 3.91449px;
            background: #ededed;
            border-radius: 2.44656px;
            padding: 0 14px;
            width: fit-content;
            color: $white;
          }
        }
      }

      .honor {
        .heading {
          h3 {
            @include font-14;
            font-weight: 700;
            font-size: 7.33967px;
            line-height: 9px;
            color: #000000;
            margin: 20px 0;

            &::before {
              content: url("../../assets/img/bullet.svg");
              position: relative;
              top: 2px;

              // display: inline-block;
              // background: $primary;
              // height: 24px;
              // width: 24px;
              // box-shadow: 0px 0px 1px 2px $primary;
              // transform: rotate(45deg);
              // border: 3px solid $white;
            }
          }
        }

        .text {
          h4 {
            @include para;
            font-weight: 700;
            font-size: 5.87173px;
          }

          p {
            @include para;
            font-style: italic;
            line-height: 0;
            font-weight: 400;
            font-size: 4.89311px;
          }
        }
      }

      .right {
        position: relative;

        .profile-img {
          border: 1px solid $primary;
        }

        .hr-line {
          width: 1px;
          height: 100%;
          position: absolute;
          background: $primary;
          left: 0;

          &::before {
            content: "";
            background: #d9d9d9;
            border: 0.489311px solid #0432a3;
            height: 5px;
            width: 5px;
            display: inline-block;
            border-radius: 5px;
            position: absolute;
            top: 0;
            left: -2px;
          }

          &::after {
            content: "";
            background: #d9d9d9;
            border: 0.489311px solid #0432a3;
            height: 5px;
            width: 5px;
            display: inline-block;
            border-radius: 5px;
            position: absolute;
            bottom: 0;
            left: -2px;
          }
        }

        .name {
          h2 {
            @include show;
            font-size: 10.7648px;
          }

          h3 {
            @include font-14;
            font-size: 5.87173px;
            color: $primary;
          }

          p {
            @include para;
            font-size: 4.89311px;
            line-height: 6px;
            margin-bottom: 8px;
            word-break: break-all;
          }

          .grid {
            display: grid;
            grid-template-columns: [col1] 33% [col2] 33% [col3] 33%;
            grid-gap: 10px;
            grid-template-rows: [row1] 0% [row2] 0%;

            .grid-cell-1 {
              @include para;
              font-size: 4.89311px;
              line-height: 6px;
              display: flex;
              align-items: center;
            }

            .message-icon {
              background: url(../../assets/img/messageicon.svg) no-repeat;
              display: inline-block;
              height: 5px;
              width: 6px;
              margin-right: 3px;
            }

            .mobile-icon {
              background: url(../../assets/img/smartphoneicon.svg) no-repeat;
              display: inline-block;
              height: 6px;
              width: 6px;
              margin-right: 3px;
            }

            .loction-icon {
              background: url(../../assets/img/loctionicon.svg) no-repeat;
              display: inline-block;
              height: 7px;
              width: 6px;
              margin-right: 3px;
            }

            .nobel-icon {
              background: url(../../assets/img/nobelicon.svg) no-repeat;
              display: inline-block;
              height: 4px;
              width: 6px;
              margin-right: 3px;
            }

            .linkdin-icon {
              background: url(../../assets/img/linkedinicon.svg) no-repeat;
              display: inline-block;
              height: 4px;
              width: 6px;
              margin-right: 3px;
            }
          }
        }
      }
    }

    .work {
      h2 {
        font-weight: 700;
        font-size: 7.33967px;
        line-height: 9px;

        &::before {
          content: url("../../assets/img/bullet.svg");
          position: relative;
          top: 2px;
        }
      }

      h3 {
        margin-top: 5px;
        font-weight: 700;
        font-size: 5.87173px;
        line-height: 7px;
        color: $grey;
      }

      h4 {
        font-weight: 500;
        font-size: 4.87px;
        line-height: 6px;
      }

      .description {
        p {
          font-weight: 400;
          font-size: 4.89311px;
          line-height: 6px;
        }
      }

      h5 {
        margin-top: 3px;
        font-weight: 700;
        font-size: 4.89311px;
        line-height: 6px;
        color: $primary;
      }

      ul {
        list-style: disc;
        margin-left: 8px;
        @media (max-width: 767px) {
          padding-left: 10px !important;
          margin-top: 5px !important;
        }
        li {
          font-weight: 400;
          font-size: 4.89311px;
          line-height: 6px;
          word-break: break-all;
        }
      }
    }

    .download {
      margin-top: 30px;

      p {
        @include font-600;
        color: $primary;
        @media (max-width: 767px) {
          font-size: 10px;
        }
      }

      .edit {
        display: flex;
        gap: 5px;
        cursor: pointer;

        .icon {
          background: url("../../assets/img/edit-blue.svg") no-repeat;
          width: 20px;
          height: 20px;
          display: block;
        }
      }

      .down {
        display: flex;
        gap: 5px;
        cursor: pointer;

        .icon {
          background: url("../../assets/img/document-download.svg") no-repeat;
          width: 20px;
          height: 20px;
          display: block;
        }
      }

      .prev {
        display: flex;
        gap: 5px;
        cursor: pointer;

        .icon {
          background: url("../../assets/img/eye-blue.svg") no-repeat;
          width: 20px;
          height: 20px;
          display: block;
        }
      }
      &.disable {
        opacity: 0.5;
      }
    }

    .second {
      position: relative;
      &.show {
        overflow: auto;
      }
      &.hidden {
        overflow: hidden;
      }
      .resume-2 {
        margin-top: 16px;
        padding: 30px 20px;
        background: url(../../assets/img/resume-2.png) no-repeat center;
        background-size: cover;
        position: relative;
        // height: 550px;
        .profile-img {
          border: 1px solid $primary;
        }

        .blue {
          border-radius: 0px;
          position: relative;

          // &::after {
          //     content: '';
          //     background: $primary;
          //     width: 50px;
          //     height: 100%;
          //     position: absolute;
          //     right: -50px;
          //     top: 0;
          // }

          h2 {
            @include font-14;
            font-weight: 500;
            font-size: 10.7648px;
            color: $white;
          }

          h3 {
            @include font-14;
            font-size: 5.87173px;
            color: $white;
          }

          .line {
            width: 76px;
            margin-bottom: 5px;
          }

          p {
            font-size: 4.89311px;
            color: $white;
            word-break: break-all;
          }
        }

        .list {
          ul {
            display: flex;
            gap: 20px;

            li {
              @include para;
              font-size: 4.89311px;
              line-height: 6px;
              display: flex;
              align-items: center;
            }

            .message-icon {
              background: url(../../assets/img/messageicon.svg) no-repeat;
              display: inline-block;
              height: 5px;
              width: 6px;
              margin-right: 3px;
            }

            .mobile-icon {
              background: url(../../assets/img/smartphoneicon.svg) no-repeat;
              display: inline-block;
              height: 6px;
              width: 6px;
              margin-right: 3px;
            }

            .loction-icon {
              background: url(../../assets/img/loctionicon.svg) no-repeat;
              display: inline-block;
              height: 7px;
              width: 6px;
              margin-right: 3px;
            }

            .nobel-icon {
              background: url(../../assets/img/nobelicon.svg) no-repeat;
              display: inline-block;
              height: 4px;
              width: 6px;
              margin-right: 3px;
            }

            .linkdin-icon {
              background: url(../../assets/img/linkedinicon.svg) no-repeat;
              display: inline-block;
              height: 4px;
              width: 6px;
              margin-right: 3px;
            }
          }
        }

        .skills {
          .line {
            border-color: $primary;
          }

          h3 {
            @include font-14;
            font-size: 7.33967px;
            font-weight: 700;
            color: $primary;
          }

          ul {
            li {
              @include font-14;
              font-size: 8px;
              color: $white;
              background: $primary;
              border-radius: 2px;
              padding: 0px 10px;
              display: inline-block;
              margin-right: 20px;
            }
          }
        }
      }

      .download {
        margin-top: 30px;

        p {
          @include font-600;
          color: $primary;
          @media (max-width: 767px) {
            font-size: 10px;
          }
        }

        .edit {
          display: flex;
          gap: 5px;
          cursor: pointer;

          .icon {
            background: url("../../assets/img/edit-blue.svg") no-repeat;
            width: 20px;
            height: 20px;
            display: block;
          }
        }

        .down {
          display: flex;
          gap: 5px;
          cursor: pointer;

          .icon {
            background: url("../../assets/img/document-download.svg") no-repeat;
            width: 20px;
            height: 20px;
            display: block;
          }
        }

        .prev {
          display: flex;
          gap: 5px;
          cursor: pointer;

          .icon {
            background: url("../../assets/img/eye-blue.svg") no-repeat;
            width: 20px;
            height: 20px;
            display: block;
          }
        }
      }
    }

    .third {
      position: relative;
      &.show {
        overflow: auto;
      }
      &.hidden {
        overflow: hidden;
      }
      .resume-3 {
        margin-top: 16px;
        padding: 30px 20px;
        background: url(../../assets/img/resume-3.png) no-repeat center;
        background-size: cover;
        position: relative;
        // height: 550px;
        .profile-img {
          border: 1px solid $primary;
        }

        .name {
          h2 {
            font-weight: 500;
            font-size: 10.7648px;
          }

          p {
            font-weight: 400;
            font-size: 5.87173px;
            line-height: 7px;
            color: $primary;
            word-break: break-all;
          }
        }

        .para {
          p {
            @include para;
            font-weight: 400;
            font-size: 4.89311px;
            line-height: 6px;
          }
        }

        .blue {
          border-radius: 0px;
          position: relative;

          &::after {
            content: "";
            background: $primary;
            width: 50px;
            height: 100%;
            position: absolute;
            right: -50px;
            top: 0;
          }

          h2 {
            @include font-14;
            font-weight: 500;
            font-size: 10.7648px;
            color: $white;
          }

          h3 {
            @include font-14;
            font-size: 5.87173px;
            color: $white;
          }

          .line {
            width: 76px;
            margin-bottom: 5px;
          }

          p {
            font-size: 4.89311px;
            color: $white;
          }
        }

        .list {
          ul {
            direction: rtl;

            li {
              @include para;
              font-size: 4.89311px;
              line-height: 12px;
              display: flex;
              align-items: center;
              gap: 5px;
            }

            .message-icon {
              background: url(../../assets/img/messageicon.svg) no-repeat;
              display: inline-block;
              height: 5px;
              width: 6px;
              margin-right: 3px;
            }

            .mobile-icon {
              background: url(../../assets/img/smartphoneicon.svg) no-repeat;
              display: inline-block;
              height: 6px;
              width: 6px;
              margin-right: 3px;
            }

            .loction-icon {
              background: url(../../assets/img/loctionicon.svg) no-repeat;
              display: inline-block;
              height: 7px;
              width: 6px;
              margin-right: 3px;
            }

            .nobel-icon {
              background: url(../../assets/img/nobelicon.svg) no-repeat;
              display: inline-block;
              height: 4px;
              width: 6px;
              margin-right: 3px;
            }

            .linkdin-icon {
              background: url(../../assets/img/linkedinicon.svg) no-repeat;
              display: inline-block;
              height: 4px;
              width: 6px;
              margin-right: 3px;
            }
          }
        }

        .boder {
          position: relative;

          &::before {
            content: "";
            border: 1px solid $primary;
            position: absolute;
            left: -8px;
            height: 100%;
          }

          .bullet {
            &::after {
              content: "";
              border: 1px solid #0432a3;
              height: 6px;
              width: 6px;
              position: absolute;
              top: -5px;
              left: -10px;
              border-radius: 10px;
            }

            &::before {
              content: "";
              border: 1px solid #0432a3;
              height: 6px;
              width: 6px;
              position: absolute;
              bottom: -5px;
              left: -10px;
              border-radius: 10px;
            }
          }
        }

        .skills {
          .line {
            border-color: $primary;
            position: absolute;
            left: 0;
            width: 45%;
          }

          .intrest {
            ul {
              li {
                font-weight: 400;
                font-size: 3.91449px;
                line-height: 15px;
                /* identical to box height */

                text-align: center;

                color: #000000;
                background: #d9d9d9;
              }
            }
          }

          .bullet {
            border: 1px solid $primary;
            border-radius: 5px;
            width: 5px;
            height: 5px;
            position: absolute;
            left: 10px;
          }

          .line2 {
            border: 0.5px solid $primary;
            position: absolute;
            right: 0;
            width: 48%;
          }

          h4 {
            font-weight: 700;
            font-size: 5.87173px;
            color: $primary;
          }

          h3 {
            @include font-14;
            font-size: 7.33967px;
            font-weight: 700;
            color: $primary;
          }

          ul {
            li {
              @include font-14;
              font-size: 8px;
              color: $white;
              background: $primary;
              border-radius: 2px;
              padding: 0px 8px;
              display: inline-block;
              margin-right: 20px;
            }
          }

          .text {
            h4 {
              font-weight: 700;
              font-size: 5.87173px;
              color: $grey;
            }

            p {
              font-weight: 400;
              font-size: 4.89311px;
            }
          }

          .description {
            p {
              font-weight: 400;
              font-size: 4.89311px;
            }
          }
        }

        .list {
          list-style: disc;
          margin-top: 10px;
          margin-left: 8px;

          li {
            font-weight: 400;
            font-size: 4.89311px;
            line-height: 6px;
          }
        }

        &.show {
          overflow: auto;
        }
        &.hidden {
          overflow: hidden;
        }
      }

      .download {
        margin-top: 30px;

        p {
          @include font-600;
          color: $primary;
          @media (max-width: 767px) {
            font-size: 10px;
          }
        }

        .edit {
          display: flex;
          gap: 5px;
          cursor: pointer;

          .icon {
            background: url("../../assets/img/edit-blue.svg") no-repeat;
            width: 20px;
            height: 20px;
            display: block;
          }
        }

        .down {
          display: flex;
          gap: 5px;
          cursor: pointer;

          .icon {
            background: url("../../assets/img/document-download.svg") no-repeat;
            width: 20px;
            height: 20px;
            display: block;
          }
        }

        .prev {
          display: flex;
          gap: 5px;
          cursor: pointer;

          .icon {
            background: url("../../assets/img/eye-blue.svg") no-repeat;
            width: 20px;
            height: 20px;
            display: block;
          }
        }
      }
    }

    .fourth {
      position: relative;
      &.show {
        overflow: auto;
      }
      &.hidden {
        overflow: hidden;
      }
      .resume-4 {
        margin-top: 16px;
        padding: 30px 20px;
        background: url(../../assets/img/resume-4.png) no-repeat center;
        background-size: cover;
        position: relative;
        // height: 550px;
        .profile-img {
          border: 3px solid #ffffff;
          position: absolute;
          top: 5px;
          right: -52px;
        }

        .icon {
          background: url("../../assets/img/graduate-icon.svg") no-repeat;
          width: 20px;
          height: 12px;
          display: inline-block;
        }
        .soft-skill-icon {
          background: url("../../assets/img/soft-skills.svg") no-repeat;
          width: 20px;
          height: 12px;
          display: inline-block;
        }
        .certificate-icon {
          background: url("../../assets/img/certificate-icon.svg") no-repeat;
          width: 20px;
          height: 12px;
          display: inline-block;
        }
        .language-icon {
          background: url("../../assets/img/language-icon.svg") no-repeat;
          width: 20px;
          height: 12px;
          display: inline-block;
        }
        .work-icon {
          background: url("../../assets/img/work-icon.svg") no-repeat;
          width: 20px;
          height: 12px;
          display: inline-block;
        }
        .organization-icon {
          background: url("../../assets/img/org-icon.svg") no-repeat;
          width: 20px;
          height: 12px;
          display: inline-block;
        }

        .bg {
          background: rgba(217, 217, 217, 0.2);
          padding: 20px;
        }

        .name {
          h2 {
            font-weight: 500;
            font-size: 10.7648px;
          }

          p {
            font-weight: 400;
            font-size: 5.87173px;
            line-height: 7px;
            color: $primary;
          }
        }

        .para {
          p {
            @include para;
            font-weight: 400;
            font-size: 4.89311px;
            line-height: 6px;
          }
        }

        .blue {
          border-radius: 0px;
          position: relative;
          width: 100%;
          &::after {
            content: "";
            // background: $primary;
            width: 50px;
            height: 100%;
            position: absolute;
            right: -24px;
            top: 0;
          }

          h2 {
            @include font-14;
            font-weight: 500;
            font-size: 10.7648px;
            color: $white;
          }

          h3 {
            @include font-14;
            font-size: 5.87173px;
            color: $white;
          }

          .line {
            width: 76px;
            margin-bottom: 5px;
          }

          p {
            font-size: 4.89311px;
            color: $white;
            word-break: break-all;
          }
        }

        .list {
          ul {
            direction: rtl;

            li {
              @include para;
              font-size: 4.89311px;
              line-height: 12px;
              display: flex;
              align-items: center;
              gap: 5px;
            }

            .message-icon {
              background: url(../../assets/img/messageicon.svg) no-repeat;
              display: inline-block;
              height: 5px;
              width: 6px;
              margin-right: 3px;
            }

            .mobile-icon {
              background: url(../../assets/img/smartphoneicon.svg) no-repeat;
              display: inline-block;
              height: 6px;
              width: 6px;
              margin-right: 3px;
            }

            .loction-icon {
              background: url(../../assets/img/loctionicon.svg) no-repeat;
              display: inline-block;
              height: 7px;
              width: 6px;
              margin-right: 3px;
            }

            .nobel-icon {
              background: url(../../assets/img/nobelicon.svg) no-repeat;
              display: inline-block;
              height: 4px;
              width: 6px;
              margin-right: 3px;
            }

            .linkdin-icon {
              background: url(../../assets/img/linkedinicon.svg) no-repeat;
              display: inline-block;
              height: 4px;
              width: 6px;
              margin-right: 3px;
            }
          }
        }

        .boder {
          position: relative;

          &::before {
            content: "";
            border: 1px solid $primary;
            position: absolute;
            left: -8px;
            height: 100%;
          }

          .bullet {
            &::after {
              content: "";
              border: 1px solid #0432a3;
              height: 6px;
              width: 6px;
              position: absolute;
              top: -5px;
              left: -10px;
              border-radius: 10px;
            }

            &::before {
              content: "";
              border: 1px solid #0432a3;
              height: 6px;
              width: 6px;
              position: absolute;
              bottom: -5px;
              left: -10px;
              border-radius: 10px;
            }
          }
        }

        .skills {
          .line {
            border-color: $primary;
            position: absolute;
            left: 0;
            width: 45%;
          }

          .intrest {
            ul {
              li {
                font-weight: 400;
                font-size: 3.91449px;
                line-height: 15px;
                /* identical to box height */

                text-align: center;

                color: #000000;
                background: #d9d9d9;
              }
            }
          }

          .bullet {
            border: 1px solid $primary;
            border-radius: 5px;
            width: 5px;
            height: 5px;
            position: absolute;
            left: 10px;
          }

          .line2 {
            border: 0.5px solid $primary;
            position: absolute;
            right: 0;
            width: 48%;
          }

          h4 {
            font-weight: 700;
            font-size: 5.87173px;
            color: $primary;
          }

          h3 {
            @include font-14;
            font-size: 7.33967px;
            font-weight: 700;
            color: $primary;
            display: flex;
            align-items: center;
          }
          .pl {
            padding-left: 20px;
          }

          h5 {
            font-weight: 700;
            font-size: 5.87173px;
            color: $grey;
          }

          p {
            font-weight: 400;
            font-size: 4.89311px;
            color: $grey;
            line-height: 6px;
          }

          ul {
            li {
              @include font-14;
              font-size: 8px;
              color: $black;
              background: #ededed;
              border-radius: 2px;
              padding: 0px 8px;
              display: inline-block;
              margin-right: 20px;
            }
          }

          .text {
            h4 {
              font-weight: 700;
              font-size: 5.87173px;
              color: $grey;
            }

            p {
              font-weight: 400;
              font-size: 4.89311px;
            }
          }

          .description {
            p {
              font-weight: 400;
              font-size: 4.89311px;
            }
          }
        }

        .list {
          list-style: disc;
          margin-top: 10px;
          margin-left: 8px;

          li {
            font-weight: 400;
            font-size: 4.89311px;
            line-height: 6px;
          }
        }

        &.show {
          overflow: auto;
        }
        &.hidden {
          overflow: hidden;
        }
      }

      .download {
        margin-top: 30px;

        p {
          @include font-600;
          color: $primary;
          @media (max-width: 767px) {
            font-size: 10px;
          }
        }

        .edit {
          display: flex;
          gap: 5px;
          cursor: pointer;

          .icon {
            background: url("../../assets/img/edit-blue.svg") no-repeat;
            width: 20px;
            height: 20px;
            display: block;
          }
        }

        .down {
          display: flex;
          gap: 5px;
          cursor: pointer;

          .icon {
            background: url("../../assets/img/document-download.svg") no-repeat;
            width: 20px;
            height: 20px;
            display: block;
          }
        }

        .prev {
          display: flex;
          gap: 5px;
          cursor: pointer;

          .icon {
            background: url("../../assets/img/eye-blue.svg") no-repeat;
            width: 20px;
            height: 20px;
            display: block;
          }
        }
      }
    }
  }
}
.edit-resume {
  .change {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;
    color: #676a79;
  }
  .bullets {
    display: flex;
    gap: 5px;
    justify-content: center;
    .dot {
      background: rgba(124, 124, 124, 0.5);
      height: 10px;
      width: 10px;
      border-radius: 10px;
      cursor: pointer;
    }
    .active {
      background: #7c7c7c;
    }
  }
  .box {
    border: 1px solid #d1dfff;
    border-radius: 5px;
    width: 32px;
    height: 18px;
  }
  .bg-orange {
    background: #f68a18;
  }
  .bg-primary {
    background: #19bcf4 !important;
  }
  .bg-purple {
    background: #8430c8;
  }
  .bg-red {
    background: #cd001a;
  }
  .bg-green {
    background: #008c39;
  }
  .btn-outline {
    padding: 5px 20px;
    border: 1px solid $primary;
    color: $primary;
  }
  .profile-img {
    margin: 0 auto;
  }
  .edit {
    @include edit-pencil;
  }
  .delete {
    @include trash;
  }
  form {
    h6 {
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 22px;
      color: #01052d;
    }
    select {
      height: 40px;
    }
    label {
      @include font-14;
      color: $grey;
      @media (max-width: 767px) {
        font-size: 12px;
      }
    }
    input {
      border: 1px solid #d1dfff;
      @media (max-width: 767px) {
        font-size: 10px;
      }
    }
    .tick {
    }
    .skill {
      label {
        @include font-600;
        color: #000;
      }
      span {
        ul {
          display: flex;
          flex-wrap: wrap;
          gap: 15px;
          li {
            font-family: "Inter";
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 22px;
            color: #19bcf4;
            @media (max-width: 767px) {
              font-size: 10px;
            }
          }
        }
      }
      .color {
        display: flex;

        .bg {
          background: rgba(238, 240, 242, 0.35);
          display: flex;
          padding: 5px;
          gap: 15px;
          align-items: center;
          .picker {
            display: flex;
            align-items: center;
            gap: 5px;
            font-family: "Inter";
            font-style: normal;
            font-weight: 400;
            font-size: 10px;
            line-height: 20px;
            /* identical to box height, or 200% */

            /* Parafont */

            color: #676a79;
            .box {
              width: 10px;
              height: 10px;
              border-radius: 0px;
            }
          }
        }
        .gr {
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 10px;
          line-height: 20px;
          /* identical to box height, or 200% */

          /* Parafont */

          color: #676a79;
        }
      }
    }
    .ui {
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 22px;
      color: #676a79;
      @media (max-width: 767px) {
        font-size: 12px;
      }
    }
  }
  .resume {
    .main-box {
      .work {
        h5 {
          color: #676a79;
        }
      }
      .resume-1 {
        .right {
          .btn-blue {
            background: #0432a3;
            border-radius: 46px;
            font-family: "Inter";
            font-style: normal;
            font-weight: 600;
            font-size: 15px;
            line-height: 22px;
            color: #ffffff;
            border: none;
            padding: 5px 10px;
            @media (max-width: 767px) {
              font-size: 10px;
            }
          }
          .name {
            h3 {
              color: #19bcf4;
            }
          }
          .hr-line {
            background: #19bcf4;
          }
        }
        .profile-img {
          width: 55.29px;
          height: 55.29px;
        }
        .honor {
          .heading {
            h3 {
              margin: 7px 0;
            }
          }
          .text {
            p {
              line-height: 10px;
            }
            h4 {
              line-height: 10px;
            }
          }
        }
        .skills {
          .heading {
            h3 {
              margin: 7px 0;
            }
          }
          ul {
            gap: 2px;
            li {
              font-weight: 400;
              font-size: 14px;
              line-height: 12px;
              font-size: 3.91449px;
              background: #ededed;
              border-radius: 2.44656px;
              padding: 0 4px;
              width: -moz-fit-content;
              width: fit-content;
            }
          }
        }
      }
    }
  }
}
.resume-popup {
  position: relative;
  .main-box {
    box-shadow: none;
    border-radius: 5px;
  }
  .text {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 19px;
    line-height: 24px;
    /* identical to box height, or 126% */

    color: #ffffff;
  }
  .arrow-left {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: -12px;
  }
  .arrow-right {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 48%;
    right: -12px;
    transform: rotate(178deg);
  }
}

.autocomplete-wrap input {
  display: block !important;
  width: 100% !important;
  padding: 0.375rem 0.75rem !important;
  font-size: 1rem !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;
  color: "#212529" !important;
  appearance: none !important;
  background-color: "#fff" !important;
  background-clip: padding-box !important;
  border-radius: 0.375rem !important;
}
.cv_check {
  .form-check-input {
    width: 20px !important;
    height: 20px !important;
  }
}

.cv-preview-modal .modal-content {
  width: 100%;
  height: 90vh;
  border: 0;
  background: transparent;
}

.cv-preview-modal .modal-header {
  border: 0;
}

.cv-preview-modal .modal-body {
  // max-height: 100vh !important;
}

.toolbar {
  display: none !important;
}

.resume h4 {
  line-height: 10px !important;
  margin-top: 10px !important;
}

.resume-title {
  font-weight: 600;
  font-size: 30px;
  line-height: 44px;
}

.resume-info {
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  color: #676a79;
  max-width: 670px;
}

.resume-card-container {
  max-width: 900px;
  width: 100%;
  margin: auto;
}
.slick-dots li button:before {
  background: #7c7c7c !important;
  border-radius: 50% !important;
  width: 10px !important;
  height: 10px !important;
}

.preview_model {
  .slick-dots {
    display: none !important;
  }
}

.cv_drop_box {
  .dropzone {
    padding-left: 0px !important;
  }
}

// .pdfFullWidth {
//   canvas {
//     width: 100% !important;
//   }
// }
