@import "../../../../assets/scss/mixin.scss";
@import "../../../../assets/scss/variable.scss";
.select-group{
    .names{
        h6{
        white-space: nowrap;
        width: 400px;
        overflow: hidden;
        text-overflow: ellipsis;
        }
    }
}
.create-post{
    .group-select{
        .names{
            cursor: pointer;
        }
    }
}