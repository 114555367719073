@import "src/assets/styles/colors";

.helpWebView {
  height: 100vh;
  overflow: auto;

  .search {
    width: 200px;
    margin-bottom: 5px;
  }

  .help .bg {
    background: url("../../assets/img/help.png") no-repeat;
    -webkit-background-size: cover;
    background-position: bottom;
    color: $color-1;
    display: flex;
    align-items: center;
    position: relative;
    position: relative;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    // background-size: 100%;
    // height: 288px;
  }

  .help .bg {
    @media screen and (max-width:1080px) {
      background-image: url(../../assets/img/helpCenter.png) !important;
      background-color: #0432A3 !important;
      background-size: 100px !important;
      background-repeat: no-repeat !important;
      background-position-x: 90% !important;
      background-position-y: 10% !important;
      min-height: 440px;
    }

    @media (max-width: 760px) {
      background-image: none !important;
    }
  }

  .mobile-res {
    @media (max-width: 760px) {
      font-size: revert !important;
      line-height: normal;
    }
  }


  .contextHelp .paddingHelp {
    padding: 100px 0px 0px 0px;
  }

  // @media (max-width: 500px) {
  .help .boxes {
    align-items: center;
    // }
  }

}

.helpWebView::-webkit-scrollbar {
  // display: none;
  /* Safari and Chrome */
  // background-color: white;
  // color: white;
  width: 1px !important;
}