.background-image {
    height: 100vh;
    width: 100%;
    background-image: url('./background.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.unlock-feature-title {
    font-size: 19px;
    font-weight: 600;
    line-height: 24px;
    color: #01052D;
}

.unlock-feature-detail {
    font-size: 15px;
    font-weight: 400;
    line-height: 22px;
    color: #676A79;
}