@import "../../assets/scss/mixin.scss";
@import "../../assets/scss/variable.scss";

.mobile {
  .bg {
    background: $primary;
    color: $white;
    min-height: 340px;
    display: flex;
    //align-items: center;
    padding-top:39px;
    padding-bottom:60px;
    position: relative;
  }
  .mob {
    background-size: 47%;
  }


  section {
    padding: 110px 0;
    background-position: right;
    .display {
      direction: ltr;
      .apps {
        display: block;
        margin-top: 40px;
        img {
          display: block;
          margin-bottom: 20px;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .mobile {
    section {
      padding: 60px 0 20px;
    }
  }
}


//Added New Css for mobile Header by Suraj
.mobile{
  .bgNew {
    background: $primary;
    color: $white;
    min-height: 379.7px;
    display: flex;
    //align-items: center;
    padding-top:39px;
    padding-bottom:60px;
    position: relative;
  }
}