@import "../../../assets/scss/mixin.scss";
@import "../../../assets/scss/variable.scss";

.wrapper-class {
  border: none;

  .image-upload {
    height: 225px;
    background: rgba(238, 240, 242, 0.35);
    border: 1px dashed #d1dfff;
    cursor: pointer;
    position: relative;

    .img-fluid {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }

    .center {
      text-align: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      @include font-14;
      color: $grey;
    }
  }
}

.editor-class {
  padding: 0rem 1rem;
  border: 1px solid #d1dfff;
  min-height: 300px;
  border-radius: 0.375rem;
}

.toolbar-class {
  border: 1px solid #ccc;
}
.accessicon {
  width: 20px;
  height: 17px;
  display: inline-block;
}

.preview {
  font-weight: normal;
  color: #0432a3;
  margin-top: 0px;
  cursor: pointer;
}

.rdw-editor-toolbar {
  border: none;
  margin-bottom: 1.5rem;
}

.rdw-option-wrapper {
  border: none;
}

.input-type {
  margin-top: 2rem;
  border: 1px solid #d1dfff;
}

.preview-button {
  margin-top: 1rem;
  text-align: right;
  color: #0432a3;
}

.title-count {
  text-align: right;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  color: #676a79;
  font-size: 12px;
}

.page-editor {
  .rdw-editor-toolbar {
    display: none;
  }
  .rdw-suggestion-dropdown {
    position: relative !important;
  }
  .rdw-editor-main {
    overflow-y: auto;
    overflow-x: hidden;
  }
}

.createpage {
  .modal-header {
    background: $linear;
  }

  .modal-title {
    color: $white;
  }

  .btn-close {
    color: $white;
    background: url(../../../assets/img/X.svg) no-repeat center;
    opacity: 5;
  }
  .autocomplete-wrapper {
    position: relative;
  }

  .autocomplete-wrapper input {
    border: 1px solid #d1dfff;
    width: 100%;
    padding: 0.375rem 0.75rem;
    border-radius: 4px;
  }
  // .autocomplete-wrapper input:focus {
  //   border-color: #0F67FF;
  //   box-shadow: none;
  //   outline: none;
  // }
  .autocomplete-wrapper .dropdown {
    width: 96%;
    padding: 0;
    text-align: left;
    max-height: 100px;
    overflow: hidden;
    overflow-y: auto;
    position: absolute;
    z-index: 999;
    background-color: #ffffff;
    border: 1px solid #d1dfff;
    border-radius: 4px;
    // box-shadow: 10px 10px 5px lightblue;
  }
  .autocomplete-wrapper .item {
    display: block;
    cursor: pointer;
    font-size: 14px;
    padding: 0.375rem 0.75rem;
  }
  .autocomplete-wrapper .item.selected-item {
    background-color: #0432a3;
    color: #fafbfc;
  }
  .autocomplete-wrapper .item:hover {
    background-color: #0432a3;
    color: #fafbfc;
  }

  .tags-input-container {
    border: 1px solid #d1dfff;
    border-radius: 0.375rem;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 0.5em;
    width: 100%;
  }
  .save-btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8.5px 30px;
    gap: 10px;
    position: absolute;
  }
}

.inviteconnections {
  .header {
    border: none;
    padding: 20px 35px !important;
    font-weight: 600px !important;
  }
  .invite-btn {
    padding: 0.375rem 0.75rem;
  }
}

.insightmodal {
  .insight-groups {
    border-bottom: 1px solid #dee2e6;
    ul {
      display: flex;
      gap: 50px;
      flex-wrap: wrap;

      li {
        @include font-600;
        color: $grey;
      }
    }
  }
}

.admin-roll {
  .profile-Img {
    height: 60px;
    width: 60px;
    border-radius: 50%;
  }
  .remove:hover {
    cursor: pointer;
  }
  .text {
    p {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 22px;
    }
  }
}

.selectedImg {
  height: 20px;
  width: 20px;
  border-radius: 50%;
}

.insight-popup {
  .modal-header {
    height: 117px;
    border-radius: 5px;

    h5 {
      margin-top: 30px;
      @include font-600;
      font-size: 19px !important;
      p {
        font-weight: 400 !important;
        font-size: 15px !important;
        line-height: 22px !important;
      }
    }
  }
  .insight-body {
    padding: 0px !important;
  }
}

.page-view-details {
  .sidebar {
    aside {
      background: $white;
      box-shadow: 0px 2px 4px rgba(171, 190, 209, 0.6);
      border-radius: 0px 0px 5px 5px;

      .view_profile_network {
        height: 41.58px;
        background: $primary;
        color: $white;
        border-radius: 5px;
        text-align: center;
        line-height: 42px;

        a {
          color: $white;
          font-weight: 600;
          font-size: 15px;
        }
      }

      .bg {
        height: 132.02px;
        background: $linear;
        border-radius: 5px;
        position: relative;

        .profile-img {
          border: 2px solid #ffffff;
          border-radius: 5px;
          height: 72px;
          width: 72px;
          position: absolute;
          margin: 20px;
        }
        .back-image-fluid {
          border-radius: 5px;
          height: 132.02px;
          width: 100%;
          object-fit: cover;
        }
      }

      .nobel {
        color: $primary;
        @include show;
        text-align: end;
        text-transform: uppercase;
        font-size: 10px;
        margin-right: 7px;
        cursor: pointer;
      }

      .profile-details {
        h6 {
          @include font-19;
          word-break: break-all;
          color: $dark-black;
        }

        p {
          @include font-14;
          color: $dark-black;
        }
      }

      .follower {
        display: flex;
        justify-content: space-around;
        margin-top: 20px;
        margin-bottom: 15px;

        .box {
          text-align: center;

          h6 {
            @include font-600;
            color: $dark-black;
          }

          p {
            @include font-14;
            color: $grey;
          }
        }

        .h-border {
          background: #d1dfff;
          width: 1px;
        }
      }

      .social {
        margin: 20px;

        ul {
          li {
            @include center-text;
            @include font-600;
            position: relative;
            border-radius: 5px;
            padding: 20px;
            cursor: pointer;

            .count {
              // position: absolute;
              // right: 28px;
              // background: $blue;
              // color: $white;
              // border-radius: 50%;
              // padding: 3px 8px;
              position: absolute;
              right: 10px;
              // background: $blue;
              // color: $white;
              font-weight: bolder;
              font-size: large !important;
              color:  #0432A3;
              border-radius: 50%;
              padding: 3px 8px;
              width: 23px;
              height: 23px;
              font-size: 14px;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }

          .active {
            background: $transparent-blue;
          }
        }
      }
    }

    .bottom-custom {
      position: unset;
      margin: 43px;

      .links {
        margin-bottom: 10px;

        ul {
          display: flex;
          flex-wrap: wrap;
          gap: 5px;

          li {
            @include para;
            font-size: 10px;
          }
        }
      }

      .copy-right {
        p {
          @include para;
          font-size: 10px;
        }
      }
    }
    @media (max-width: 991px) {
      .bottom-custom {
        position: unset;
        margin: 43px;
        display: none;
      }
    }
  }

  @media (max-width: 992px) {
    .web-footer {
      display: none;
    }
  }
}

@media (max-width: 767px) {
  .createpage {
    .autocomplete-wrapper {
      input,
      .networks-popup {
        .modal-body {
          .form-select {
            margin-bottom: 10px;
          }
        }
      }
    }
  }
}
.page-drag {
  .dropzone{
    padding-left: 0 !important; 
    padding-right: 0 !important;
  }
}

.phone_number_input{
  .label {
font-size: 14px !important;
  }
}