@import "../../../assets/scss/mixin.scss";
@import "../../../assets/scss/variable.scss";

.invitations {
  .wrapper {
    position: relative;
    background: $white;

    h3 {
      font-weight: 600;
      font-size: 15px;
      line-height: 22px;
      color: $grey;
    }

    .nav-tabs .nav-item .nav-link {
      border: none;
      font-weight: 600;
      font-size: 15px;
      line-height: 22px;
      color: #000000;
    }

    .nav-tabs .nav-item .active {
      border-bottom: 4px solid #0432a3;
      color: #0432a3;
      border-radius: 4px;
    }

    .search {
      position: relative;

      input {
        border: 1px solid #d1dfff;
        border-radius: 5px;
        width: 100%;
        height: 40px;
        padding: 5px 39px 5px 20px;

        &::placeholder {
          color: $placeholder;
          @include font-14;
        }
      }

      .search-icon {
        @include search-icon;
        right: 12px;
        top: 10px;
      }
    }
  }
}
