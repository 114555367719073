.explore-feature-card{
    background-color: #27354E;
    border-radius: 24px;
    padding: 20px 24px;
    font-family: 'Inter',sans-serif;
    color: #fff;
    margin: 12px 0;
    div h6 {
font-size: 1.5rem;
font-weight: 500;
line-height: 36px;
    }
    p.explore-description-details {
font-size: 16px !important;
font-weight: 400 !important;
line-height: 24px !important;
    }
}