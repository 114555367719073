@import "src/assets/styles/colors";

.banner {
  background: url("../../../../assets/img/bg2crop.jpg") no-repeat;
  background-repeat: no-repeat;
  background-size: auto;
  background-position: top center;
  height: 750px;
  position: relative;
  background-color: $color-3;
  margin-bottom: 80px;
}

.banner .btn:hover {
  color: $color-1;
  background-color: $color-3;
}
.banner .heading {
  padding-top: 56px;
}
.banner .heading h1 {
  font-weight: 700;
  font-size: 48px;
  line-height: 1.48;
  color: $color-1;
  margin-bottom: 20px;
  letter-spacing: 0.1px;
}

.banner .heading p {
  font-weight: 300;
  font-size: 16px;
  line-height: 30px;
  letter-spacing: 0.1px;
  color: $color-1;
}

@media (max-width: 991px) {
  .banner {
    height: 100%;

    form {
      width: 100%;
      margin: 20px 0 100px;
    }
    .forgot {
      form {
        margin-bottom: 0;
      }
    }
  }

  .banner .heading h1 {
    font-weight: 700;
    font-size: 25px;
    line-height: 38px;
    color: $color-1;
  }
}
