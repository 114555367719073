.example-custom-input {
  background: url("../../../assets/img/calendars.svg") no-repeat right;
  /* background-size: 20px; */
  width: 100%;
  height: 37px;
  background-position: right 8px center;
  padding: 6px;
  border: 1px solid #d1dfff;
  border-radius: 4px;
  cursor: pointer;
  color: #212529;
}
.example-custom-input-left {
  background: url("../../../assets/img/calendars.svg") no-repeat right;
  /* background-size: 20px; */
  width: 100%;
  height: 37px;
  background-position: left 8px center;
  padding: 6px 20px 6px 6px;
  border: 1px solid #d1dfff;
  border-radius: 4px;
  cursor: pointer;
  text-align: end;
}

/* .example-custom-input:focus-visible {
    border-bottom-color: #b51148;
    outline: none;
  } */

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range,
.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected {
  background-color: #0432a3;
  border-radius: 50%;
  /* box-shadow: inset 0 0 0 2px white; */
  /* border: 1px solid #0432a3; */
  /* margin: 0.066rem; */
}

.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover,
.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected:hover,
.react-datepicker__quarter-text--keyboard-selected:hover,
.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected:hover,
.react-datepicker__quarter-text--keyboard-selected:hover {
  background-color: #0432a3;
  border-radius: 50%;
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover {
  border-radius: 50%;
}

.example-datepicker-class .react-datepicker__today-button {
  color: #b51148;
  background-color: white;
  border-top: 0px;
  text-align: right;
  margin-right: 20px;
}

.react-datepicker-wrapper {
  display: block !important;
}

.react-datepicker__day:hover {
  background-color: #0432a3;
  color: #ffff;
}

.react-datepicker {
  border: 1px solid #d1dfff;
}

.react-datepicker__current-month {
  color: #ffff;
}

.react-datepicker__header {
  text-align: center;
  background-color: #0432a3;
  /* border-bottom: 1px solid #aeaeae; */
  border-top-left-radius: 0.3rem;
  padding: 8px 0;
  position: relative;
  color: #ffff;
}

.react-datepicker__day-name {
  color: #fff;
  display: inline-block;
  width: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
  margin: 0.166rem;
}

/* .react-datepicker__year-dropdown,
.react-datepicker__month-dropdown,
.react-datepicker__month-year-dropdown {
  background-color: #fff;
  color: #0432a3;
  font-size: 14px;
  position: absolute;
  width: 60%;
  left: 25%;
  top: 30px;
  z-index: 1;
  text-align: center;
  border-radius: 0.3rem;
  border: 1px solid #d1dfff;
}
 */

.react-datepicker__month-dropdown-container--select,
.react-datepicker__month-year-dropdown-container--select,
.react-datepicker__year-dropdown-container--select {
  margin-top: 10px;
  font-size: 14px;
}

.react-datepicker__year-text--selected,
.react-datepicker__year-text--selected:hover,
.react-datepicker__year-text--keyboard-selected {
  border-radius: none;
  background-color: #0432a3;
}

.react-datepicker__navigation {
  top: 6px;
}

/* .react-datepicker__month-select option:checked,
option:focus {
  background-color: lime;
} */
