@import "src/assets/styles/colors";
.otp input {
  border: 1px solid $color-6;
  border-radius: 4px;
  width: 100%;
  height: 40px;
  padding: 17px;
  margin-bottom: 20px;
  margin-top: 10px;
}

.resent:hover{
  cursor: pointer;
}

.otp-span {
  display:inline-block;
  font-weight: 600;
  text-decoration: none;
  font-size: 15px;
  line-height: 22px;
  color: #0432a3;
  margin-top: 15px;
  margin-bottom: 10px;
  cursor: pointer;

}