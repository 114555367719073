@import "../../../assets/scss/mixin.scss";
@import "../../../assets/scss/variable.scss";

.create-event {
  .wrapper-class {
    border: none;
    .speaker {
      position: relative;
      .add {
        @include add-icon;
        position: absolute;
        top: 25px;
        right: 5px;
        background-size: 24px;
      }
    }
    .image-preview {
      height: 290px;
      background: rgba(238, 240, 242, 0.35);
      border: 1px dashed #d1dfff;
      cursor: pointer;
      position: relative;
      border-radius: 8px;

      img {
        height: 290px;
        width: 100%;
      }
    }

    .image-upload {
      height: 290px;
      background: rgba(238, 240, 242, 0.35);
      border: 1px dashed #d1dfff;
      cursor: pointer;
      position: relative;
      border-radius: 8px;

      .img-fluid {
        object-fit: cover;
        height: 100%;
        width: 100%;
      }

      .center {
        text-align: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        @include font-14;
        color: $grey;
      }
    }

    .title input {
      margin-top: 1.5rem;
      border: 1px solid #d1dfff;
      border-radius: 4px;
      height: 37.6px;
      width: 100%;
      padding: 10px;
      font-size: 1rem;
      font-weight: 400;
    }

    .label {
      display: flex;
      gap: 1rem;

      .btn-active {
        background: #0432a3;
        color: #ffffff;
        border-radius: 25px;
        cursor: pointer;
        padding: 6px 21px;

        span {
          font-weight: 400;
          line-height: 22px;
          font-size: 15px;
        }
      }

      .round {
        padding: 6px 21px;
        border-radius: 25px;
        color: #0432a3;
        cursor: pointer;
        border: 1px solid #0432a3;
      }

      .round:hover {
        background: #0432a3;
        color: #ffffff;
        padding: 6px 21px;
        border-radius: 25px;
        cursor: pointer;
      }
    }
  }

  .preview {
    font-weight: normal;
    color: #0432a3;
    margin-top: 0px;
    cursor: pointer;
  }

  .modal-header {
    background: $linear;
    position: relative;

    .modal-title {
      color: $white;

      .icon-expand {
        position: absolute;
        right: 50px;
        top: 19px;
      }
    }

    .btn-close {
      color: $white;
      background: url(../../../assets/img/X.svg) no-repeat center;
      opacity: 5;
    }
  }

  span {
    cursor: pointer;
    font-size: 15px;
  }

  .title {
    @include font-600;
    color: $primary;
    font-size: 18.75px;
  }

  h5 {
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    color: #676a79;
    margin: 0;
  }

  .wrappers {
    box-shadow: 0px 2px 4px rgba(171, 190, 209, 0.6);
    border-radius: 5px 5px 0px 0px;

    .profiles {
      display: flex;
      gap: 15px;

      .img {
        @include profile-circle;
      }

      .details {
        h6 {
          @include small;
        }

        p {
          @include para;
        }
      }
    }

    .hrs {
      @include small;

      .text-blue {
        color: $primary;
        display: flex;
      }
    }

    .text {
      h4 {
        @include h1;
      }

      p {
        @include para;
      }
    }

    .translate {
      color: $blue;
      text-transform: capitalize;
      @include small;
      margin-top: 10px;
    }

    .comments {
      .profile-img {
        @include profile-circle;
      }
    }
  }

  .input-type {
    margin-top: 1.5rem;
    border: 1px solid #d1dfff;
  }

  .preview-button {
    margin-top: 1rem;
    text-align: right;
    color: #0432a3;
    .imgaes {
      display: flex;
      gap: 20px;
      position: relative;
      .preview-img {
        @include profile-circle;
        position: relative;
        cursor: pointer;
        .close {
          position: absolute;
          background: $grey;
          font-size: 8px;
          border-radius: 50%;
          padding: 1px 4px;
          color: $white;
          top: -3px;
          right: -6px;
          box-shadow: 0px 1px 0px 3px #fff;
          cursor: pointer;
        }
        .count-img {
          display: inline-block;
          width: 36px;
          height: 36px;
          border-radius: 55%;
          background: #0432a3;
          color: #ffffff;
          text-align: center;
          line-height: 34px !important;
          font-weight: 400;
          font-size: 14px;
        }
      }
    }
  }

  .title-count {
    text-align: right;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    color: #676a79;
    font-size: 12px;
  }

  .autocomplete-wrapper {
    position: relative;
  }

  .autocomplete-wrapper input {
    margin-top: 1.5rem;
    border: 1px solid #d1dfff;
    width: 100%;
    padding: 0.375rem 0.75rem;
    border-radius: 4px;
    padding-right: 50px;
    font-size: 1rem;
    color: #212529;
  }
  // .autocomplete-wrapper input:focus {
  //   border-color: #0F67FF;
  //   box-shadow: none;
  //   outline: none;
  // }
  .autocomplete-wrapper .dropdown {
    width: 100%;
    padding: 0;
    text-align: left;
    max-height: 130px;
    overflow: hidden;
    overflow-y: auto;
    position: absolute;
    z-index: 1;
    background-color: #ffffff;
    border: 1px solid #d1dfff;
    border-radius: 4px;
    // box-shadow: 10px 10px 5px lightblue;
  }
  .autocomplete-wrapper .item {
    display: block;
    cursor: pointer;
    font-size: 14px;
    padding: 0.375rem 0.75rem;
  }

  .autocomplete-wrapper .item .name-heading-drop {
    line-height: 20px;
    color: $dark-black;
    font-size: 15px;
    font-weight: 400;
  }

  .autocomplete-wrapper .item .sub-heading-drop {
    font-weight: 400;
    color: $grey;
    font-size: 11px;
    margin-top: 0px;
    // line-height: 5px;
  }

  .autocomplete-wrapper .item.selected-item {
    background-color: #b0c4de;
    color: $dark-black;
  }
  .autocomplete-wrapper .item:hover {
    background-color: #b0c4de;
    color: $dark-black;
  }
}

.event-create-popup {
  .check {
    background: url("../../../assets/img/check-mark.png") no-repeat center;
    height: 100px;
  }

  h4 {
    @include font-19;
    color: $black;
  }

  p {
    @include font-14;
    font-size: 15px;
    color: $grey;
  }

  .ci-share {
    @include ci-share;
  }
}

.dicardModalHeader {
  .modal-title {
    font-family: "Inter" !important;
    font-style: normal;
    font-weight: 600 !important;
    font-size: 19px !important;
    line-height: 24px !important;
  }
}


.rc-time-picker-clear-icon:after{
  content : "" !important;
}