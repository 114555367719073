@import "../../../../assets/scss/mixin.scss";
@import "../../../../assets/scss/variable.scss";

.post-main-box {
  background: $white;
  padding: 20px;
  margin-top: 20px;
  border-radius: 5px;
  position: relative !important;
  &:last-child {
    .flex {
      .drop {
        // .box-repost{
        //   bottom: 40px !important;
        // }
        // .box-share{
        //   bottom: 0px !important;
        // }
      }
    }
  }
  .post {
    display: flex;
    gap: 10px;
    align-items: center;
    .name-bold {
      color: $dark-black;
      font-weight: 600;
      cursor: pointer;
      text-transform: capitalize;
    }

    .name-bold:hover {
      text-decoration: underline;
      color: $primary;
    }
    .info-title {
      h5 {
        color: #01052d;
        font-weight: 600;
        font-size: 15px;
        cursor: pointer;
        overflow: hidden;
        text-overflow: ellipsis;
        text-wrap: nowrap;
        max-width: 18rem;
        margin-bottom: 0px;
        margin-right: 10px;
      }
      span {
        margin-top: 0px;
      }
    }

    .dots {
      background: white;
      @include center-text;
      gap: 1px;
      justify-content: center;
      height: 33px;
      width: 25px !important;
      border-radius: 5px;
      margin-left: 13% !important;
      cursor: pointer;

      span {
        font-size: 13px;
        color: $black;
      }
    }

    .drop {
      .box {
        position: absolute;
        background: #ffffff;
        border-radius: 5px;
        padding: 17px 15px;
        z-index: 50;
        right: 0rem;
        top: 32px;
        border: 1px solid #e5e5e5;

        ul {
          li {
            @include para;
            display: flex;
            align-items: center;
            gap: 15px;
            line-height: 34px;
            cursor: pointer;
          }
          li:hover {
            color: $primary;
          }
        }
      }
    }

    .img {
      width: 24px;
      height: 24px;
      border-radius: 50%;

      img {
        border-radius: 50%;
        width: 24px;
        height: 24px;
        object-fit: cover;
        cursor: pointer;
      }
    }

    span {
      font-weight: 400;
      font-size: 15px;
      line-height: 22px;
      color: $grey;
      margin-top: 3px;
      @media (max-width: 767px) {
        font-size: 10px;
      }
    }

    .share {
      span {
        color: $grey;
        font-weight: 400;
      }
    }
  }

  .varify {
    @include verify-profile;
  }

  .share-article-post {
    .box {
      background: rgba(4, 50, 163, 0.05);
      border-radius: 5px;
      overflow: hidden;

      .boxe-img {
        height: 300px;
        background: $linear;
      }

      .text {
        padding-left: 10px;

        span {
          font-weight: 400;
          line-height: 20px;
          font-size: 10px;
          color: #676a79;
          display: block;
        }

        h6 {
          font-weight: 600;
          font-size: 15px;
          line-height: 22px;
          color: #676a79;
          word-break: break-all;
          cursor: pointer;
        }
      }
    }
  }

  .share-event-post {
    .box {
      background: rgba(4, 50, 163, 0.05);
      border-radius: 5px;
      overflow: hidden;

      .btn-light {
        border: 1px solid #0432a3;
        color: #0432a3;
        cursor: pointer;
        margin-right: 10px;
      }

      .boxe-img {
        background: $linear;
        height: 300px;
      }

      .text {
        padding-left: 10px;

        .btn-blue {
          background: #008c39;
          padding: 5px 15px;
        }

        p {
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #676a79;
        }

        span {
          font-weight: 400;
          line-height: 20px;
          font-size: 10px;
          color: #676a79;
          display: block;
        }

        h6 {
          font-weight: 600;
          font-size: 15px;
          line-height: 22px;
          color: #676a79;
          word-break: break-all;
          cursor: pointer;
        }
      }
    }
  }

  .shareRepost {
    border: 1px solid $border;
    border-radius: 5px;
    padding: 20px;
    margin-top: 10px;

    .main-Post-div {
      display: flex;
      flex-direction: row;
      gap: 10px;
      margin-bottom: 20px;

      .prof-img {
        width: 40px;
        height: 40px;
        border-radius: 55%;
        box-shadow: 0 0 0px 4px #008c39;

        img {
          border-radius: 50%;
          width: 100%;
          height: 100%;
          box-shadow: 0 0 0px 2px #ffffff;
          cursor: pointer;
        }
      }

      .details {
        p {
          font-weight: 400;
          font-size: 15px;
          line-height: 22px;
          color: #676a79;
          font-size: 10px;
          margin: 0px;
          line-height: 14px;
        }

        span {
          font-weight: 400;
          font-size: 15px;
          line-height: 22px;
          color: #676a79;
          font-size: 10px;
          margin: 0px;
          line-height: 14px;
        }

        h4 {
          font-weight: 600;
          font-size: 30px;
          line-height: 44px;
          color: #01052d;
          font-size: 15px;
          line-height: 18px;
          margin: 0px;
          overflow: hidden;
          max-width: 10rem;
          cursor: pointer;
        }

        .userNameTextWrap {
          max-height: 3em;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          width: 100%;
        }
      }
    }

    .descriptionOverFlowSpan {
      font-weight: 400;
      font-size: 15px;
      line-height: 22px;
      color: $grey;
      white-space: pre-line;
    }
  }

  .main-post {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
    justify-content: space-between;

    .main-Post-div {
      display: flex;
      flex-direction: row;
      gap: 10px;
    }

    .prof-img {
      width: 40px;
      height: 40px;
      border-radius: 55%;
      box-shadow: 0 0 0px 4px #008c39;

      img {
        border-radius: 50%;
        width: 100%;
        height: 100%;
        box-shadow: 0 0 0px 2px #ffffff;
        cursor: pointer;
      }
    }

    .details {
      // flex: 0 0 30%;

      h4 {
        @include h1;
        font-size: 15px;
        line-height: 18px;
        // margin: 0px;
        margin-right: 5px;
        overflow: hidden;
        max-width: 10rem;
        cursor: pointer;
        text-transform: capitalize;
      }

      h4:hover {
        text-decoration: underline;
        color: $primary;
      }

      p {
        @include para;
        font-size: 10px;
        margin: 0px;
        line-height: 14px;
        // width: max-content;
      }
    }

    .varify {
      @include verify-profile-big;
      width: 18px;
      height: 18px;
      border-radius: 50%;
    }

    .hiring {
      @include hiring;
    }
    .seeking {
      @include seeking;
    }
  }

  .text {
    .despan {
      @include para;
      white-space: pre-line;

      // span {
      //   color: $primary;
      // }
    }

    .translate {
      span {
        color: $blue;
        font-size: 10px;
        font-weight: 400;
        cursor: pointer;
      }
    }
  }

  .post-img {
    position: relative;
    margin-bottom: 16px;
    display: flex;
    justify-content: center;

    .showtext:hover {
      cursor: pointer;
    }

    // .likes1 {
    //   background: #ffffff;
    //   border-radius: 13px;
    //   width: 150px;
    //   height: 30px;
    //   position: absolute;
    //   bottom: 10px;
    //   left: 20px;
    //   display: flex;
    //   align-items: center;
    //   justify-content: space-around;
    //   box-shadow: 0 5px 24px rgb(0 0 0 / 10%);
    //   img {
    //     cursor: pointer;
    //   }

    //   img:hover{
    //     transform:scale(2.0);
    // }
    // }

    .member {
      position: absolute;
      bottom: 10px;
      right: 10px;
      background: $white;
      opacity: 0.4;
      border-radius: 13.7288px;
      padding: 3px 10px;
      display: flex;
      align-items: center;

      span {
        font-weight: 400;
        font-size: 10px;
      }
    }
  }

  .flex {
    display: flex;
    gap: 16px;
    justify-content: space-between;

    .drop {
      .box {
        position: absolute;
        background: #ffffff;
        border-radius: 5px;
        z-index: 50;
        border: 1px solid #e5e5e5;
        padding: 8px 15px;
        cursor: default;
        width: 252px;

        .shareListing {
          display: flex;
          gap: 10px;
        }

        ul,
        li {
          font-weight: 400;
          font-size: 15px;
          line-height: 22px;
          color: #676a79;
          line-height: 34px;
          cursor: pointer;
        }

        li:hover {
          color: $primary;
        }
      }

      .box-repost {
        bottom: -96px;
        right: 4rem;
      }

      .box-share {
        bottom: -60px;
        right: 0rem;
      }
    }

    span {
      @include para;
      font-size: 12px;
      cursor: pointer;
      @media (max-width: 767px) {
        font-size: 9px !important;
        margin-left:1px !important;
      }
      img {
        margin-top: -2px;
        max-width: 20px;
        width: 15px;
        @media (max-width: 767px) {
          max-width: 14px;
          // vertical-align: text-bottom;
          vertical-align: middle;
        }
      }
    }
    .comment {
      img {
        @media (max-width: 767px) {
          max-width: 14px;
        }
      }
    }
    .share {
      img {
        @media (max-width: 767px) {
          max-width: 14px;
        }
      }
    }

    .others {
      span {
        font-size: 12px;
        margin-left: 1px;
      }
      span:hover {
        text-decoration: underline;
        color: $primary;
      }
    }
    .d-flex {
      .like_main_comment_post {
        display: inline-block;
      }

      .likes_comment_post {
        display: none;
      }

      .likes_comment_post {
        background: #ffffff;
        border-radius: 13px;
        bottom: 22px;
        left: 5px;
        width: 210px;
        height: 45px;
        position: absolute;
        align-items: center;
        justify-content: space-around;
        border: 1px solid #e5e5e5;
        box-shadow: 0 5px 24px rgba(0, 0, 0, 0.1);
        z-index: 10;
        img {
          cursor: pointer;
          height: 25px;
        }
        img:hover {
          transform: scale(1.7);
        }
      }

      // .like_main_comment {

      //   transition: opacity  1s ease;
      // }

      .like_main_comment_post:hover .likes_comment_post {
        display: flex;
      }
    }
  }
  //  .main-view{
  //   position: relative;
  //   .image-view-count {
  .image-view-count {
    position: absolute;
    border-radius: 10px;
    width: 55px;
    display: flex;
    color: white;
    background: rgba(0, 0, 0, 0.5);
    right: 35px;
    bottom: 35px;
    font-size: 14px;
    align-items: center;
    justify-content: center;
  }
  .image-video-view-count {
    position: absolute;
    border-radius: 10px;
    width: 55px;
    display: flex;
    color: white;
    background: rgba(0, 0, 0, 0.5);
    right: 35px;
    bottom: 100px;
    font-size: 14px;
    align-items: center;
    justify-content: center;
  }
}
@media(max-width:767px){
  .post-main-box{
    padding: 15px 10px;
    margin-bottom: 10px;
    .main-post{
      gap: 0;
      margin-bottom: 10px;
      .post-main-box .main-post .prof-img {
        margin-left: 0 !important;
      }
      .varify {
        margin-left: 2px !important;
        margin-right: 2px !important;
      }
      .hiring,.seeking  {
        margin-left: 2px !important;
        margin-right: 2px !important;
        width: 50px;   
      }
      .details{
        h4{
          max-width:140px !important;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          display: inline-block !important;
        }
      }
    }
    .like_main_comment_post .like {
      margin-right: 2px !important;
    }
    .flex{
      gap: 5px;
      .d-flex {
        gap: 6px !important;
     
      }
      .curserPointer {
        margin-right: 0 !important;
        gap: 0 !important;
      }
    }
    .post-info-row{
      margin-top: 20px !important;
      position: relative;
      .viewReactionsType.viewReactionsTypeTop {
        position: absolute;
        left: 0;
        top: -22px;
        z-index: 9;
      }
    }
    .shareRepost{
      padding:15px 10px;
      .pollBoxContainer{
        margin-bottom:5px;
      }
    }
    .comments-box-main{
      .short .form-select{
        width: auto;
        max-width: 100%;
      }
      .read .msg-box{
        padding: 20px 10px 10px 15px;
        .commentNameDiv .namewidth {
          width: 55%;
        }
        h5{
          font-size: 13px;
          white-space: nowrap;
        }
        .time span{
          font-size: 10px;
        }
      }
      .comments-popup-right{
        aside.EmojiPickerReact {
          width:100% !important;
        }
      }
    }
    .post .drop .box ul li {
      font-size: 12px;
      word-break: normal;
      line-height: 27px;
    }
  }

  .create-post{
    .create-post-user {
      padding: 0;
    }
    .createPostNameSelect span, .form-select span {
      font-size: 10px; 
    }
    .create-post-shared {
      margin-right: -2px;
    }
    div.createPostNameSelect{
      padding: 5px 15px 5px 7px;
      span{
        font-size: 10px; 
        line-height: 22px;
      }
      &.form-select{
        background-position: right .2rem center;
      }
    }
    div.form-select{
      padding: 5px 15px 5px 7px;
      span {
         font-size: 10px; 
         line-height: 22px;
      }
      &.form-select{
        background-position: right .3rem center;
      }
    }
    .create-post-loader{
      .dots {
        width: 29px;
      }
    }
  }
}
@media(max-width:380px){
  .post-main-box{
    .main-post{
      .hiring,.seeking {
        width: 45px;      
      }
      .details{
        h4{
          font-size: 12px;
        }
      }
    }
  }
  
}
@media(max-width:359px){
  .post-main-box{
    .main-post{
      .hiring,.seeking {
        width: 45px;      
      }
      .details{
        h4{
          max-width: 103px !important;
        }
      }
    }
    .flex {
      span {
        font-size: 7.3px !important;
      }
      .d-flex {
        gap: 3px !important;
      }
    }
    .comments-box-main{
      .comments-popup-right {
        right: 0 !important;
        aside.EmojiPickerReact {
          width:95% !important;
        }
      }
    }
  }
  .create-post{
    .createPostNameSelect span, .form-select span {
      font-size: 10px;
    }
  }
}