@import "../../../../assets/scss/mixin.scss";
@import "../../../../assets/scss/variable.scss";

.create-post {
  .modal-header {
    background: $linear;
    position: relative;
    height: 74px;

    .modal-title {
      color: $white;
      font-size: 19px;
      font-weight: 600;

      .icon-expand {
        position: absolute;
        right: 50px;
        top: 27px;
      }
    }

    .btn-close {
      color: $white;
      background: url(../../../../assets/img/X.svg) no-repeat center;
      opacity: 5;
    }
  }

  // span {
  //   margin: 0px;
  //   font-size: 15px;
  //   color: #01052d;
  // }

  .showtext {
    position: relative;
    top: 20px;
    padding-left: 20px;
    -webkit-line-clamp: 2;
    overflow: hidden;
    word-wrap: break-word;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    margin-bottom: 50px !important;
  }

  .react-autocomplete-input {
    margin-top: 18px;
  }

  // .hashtag {
  //   color: green;
  // }
  // .Editor {
  //   max-width: 400px;
  //   margin: auto;
  //   padding: 20px;
  //   border: 1px solid black;
  // }

  // .hashtagList {
  //   margin: 20px 0 0 0;
  //   list-style-type: none;
  // }

  // .hashtagListItem.current {
  //   color: blue;
  // }
  // .mention {
  //   background: lightblue;
  // }

  // .mentionList {
  //   position: absolute;
  //   margin: 5px 0 0 0;
  //   padding: 0;
  //   box-shadow: 0 0 2px black;
  //   list-style: none;
  //   background: white;
  // }

  // .mentionListItem {
  //   padding: 10px;
  // }

  // .mentionListItem.current {
  //   background: lightgrey;
  // }

  .react-autocomplete-input > li.active {
    background-color: $primary;
    color: #fff;
  }

  textarea {
    resize: none;
  }

  .share-article-post {
    .box {
      background: rgba(4, 50, 163, 0.05);
      border-radius: 5px;
      overflow: hidden;

      .boxe-img {
        height: 300px;
      }

      .text {
        padding-left: 10px;

        span {
          font-weight: 400;
          line-height: 20px;
          font-size: 10px;
          color: #676a79;
          display: block;
        }

        h6 {
          font-weight: 600;
          font-size: 15px;
          line-height: 22px;
          color: #676a79;
          word-break: break-all;
          cursor: pointer;
        }
      }
    }
  }

  .share-event-post {
    .box {
      background: rgba(4, 50, 163, 0.05);
      border-radius: 5px;
      overflow: hidden;

      .btn-light {
        border: 1px solid #0432a3;
        color: #0432a3;
        cursor: pointer;
        margin-right: 10px;
      }

      .boxe-img {
        height: 300px;
      }

      .text {
        padding-left: 10px;

        .btn-blue {
          background: #008c39;
          padding: 5px 15px;
        }

        p {
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #676a79;
        }

        span {
          font-weight: 400;
          line-height: 20px;
          font-size: 10px;
          color: #676a79;
          display: block;
        }

        h6 {
          font-weight: 600;
          font-size: 15px;
          line-height: 22px;
          color: #676a79;
          word-break: break-all;
          cursor: pointer;
        }
      }
    }
  }

  .fullHeight {
    height: 100%;
    overflow-y: scroll;
  }

  .title {
    @include font-600;
    color: $primary;
    font-size: 18.75px;
  }

  .read {
    .pro-img {
      @include profile-circle;
      flex: 0 0 9%;
      margin-right: 15px;
    }

    .likes {
      @include para;
      @include center-text;
    }

    .msg-box {
      background: $light-grey;
      padding: 20px;
      position: relative;
      border-radius: 0px 5px 5px 5px;

      .hour {
        position: absolute;
        top: 18px;
        right: 22px;
        @include para;
      }

      .translate {
        color: $blue;
        font-size: 10px;
        margin-top: 10px;
      }
    }
  }

  .thoughts {
    @include center-text;

    .pro-img {
      @include profile-circle;
    }

    .type {
      flex: 0 0 80%;
    }
  }

  .reaction {
    h6 {
      color: $black;
      @include font-600;
      margin: 0;
    }

    p {
      @include para;
    }

    ul {
      li {
        @include font-600;
        color: $primary;

        span {
          margin-left: 10px;
        }
      }
    }
  }

  .box-img {
    position: relative;
    margin-top: 20px;

    .click-anywhere {
      .tag {
        position: absolute;
        transform: translate(-50%, -50%);
        left: 50%;
        top: 50%;
        background: #021951;
        color: #fff;
        padding: 5px 20px;
        border-radius: 25px;
        @include font-14;
        font-size: 10px;
      }
    }

    .right-side {
      position: absolute;
      right: 10px;
      top: 10px;

      .tag {
        display: inline-block;
        background: $transparent-black;
        border-radius: 14px;
        color: $white;
        padding: 5px 15px;
        @include small;
        font-size: 10px;
        cursor: pointer;
      }

      .close {
        display: inline-block;
        background: $transparent-black;
        border-radius: 14px;
        color: $white;
        padding: 5px 12px;
        @include small;
        font-size: 10px;
        cursor: pointer;
        margin-left: 5px;
      }
    }

    .left-side {
      position: absolute;
      left: 30px;
      top: 20px;
    }
  }

  .dots {
    position: relative;
    height: 28px;

    .drop-down {
      position: absolute;
      background: $white;
      @include small;
      color: $grey;
      border-radius: 5px;
      padding: 7px 23px;
      top: 30px;
      box-shadow: 1px 2px 4px #d7d7da;
      width: 116px;
      right: 0px;
      z-index: 1;
    }
  }

  .pro-img {
    @include profile-48;
  }

  .add {
    border-radius: 20px;
    @include small;
    color: $primary;
    padding: 0px 10px;
    cursor: pointer;
  }

  .emoj {
    img {
      width: 100%;
      height: 100%;
    }
  }

  .emoj :hover {
    cursor: pointer;
  }
  .noemoj:hover {
    cursor: not-allowed;
  }

  .post {
    margin-top: 0px;
    @include para;
    text-decoration: underline;
    margin-right: 10px;
  }

  .profileImageClass {
    display: flex;
    justify-content: center;
    width: fit-content;
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 48px;
    height: 24px;
    input {
      opacity: 0;
      width: 0;
      height: 0;
    }
  }
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $white;
    border: 1px solid $grey;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    &:before {
      position: absolute;
      content: "";
      height: 16px;
      width: 16px;
      left: 3px;
      bottom: 3px;
      background-color: $grey;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }
  }
  input {
    &:checked {
      + {
        .slider {
          background-color: $primary;
          &:before {
            -webkit-transform: translateX(23px);
            -ms-transform: translateX(23px);
            transform: translateX(23px);
          }
        }
      }
    }
    &:focus {
      + {
        .slider {
          box-shadow: 0 0 1px $grey;
        }
      }
    }
  }
  .slider.round {
    border-radius: 34px;
    background-color: $grey;
    &:before {
      border-radius: 50%;
      background-color: $white;
    }
  }

  .tooltip {
    position: absolute;
    background: rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    opacity: 1;
    width: 200px;
    top: -60px;
    right: -15px;

    &::after {
      content: "";
      position: absolute;
      left: 15px;
      width: 0px;
      height: 0px;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 15px solid #e5e5e5;
    }

    p {
      @include para;
      font-size: 10px;
      line-height: 19px;
    }
  }

  h5 {
    @include para;
    margin: 0;
  }

  .shedule {
    h6 {
      @include font-600;
      margin-top: 15px;
      margin-bottom: 15px;
      color: $grey;
    }

    p {
      @include font-600;
      color: $grey;
    }

    .input-group {
      border: 1px solid $border;
      border-radius: 5px;
      padding-left: 5px;

      .input-group-text {
        background: none;
        border: none;

        .calendar {
          @include calender-icon;
        }

        .clock {
          @include clock-icon;
        }
      }

      input {
        border: none;
      }
    }

    input[type="date"]::-webkit-calendar-picker-indicator {
      position: absolute;
      left: 0;
    }

    input[type="time"]::-webkit-calendar-picker-indicator {
      position: absolute;
      left: 0;
      -webkit-appearance: none;
    }

    //added to resolve timer issue in safari
    input[type="time"] {
      -webkit-appearance: none;
      appearance: none;
    }

    input::-webkit-datetime-edit {
      position: relative;
      left: 15px;
    }

    input::-webkit-datetime-edit-fields-wrapper {
      position: relative;
      left: 15px;
    }
  }

  .flex {
    span {
      @include small;
      color: $primary;
    }

    .others {
      .love {
        background-size: 54%;
      }

      .hand {
        background-size: 54%;
      }

      .lamp {
        background-size: 54%;
      }

      span {
        @include para;
        font-size: 10px;
      }
    }
  }
  .cursor-not-allowed {
    cursor: not-allowed;
  }

  .form-select {
    border: 1px solid $grey;
    padding: 5px 16px 5px 10px;
    display: flex;

    span {
      color: $grey;
      white-space: nowrap;
      width: 170px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .details {
    p {
      @include para;
    }

    h6 {
      @include font-600;
      margin: 0;
      color: $grey;
      text-transform: capitalize;
      word-break: break-all;
    }

    .pro-img {
      width: 64px;
      height: 64px;
      border-radius: 55%;
      // @include profile-48;
    }

    input {
      width: 20px;
      height: 20px;
      accent-color: $primary;
    }
  }

  .input {
    display: flex;

    input {
      width: 15px;
      height: 15px;
      accent-color: $primary;
    }

    .facebook {
      display: flex;
      gap: 5px;
      align-items: center;
      margin-right: 12px;

      .icon {
        @include facebook;
      }
    }

    .twitter {
      display: flex;
      gap: 5px;
      align-items: center;
      margin-right: 12px;

      .icon {
        @include twitter;
      }
    }

    .insta {
      display: flex;
      gap: 5px;
      align-items: center;
      margin-right: 12px;

      .icon {
        @include instagram;
      }
    }
  }

  .small-tooltip {
    background: yellow;
    @include font-14;
    font-size: 10px;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    color: $white;
    padding: 5px 20px;
    display: block;
    text-transform: capitalize;

    &::before {
      content: "";
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid rgba(0, 0, 0, 0.5);
      position: absolute;
      top: -5px;
      transform: translate(-50%, -50%);
      left: 50%;
    }
    span {
      color: $white;
      cursor: pointer;
      font-size: 10px;
      margin-left: 10px;
    }
  }
  .small-tooltip-right {
    background: yellow;
    @include font-14;
    font-size: 10px;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    color: $white;
    padding: 5px 20px;
    display: block;
    text-transform: capitalize;

    &::before {
      content: "";
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid rgba(0, 0, 0, 0.5);
      position: absolute;
      top: -5px;
      transform: translate(-50%, -50%);
      left: 12%;
    }
    span {
      color: $white;
      cursor: pointer;
      font-size: 10px;
      margin-left: 10px;
    }
  }
  .small-tooltip-left {
    background: yellow;
    @include font-14;
    font-size: 10px;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    color: $white;
    padding: 5px 20px;
    display: block;
    text-transform: capitalize;

    &::before {
      content: "";
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid rgba(0, 0, 0, 0.5);
      position: absolute;
      top: -5px;
      transform: translate(-50%, -50%);
      left: 89%;
    }
    span {
      color: $white;
      cursor: pointer;
      font-size: 10px;
      margin-left: 10px;
    }
  }

  .tagedperson:hover {
    cursor: pointer;
  }
  .addemoji {
    height: 60vh;
    width: 100%;
    .EmojiPickerReact {
      .epr-category-nav {
        padding-top: 0px;
      }
    }
  }
  .no-dropdown {
    border: none !important;
    background-image: none !important;
  }
  .createPostNameSelect {
    span {
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 22px;
    }
  }
}
.create-post-modal {
  max-width: 600px;
}

@media (max-width: 991px) {
  .create-post {
    .mt {
      margin-top: 100px;
    }
  }
  .addemoji {
    height: 60vh !important;
    width: 100% !important;
  }
}
.react-autocomplete-input {
  left: auto !important;
  bottom: 100px !important;
  width: 200px !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  height: 200px !important;
}

.ck.ck-editor__main > .ck-editor__editable {
  border: 0px !important;
  box-shadow: none;
}

.ck-toolbar {
  border: none !important;
  box-shadow: none !important;
}

.ck-editor__editable_inline {
  border: none !important;
  box-shadow: none !important;
}

.ck-sticky-panel__content {
  border: none !important;
}

.hashtag-highlight {
  background-color: yellow; /* Change this to your preferred highlight color */
  font-weight: bold;
}

.ck.ck-list__item > .ck-button.ck-on {
  background: linear-gradient(
    120.5deg,
    #5b2e92 0%,
    #29409c 50.49%,
    #0f91d2 100%
  ) !important;
}

.render-ele {
  display: flex;
  column-gap: 50px;
}

.ck-on .custom-item {
  color: white !important;
}

.ck-on .custom-item-username {
  color: #b1b1b1 !important;
}

.info-block {
  display: flex;
  flex-direction: column;
}

.item-img {
  width: 40px !important;
  height: 40px !important;
  border-radius: 20px;
}
