.give_smart_interview {
  // background: #eff2f6;
  padding-top: 30px;
  // height: 93vh;
  .interview_container {
    border-radius: 5px;
    background: #fff;
    box-shadow: 0px 2px 4px 0px rgba(171, 190, 209, 0.6);
  }
  .sub_btn {
    border-radius: 5px;
    background: #0432a3;
  }
  .question {
    p {
      color: #6c757d;
    }
    .timer {
      text-align: end;
      p {
        font-weight: 600;
        font-size: 19px;
      }
      &.red {
        p {
          color: #d9534f;
        }
      }
      &.green {
        p {
          color: #008c39;
        }
      }
    }
  }
  .cam-container {
    position: relative;
    padding: 5px 0 0 0;
    // padding-bottom: 40px;
    .timer {
      position: absolute;
      width: 100%;
      bottom: 10px;
      text-align: center;
      p {
        color: #d9534f;
        span {
          background-color: #fff;
          padding: 5px;
        }
      }
    }
  }
}
