@import "../../../assets/scss/mixin.scss";
@import "../../../assets/scss/variable.scss";

.thumbnailCard {
  box-shadow: 0px 0px 16px rgba(171, 190, 209, 0.4);
  border-radius: 5px;
  width: 100%;
  min-height: 513px;

  .img {
    //  max-width: 378.24px;
    height: 283.68px;
    background: $grey;
    border-radius: 5px 5px 0px 0px;

    img {
      // max-width: 378.24px;
      border-radius: 5px 5px 0px 0px;
      height: 283.68px;
      background: $grey;
      width: 100%;
    }
  }

  .main-content {
    max-height: 230px;
    .caption {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .left {
        display: flex;
        align-items: center;
        gap: 15px;

        .profile-image {
          height: 32.53px;
          width: 32.53px;

          img {
            border-radius: 55%;
            height: 32.53px;
            width: 32.53px;
          }
        }
      }

      .text {
        span {
          @include small;
        }
      }

      .language {
        span {
          @include small;
        }
      }
    }
  }

  .dates {
    position: relative;
    margin: 10px 0px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    &::before {
      content: url(../../../assets/img/calenda.svg);
      position: absolute;
      display: inline;
      top: 3px;
      left: 0px;
    }

    span {
      @include small;
      font-size: 14px;
      color: $grey;
      margin-left: 24px;
    }
  }

  .text {
    max-height: 48px;

    h6 {
      @include h1;
      font-size: 19px;
      margin-bottom: 10px;
      line-height: 24px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }

  .main-text {
    p {
      @include small;
      font-size: 12px;
      color: $grey;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }

  .read {
    margin-top: 8px;

    a {
      color: $primary;
      font-weight: 400;
      font-size: 12px;
    }
  }
}
