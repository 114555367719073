@import "../../../assets/scss/mixin.scss";
@import "../../../assets/scss/variable.scss";

.create-campaign {
  .left {
    background: $white;
    .header {
      background: $white;
      position: relative;
      margin-left: 30px;
      @media (max-width: 767px) {
        margin-left: 0;
      }
      .title {
        color: $black;
        padding: 10px;
        h3 {
          font-weight: 600;
          font-size: 30px;
          line-height: 44px;
          @media (max-width: 767px) {
            font-size: 22px;
            line-height: 24px;
          }
        }
        .icon-expand {
          position: absolute;
          right: 50px;
          top: 19px;
        }
      }

      .btn-close {
        background: url(../../../assets/img/Closeicon.svg);
        opacity: 5;
        width: 24px;
        height: 24px;
        background-repeat: no-repeat;
        @media (max-width: 767px) {
          margin-top: 0 !important;
        }
      }
    }
    .btn-tabs {
      margin: 20px;
      @media (max-width: 767px) {
        margin: 0;
      }
      .btn {
        @media (max-width: 767px) {
          margin: 0 5px !important;
        }
      }
      .border-btn {
        border: 1px solid #0432a3;
        border-radius: 26px;
        padding: 10px 20px;
        color: $primary;
        margin-right: 20px;
      }

      .blue-btn {
        border-radius: 26px;
        background: $primary;
        padding: 10px 20px;
        color: $white;
        margin-right: 20px;
      }
    }
    .main-body {
      margin: 30px;
      @media (max-width: 767px) {
        margin: 30px 0;
      }
      .create-camp {
        .camp-slider {
          padding: 10px;
          h5 {
            font-size: 15px;
            font-family: Inter;
            font-weight: 600;
            line-height: 22.5px;
            margin-bottom: 15px;
          }
          .rs-slider-mark-content {
            margin-left: -30% !important;
          }
          .end-margin {
            margin-left: 50% !important;
          }
          .rs-slider-progress-bar {
            background-color: $primary !important;
          }
          .rs-slider-handle::before {
            border: 2px solid $primary !important;
          }
          .rs-slider-graduator > ol > li.rs-slider-pass::before {
            border-color: $primary !important;
          }
          .rs-tooltip {
            display: none;
          }
        }
      }
    }
  }
  .create-camp {
    .schedule {
      h6 {
        @include font-600;
        margin-top: 15px;
        margin-bottom: 15px;
        color: $grey;
      }

      p {
        @include font-600;
        color: $grey;
      }

      .input-group {
        border: 1px solid $border;
        border-radius: 5px;
        padding-left: 5px;
        @media (max-width: 767px) {
          margin-top: 5px;
        }
        .input-group-text {
          background: none;
          border: none;

          .calendar {
            @include calender-icon;
          }

          .clock {
            @include clock-icon;
          }
        }

        input {
          border: none;
        }
        input[type="date"] {
          height: auto;
          width: auto;
        }
        input[type="time"] {
          height: auto;
          width: auto;
        }
      }

      .padding-left-case {
        padding-left: 0;
      }

      input[type="date"]::-webkit-calendar-picker-indicator {
        position: absolute;
        left: 0;
      }

      input[type="time"]::-webkit-calendar-picker-indicator {
        position: absolute;
        left: 0;
      }

      input::-webkit-datetime-edit {
        position: relative;
        left: 15px;
      }

      input::-webkit-datetime-edit-fields-wrapper {
        position: relative;
        left: 15px;
      }
    }
    .check {
      display: flex;
      gap: 10px;
      margin-top: 20px;
      // margin-bottom: 20px;
      input {
        @include tick;
      }
      label {
        font-weight: 600;
        font-size: 15px;
        line-height: 22.5px;
        align-items: center;
        color: #676a79;
        display: inline;
        @media (max-width: 767px) {
          margin-right: 0 !important;
          margin-left: 0 !important;
        }
      }
    }
    .camp-form {
      .autocomplete-wrapper {
        position: relative;
      }

      .autocomplete-wrapper input {
        border: 1px solid #d1dfff;
        width: 100%;
        padding: 0.375rem 0.75rem;
        border-radius: 4px;
      }
      .autocomplete-wrapper .dropdown {
        width: 96%;
        padding: 0;
        text-align: left;
        max-height: 100px;
        overflow: hidden;
        overflow-y: auto;
        position: absolute;
        z-index: 1;
        background-color: #ffffff;
        border: 1px solid #d1dfff;
        border-radius: 4px;
        // box-shadow: 10px 10px 5px lightblue;
        &::-webkit-scrollbar {
          width: 5px;
        }
        &::-webkit-scrollbar-track {
          box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        }
        &::-webkit-scrollbar-thumb {
          background-color: #363b99;
          border-radius: 10px;
        }
      }
      .autocomplete-wrapper .item {
        display: block;
        cursor: pointer;
        font-size: 14px;
        padding: 0.375rem 0.75rem;
      }
      .autocomplete-wrapper .item.selected-item {
        background-color: #0432a3;
        color: #fafbfc;
      }
      .autocomplete-wrapper .item:hover {
        background-color: #0432a3;
        color: #fafbfc;
      }
      .form-label {
        margin-top: 20px;
        margin-bottom: 10px;
      }
      .type {
        .type-dropdown {
          .form-label {
            margin-top: 35px !important;
          }
        }
      }

      .tags-input-container {
        border: 1px solid #d1dfff;
        border-radius: 0.375rem;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 0.5em;
        width: 100%;
      }
      .industry {
        span {
          font-weight: 600;
          font-size: 15px;
          line-height: 22px;
          display: flex;
          align-items: center;
          color: #0432a3;
          // margin: 10px 0px;
          cursor: pointer;
          display: inline-block;
        }
      }

      .placetext {
        margin-left: 35px;
        margin-bottom: 10px;
        margin-top: 20px;
        p {
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 15px;
          line-height: 22px;
        }
      }
      .footer {
        margin-top: 30px;
        margin-bottom: 30px;
        flex-wrap: wrap;
        @media (max-width: 767px) {
          margin-top: 0;
        }
        h4 {
          font-weight: 600;
          font-size: 19px;
          line-height: 24px;
          @media (max-width: 767px) {
            font-size: 14px;
            line-height: 22px;
            margin-bottom: 10px;
          }
        }
        .btns {
          padding-left: 50px;
          @media (max-width: 767px) {
            padding-left: 0;
            flex-basis: 100%;
            flex-wrap: wrap;
          }
          .btn {
            margin-left: 0 !important;
          }
          .border-btn {
            border: 1px solid #0432a3;
            padding: 10px 30px;
            color: $primary;
            margin-right: 20px;
            @media (max-width: 767px) {
              margin-left: 0 !important;
              margin-right: 0;
              width: 100%;
              padding: 10px;
            }
          }

          .blue-btn {
            background: $primary;
            padding: 10px 30px;
            color: $white;
            margin-right: 20px;
            @media (max-width: 767px) {
              margin-left: 0;
              margin-right: 0;
              width: 100%;
              margin-top: 5px;
              padding: 10px;
            }
          }
        }
      }
      .gender {
        .btn {
          border-radius: 0px;
        }

        .border-btn {
          border: 1px solid #d1dfff;
          // padding: 10px 30px;
          color: $primary;
          // border-radius: 5px;
          // border-right: none;
          padding: 10px;
        }

        .blue-btn {
          background: $primary;
          // padding: 10px 30px;
          color: $white;
          border-radius: 5px;
        }
      }
      .age {
        .form-select {
          width: auto !important;
        }
        .year {
          margin-top: 15px;
          .row {
            width: 0;
          }
        }
        .month {
          margin-top: 15px;
          .row {
            width: 0;
          }
        }
        .dot {
          margin-left: 25px;
          margin-top: 15px;
        }
      }
      .hashtag {
        margin-top: 20px !important;
      }
      .action-link {
        .type-dropdown {
          margin-top: 14px;
        }
      }
    }

    .select-card {
      // height: 80px;
      border: 1px solid #ced4da;
      border-radius: 5px;
      // padding: 10px;
      width: 100%;
    }
    .post-drop {
      border: 1px solid #ced4da;
      border-radius: 5px;
      padding: 10px;
      margin-top: 50px;
      float: left;
      width: 100%;
      overflow-y: auto;
      height: 150px;
      // background-color: #00a3be;
      // color: #f9fafc;
      // display: flex;
      padding-left: 0;
      &::-webkit-scrollbar {
        width: 5px;
      }
      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      }
      &::-webkit-scrollbar-thumb {
        background-color: #363b99;
        border-radius: 10px;
      }

      // .left {
      //   display: "flex";
      //   justify-content: "center";
      //   // width: 60,
      //   margin-top: 3;
      //   margin-left: "40px";
      // }

      // .right {
      //   width: "100%";
      // }

      .card-postbar {
      }

      .card-postbar:hover {
        background-color: #ced4da;
        cursor: pointer;
      }
      .active-card-postbar {
        background-color: #ced4da;
      }
    }
    select {
      &::-webkit-scrollbar {
        width: 5px;
      }
      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      }
      &::-webkit-scrollbar-thumb {
        background-color: #363b99;
        border-radius: 10px;
      }
    }
  }
}

.payment {
  .check {
    display: flex;
    gap: 10px;
    margin-top: 15px;
    // margin-bottom: 20px;
    input {
      @include tick;
    }
    label {
      font-size: 14px;
      font-family: Inter;
      line-height: 20px;
      align-items: center;
      color: #676a79;
      display: flex;
      justify-content: center;
      margin-top: 3px;
    }
  }
  .campaign-cost {
    p {
      font-size: 14px;
      font-family: Inter;
      line-height: 20px;
    }
  }
  .total-cost {
    strong {
      font-size: 15px;
      font-family: Inter;
      font-weight: 600;
      line-height: 22.5px;
    }
  }
  .payment-card {
    margin-top: 30px;
    width: 513.369px;
    flex-shrink: 0;
    border-radius: 5px;
    border: 1px solid var(--borderline, #d1dfff);
    .header {
      width: 513px;
      height: 40px;
      flex-shrink: 0;
      border-radius: 5px 5px 0px 0px;
      background: var(--boxcolour, rgba(238, 240, 242, 0.35));
      p {
        color: var(--parafont, $grey);
        display: flex;
        align-items: center;
        padding: 10px;
        margin-left: 10px;
        font-size: 15px;
        font-family: Inter;
        line-height: 22.5px;
      }
    }
    .card-details {
      h5 {
        color: $grey;
        font-size: 15px;
        font-family: Inter;
        font-weight: 600;
        line-height: 22.5px;
      }
      p {
        color: $grey;
        font-size: 14px;
        font-family: Inter;
        line-height: 20px;
      }
      .card-image {
        object-fit: cover;
        height: 100%;
        width: 100%;
        padding: 20px;
      }
    }
  }
  .addcard {
    color: $primary;
    font-size: 15px;
    font-family: Inter;
    line-height: 22.5px;
  }
}

.adbar-preview {
  height: 300px;
  border-radius: 5px;
  background: $white;
  h5 {
    font-size: 15px;
    font-family: Inter;
    font-weight: 600;
    line-height: 22.5px;
    margin: 20px;
    padding-top: 10px;
  }
  .advertisement-side {
    border-radius: 6.744px;
    background: #fff;
    box-shadow: 0px 3px 10px 0px rgba(171, 190, 209, 0.6);
    .ad-image {
      background: url("../../../assets/img/adbar.png") no-repeat;
      background-size: cover;
      height: 204px;
      padding: 20px;
      position: relative;
      border-radius: 6.744px;
    }
  }
}

.adpreview {
  border-radius: 5px;
  background: $white;
  h5 {
    font-size: 15px;
    font-family: Inter;
    font-weight: 600;
    line-height: 22.5px;
    margin: 0px 20px 10px 20px;
    padding-top: 10px;
  }
  .like,
  .comment,
  .share {
    @media (max-width: 767px) {
      span {
        font-size: 10px;
        img {
          max-width: 12px;
        }
      }
    }
  }
  .dashboard-view {
    padding: 13px;
    .main-post {
      display: flex;
      gap: 20px;
      margin-bottom: 20px;
      justify-content: space-between;

      .main-Post-div {
        display: flex;
        flex-direction: row;
        gap: 10px;
      }

      .prof-img {
        width: 30px;
        height: 30px;
        border-radius: 55%;
        box-shadow: 0 0 0px 4px #008c39;

        img {
          border-radius: 50%;
          width: 100%;
          height: 100%;
          box-shadow: 0 0 0px 2px #ffffff;
          cursor: pointer;
        }
      }

      .details {
        // flex: 0 0 30%;

        h4 {
          @include h1;
          font-size: 15px;
          line-height: 18px;
          margin: 0px;
          // margin-bottom: 5px;
          overflow: hidden;
          max-width: 10rem;
          cursor: pointer;
        }

        h4:hover {
          text-decoration: underline;
          color: $primary;
        }

        p {
          @include para;
          font-size: 10px;
          margin: 0px;
          line-height: 14px;
          // width: max-content;
        }
      }
    }

    .text {
      .despan {
        @include para;
        white-space: pre-line;

        // span {
        //   color: $primary;
        // }
      }

      .translate {
        span {
          color: $blue;
          font-size: 10px;
          font-weight: 400;
        }
      }
    }

    .flex {
      display: flex;
      gap: 16px;
      justify-content: space-between;
      .others span {
        font-size: 12px;
        margin-left: 1px;
        font-weight: 400;
        line-height: 22px;
        color: $grey;
      }
    }

    .scheduleFeatureCustomTimePicker {
      input {
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #212529;
      }
    }
  }
   
}
