@import "../../../assets/scss/mixin.scss";
@import "../../../assets/scss/variable.scss";
.layout-view {
  .sidebar {
    aside {
      background: $white;
      box-shadow: 0px 2px 4px rgba(171, 190, 209, 0.6);
      border-radius: 0px 0px 5px 5px;

      .view_profile_network {
        height: 41.58px;
        background: $primary;
        color: $white;
        border-radius: 5px;
        text-align: center;
        line-height: 42px;

        a {
          color: $white;
          font-weight: 600;
          font-size: 15px;
        }
      }

      .bg {
        height: 132.02px;
        background: $linear;
        border-radius: 5px;
        position: relative;

        .profile-img {
          border: 2px solid #ffffff;
          border-radius: 5px;
          height: 72px;
          width: 72px;
          position: absolute;
          margin: 20px;
        }
        .back-image-fluid {
          border-radius: 5px;
          height: 132.02px;
          width: 100%;
          object-fit: cover;
        }
      }

      .nobel {
        color: $primary;
        @include show;
        text-align: end;
        text-transform: uppercase;
        font-size: 10px;
        margin-right: 7px;
        cursor: pointer;
      }

      .profile-details {
        h6 {
          @include font-19;
          word-break: break-all;
          color: $dark-black;
        }

        p {
          @include font-14;
          color: $dark-black;
        }
      }

      .follower {
        display: flex;
        justify-content: space-around;
        margin-top: 20px;
        margin-bottom: 15px;

        .box {
          text-align: center;

          h6 {
            @include font-600;
            color: $dark-black;
          }

          p {
            @include font-14;
            color: $grey;
          }
        }

        .h-border {
          background: #d1dfff;
          width: 1px;
        }
      }

      .social {
        margin: 20px;

        ul {
          li {
            @include center-text;
            @include font-600;
            position: relative;
            border-radius: 5px;
            padding: 20px;
            cursor: pointer;

            .count {
              position: absolute;
              right: 10px !important;
              background: #19BCF4;
              font-weight: bolder;
              font-size: large !important;
              color: #fff !important;
              border-radius: 50%;
              padding: 5px !important;
              // padding: 2px 8px !important;

              width: 23px;
              height: 23px;
              font-size: 14px;
              display: flex;
              align-items: center;
              justify-content: center;
              
              // line-height: 20px;
            }
          }

          // .active {
          //   background: $transparent-blue;
          // }
        }
      }
    }

    .bottom-custom {
      // position: unset;
      // margin: 43px;

      .links {
        margin-bottom: 10px;

        ul {
          display: flex;
          flex-wrap: wrap;
          gap: 5px;

          li {
            @include para;
            font-size: 10px;
          }
        }
      }

      .copy-right {
        p {
          @include para;
          font-size: 10px;
        }
      }
    }
    @media (max-width: 991px) {
      .bottom-custom {
        position: unset;
        margin: 43px;
        display: none;
      }
    }
  }

  @media (max-width: 992px) {
    .web-footer {
      display: none;
    }
  }
}
