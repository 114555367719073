@import "../../../../assets/scss/mixin.scss";
@import "../../../../assets/scss/variable.scss";

.msg-sidebox {
  height: 89vh;
  background: $white;
  border-radius: 5px;
  box-shadow: 0px 2px 4px rgba(171, 190, 209, 0.6);
  overflow-y: auto;

  .msg-sidebox-top {
    background-color: $white;
    z-index: 10;
    position: sticky;
    top: 0;
    left: 0;
  }

  .msg-tab {
    cursor: pointer;
    h2 {
      @include font-19;
      color: $grey;
      font-size: 18px;
      @media (max-width: 767px) {
        font-size: 14px;
      }
    }
    &.active h2 {
      color: $black;
    }
  }

  // .group {
  //   h2 {
  //     @include font-19;
  //     color: $grey;
  //     font-size: 18px;
  //     @media (max-width: 767px) {
  //       font-size: 14px;
  //     }
  //   }
  // }

  .dot {
    position: relative;
    cursor: pointer;
    .dropdown {
      position: absolute;
      // left: 0;
      right: 0;
      z-index: 1;
      background: #ffffff;
      box-shadow: 0px 2px 4px rgba(171, 190, 209, 0.6);
      padding: 10px;
      width: max-content;
    }
  }

  .icon-edit {
    .edit {
      @include edit-black;
    }
  }

  .search-box {
    position: relative;

    input {
      border: none;
      padding: 5px 30px;

      ::placeholder {
        @include font-14;
        color: $grey;
      }
    }

    &::before {
      content: "";
      background: url(../../../../assets/img/gg_search.svg) no-repeat;
      width: 22px;
      height: 22px;
      z-index: 5;
      cursor: pointer;
      display: inline-block;
      position: absolute;
      top: 7px;
    }
  }

  .buttons {
    .btn {
      padding: 3px 5px;
      background: $transparent-blue;
      border-radius: 25px;
      @include font-14;
      color: $grey;
      &.btn-blue {
        background: #0432a3;
        color: #ffffff;
      }
    }
  }

  .archieve {
    background: #fbfbfc;
    /* shaddow2px */

    box-shadow: 0px 2px 4px rgba(171, 190, 209, 0.6);
    padding: 12px 19px;
    cursor: pointer;

    span {
      @include font-600;
    }
  }
}
