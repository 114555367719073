@import "../../../../assets/scss/mixin.scss";
@import "../../../../assets/scss/variable.scss";

.featured-article {
  h4 {
    @include h1;
    font-size: 14px;
    line-height: 10px;
    margin-bottom: 10px;
  }

  .box {
    display: flex;
    gap: 15px;
    margin-top: 20px;

    .img {
      height: 50px;
      width: 84px;
      border-radius: 5px;
      background: $linear;

      img {
        height: 50px;
        width: 84px;
        border-radius: 5px;
      }
    }

    .text {
      h6 {
        @include h1;
        font-size: 14px;
        line-height: 18px;
        text-transform: capitalize;
      }

      h6:hover {
        text-decoration: underline;
      }

      span {
        font-weight: 400;
        font-size: 10px;
      }
    }
  }

  span {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $grey;
    cursor: pointer;
  }
  span:hover {
    color: $primary;
  }
}

.bg-white {
  background-color: $white;
}
