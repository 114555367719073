@import "../../../assets/scss/mixin.scss";
@import "../../../assets/scss/variable.scss";

.leads-modal {
  .textbox-custom {
    min-height: 100px;
    width: 100%;
    border: 1px solid $border;
    padding: 10px;
  }

  .form-select {
    border: none;
    font-weight: 600;
    color: $primary !important;
    padding: 0px 0px 0px 0px;
    margin-left: 30px;
  }

  .input-group {
    border: 1px solid $border;
    border-radius: 5px;
    padding-left: 5px;

    .input-group-text {
      background: none;
      border: none;

      .calendar {
        @include calender-icon;
      }

      .clock {
        @include clock-icon;
      }
    }

    input {
      border: none;
    }
    input[type="date"] {
      height: auto;
    }
    input[type="time"] {
      height: auto;
    }
  }

  input[type="date"]::-webkit-calendar-picker-indicator {
    position: absolute;
    left: 0;
  }

  input[type="time"]::-webkit-calendar-picker-indicator {
    position: absolute;
    left: 0;
  }

  input::-webkit-datetime-edit {
    position: relative;
    left: 15px;
  }

  input::-webkit-datetime-edit-fields-wrapper {
    position: relative;
    left: 15px;
  }
}
