.custom-tag{
    height: 232px;
    width: 100%;
    background-color: black;

    .ad-black-shade{
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.39);
    }

    .ad-desc{
        font-weight: 400;
        font-size: 14px;
        line-height: 1.28;
        color: #ffffff;
    }
    .ad-link{
        font-size: 14px;
        line-height: 1.28;
        color: #ffffff;
        font-weight: 600;
        cursor: pointer;
    }
}

.ad-content{
    padding-top: 140px;
    padding-left: 30px;
}
.advertisement-side{
.carousel-indicators{
    button{
        width: 10px !important;
    border-radius: 50% !important;
    height: 10px !important;
    }
}
}
