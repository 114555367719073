@import "../../../assets/scss/mixin.scss";
@import "../../../assets/scss/variable.scss";

.job-detail{
  .modal-title{
    color: black;
    margin-top: 20px;
  }
    .portal-cover-img {
        max-height: 657px;
        position: relative;
        .img-cover {
          //   height: 100%;
          object-fit: cover;
          width: 100%;
        }
        .header-row {
          position: absolute;
          top: 44px;
          // padding-left: 99px;
          display: flex;
          width: 100%;
          align-items: center;
          z-index: 1;
          .job_details_header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            max-width: 1166px;
            width: 100%;
            margin: auto;;
          }
          .authenticate {
            margin-left: auto !important;
            // padding-right: 99px;
            display: flex;
            align-items: center;
            .login {
              color: #ffffff;
              font-weight: 600;
              font-size: 14px;
              padding-right: 25px;
            }
          }
        }
        .search {
          position: absolute;
          left: 20%;
          top: 45%;
          margin: 10px;
          padding: 10px;
          width: 65%;
          color: $white;
          z-index:1
          
        }
      }
      .job-description{
        margin-top: 50px;
        .apply{
            display: flex;
            justify-content: flex-end;
            .btn{
                color: $white;
                border-radius: 0px;
            }
        }
        .job-title{
          font-weight: 600;
          font-size: 30px;
          line-height: 40px;
          color: $dark-black;
          margin-bottom: 20px;
        }
        .job-details{
            .skills{
                margin-bottom: 30px;
            }
            .qualification{
              h5{
                margin-bottom: 10px;
              }
              ul{
                list-style: disc !important;
                li{
                    margin:20px 20px 15px 25px;;
                }
              }
            }
            .job-descrp{
                margin-bottom: 30px;
                p{
                    margin-bottom: 20px;
                    margin-top: 20px;
                }
                ul{
                    list-style: disc !important;
                    li{
                        margin:10px 20px 0px 25px;;
                    }
                  }

            }
            .looking{
                margin-bottom: 30px;
                margin-top: 20px;
                h5{
                    margin-bottom: 20px;
                }
                ul{
                    list-style: disc !important;
                    li{
                        margin:10px 20px 0px 25px;;
                    }
                  }
            }
        }
        
        // .job-location{
        //   font-weight: 500;
        //   font-size: 20px;
        //   line-height: 22px;
        //   color: $dark-black;
        //   margin-bottom: 20px;
        // }
        // .job-salary{
        //   font-weight: 500;
        //   font-size: 20px;
        //   line-height: 22px;
        //   color: $dark-black;
        //   margin-bottom: 20px;
        // }
        // .job-type{
        //   font-weight: 500;
        //   font-size: 20px;
        //   line-height: 22px;
        //   color: $dark-black;
        //   margin-bottom: 20px;
        // }
        // .job-description{
        //   font-weight: 500;
        //   font-size: 20px;
        //   line-height: 22px;
        //   color: $dark-black;
        //   margin-bottom: 20px;
        // }
        // .job-apply{
        //   font-weight: 500;
        //   font-size: 20px;
        //   line-height: 22px;
        //   color: $dark-black;
        //   margin-bottom: 20px;
        // }
        // .job-apply-btn{
        //   background: $primary;
        //   padding: 8px 20px;
        //   @include small;
        //   border-radius: 20px;
        //   color: $white;
        //   cursor: pointer;
        // }
      }
      .modal-header {
        display: flex;
        justify-content: center;
        border: none;
        .btn-close {
        opacity: 0;
        padding: 0px;
        margin: 0px;
      }
    }
    .alert{
        .btn{
            color: $white;
            margin: 20px 0px 20px 150px
        }
    }

    .portal-cover-img::after{
      content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: 0;
        background: linear-gradient(45deg, black, transparent);
    }
}


@media screen and (max-width:650px){
  .search {
    .job_location_company{
      flex-direction: column;
    }
  }
}