@import "../../../assets/scss/mixin.scss";
@import "../../../assets/scss/variable.scss";

.post-jobs.bg-job {
  min-height: 100vh;
  border-radius: 5px;
  // background-color: #e5e5e5;

  .bg-white {
    border-radius: 5px;
  }

  .heading {
    @include font-19;
  }

  .sub-heading {
    @include small;
    font-weight: 600;
    color: $dark-black;
  }

  .add-skills-recruiter {
    height: 33px;
    margin-top: 11px;
    margin-right: 5px;
    font-size: 15px;
    font-weight: 400;
    padding: 5px 10px 5px 10px;
    border-radius: 20px;
    color: $white;
    background-color: $primary;
    // p {
    //   min-width: max-content;
    // }
  }
  .addSkillInput {
    margin-top: 11px;
    height: 33px;
  }
  .remove-icon {
    vertical-align: middle;
    margin-left: 5px;
  }
  .plus {
    width: 17px;
    height: 17px;
  }
  .add-skills-recruiter-btn {
    cursor: pointer;
    height: 33px;
    margin-top: 11px;
    margin-right: 5px;
    font-size: 15px;
    font-weight: 400;
    padding: 5px 10px 5px 10px;
    border-radius: 20px;
    color: $primary;
    border-radius: 20px;
    border: 1px solid $primary;
    // background-color: $primary;
  }

  .screening-box {
    margin: 16px;
    min-height: 140px;
    border: 1px solid $border;
    border-radius: 5px;

    .box-bar {
      min-height: 40px;
      background-color: rgba(238, 240, 242, 0.35);
    }
  }

  .sub-content {
    @include small;
    color: $grey;

    @media (max-width: 767px) {
      display: list-item;
    }
  }

  .job-editor {
    padding-left: 16px;
    padding-right: 16px;

    .rdw-editor-toolbar {
      margin-bottom: 0px;
    }

    .editor-class {
      border-radius: 0px;
    }
  }

.org-name {
  display: block;
  color: #717171;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-top: 2px;

  .location {
    position: relative;

    &::before {
      content: "";
      width: 4px;
      height: 4px;
      background-color: #676a79;
      display: inline-block;
      vertical-align: middle;
      margin: 0 5px 0 2px;
      border-radius: 50%;
    }
  }
}
}

.autocompletejob-wrapper1 {
  // position: relative;
  width: 100%;
}

.autocompletejob-wrapper1 input {
  // border: none;
  width: 100%;
  padding: 0.375rem 0.75rem;
  border-radius: 0.375rem;
}

.autocompletejob-wrapper1 .dropdown {
  width: 100%;
  padding: 0;
  text-align: left;
  max-height: 150px;
  overflow: hidden;
  overflow-y: auto;
  position: absolute;
  z-index: 1;
  background-color: #ffffff;
  border: 1px solid #d1dfff;
  border-radius: 4px;
}

.autocompletejob-wrapper1 .item {
  display: block;
  cursor: pointer;
  font-size: 14px;
  padding: 0.375rem 0.75rem;
}

.autocompletejob-wrapper1 .item.selected-item {
  background-color: #0432a3;
  color: #fafbfc;
}

.autocompletejob-wrapper1 .item:hover {
  background-color: #0432a3;
  color: #fafbfc;
}

.post-job-title {
  font-size: 15px;
  font-weight: 600;
  line-height: 22px;
  color: #676A79;
}

.post-job-location {
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
  color: #676A79;
}

.post-job-saved-text {
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
  color: #676A79;
}

.post-job-draft-text {
  font-size: 15px;
  font-weight: 600;
  line-height: 22px;
  color: #676A79;
}
