@import "../../assets/scss/mixin.scss";
@import "../../assets/scss/variable.scss";

.web-footer-bottom {
  display: none;
}

@media (max-width: 992px) {
  .web-footer-bottom {
    display: block;
  }
}

.newsletter {
  .main-center {
    .search {
      position: relative;

      input {
        border: 1px solid #d1dfff;
        border-radius: 5px;
        width: 100%;
        height: 40px;
        padding: 5px 39px 5px 20px;

        &::placeholder {
          color: $placeholder;
          @include font-14;
        }
      }

      .search-icon {
        @include search-icon;
        right: 12px;
        top: 10px;
      }
    }

    .wrapper {
      position: relative;
      background: $white;
      border-radius: 5px;

      .add-cursor :hover {
        cursor: pointer;
      }

      .infinite-scroll-component {
        overflow: hidden !important;
      }

      .blue-btn {
        border-radius: 26px;
        background: #0432a3;
        padding: 10px 20px;
        color: #ffffff;
        margin-right: 20px;
      }

      .border-btn {
        border: 1px solid #0432a3;
        padding: 10px 30px;
        color: #0432a3;
        margin-right: 20px;
        border-radius: 26px;
      }

      .btn {
        @media (max-width: 767px) {
          margin-top: 20px;
        }
      }

      .border-btn-square {
        border: 1px solid #0432a3;
        padding: 10px 30px;
        color: #0432a3;
        margin-right: 20px;
      }

      .border-btn-square-not-allowed {
        border: 1px solid #0432a3;
        padding: 10px 30px;
        color: #0432a3;
        margin-right: 20px;
      }

      .border-btn-square-not-allowed:hover {
        cursor: not-allowed;
      }

      .nav-tabs {
        .manage-invitation {
          position: absolute;
          right: 20px;
          cursor: pointer;

          a {
            color: $grey !important;
          }

          a:hover {
            color: $primary !important;
          }
        }

        .nav-insight-item {
          padding: 0 30px 0 30px !important;
        }

        .nav-item {
          padding: 0 28px 0 0px;

          @media (max-width: 767px) {
            padding-right: 10px;
            margin-bottom: 10px;

            &:last-child {
              padding-right: 0;
            }
          }

          .nav-link {
            border: none;
            padding: 0 0 9px;
            @include font-600;
            color: $grey;

            @media (max-width: 767px) {
              font-size: 14px;
            }
          }

          .active {
            border-bottom: 4px solid $primary;
            color: $primary;
            border-radius: 4px;
          }
        }
      }

      .end {
        @include font-600;
        color: $primary;
        position: absolute;
        right: 18px;
        cursor: pointer;
      }

      .card-height-article {
        height: 550px;

        @media (max-width: 767px) {
          height: auto;
        }
      }

      .card-height-page-article {
        height: 450px;
      }

      .card-height-page {
        height: 427px;

        @media (max-width: 767px) {
          height: auto;
        }
      }

      .card-height-event {
        height: 450px;
      }

      .card-height-groups {
        min-height: 405px;

        @media (max-width: 767px) {
          min-height: auto;
        }
      }

      .card {
        border: 1px solid $border;
        margin-bottom: 20px;
        width: 100%;
        border-radius: 5px;

        .followicon {
          margin-right: 3px;
        }

        .groupicon {
          background: url(../../assets/img/groupicon.png) no-repeat center;
          width: 20px;
          height: 14px;
          display: inline-block;
          cursor: pointer;
        }

        .pageicon {
          border-radius: 50%;
          width: 100px;
          height: 100px;
          object-fit: cover;
        }

        .card-image-square {
          height: 300px;
          width: 100%;
          object-fit: cover;
          background: $linear;
        }

        .card-header {
          background: white;
          border: none;

          .name {
            @include small;
            color: $dark-black;
          }

          .name:hover {
            text-decoration: underline;
            color: $primary;
            cursor: pointer;
          }

          .date {
            display: flex;
            margin-top: 10px;
            justify-content: space-between;
            align-items: center;

            .days {
              @include para;
              font-size: 14px;
            }

            .subscribe {
              background: $transparent-blue;
              padding: 2px 12px;
              @include font-14;
              color: $primary;
              border-radius: 5px;
              cursor: pointer;
            }
          }

          .profile-img {
            @include profile-circle;
          }

          h3 {
            @include font-600;
            font-size: 19px;
            margin-top: 16px;
            color: $dark-black;
            text-transform: capitalize;
          }
        }

        .card-text-control {
          display: inline;
          -webkit-line-clamp: 2;
          text-overflow: ellipsis;
          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          word-wrap: break-word;
        }

        .card-page-text-control {
          display: inline;
          -webkit-line-clamp: 4;
          text-overflow: ellipsis;
          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          word-wrap: break-word;
        }

        .card-event-control {
          display: inline;
          -webkit-line-clamp: 1;
          text-overflow: ellipsis;
          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          word-wrap: break-word;
        }

        .card-body {
          p {
            @include para;
            font-size: 14px;
          }

          img {
            display: none;
          }

          .read {
            color: $primary;
            @include font-14;
            margin-top: 9px;
            cursor: pointer;
            display: inline-block;
            font-weight: bold;
          }
        }
      }
    }
  }
}

.article {
  position: relative;

  .wrapper {
    box-shadow: 0px 2px 4px rgba(171, 190, 209, 0.6);
    border-radius: 5px 5px 5px 5px;

    .newsletter-image {
      width: 100%;
      height: 300px;
      border-radius: 5px 5px 5px 5px;
      object-fit: cover;
      background: $linear;
      border-bottom-right-radius: 0px;
      border-bottom-left-radius: 0px;
    }

    .side-icon {
      margin-top: 128px;
      margin-left: 39px;

      @media (max-width: 767px) {
        margin-left: 0;
      }

      span {
        @include font-14;
        color: $grey;
      }

      .like {
        @include like-border;
      }

      .like-blue {
        @include like-border-blue;
      }

      .comment {
        @include comment-border;
      }

      .reply {
        @include reply-border;
      }
    }

    .profiles {
      display: flex;
      gap: 15px;

      .img {
        @include profile-circle;
      }

      .details {
        h6 {
          @include small;
          cursor: pointer;
          font-weight: 600;
          text-transform: capitalize;
          word-break: break-all;
        }

        h6:hover {
          text-decoration: underline;
          color: $primary;
        }

        p {
          @include para;
        }
      }
    }

    .hrs {
      @include small;

      .text-blue {
        color: $primary;
        display: flex;
        cursor: pointer;
      }
    }

    .text {
      h4 {
        @include h1;
        margin-bottom: 20px;
        word-break: break-all;

        @media (max-width: 767px) {
          font-size: 22px;
          line-height: 28px;
        }
      }

      p {
        @include para;
        margin-bottom: 20px;
        word-break: break-all;
      }

      img {
        width: 100% !important;
      }
    }

    .share {
      @include reply;
    }

    .input {
      input {
        background: rgba(238, 240, 242, 0.35);
        border-radius: 5px;
        border: none;
        width: 100%;
        height: 41px;
        padding: 20px;
      }
    }

    .translate {
      color: $blue;
      text-transform: capitalize;
      @include small;
      margin-top: 10px;
    }

    .comments {
      .profile-img {
        @include profile-circle;
      }

      .like-comment {
        @include like-border;
      }

      .like-blue-comment {
        @include like-border-blue;
      }

      .comment {
        @include comment-border;
      }

      .dropDownBox {
        @include dropdown;
        top: auto;
        right: 1rem;

        ul {
          li {
            gap: 10px;
            align-items: center;
          }
        }
      }

      .text-comment {
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: $grey;
        cursor: pointer;

        @media (max-width: 767px) {
          font-size: 10px;
          line-height: 14px;
        }
      }

      .comment-title {
        font-size: 17px;
        font-weight: 600;
        color: $dark-black;
        padding-top: 15px;
      }

      .blue-like {
        color: $primary;
      }

      .pl-col {
        padding-left: 0px;
      }

      .comment-text-box {
        background: rgba(238, 240, 242, 0.35);
        border-radius: 5px 5px 5px 5px;
        position: relative;
        padding: 16px;

        .comment-content {
          display: flex;
          flex-direction: column;
        }

        h5 {
          font-weight: 600;
          color: $dark-black;
          font-size: 15px;
          // line-height: 0px;
        }

        h5:hover {
          text-decoration: underline;
          color: $primary;
          cursor: pointer;
        }

        p {
          font-weight: 400;
          line-height: 22px;
          color: $grey;
          font-size: 14px;
        }

        .time {
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: $grey;

          span {
            font-size: 14px;
          }
        }
      }

      .load-text {
        text-align: center;
        font-weight: 600;
        color: $dark-black;
        font-size: 15px;

        span {
          cursor: pointer;
        }
      }
    }
  }
}

.networks {
  .wrapper {
    h4 {
      @include font-600;
      color: $grey;
      word-break: break-all;

      a {
        @include font-600;
        color: $dark-black;
        word-break: break-all;
      }
    }

    .view {
      @include small;
      color: $grey;
      cursor: pointer;
    }

    .view:hover {
      color: $primary;
    }

    .profile-img {
      @include profile-80;
    }

    .details {
      h2 {
        @include font-600;
      }

      p {
        @include font-14;
        color: $grey;

        span {
          font-size: 10px;
          margin-left: 5px;
        }
      }
    }

    .btn-width {
      width: "20%";
    }

    .btn-light {
      color: $primary;
      display: block;
    }

    .btn-margin-bottom {
      position: absolute;
      bottom: 0;
      right: 0px;
      left: 3%;
      margin: 10px;
      margin-left: 0;
      width: 94%;

      @media (max-width: 767px) {
        position: relative;
        padding-left: 20px;
        width: 100%;
        margin: auto auto 0;
      }
    }

    .suggestion {
      .slider-box {
        display: flex;
        gap: 10px;
        position: relative;

        .people-card-height {
          min-height: 266px;

          .btn {
            padding: 10px 32px;
          }

          @media (min-width: 576px) {
            .btn {
              padding: 10px 32px;
            }
          }
        }

        .card {
          width: 154px;
          padding: 8px;
          text-align: center;

          @media (max-width: 767px) {
            width: 48%;
          }

          .rounded-img {
            margin: 0 auto;
          }

          h3 {
            @include font-600;
            text-transform: capitalize;

            .verify {
              @include verify-profile;
            }
          }

          .hiring {
            @include small;
            font-size: 10px;
            color: #8430c8;
            border: 1px solid #8430c8;
            border-radius: 5px;
            width: 60%;
            margin: 10px auto;
          }
          .seeking {
            @include small;
            font-size: 10px;
            border-radius: 5px;
            width: 60%;
            margin: 10px auto;
            color: #f68a18;
            border: 1px solid #f68a18;
          }

          .designation {
            @include small;
            color: $grey;
            margin-bottom: 9px;
            font-size: 14px;

            @media (max-width: 767px) {
              font-size: 10px;
              line-height: 14px;
            }
          }

          .connections {
            @include small;
            font-size: 10px;
            color: $grey;
            margin-bottom: 5px;
          }

          .connections:hover {
            cursor: pointer;
            text-decoration: underline;
            text-decoration-color: $grey;
          }
        }

        .custom-arrow {
          position: absolute;
          width: 100%;
          height: 100%;
          align-items: center;
          display: flex;

          .arrows {
            display: flex;
            justify-content: space-between;
            width: 100%;

            .arrow {
              background: #fff;
              border-radius: 50%;
              padding: 5px 10px;
              box-shadow: 0px 3.6px 7.2px rgba(171, 190, 209, 0.3);
              cursor: pointer;

              .right {
                transform: rotate(180deg);
              }
            }
          }
        }
      }
    }

    .slider-box-network {
      position: relative;
      .react-multiple-carousel__arrow--right::before {
        content: "" !important;
      }
      .react-multiple-carousel__arrow--right {
        background: url("../../assets/img/rightarrowcircle.svg") no-repeat
          center center !important;
        background-size: cover !important;
        width: 30px !important; /* adjust size as needed */
        height: 30px !important; /* adjust size as needed */
      }

      .react-multiple-carousel__arrow--left::before {
        content: "" !important;
      }
      .react-multiple-carousel__arrow--left {
        background: url("../../assets/img/leftarrowcircle.svg") no-repeat center
          center !important;
        background-size: cover !important;
        width: 30px !important; /* adjust size as needed */
        height: 30px !important; /* adjust size as needed */
      }
      .people-card-height {
        min-height: 250px;
      }

      .card {
        width: 154px;
        padding: 8px;
        text-align: center;

        .rounded-img {
          margin: 0 auto;
        }

        h3 {
          @include font-600;

          .verify {
            @include verify-profile;
          }
        }

        .hiring {
          @include small;
          font-size: 10px;
          color: #8430c8;
          border: 1px solid #8430c8;
          border-radius: 5px;
          width: 60%;
          margin: 10px auto;
        }
        .seeking {
          @include small;
          font-size: 10px;
          border-radius: 5px;
          width: 60%;
          margin: 10px auto;
          color: #f68a18;
          border: 1px solid #f68a18;
        }

        .designation {
          @include small;
          color: $grey;
          margin-bottom: 9px;
          font-size: 14px;
        }

        .connections {
          @include small;
          font-size: 10px;
          color: $grey;
          margin-bottom: 5px;
        }

        .connections:hover {
          text-decoration: underline;
          text-decoration-color: $grey;
          cursor: pointer;
        }
      }

      .custom-arrow {
        position: absolute;
        width: 100%;
        height: 100%;
        align-items: center;
        display: flex;

        .arrows {
          display: flex;
          justify-content: space-between;
          width: 100%;

          .arrow {
            background: #fff;
            border-radius: 50%;
            padding: 5px 10px;
            box-shadow: 0px 3.6px 7.2px rgba(171, 190, 209, 0.3);
            cursor: pointer;

            .right {
              transform: rotate(180deg);
            }
          }
        }
      }
    }

    .groups {
      .line {
        border: 1px solid $border;
        background: $border;
        opacity: 0.5;
      }

      .groups-card {
        // display: flex;
        // gap: 27px;

        .card {
          .bg {
            background: $linear;
          }

          .card-img {
            height: 80px;
            position: relative;
            border-bottom-left-radius: 0px;
            border-bottom-right-radius: 0px;
            background: $linear;

            .circle-icon-group-size {
              height: 64px;
              width: 64px;
              border-radius: 50%;
              object-fit: cover;
            }

            .circle-icon {
              position: absolute;
              transform: translate(-50%, -50%);
              left: 50%;
              top: 90%;
              // width: 100px;
              // height: 100px;
              // .circle-img{
              //   border-radius: 50%;
              //   width: 100%;
              //   height: 100%;
              // }
            }

            .circle-icon :hover {
              cursor: pointer;
            }
          }

          .header {
            text-align: center;
            margin-top: 44px;

            h4 {
              @include font-600;
              color: $black;
              margin-bottom: 0px;
              cursor: pointer;
              text-transform: capitalize;
            }

            p {
              @include small;
              color: $grey;

              span {
                @include font-600;
                color: $black;
                margin-right: 7px;
              }
            }

            .group-head-p {
              padding: 5px;
            }
          }

          .text {
            text-align: center;

            p {
              @include small;
              color: $grey;
              //               white-space: nowrap;
              // overflow: hidden;
              // text-overflow: ellipsis;
            }

            .link {
              @include small;
              color: $primary;
              font-size: 12px;
              margin-bottom: 15px;
            }
          }
        }
      }
    }

    .events {
      .border-btn {
        border: 1px solid #0432a3;
        border-radius: 26px;
        padding: 10px 20px;
        color: $primary;
        margin-right: 20px;
      }

      .blue-btn {
        border-radius: 26px;
        background: $primary;
        padding: 10px 20px;
        color: $white;
        margin-right: 20px;
      }

      .card {
        .event-card-image {
          width: 100%;
          height: 200px;
          border-radius: 5px 5px 0px 0px;
          object-fit: cover;
        }

        .img-section {
          position: relative;
          width: 100%;
          height: 200px;
          border-radius: 5px 5px 0px 0px;
          background: $linear;

          img {
            border-radius: 5px 5px 0px 0px;
          }

          .tag {
            position: absolute;
            right: 10px;
            top: 5px;
            background: $white;
            border-radius: 5px;
            padding: 5px 10px;
            cursor: pointer;
            @include font-14;
          }

          .tag-color-physical {
            color: $orange;
          }

          .tag-color-virtual {
            color: $primary;
          }

          .pin {
            @include pin-icon;
            position: absolute;
            bottom: 10px;
            right: 10px;
            background-color: $white;
            border-radius: 50%;
            padding: 15px;
          }

          .unpin {
            @include unpin-icon;
            position: absolute;
            bottom: 10px;
            right: 10px;
            background-color: #f68a18;
            border-radius: 50%;
            padding: 15px;
          }
        }

        .header {
          h4 {
            color: $black;
            cursor: pointer;
            text-transform: capitalize;
          }
        }

        .date {
          font-size: 13px;
          margin-top: 5px;

          .calender-icon {
            margin-right: 6px;
          }

          display: flex;
          // @include font-14;
          color: $grey;
        }

        .location {
          @include center-text;
          gap: 10px;
          font-size: 13px;

          .icon {
            @include location-icon;
            height: 15px;
            width: 19px;
          }

          @include font-14;
          color: $grey;
        }

        .people-pic {
          @include font-14;
          color: $grey;
          display: flex;
          align-items: center;
          // justify-content: space-between;
          margin-top: 14px;
          gap: 5px;
          margin-left: 17px;

          .d-flex {
            margin: 0;

            span {
              display: inline-block;
              @include profile-circle;
            }

            .profiles-img {
              span {
                display: inline-block;
                margin-left: -17px;
                @include profile-circle;
                background: $primary;
                color: $white;
                text-align: center;
                line-height: 34px !important;
                @include font-14;
              }
            }
          }
        }

        .d-flex {
          margin-top: 16px;
          margin-bottom: 16px;
          justify-content: space-between;

          .btn {
            width: 176px;
          }

          .share-icon {
            @include share-blue;
          }
        }
      }
    }

    .pages {
      .groups-card {
        .card {
          .card-img {
            height: 138px;
          }
        }
      }
    }

    .hashtag {
      .card {
        .hash {
          @include hash-blue;
          background-color: $border;
          border-radius: 50%;
          width: 40px;
        }

        .header {
          h4 {
            color: $black;
          }

          p {
            color: $grey;
          }
        }
      }
    }
  }
}

.event-details {
  .wrapper {
    border-radius: 5px;

    .box-img {
      height: 300px;
      border-radius: 5px;
      overflow: hidden;
      position: relative;
      background: $linear;

      .calendar {
        position: absolute;
        top: 20px;
        left: 20px;
        background: $white;
        border-radius: 5px;
        padding: 5px;
        @include font-14;
        text-align: center;

        .day {
          border-radius: 5px 5px 0px 0px;
          background: $primary;
          color: $white;
          padding: 8px 20px;
        }

        .date {
          @include font-600;
          padding: 8px;
        }
      }

      .img_event_detail {
        height: 300px;
        width: 100%;
      }
    }

    .section {
      .heading {
        h2 {
          @include font-600;
          font-size: 30px;
          color: $black;
          line-height: 32px;
          word-break: break-all;
        }

        .btn {
          background: $primary;
          color: $white;
        }

        .dropDownBox {
          position: absolute;
          background: $white;
          border-radius: 5px;
          padding: 17px 15px;
          z-index: 50;
          border: 1px solid $border;
          top: 66px;
          right: 1rem;

          li {
            font-weight: 400;
            font-size: 15px;
            line-height: 22px;
            color: $grey;
            display: flex;
            gap: 10px;
            align-items: center;
            line-height: 34px;
            cursor: pointer;
          }
        }

        .share-icon {
          @include share-blue;
        }

        .bell-icon {
          @include bell-icon;
        }
      }

      .virtual {
        .btn {
          filter: drop-shadow(0px 2px 4px rgba(171, 190, 209, 0.6));
          background: $white;
          color: $primary;
          padding: 4px 9px;
        }

        .link {
          margin-top: 10px;
          @include font-14;
          color: $primary;

          .copy {
            @include copy-icon;
          }
        }
      }

      .content {
        p {
          @include para;
          color: $grey;
          word-break: break-all;
        }

        .work {
          @include para;
          color: $primary;
        }

        .technology {
          ul {
            li {
              @include para;
              line-height: 30px;

              .task {
                @include task-icon;
              }

              .calender {
                @include calender-icon;
                display: inline-block;
                height: 15px;
                width: 36px;
              }

              .location {
                @include location-icon;
                display: inline-block;
                height: 15px;
              }
            }
          }
        }
      }

      .events-add {
        .people-pic {
          @include font-14;
          color: $grey;
          display: flex;
          align-items: center;

          .d-flex {
            margin: 0;

            span {
              display: inline-block;
              @include profile-circle;
            }

            .profiles-img {
              span {
                display: inline-block;
                margin-left: -17px;
                @include profile-circle;
                background: $primary;
                color: $white;
                text-align: center;
                line-height: 34px !important;
                @include font-14;
              }
            }
          }
        }

        .btn {
          color: $primary;
        }

        .border-btn {
          border: 1px solid $primary;
        }
      }

      .related {
        @include font-19;
      }
    }
  }
}

.page-details {
  .wrapper {
    background: $white;
    border-radius: 4.88927px;
    margin-top: 26px;

    .nav-tabs {
      border: none;

      .nav-insight-item {
        padding: 0 30px 0 30px !important;

        @media (max-width: 767px) {
          padding: 0 10px !important;
        }
      }

      .nav-item {
        padding: 0 28px 0 0px;

        .nav-link {
          border: none;
          padding: 0 0 9px;
          @include font-600;
          color: $grey;
        }

        .active {
          // border-bottom: 4px solid $primary;
          color: $primary;
          // border-radius: 4px;
        }
      }
    }

    .big-img {
      height: 213px;
      background: $linear;
      border-radius: 4.88927px;
      overflow: hidden;
      position: relative;

      .edit {
        position: absolute;
        right: 20px;
        top: 10px;
      }

      .edit:hover {
        cursor: pointer;
      }
    }

    .img-cover {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }

    .left {
      .profile-details {
        h3 {
          text-transform: capitalize;

          @media (max-width: 767px) {
            font-size: 18px;
            line-height: 32px;
          }
        }

        .img {
          @include profile-100;
          position: relative;
          top: -15px;

          @media (max-width: 767px) {
            width: auto;
          }

          img {
            @media (max-width: 767px) {
              // width: 60px;
              // height: 60px;
            }
          }

          .edit {
            position: absolute;
            right: 5px;
            bottom: 0px;
          }

          .edit:hover {
            cursor: pointer;
          }
        }

        .details {
          word-break: break-word;
          width: 53%;

          h1 {
            margin-bottom: 20px;
            @include font-600;
            font-size: 30px;
            line-height: 30px;

            @media (max-width: 767px) {
              font-size: 16px;
              margin-bottom: 5px;
            }
          }

          p {
            @include font-600;
            color: $primary;
          }

          small {
            @include font-14;
            color: $grey;
          }
        }

        .list {
          margin-top: 23px;

          ul {
            display: flex;
            gap: 50px;
            flex-wrap: wrap;

            @media (max-width: 767px) {
              display: inline-block;
              white-space: nowrap;
              overflow: auto hidden;
              width: 90%;
            }

            li {
              @include font-600;
              color: $grey;
              cursor: pointer;

              @media (max-width: 767px) {
                display: inline-block;
              }
            }
          }
        }
      }
    }

    .right {
      position: relative;

      .btn-light {
        color: $primary;
      }

      .more {
        .box {
          position: absolute;
          background: #ffffff;
          border-radius: 5px;
          padding: 17px 15px;
          z-index: 50;
          right: 1rem;
          top: 118px;
          border: 1px solid #e5e5e5;

          ul {
            li {
              @include para;
              display: flex;
              align-items: center;
              gap: 15px;
              line-height: 34px;
              cursor: pointer;
            }
            li:hover {
              color: $primary;
            }
          }
        }
      }

      .dropdown-toggle {
        &::after {
          margin-left: 10px;
        }
      }

      .dropdown-item {
        @include para;
        font-weight: 600;
        line-height: 33px;

        &:hover {
          background: transparent;
          cursor: pointer;
        }
      }

      .dropdown-menu {
        padding: 10px !important;
      }

      .list {
        display: flex;
        align-items: flex-end;
        // height: 97px;

        ul:not(.custom-drop) {
          display: flex;
          gap: 30px;

          @media (max-width: 767px) {
            margin-bottom: 10px !important;
          }

          li {
            @include font-600;
            color: $primary;
            cursor: pointer;
          }
        }
      }
    }
  }

  .middle {
    .b-5 {
      border-radius: 5px;
    }

    .thoughts {
      display: flex;
      gap: 23px;

      .pro-img {
        width: 35px;
        height: 35px;
        border-radius: 55%;

        img {
          width: 35px;
          height: 35px;
          border-radius: 55%;
        }
      }

      .type {
        width: 100%;

        input {
          border: none;
          border-bottom: 1px solid #d1dfff;
          border-radius: 0px;
          width: 100%;
        }
      }
    }

    .read {
      .pro-img {
        width: 100px;
        height: 35px;
        border-radius: 55%;

        img {
          width: 35px;
          height: 35px;
          border-radius: 55%;
        }
      }

      .msg-box {
        background: rgba(238, 240, 242, 0.35);
        border-radius: 0px 5px 5px 5px;
        position: relative;

        .threedots {
          span {
            color: #01052d;
          }
        }

        h5 {
          @include h1;
          font-size: 15px;
          line-height: 18px;
        }

        p {
          @include para;
          font-size: 18px;
        }

        span {
          color: #19bcf4;
          font-size: 10px;
          font-weight: 400;
          cursor: pointer;
        }

        .time {
          // position: absolute;
          // top: 22px;
          // right: 15px;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: $grey;

          span {
            color: $grey;
            font-size: 14px;
          }
        }
      }

      .flex {
        justify-content: normal;

        // span {
        // font-size: 14px;
        // }
      }
    }

    .about {
      h2 {
        @include font-19;
      }

      p {
        @include para;
        color: $grey;
        word-wrap: break-word;
      }

      .see {
        color: $primary;

        &:hover {
          cursor: pointer;
        }
      }
    }

    .msg-box {
      background: $white;
      border-radius: 5px;
      padding: 20px;

      .type {
        display: flex;
        gap: 20px;

        textarea {
          width: 100%;
          resize: none;
          border: none;

          :focus-visible {
            border: none;
          }
        }
      }

      .profile {
        width: 40px;
        height: 35px;
        background: $grey;
        border-radius: 50%;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          border-radius: 50%;
          width: 100%;
          height: 100%;
        }
      }

      .social {
        display: flex;
        gap: 13px;
        flex-wrap: wrap;

        .box {
          display: flex;
          gap: 10px;
          background: rgba(238, 240, 242, 0.35);
          border-radius: 5px;
          padding: 5px 12px;
          cursor: pointer;

          p {
            margin: 0;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: $grey;
          }
        }
      }
    }

    .section {
      gap: 10px;

      .img {
        @include profile-circle;
      }

      .info-details {
        width: 150px;

        h6 {
          @include font-600;
        }
      }

      .verify {
        @include verify-profile;
      }

      .post {
        p {
          @include font-14;
          color: $grey;
        }
      }
    }

    .profile-details {
      // .img {
      //   @include profile-48;
      // }

      .verify {
        @include verify-profile-big;
      }

      .hiring {
        @include hiring;
      }
      .seeking {
        @include seeking;
      }

      .info-details {
        width: 160px;

        h6 {
          @include font-600;
        }

        p {
          @include para;
          color: $grey;
        }
      }

      .context {
        p {
          @include para;
          color: $grey;
        }

        .translate {
          margin-top: 10px;

          p {
            font-size: 10px;
            color: $black;
          }
        }
      }

      .box-img {
        height: 262px;
        position: relative;

        .others {
          display: flex;
          gap: 10px;
          align-items: center;
          position: absolute;
          bottom: 10px;
          left: 10px;
          background: $white;
          border-radius: 25px;
          padding: 5px 10px;

          .hand {
            width: 15px;
            height: 15px;
          }
        }
      }

      .like {
        span {
          small {
            margin-left: 8px;
            @include font-14;
            font-size: 10px;
            color: $primary;
          }
        }
      }

      .comment {
        span {
          @include font-14;
          color: $grey;
        }
      }

      .share {
        span {
          @include font-14;
          color: $grey;
        }
      }
    }

    .people-admin {
      .thoughts {
        align-items: flex-end;
        gap: 15px;
      }

      .read {
        .pro-img {
          width: 35px;
        }

        .msg-box {
          position: relative;
          padding-right: 45px;

          h6 {
            @include font-600;
          }

          p {
            @include para;
          }

          .hour {
            @include font-14;
            color: $grey;
            position: absolute;
            right: 15px;
            top: 15px;
          }
        }

        .likes {
          span {
            @include para;
            margin-right: 20px;
          }
        }
      }
    }
  }

  .side {
    h3 {
      @include font-600;
      color: $black;
    }

    .box {
      .img {
        @include profile-50;
      }

      .content {
        h4 {
          @include font-14;
          font-size: 15px;
          color: $grey;
          width: 55%;
          cursor: pointer;
        }

        p {
          @include para;
          font-size: 10px;
          color: $grey;
          width: 55%;
        }

        .btn {
          border: 1px solid $primary;
          color: $primary;
          padding: 4px 20px;
        }

        .follow-btn {
          width: 102px;
          height: 25px;
          padding: 1px 20px;
          margin-top: 4px;

          span {
            text-align: center;
          }
        }
      }
    }
  }

  .side-admin {
    .bg-white {
      box-shadow: 0 2px 4px rgba(171, 190, 209, 0.6);
      border-radius: 5px;
    }

    h6 {
      @include font-600;
    }

    .main-post {
      display: flex;
      gap: 20px;
      margin: 10px 0px;

      .prof-img {
        width: 40px;
        height: 40px;
        border-radius: 55%;
        box-shadow: 0 0 0px 4px #008c39;

        img {
          border-radius: 50%;
          width: 100%;
          height: 100%;
          box-shadow: 0 0 0px 2px #ffffff;
          cursor: pointer;
        }
      }

      .details {
        flex: 0 0 50.3333%;
        word-break: break-all;

        h4 {
          @include h1;
          font-size: 15px;
          line-height: 18px;
          margin: 0px;
          margin-bottom: 5px;
        }

        p {
          @include para;
          font-size: 10px;
          margin: 0px;
          line-height: 16px;
        }
      }
    }

    .imgs {
      display: flex;
      position: relative;

      span {
        @include profile-48;
        display: inline-block;
      }

      .img {
        span {
          margin-left: -17px;
          cursor: pointer;
        }
      }
    }
  }
}

.create-pages {
  .modal-header {
    background: $linear;
    position: relative;

    .modal-title {
      color: $white;

      .icon-expand {
        position: absolute;
        right: 50px;
        top: 19px;
      }
    }

    .btn-close {
      color: $white;
      background: url(../../assets/img/X.svg);
      opacity: 5;
    }
  }

  span {
    cursor: pointer;
    font-size: 15px;
  }

  .title {
    @include font-600;
    color: $primary;
    font-size: 18.75px;
  }

  h5 {
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    color: #676a79;
    margin: 0;
  }
}

.wrappers {
  box-shadow: 0px 2px 4px rgba(171, 190, 209, 0.6);
  border-radius: 5px 5px 0px 0px;

  .profiles {
    display: flex;
    gap: 15px;

    .img {
      @include profile-circle;
    }

    .details {
      h6 {
        @include small;
      }

      p {
        @include para;
      }
    }
  }

  .hrs {
    @include small;

    .text-blue {
      color: $primary;
      display: flex;
    }
  }

  .text {
    h4 {
      @include h1;
    }

    p {
      @include para;
    }
  }

  .translate {
    color: $blue;
    text-transform: capitalize;
    @include small;
    margin-top: 10px;
  }

  .comments {
    .profile-img {
      @include profile-circle;
    }
  }
}

.networks-popup {
  .modal-header {
    height: 74px;
    border-radius: 5px;

    h5 {
      @include font-600;
      font-size: 19px;
    }
  }

  .modal-body {
    .box {
      .main-drag {
        position: relative;

        .drag-img {
          background: url(../../assets/img/box.png) no-repeat center;
          background-size: cover;
          height: 133px;
          position: relative;
          border-radius: 5px;
          overflow: hidden;

          .create-page-edit {
            position: absolute;
            top: 3.08px;
            right: 0.95px;
          }

          .text-center {
            position: absolute;
            transform: translate(-50%, -50%);
            top: 50%;
            left: 50%;

            .img {
              background: url(../../assets/img/gallery-add.png) no-repeat center;
              height: 60px;
              background-size: 50px;
            }

            .text {
              p {
                font-size: 13px;
                color: $grey;

                span {
                  color: $primary;
                  text-decoration: underline;
                }
              }
            }
          }
        }

        .add-icon {
          display: grid;
          place-content: center;
          cursor: pointer;

          .create-page-icon {
            position: inherit;
            border-radius: 50%;
          }

          .create-page-edit {
            position: absolute;
            bottom: 6px;
            margin-left: 70px;
          }

          .img {
            background: url(../../assets/img/gallery-add-dark.png) no-repeat
              center;
            height: 30px;
          }

          background: #d9d9d9;
          width: 100px;
          height: 100px;
          border-radius: 50%;
          @include font-14;
          color: $primary;
          text-decoration: underline;
          text-align: center;
          position: absolute;
          bottom: -75px;
          box-shadow: 1px 0px 0px 5px #fff;
          left: 20px;
        }
      }

      .mt-7 {
        margin-top: 7rem;
      }
    }

    label {
      @include font-14;
      color: $grey;
    }

    .switch {
      position: relative;
      display: block;
      width: 37px;
      height: 10px;

      input {
        opacity: 0;
        width: 0;
        height: 0;
      }
    }

    .slider {
      position: absolute;
      cursor: pointer;
      height: 16px;
      width: 32px;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: $grey;
      -webkit-transition: 0.4s;
      transition: 0.4s;

      &:before {
        position: absolute;
        content: "";
        height: 10px;
        width: 10px;
        left: 3px;
        bottom: 3px;
        background-color: $white;
        -webkit-transition: 0.4s;
        transition: 0.4s;
      }
    }

    input {
      &:checked {
        + {
          .slider {
            background-color: $primary;

            &:before {
              -webkit-transform: translateX(16px);
              -ms-transform: translateX(26px);
              transform: translateX(16px);
            }
          }
        }
      }

      &:focus {
        + {
          .slider {
            box-shadow: 0 0 1px #2196f3;
          }
        }
      }
    }

    .slider.round {
      border-radius: 34px;

      &:before {
        border-radius: 50%;
      }
    }
  }
}

.admin-popup {
  .confirmpass {
    width: 100%;
  }

  label {
    @include font-600;
    color: $grey;
  }

  .input-admin {
    position: relative;

    span {
      @include font-14;
      color: $primary;
      background: $transparent-blue;
      position: absolute;
      top: 47px;
      left: 22px;
      padding: 1px 10px;
      border-radius: 25px;
    }
  }

  h4 {
    @include font-600;
    font-size: 19px;
  }

  p {
    @include para;

    .growth {
      color: $green;
    }

    .decline {
      color: rgba(247, 0, 0, 1);
    }
  }

  .toast {
    box-shadow: none;
    border: none;
    margin-bottom: 10px;
    width: fit-content;

    .toast-header {
      border: 1px solid $primary;
      border-radius: 30px;
      box-shadow: none;
      color: $primary;
      @include font-14;
      font-size: 15px;

      .img {
        width: 20px;
        height: 20px;

        img {
          border-radius: 50%;
        }
      }
    }
  }

  .grey-bg {
    height: 282px;
    background: $transparent-grey;
    padding: 20px;

    .img {
      @include profile-64;
    }

    .text {
      h6 {
        @include font-600;
        font-size: 15px;
        color: $black;
        margin-bottom: 12px;
      }

      p {
        @include para;
      }
    }

    .edit {
      @include edit-pencil;
    }

    .delete {
      @include trash;
    }
  }

  .add-scroll {
    overflow-y: auto;
    overflow-x: hidden;
  }

  .nav-group-item {
    padding: 0 30px 0 30px !important;
  }

  .nav-item {
    padding: 0 28px 0 0px;

    @media (max-width: 767px) {
      padding-right: 10px;
      margin-bottom: 10px;

      &:last-child {
        padding-right: 0;
      }
    }

    .nav-link {
      border: none;
      padding: 0 0 9px;
      @include font-600;
      color: $grey;

      @media (max-width: 767px) {
        font-size: 14px;
      }
    }

    .active {
      border-bottom: 4px solid $primary;
      color: $primary;
      border-radius: 4px;
    }
  }
}

@media (max-width: 1200px) {
  .newsletter {
    .main-center {
      .wrapper {
        .overflow {
          overflow-x: auto;

          .nav-tabs {
            margin-top: 40px;
            width: auto;
          }
        }

        .overflow_hidden {
          overflow-x: hidden;
        }

        .end {
          position: relative;
          right: 0;
          top: 0;
          left: 0;
        }
      }
    }
  }

  .page-details {
    .wrapper {
      .left {
        .profile-details {
          .list {
            ul {
              gap: 30px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .event-details {
    .wrapper {
      .box-img {
        height: 183px;

        .calendar {
          bottom: 15px;
          left: 15px;
          top: auto;
        }
      }

      .section {
        .heading {
          h2 {
            font-weight: 700;
            font-size: 24px;
            line-height: 138%;
            /* or 33px */

            letter-spacing: 0.0075em;
            font-feature-settings: "tnum" on, "lnum" on;

            color: #000000;
          }
        }
      }
    }
  }

  .page-details {
    .wrapper {
      .left {
        .profile-details {
          .list {
            ul {
              gap: 20px;
            }
          }
        }
      }
    }
  }
}

.custom-drop {
  // display: block !important;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  color: #0432a3;
  cursor: pointer;
  transform: translate(-46px, 23px) !important;
}

.custom-li {
  display: block !important;
}

.custom-more {
  background-color: white;
}

.dropdown-item img {
  width: 30px;
  height: 30px;
}

.dropdown-item {
  padding-left: 5px;
}
