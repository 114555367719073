@import "../../../../assets/scss/mixin.scss";
@import "../../../../assets/scss/variable.scss";

.job-card-layout {
  border-radius: 5px;
  cursor: pointer;
  .job-card {
    border-radius: 5px 5px 0px 0px;
    min-height: 130px;
    background-color: #e5e5e5;
    position: relative;

    .img-icon {
      height: 40px;
      width: 40px;
    }

    .save-icon {
      height: 15px;
      width: 15px;
      
    }
    .apllied {
      background: $blue;
      height: 20px;
      padding: 0px 7px;
      text-align: center;
      margin-left: 10px;
      white-space: nowrap;
      
    }
    .featured {
      background:rgba(128, 128, 128, 0.5);
      height: 20px;
      padding: 0px 7px;
      text-align: center;
      margin-left: 10px;
      white-space: nowrap;
    }

    .job-title {
      // display: block;
      color: $dark-black;
      font-size: 15px;
      font-style: normal;
      font-weight: 600;
      line-height: 22.5px;
    }

    .bottom-bar {
      position: absolute;
      bottom: 7px;
      width: 95%;
    }
  }

  .bottom-card {
    background-color: rgba(4, 50, 163, 0.05);
    border-radius: 0px 0px 5px 5px;

    .address-text {
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      //line-height: 20px;
    }
  }
 
}

.job-card-layout:hover,
.job-card-layout-active {
  border: 1px solid $primary; /* Replace with your primary color value */
  cursor: pointer;
}
@media(max-width:767px){
  .job-card-layout{
    .job-card{
      .bottom-bar {
        width: 100%;
        left: 0;
        padding: 0 15px;
      }
    }
  }
}