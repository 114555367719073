@import "../../assets/scss/mixin.scss";
@import "../../assets/scss/variable.scss";

.job-details {
  h1 {
    @include font-600;
  }

  .job-style {
    @include para;
  }
  .btn-border {
    border: 1px solid $primary;
    color: $primary;
  }
  .profile-img {
    @include profile-50;
  }
  .job-desc {
    p {
      @include para;
    }
  }
  .bg-white {
    border-radius: 5px;
    position: relative;
    word-wrap: break-word;
  
    .edit {
      cursor: pointer;
    }
    h2 {
      @include font-19;
    }
    h3 {
      @include para;
      @include font-600;
      margin: 20px 0px;
    }
    ul {
      li {
        @include para;
        list-style-type: disc;
        margin-left: 18px;
      }
    }
  }
}
