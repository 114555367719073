.app-download-container{
font-family: 'Inter', sans-serif;
max-width: 1400px;
margin: auto;
padding-left:20px;
padding-right: 20px;
    .download-section{
        display: flex;
        flex-direction: column;
        align-items: center;
        .download-app-title{
font-size: 2.25rem;
font-weight: 600;
line-height: 50px;
margin-bottom: 14px;
color: #01052D;
        }
        .download-description{

font-size: 0.93rem;
font-weight: 400;
line-height: 22.5px;
text-align: center;
margin-bottom: 43px;
// padding: 0 20px;
max-width: 610px;
color: #676A79;
        }
  
    }
    @media screen and (max-width:1284px) {
        .download-section{
                margin-bottom: 30px;
        }
   
    }
}
.cursor-pointer{
    cursor: pointer;
}
