@import "../../assets/scss/mixin.scss";
@import "../../assets/scss/variable.scss";

input[type="file"] {
  opacity: 0;
  position: absolute;
  width: 0px;
}

.showcarousalimg {
  .ViewCount {
    display: none;
  }
}

.d-none {
  display: none;
}

.disabledItem {
  pointer-events: none;
  opacity: 50%;
}

.publishModalClass {
  max-height: 70vh;
  overflow-y: scroll;
}

.imgCrousle {
  min-width: 100px;
  min-height: 100px;
  width: inherit;
  height: 200px;

  img {
    width: inherit;
    height: inherit;
    padding-left: 3px;
  }
}

.descriptionOverFlow {
  display: -webkit-box;
  max-width: 90%;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.descriptionOverFlowSpan {
  word-wrap: break-word !important;
  font-size: 15px;
  font-weight: 600;
  color: $dark-black;
  margin-bottom: 10px;
}

.viewPollmodal-body {
  padding: 0 30px 30px 30px;

  .centerText {
    text-align: center;
  }

  li {
    display: flex;
    justify-content: center;
  }
}

.viewPollModalMain {
  .d-flex {
    margin: 20px 0;

    .img {
      margin: 0 8px;
    }

    img {
      // background: url();
      border-radius: 50px;
      height: 48px;
      width: 48px;
    }
  }

  .viewPolldiv {
    width: calc(100% - 112px);
    word-break: break-word;
    h6 {
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 22px;
      color: black;
    }

    p {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 13.25px;
      line-height: 20px;
      color: $grey;
    }
  }
}

.main-img {
  max-height: 350px !important;
  object-fit: cover;
}

.main-img:hover {
  cursor: pointer;
}

.font10 {
  font-size: 10px;
}

.viewReactionsType {
  display: flex;

  img {
    width: 15px;
    height: 15px;
  }
}

.pollButton {
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  /* width: 100%; */
  // height: 4em;
}

.pollTitle {
  overflow: hidden;
  // width: 100%;
  text-align: start;
  overflow: hidden;
}

.selfAlignCenter {
  align-self: center;
}

.votedView {
  display: flex;
  justify-content: space-around;
}

.selectedBlue {
  background-color: #0432a36e;
}

.tag {
  background-color: black;
}

// .imageContainer {
//   max-height: calc(100vh - 150px);
//   scroll-behavior: smooth;
//   overflow: auto;
// }

.addPhotodiv {
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.imageList {
  height: 300px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

.themeList {
  flex-basis: calc(50% - 6px);
  padding: 2px;
  margin: 0.4rem 0;
  list-style-type: none;
  line-height: 0;
}

.fullthemeList {
  flex-basis: calc(100% - 6px);
  padding: 2px;
  margin: 0.4rem 0;
  list-style-type: none;
  line-height: 0;
}
.celebrationPost {
  height: auto !important;
  li {
    width: 100%;
    background: url("../../assets/img/celebBackground.jpg") no-repeat center
      center;
    background-size: 100% 100%;
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    padding: 10px 20px;
    @media screen and (max-width: 600px) {
      padding: 20px;
      flex-flow: column;
    }
  }
}
.celebImages {
  object-position: center;
  object-fit: cover;
  width: 50% !important;
  cursor: pointer;
  padding-block: 50px;
  text-align: center;
  @media screen and (max-width: 600px) {
    padding-block: 20px 10px;
    width: 100% !important;
  }
  img {
    width: auto;
    max-width: 100%;
    max-height: 100%;
    height: auto;
    object-fit: cover;
  }
}
.celebImageSelect {
  object-position: center;
  width: 100%;
  cursor: pointer;
  text-align: center;
  object-fit: contain;
  padding-block: 0;
  margin: 0 auto;
  height: 100%;

  @media screen and (max-width: 600px) {
    padding-block: 20px 10px;
    width: 100% !important;
  }
}

.celebImageClose {
  position: absolute;
  // margin-top: 20px;
  right: 40px;
  top: 20px;
  display: inline-block;
  background: $transparent-black;
  border-radius: 14px;
  color: $white;
  padding: 5px 12px;
  @include small;
  font-size: 10px;
  cursor: pointer;
  // margin-left: 5px;
}

.imagestwo {
  display: -webkit-box;
}
.basic-div {
  .center {
    position: absolute;
    background-color: $transparent-black;
    width: 100%;
    display: flex;
    z-index: 999;
    bottom: 1%;
    top: 0%;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 40px;
  }
}
// .react-multi-carousel-item{
//   padding-left: 5px;
// }
// .react-multi-carousel-track{
//   gap: 3px;
// }

.mini_row {
  display: flex;
  flex-direction: row;
}

.mini_row p {
  margin: 10px !important;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 20px;
  color: #676a79;
}

.customToolTip_class {
  z-index: 50;
}

.postImg {
  padding-right: calc(var(--bs-gutter-x) * 0.2);
  padding-left: calc(var(--bs-gutter-x) * 0.2);
}

.mb_2 {
  margin-bottom: 2%;
}

.pollContainer p {
  margin: 15px 0;
}

.btn-border-blue {
  border: 1px solid $primary;
  color: $primary;
  width: 100%;
}

.userNameTextWrap {
  max-height: 3em;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  // width: 100%;
}

.dashboard {
  .maxWid {
    max-width: 20rem !important;
  }

  .left {
    background: $white;

    .profile-details {
      h6 {
        font-weight: 600;
        font-size: 19px;
        line-height: 24px;
        word-break: break-all;
        color: $headfont;
        text-transform: capitalize;
      }

      p {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: $dark-black;
      }
    }

    .follower {
      display: flex;
      justify-content: space-around;
      margin-top: 20px;
      margin-bottom: 15px;

      .h-border {
        background: #d1dfff;
        width: 1px;
      }

      .box {
        text-align: center;

        h6 {
          font-weight: 600;
          font-size: 15px;
          line-height: 22px;
          color: $dark-black;
        }

        p {
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #676a79;
        }
      }
    }

    .starter {
      color: #0432a3;
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      text-align: end;
      text-transform: uppercase;
      font-size: 10px;
      margin-right: 7px;
      cursor: pointer;
    }

    .profile {
      height: 132.02px;
      background: $linear;
      border-radius: 5px;
      // padding: 20px;
      position: relative;

      .back-image-fluid {
        border-radius: 5px;
        height: 132.02px;
        width: 100%;
        object-fit: cover;
      }

      .img {
        border: 2px solid $white;
        border-radius: 5px;
        width: 72px;
        height: 72px;
        background: $white;
        display: flex;
        position: absolute;
        margin: 20px;
      }

      .starter {
        position: absolute;
        right: 5px;
        font-weight: 500;
        font-size: 10px;
        line-height: 20px;
        text-align: right;
        text-transform: uppercase;
        bottom: -19px;

        span {
          color: $primary;
        }
      }
    }

    .leftMenuP {
      margin-top: 20px;
    }

    .text {
      h1 {
        @include h1;
        font-size: 19px;
        word-break: break-word;
        line-height: 24px;
      }

      p {
        border: 1px solid #d1dfff;
        padding: 10px;
        @include para;
        font-size: 14px;
      }

      small {
        @include small;
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
      }
    }

    .update {
      height: 41.58px;
      background: $primary;
      color: $white;
      border-radius: 5px;
      text-align: center;
      line-height: 42px;

      a {
        color: $white;
        font-weight: 600;
        font-size: 15px;
      }
    }

    .article {
      h4 {
        @include h1;
        color: $otherfont;
        font-size: 14px;
        line-height: 10px;
        margin-bottom: 10px;
      }

      .box {
        display: flex;
        gap: 15px;
        margin-top: 20px;

        .img {
          height: 50px;
          width: 84px;
          border-radius: 5px;
          background: $linear;

          img {
            height: 50px;
            width: 84px;
            border-radius: 5px;
          }
        }

        .text {
          h6 {
            @include h1;
            font-size: 14px;
            line-height: 18px;
          }

          h6:hover {
            text-decoration: underline;
          }

          span {
            font-weight: 400;
            font-size: 10px;
          }
        }
      }

      span {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: $grey;
        // cursor: pointer;
      }
      // span:hover {
      //   color: $primary;
      // }
    }
    .pages {
      h4 {
        @include h1;
        color: $otherfont;
        font-size: 14px;
        line-height: 10px;
        margin-bottom: 10px;
      }

      .box {
        margin-top: 20px;

        .img {
          height: 80px;
          border-radius: 5px;
          background: $linear;

          img {
            height: 80px;
            width: 100%;
            border-radius: 5px;
          }
          .page-icon {
            width: 50px;
            height: 50px;
            position: relative;
            border-radius: 55%;
            bottom: 57px;
            left: 10px;
            border: 2px solid #ffffff;
            background: white;
          }
        }

        .text {
          margin-top: 5px;
          h6 {
            @include h1;
            font-size: 14px;
            line-height: 18px;
          }

          h6:hover {
            text-decoration: underline;
          }

          span {
            font-weight: 400;
            font-size: 10px;
          }
        }
      }

      span {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: $grey;
        // cursor: pointer;
      }
      // span:hover {
      //   color: $primary;
      // }
    }

    .groups {
      h4 {
        @include h1;
        color: $otherfont;
        font-size: 14px;
        line-height: 10px;
        margin-bottom: 10px;
      }

      ul {
        margin-top: 10px;
        margin-bottom: 0;

        li {
          @include para;
          font-size: 14px;
          line-height: 19px;
          display: flex;
          gap: 6px;
          cursor: pointer;

          &::before {
            content: url("../../assets/img/people.svg");
          }
        }
        li:hover {
          text-decoration: underline;
        }
      }

      span {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: $grey;
        margin-left: 26px;
        cursor: pointer;
      }
      span:hover {
        color: $primary;
      }
    }

    .events {
      h4 {
        @include h1;
        color: $otherfont;
        font-size: 14px;
        line-height: 10px;
        margin-bottom: 10px;
      }

      ul {
        margin: 0;

        li {
          @include para;
          font-size: 14px;
          line-height: 30px;
          display: flex;
          gap: 6px;
          cursor: pointer;
        }
        li:hover {
          text-decoration: underline;
        }
      }

      span {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: $grey;
        cursor: pointer;
        margin-left: 26px;
      }
      span:hover {
        color: $primary;
      }
    }

    .follow {
      h4 {
        @include h1;
        color: $otherfont;
        font-size: 14px;
        line-height: 10px;
        margin-bottom: 10px;
      }

      .tag_parent {
        margin-top: 10px;
        margin-bottom: 10px;
        display: flex;
        flex-wrap: wrap;
        gap: 12px;
      }

      span {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: $grey;
        cursor: pointer;
      }
      span:hover {
        color: $primary;
      }

      .tags_text {
        font-family: "Inter";
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: $black;
        padding: 5px 10px 10px 5px;

        background-color: #e9f0f8;
        word-wrap: break-word;
      }

      ul {
        margin: 0;

        li {
          @include para;
          font-size: 14px;
          line-height: 25px;
        }
      }
    }

    .discover {
      h4 {
        @include h1;
        font-weight: 600;
        font-size: 15px;
        line-height: 22px;
        color: $primary;
      }
    }
  }

  .center {
    .msg {
      .msg-box-create {
        background: $white;
        border-radius: 5px;
        padding: 20px;

        .commentClass {
          line-break: anywhere;
        }

        .type {
          display: flex;
          gap: 20px;

          textarea {
            width: 100%;
            resize: none;
            border: none;

            :focus-visible {
              border: none;
            }
          }
        }

        .profile {
          width: 40px;
          height: 35px;
          background: $grey;
          border-radius: 50%;
          overflow: hidden;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            border-radius: 50%;
            width: 100%;
            height: 100%;
          }
        }

        .social {
          display: flex;
          gap: 13px;
          flex-wrap: wrap;

          .box {
            display: flex;
            gap: 10px;
            background: rgba(238, 240, 242, 0.35);
            border-radius: 5px;
            padding: 5px 12px;
            cursor: pointer;

            p {
              margin: 0;
              font-weight: 400;
              font-size: 14px;
              line-height: 20px;
              color: $grey;
            }
          }
        }
      }
    }
  }

  .middle {
    .member {
      box-shadow: 0px 2px 4px rgb(171 190 209 / 60%);
      border-radius: 5px;
      background: $white;
      margin-top: 26px;
      text-align: center;
      display: flow-root;

      .button {
        background: #f2f5fa;
        padding: 8px;
        margin: 15px 30px;

        a {
          font-weight: 600;
          font-size: 15px;
          color: $primary;
        }
      }
    }
  }
}

.create-post-header {
  .modal-header {
    border-bottom: 1px solid $border;
    padding: 27px 26px;

    .modal-title {
      @include font-19;
    }

    .btn-close {
      opacity: 1;
    }
  }
}

.textInputArea {
  // height: 100%;
  overflow-y: auto;
}

.search-tag {
  background: $white;

  .dataResult {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 200px;
    .imgList {
      height: 28px;
      width: 28px;
      border-radius: 50%;
      margin-bottom: 2px;
    }
  }
}
.search-tag :hover {
  cursor: pointer;
}

.selectrecpt {
  .showreclist {
    max-height: calc(60vh - 150px);
    scroll-behavior: smooth;
    overflow-y: auto;
    overflow-x: hidden;
    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }
    &::-webkit-scrollbar-thumb {
      background-color: #363b99;
      border-radius: 10px;
    }

    p {
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 22px;
      color: $black;
    }
    small {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 22px;
      color: $grey;
    }
  }

  .username {
    overflow-wrap: break-word;
  }
}

.showcelebpost {
  .celebtags {
    -webkit-line-clamp: 1;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    word-wrap: break-word;
    color: blue;
  }
}

.larger-image-view {
  .img-crousle {
    min-width: 100px;
    min-height: 350px;
    width: inherit;
    height: 350px;
    img {
      height: 100%;
      object-fit: contain;
    }
    img:hover {
      cursor: pointer;
    }
  }
}
.file-larger-title {
  .modal-title {
    width: 40%;
  }
  .btn-light {
    color: #0432a3;
    display: block;
  }
  .followicon {
    margin-right: 3px;
  }
  // .btn {
  //   padding: 10px 20px;
  // }
  //  .varify {
  //   position: relative;
  //   background: #19bcf4;
  //   width: 15px;
  //   height: 15px;
  //   border-radius: 55%;
  //  }
  .varify {
    @include tick-varify;
    width: 18px !important;
    height: 18px !important;
    border-radius: 50%;

    .checkMark {
      @include checkmark;
    }
  }
  // .connect {
  //   .btn {
  //     padding: 8.5px 20px;
  //   }
  // }
}
.larger-view-main-box {
  background: $white;
  border-radius: 5px;
  position: relative !important;
  .post {
    display: flex;
    // gap: 10px;

    .img {
      width: 25px;
      height: 25px;
      background: aqua;
      border-radius: 50%;

      img {
        border-radius: 50%;
        width: 100%;
        height: 100%;
      }
    }

    span {
      @include h1;
      font-size: 15px;
    }

    .share {
      span {
        color: $grey;
        font-weight: 400;
      }
    }
  }

  .varify {
    @include verify-profile;
  }

  .main-post {
    display: flex;
    margin-bottom: 20px;
    justify-content: space-between;

    .main-Post-div {
      display: flex;
      flex-direction: row;
      gap: 10px;
    }

    .prof-img {
      width: 55px;
      height: 55px;
      border-radius: 55%;
      box-shadow: 0 0 0px 4px #008c39;

      img {
        border-radius: 50%;
        width: 100%;
        height: 100%;
        box-shadow: 0 0 0px 2px #ffffff;
      }
    }

    .details {
      flex: 0 0 30%;
      .userNameTextWrap {
        max-height: 3em;
        overflow: hidden;
        white-space: nowrap;
        margin-right: 5px;
        text-overflow: ellipsis;
        // width: 100%;
        cursor: pointer;
      }

      h4 {
        @include h1;
        font-size: 15px;
        line-height: 18px;
        margin: 0px;
        overflow: hidden;
        max-width: 10rem;
      }
      h4:hover {
        text-decoration: underline;
        color: $primary;
      }

      p {
        @include para;
        font-size: 10px;
        margin: 0px;
        line-height: 16px;
      }
    }

    .varify {
      @include verify-profile-big;
    }

    .hiring {
      @include hiring;
    }
    .seeking {
      @include seeking;
    }
  }

  .text {
    span {
      @include para;

      span {
        color: $primary;
      }
    }

    .translate {
      span {
        color: $blue;
        font-size: 10px;
        font-weight: 400;
      }
    }
  }

  .post-img {
    position: relative;
    margin-bottom: 16px;
    display: flex;
    justify-content: center;

    .showtext:hover {
      cursor: pointer;
    }

    .likes1 {
      background: #ffffff;
      border-radius: 13px;
      width: 150px;
      height: 30px;
      position: absolute;
      bottom: 10px;
      left: 20px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      box-shadow: 0 5px 24px rgb(0 0 0 / 10%);
      img {
        cursor: pointer;
      }

      img:hover {
        transform: scale(2);
      }
    }

    .member {
      position: absolute;
      bottom: 10px;
      right: 10px;
      background: $white;
      opacity: 0.4;
      border-radius: 13.7288px;
      padding: 3px 10px;
      display: flex;
      align-items: center;

      span {
        font-weight: 400;
        font-size: 10px;
      }
    }
  }

  .flex {
    display: flex;
    gap: 16px;
    justify-content: space-between;

    span {
      @include para;
    }

    .others {
      span {
        font-size: 10px;
      }
    }
  }

  .thoughts {
    display: flex;
    gap: 23px;

    .pro-img {
      width: 35px;
      height: 35px;
      border-radius: 55%;

      img {
        width: 35px;
        height: 35px;
        border-radius: 55%;
      }
    }

    .type {
      width: 100%;

      input {
        border: none;
        border-bottom: 1px solid #d1dfff;
        border-radius: 0px;
        width: 100%;
      }
    }
  }

  .read {
    .commentDiv {
      justify-content: space-around;
      flex-wrap: nowrap;

      .prof-img {
        max-width: 60px !important;
      }

      // .reply-prof-img{
      //   max-width: 0px !important;
      // }
    }

    .msg-box {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .commentNameDiv {
        display: flex;
        flex-direction: column;
      }
    }

    .likeShareDiv {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    .prof-img {
      width: 100px;
      height: 35px;
      border-radius: 55%;

      img {
        width: 35px;
        height: 35px;
        border-radius: 55%;
      }
    }

    .msg-box {
      background: rgba(238, 240, 242, 0.35);
      border-radius: 0px 5px 5px 5px;
      position: relative;

      h5 {
        @include h1;
        font-size: 15px;
        line-height: 14px;
      }

      p {
        @include para;
        font-size: 14px;
      }

      span {
        color: #19bcf4;
        font-size: 10px;
        font-weight: 400;
        cursor: pointer;
      }

      .time {
        position: absolute;
        top: 22px;
        right: 15px;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: $grey;

        span {
          color: $grey;
          font-size: 14px;
        }
      }
    }

    .flex {
      justify-content: normal;

      span {
        font-size: 14px;
      }
    }
  }
}

// div[aria-label="Pop-out"] {
//   display: none;
// }

@media (max-width: 1200px) {
  .member {
    .modal-content {
      height: auto;

      .main-box {
        width: auto;
        flex-wrap: wrap;
        margin: 0;
        justify-content: center;

        .box {
          width: auto;
        }
      }
    }
  }
}

.load_more {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: $grey;
  margin-top: 10px;
  cursor: pointer;
  display: inline-block;
}

.load_more:hover {
  text-decoration: underline;
}

@media (max-width: 991px) {
  .imagestwo {
    display: block;
    padding: 0px;
  }
  .file-larger-title {
    .modal-title {
      width: 85% !important;
    }
    .modal-header {
      .btn-close {
        margin-bottom: 20px;
      }
    }
    .connect {
      .btn {
        // width: 130px;
      }
    }
  }
}

// overflow: hidden;
// width: 100px;
// margin: 0 20px;
// text-overflow: ellipsis;

.sidebar-sticky-left {
  position: sticky;
}

.sidebar-sticky {
  position: sticky;
  top: 10px;
}

/* Css written for notification list in notofication section */
.notification-list {
  display: "flex";
  align-items: "center";
  justify-content: "space-between";
  width: "511px";
  height: "70px";
  border-bottom: "1px solid #F0F7FF";
}

.noPostShowBox {
  background: rgba(4, 50, 163, 0.1);
  @include borderRadius(5px);
  padding: 20px 30px;
  display: flex;
  align-items: center;
  text-align: start;
  justify-content: space-between;
  @media screen and (max-width: 767px) {
    flex-flow: column;
    gap: 10px;
  }
  .msgBox {
    flex: 1 1 auto;
    h6 {
      font-size: 14px;
      font-weight: 600;
      line-height: 1.5;
      color: $dark-black;
    }
  }
  .imgBox {
    flex: 1 1 75%;
  }
}
