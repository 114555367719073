@import "../../assets/scss/mixin.scss";
@import "../../assets/scss/variable.scss";

.web-footer-bottom {
  display: none;
}

@media (max-width: 992px) {
  .web-footer-bottom {
    display: block;
  }
}

.wallet {
  .main-center {
    .wrapper {
      position: relative;
      background: $white;
      border-radius: 5px;
      margin-bottom: 20px;

      .add-cursor :hover {
        cursor: pointer;
      }

      .infinite-scroll-component {
        overflow: hidden;
      }
    }

    // .float-end {
    //   .form-select {
    //     border: none;
    //     padding: 5px 16px 5px 10px;
    //     display: flex;
    //     font-size: 15px;
    //   }
    // }

    .min-height {
      min-height: 260px;
    }

    .min-height-220 {
      min-height: 220px;
    }

    .wallet_header {
      color: #01052d;
      font-size: 19px;
      font-style: normal;
      font-weight: 600;
    }

    .text {
      font-size: 15px;
      font-style: normal;
      font-weight: 600;
    }

    .wallet_subdescription {
      font-size: 14px;
      font-weight: 400;
    }

    .wallet_amount {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
    }

    .f-red {
      color: $red;
    }

    .f-green {
      color: $green;
    }

    .fw-700 {
      font-weight: 700 !important;
    }

    .align_end {
      text-align: end;
    }

    .card-left {
      display: flex;
      align-items: center;

      img {
        height: 34px;
        width: 55px;
        padding: 0px;
        object-fit: cover;
      }

      .heading {
        padding-left: 5px;

        // p {
        //   @include font-600;
        //   color: $dark-black;
        // }

        .para {
          @include para;
        }
      }
    }

    .check {
      font-size: 15px;
      display: flex;
      align-items: center;
      input {
        width: 15px;
        height: 15px;
        accent-color: #0432a3;
      }
    }

    .add {
      font-weight: 600;
      font-size: 15px;
      line-height: 22px;
      color: $primary;
      cursor: pointer;
      text-align: center;
    }
  }
}

@media (max-width: 1200px) {
  .newsletter {
    .wrapper {
      .overflow {
        overflow-x: visible;

        .nav-tabs {
          margin-top: 40px;
          width: auto;
        }
      }

      .overflow_hidden {
        overflow-x: hidden;
      }

      .end {
        position: relative;
        right: 0;
        top: 0;
        left: 0;
      }
    }
  }
}
