@import "../../../assets/styles/colors";
@import "../../../assets/scss/variable";

.form-input {
  position: relative;
}

.form-label {
  margin-bottom: 0.5rem;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: $dark-black;
}

.form-check-label {
  font-weight: 500;
  color: $black;
  margin-left: 5px;
}

.form-check-input {
  min-width: 1rem;
}

.form-input img {
  position: absolute;
  left: 10px;
  top: 8px;
  z-index: 1;
}

.form-input-eye {
  position: absolute;
  z-index: 99;
  right: 40px;
}
.form-input-eye:hover {
  cursor: pointer;
}

.form-input .form-control {
  padding: 0.375rem 37px 6px 40px;
  border: 1px solid $color-6;
  border-radius: 4px;
}

.error-form{
  .form-control{
    border: 1px solid $red;
  }
}
