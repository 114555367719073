@import "src/assets/styles/colors";
@import "src/assets/scss/variable.scss";
.legal .text h5 {
  font-weight: 600;
  font-size: 30px;
  line-height: 44px;
  color: $color-4;
}

.legal .text p {
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  color: $color-5;
}

.legal .text img {
  width: 100%;
}

.legal .acc ul .active {
  color: $color-4;
}

.legal .nav-tabs {
  margin-top: 14px;
  border-bottom: 1px solid #d1dfff;
}

.legal .nav-tabs .nav-item .nav-link {
  border: none;
  color: #676a79;
  font-size: 15px;
  font-weight: 600;
  line-height: 22px;
  padding: 0 0 9px;
}
.legal .nav-tabs .nav-item .active {
  border-bottom: 4px solid #0432a3;
  border-radius: 4px;
  color: $dark-black;
}

.legal .acc {
  margin-top: 177px;
}
.legal .text h6 {
  font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 30px;
line-height: 44px;
/* identical to box height, or 147% */

margin-bottom: 20px;
color: #000000;
}

.legal .text h6{
font-weight: 600;
font-size: 30px;
line-height: 44px;
margin-bottom: 20px;
}

.legal .acc ul {
  display: flex;
  gap: 0;
  justify-content: space-between;
}

.legal .acc ul li {
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  text-align: center;
  color: $color-5;
  cursor: pointer;
}


.legal .bg {
  //background: url("../../assets/img/legal.png") no-repeat;
  background-color: $color-3;
  background-size: auto;
  min-height: 340px;
  //background-position: bottom;
  color: $color-1;
  display: flex;
  align-items: center;
  position: relative;
}


.legal .bg1 {
  background-size: auto;
  background-position: bottom;
  mix-blend-mode: overlay;
}

.legal .margin-top {
  margin-top: 35px;
}

@media (max-width: 500px) {
  .legal .acc {
    margin-top: 80px;
  }

  .legal .acc ul {
    display: flex;
    gap: 10px;
    flex-direction: column;
  }

}

@media (max-width: 1440px) {
  .legal .bg1 {
    background-size: auto;
    width:100%;
    background-position: bottom;
    mix-blend-mode: overlay;
  }
  
}

@media (max-width: 1000px) {
  .legal .margin-top {
    margin-top: 0px;
  }
}

@media (max-width: 767px) {
  .legal {
    .bg {
      .bg1 {
        max-width: 200px;
        margin: auto;
        display: block;
      }
    }
    .acc {
      overflow-x:auto;
      ul {
        display: inline-block;
        overflow-x: scroll;
        overflow-y: hidden;
        width: 100%;
        white-space: nowrap;
      }
      li {
        display: inline-block;
        margin-right: 15px;
        a {
          min-width: 45px;
          display: inline-block;
          vertical-align: top;
          white-space: nowrap;
          font-weight: 700;
          padding: 10px 2px;
          &.nav-link-active{
            color: #0432A3;
          }
        }
      }
    }
    .text {
      h6 {
        font-size: 22px;
      }
    }
  }
  .tab-content{
    margin-top: 0 !important;
    .container{
      padding:0;
    }
    .tab-pane{
      .my-5{
        margin-top:15px !important;
        margin-bottom:15px !important;
      }
      h6{
        font-size: 18px;
        margin-bottom: 5px;
      }
    }
  }
}


.nav-link-active {

  
     border-bottom: #0432A3 solid 3px !important;

  
}