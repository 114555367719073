@import "../../assets/scss/mixin.scss";
@import "../../assets/scss/variable.scss";

.tooltip-inner {
  background-color: $grey;
  opacity: 1 !important;
}
.tooltip.bs-tooltip-right .tooltip-arrow::before {
  border-right-color: $grey !important;
}
.tooltip.bs-tooltip-left .tooltip-arrow::before {
  border-left-color: $grey !important;
}
.tooltip.bs-tooltip-bottom .tooltip-arrow::before {
  border-bottom-color: $grey !important;
}
.tooltip.bs-tooltip-top .tooltip-arrow::before {
  border-top-color: $grey !important;
  border-bottom-color: $grey !important;
}
.majority {
  h1 {
    @include h1;
    @media (max-width: 767px) {
      font-size: 22px;
      line-height: 32px;
    }
  }
  p {
    @include para;
    line-height: 20px;
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .logo {
    margin-top: 20px;
  }
  table {
    @media (max-width: 767px) {
      overflow-x: auto;
      width: 100%;
      max-width: 400px;
      display: block;
    }
    // img {
    //   margin-top: 15px;
    // }
    tr {
      border-color: rgba(209, 223, 255, 1) !important;
    }
    .package_head {
      font-size: 19px;
      font-weight: 600;
    }
  }

  // display: flex;
  // gap: 25px;
  .box {
    background: #ffffff;
    box-shadow: 0px 2px 4px rgb(171 190 209 / 60%);
    border-radius: 5px;
    height: 1050px;
    // overflow-y: scroll;
    a {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #0432a3;
    }
    span {
      cursor: pointer;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #0432a3;
    }
    .flex {
      display: flex;
      gap: 13px;
      align-items: center;
      margin-bottom: 15px;
    }
    h2 {
      font-weight: 600;
      font-size: 19px;
      line-height: 24px;
      color: #0b0914;
      margin: 0px;
    }
    ul {
      margin-top: 20px;
      li {
        font-weight: 400;
        font-size: 14px;
        line-height: 27px;
        color: #676a79;
        display: flex;
        align-items: flex-start;
        // &::before {
        //   content: url(../../assets/img/tick-member.svg);
        //   margin-right: 10px;
        //   padding-top: 10px;
        // }
        .img-check {
          margin-right: 10px;
          padding-top: 5px;
        }
      }
    }
    .bottom {
      .price {
        text-align: center;
        span {
          font-weight: 600;
          font-size: 19px;
          line-height: 24px;
          color: $dark-black;
          del {
            color: $grey;
          }
        }
      }
      .sav {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: #008c39;
        height: 20px;
      }
      .more {
        background: $primary;
        border-radius: 5px;
        text-align: center;
        height: 40px;
        cursor: pointer;
        @media (max-width: 767px) {
          margin-top: 20px !important;
          height: auto;
        }
        span {
          line-height: 40px;
          color: #ffffff;
        }
      }
    }
  }
  .box-active {
    // box-shadow: 0px 2px 4px rgb(25 188 244 / 60%);
    border: 1px solid rgba(25, 188, 244, 0.6);
  }
  .bottom {
    // padding: 0px 15px 10px 15px;
    @media (max-width: 767px) {
      padding: 10px 10px 20px;
    }
  }

  tbody {
    .width {
      width: 25%;
    }
  }

  @media (min-width: 768px) {
    .col-md-3 {
      flex: 0 0 auto;
      width: 24%;
    }
  }

  .gap-10 {
    gap: 10px;
  }
}

.details {
  .price {
    margin-top: 20px;
    span {
      font-weight: 600;
      font-size: 19px;
      line-height: 24px;
      color: $dark-black;
      del {
        color: $grey;
      }
    }
  }
}

.topfeature {
  display: flex;
  gap: 6px;
  .sub-heading {
    font-size: 13px !important;
  }
}

#more-details {
  display: none;
}

#more-btn-card:hover #current-plan {
  display: none;
}

#more-btn-card:hover #more-details {
  display: inline;
}

// #more-btn-card:hover:before {
//   content: "Reply!"
// }

#more-btn-card {
  line-height: 40px;
  color: #ffffff;
  cursor: pointer;
  font-weight: 400;
  font-size: 14px;
}

.membership-price .carousel-indicators [data-bs-target] {
  background-color: #000 !important;
}

@media (max-width: 991px) {
  .majority {
    .main-box {
      flex-wrap: wrap;
    }
  }
}
