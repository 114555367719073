@import "../../../assets/scss/mixin.scss";
@import "../../../assets/scss/variable.scss";

.job-updates {
  box-shadow: 0px 2px 4px rgba(171, 190, 209, 0.6);
  border-radius: 5px;
  height: 194px;
  position: relative;
  display: flex;
  margin-left: auto;
  width: 100%;
  margin-top: 25px;
  background-color: #fff;
  margin-bottom: -100px;
  span {
    font-size: 30px;
    font-weight: 700 !important;
    line-height: 50px;
    color: $dark-black;
    font-weight: bolder;
    margin-left: 10%;
    h4 {
      font-size: 30px;
      line-height: 50px;
    }
  }
  input {
    box-shadow: 0px 2px 4px rgba(171, 190, 209, 0.6);
    // border-radius: 5px;
    // position: relative;
    height: 45px;
    border: none;
    width: 100%;
    padding-left: 20px;
    top: 40%;
    right: 8px;
    display: flex;
    align-items: center;
  }
  .error-message {
    margin-top: 80px; 
  }
  .btn {
    position: absolute;
    color: $white;
    height: 45px;
    top: 38%;
    right: 21px;
  }
}

.portal-footer {
  position: absolute;
  bottom: 0;
  background: rgba(244, 245, 250, 1);
  height: 200px;
  width: 100%;
  margin-top: auto;

    .inner-footer-div {
      position: absolute;
      top: 44px;
      padding-left: 99px;
      display: flex;
      width: 100%;
      align-items: center;
      .policy{
        margin-left: auto !important;
        padding-right: 99px;
        display: flex;
        align-items: center;
        p{
            margin-top: 0px !important;
            padding-left: 25px;
        }
    }
   
    }

    .line{
        margin: 120px 99px 50px 99px;
    }
    .bottom{
      p{
        padding-left: 99px;
      }
    }
    
}

.tp-footer{
  padding-left: 8%;
  padding-right: 6%;
  height: 242px;
  margin: auto;
}
.footer_container{
  max-width: 1166px;
  margin: auto;
  padding:0 10px
}
.bgcolor_applicant{
  background-color:#fff !important;
  padding: 0;

}
.bgcolor_applicant .footer_container{
  // margin-left: 150px !important;
  margin: auto !important;
  margin-left: 100px;

}

@media (max-width: 767px) {
  .job-updates{
    
      span {
        font-size: 20px;
        line-height: 30px;
        h4{
          font-size: 20px;
          line-height: 30px;  
        }
     
    } 
    // .btn{
    //   height: 45px;
    //   top: 54%;
    //   right: 12px;
    //  }
     input{
      top: 0;
      right: 0;
     }
  }
  .tp-footer {
    .footer_container{
      .row{
        margin-top: 80px !important;
      }
    .footer-logo{
      text-align: center;
    }
    .footer-links{
      ul{
        display: block;
       li{
        
        a{
        text-align: center;
        justify-content: center;
        padding: 0;
       }
       
       }
      }
      }
    }
}
}

@media (max-width: 850px) {
  .job-updates{

    .btn{
      // height: 45px;
      top: 0%;
      right: 12px;
     }
    input{
      top: 0;
      right: 0;
     }
  }
}