@import "../../../../assets/scss/mixin.scss";
@import "../../../../assets/scss/variable.scss";

.sidebar-custom {
  .wrapper {
    background: $white;
    padding: 20px;
    border-radius: 5px;

    .text {
      p {
        @include font-600;
        color: $dark-black;
      }
    }

    .delete {
      @include trash;
    }

    .video {
      position: relative;

      .play {
        position: absolute;
        transform: translate(-50%, -50%);
        left: 50%;
        top: 50%;
        background: rgba(255, 255, 255, 0.34);
        width: 34px;
        height: 34px;
        border-radius: 50%;
        display: grid;
        place-content: center;
        cursor: pointer;
      }
    }
  }
}
.sidebar-refer {
  .box {
    background: $white;
    border-radius: 5px;
  }
}

.insight-side {
  .b-5 {
    border-radius: 5px;
    .edit {
      cursor: pointer;
    }
  }

  .word-break {
    h6 {
      @include font-14;
    }

    p {
      @include para;
    }
  }

  .button {
    margin-top: 30px;
    float: right;

    .btn {
      padding: 0 10px;
      border-radius: 25px;
    }
  }

  h2 {
    @include font-600;
    font-size: 19px;
  }

  .box {
    h6 {
      @include font-14;
      color: $grey;
      margin-bottom: 15px;
    }

    p {
      @include font-600;
      font-size: 19px;
      color: $black;
    }
    .growth {
      color: $green !important;
    }
    .decline {
      color: $red !important;
    }

    small {
      @include font-14;
      font-size: 10px;
      color: $grey;
      display: flex;
      align-items: baseline;
      gap: 8px;
      justify-content: flex-end;
      margin-bottom: 15px;

      .up {
        width: 0;
        height: 0;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-bottom: 5px solid $green;
      }
      .down {
        transform: rotate(180deg);
        width: 0;
        height: 0;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-bottom: 5px solid $red;
      }
    }

    .btn {
      @include font-14;
      font-size: 10px;
      color: $grey;
      padding: 0;

      .right {
        width: 0;
        height: 0;
        border-top: 25px solid transparent;
        border-left: 50px solid #555;
        border-bottom: 25px solid transparent;
      }
    }
  }
}

.post-job {
  background: $transparent-linear;
  border-radius: 5px;
  padding: 30px;

  h3 {
    @include font-14;

    color: $primary;

    span {
      font-size: 30px;
      font-weight: 600;
    }
  }

  //   .imgs {
  //     span {
  //       @include profile-circle;
  //       display: inline-block;
  //     }
  //   }

  .imgs {
    span {
      @include profile-circle;
      display: inline-block;
      margin-left: -16px;
    }
    .plus-count {
      background: $primary;
      color: $white;
      text-align: center;
      line-height: 36px !important;
      @include font-14;
    }
    :first-child {
      margin-left: 0px;
    }
  }

  .more {
    span {
      @include profile-circle;
      display: inline-block;
      margin-left: -16px;
    }
  }
}

.advertisement-side {
  h6 {
    color: $grey;
    @include font-14;
  }

  .float-end {
    span {
      font-size: 10px;
      color: $grey;
    }
  }

  .ad-image {
    background-size: cover;
    height: 204px;
    padding: 20px;
    position: relative;
    .bottoms {
      position: absolute;
      bottom: 0;
    }

    h4 {
      @include font-14;
      color: $white;
    }

    button {
      padding: 0;
      background: transparent;
      border: none;
      @include font-14;
      color: $white;
      font-weight: 600;
    }

    .dots {
      margin: 0 auto;
      display: flex;
      gap: 2px;

      .dot {
        height: 3px;
        width: 3px;
        background: $white;
        border-radius: 50%;
      }
    }
  }
}

.archive-side {
  box-shadow: 0px 2px 4px rgba(171, 190, 209, 0.6);

  .bg-white {
    border-radius: 5px;

    ul {
      li {
        @include font-600;
        line-height: 40px;
        color: $grey;
        cursor: pointer;
      }
    }
  }
}

.np-mail-side {
  h2 {
    @include font-600;
    font-size: 19px;
  }
  .details {
    .profile-img {
      @include profile-40;
    }
    h3 {
      @include font-600;
      color: #01052d;
    }
    p {
      @include small;
      color: #01052d;
      font-size: 10px;
    }
    .light-font {
      color: $grey;
    }
    button {
      @include font-14;
      color: $primary;
      background: none;
      border: none;
      .arrow {
        border: solid $primary;
        border-width: 0 0.9375px 0.9375px 0;
        display: inline-block;
        padding: 3px;
        top: -2px;
        position: relative;
        left: 4px;
      }
      .down {
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
      }
    }
  }
}

.customHeaderModal {
  .modal-title {
    font-size: 19px;
    color: #01052d;
    font-weight: 600;
    line-height: normal;
  }
  .btn-close {
    color: #01052d;
  }
}
.customHeaderModal + .modal-body {
  p {
    font-size: 16px;
    line-height: 1.5;
    color: #676a79;
  }
  .btn {
    margin-top: 20px;
  }
}

.screening_model{
  .modal-content{
    overflow-y: hidden !important;
    background: radial-gradient(45.83% 145.44% at 50% 50%, #222E42 0%, #1A1F2C 100%);
    // background-color: transparent;
  }
  .btn{
    color: #0432A3;
    background-color: #fff;
  }
.modal-header{
  border: none;
  padding: 10px !important;
  
    .btn-close{
      --bs-btn-close-color: white !important;
      background: url(../../../../assets/img/close-white.svg) no-repeat !important ;
      opacity: 1.5;
  }
  
}
  .main_body{

    .part_1{
      background: url("../../../../assets/img/screeningEllipse.png");
      background-repeat: no-repeat;
        background-size: contain;
    background-position: center;
    img{
      width: 100%;
    }
    }
  }

    .col-6{
     background-color: #27354E
    
  }
  
}