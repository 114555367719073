.comment-post-view {
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    // border: 1px solid #E9E9E9;
    border-radius: 8px;
    height: 100vh;
    padding-bottom: 60px;
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow: scroll;

    .text-blue-500 {
        font-weight: 500;
        color: #0d6efd;
    }

    .showcarousalimg {
        .ViewCount {
            position: absolute;
            border-radius: 10px;
            max-width: 48px;
            // max-width: 48px;
            height: 18px;
            padding: 0px;
            display: flex;
            color: white;
            background: rgba(0, 0, 0, 0.5);
            right: 17px;
            bottom: 4px;
            font-size: 12px;
            align-items: center;
            justify-content: center;
        }
    }

    .play-box {
        position: absolute;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
        background: black;
        opacity: 1;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        background: rgba(0, 0, 0, 0.5);
    }

    .play-icon {
        height: 20px;
        width: 20px;
        position: relative;
        left: 36%;
    }

    .top-section {
        flex: 1;

        .post-head-align {
            display: flex;
            justify-content: space-between;
            padding: 10px;

            .profile-details {
                display: flex;
                align-items: center;
                width: calc(100% - 105px);
                flex: 0 0 auto;
                min-width: 0;

                .prof-img {
                    width: 37px;
                    height: 37px;
                    border-radius: 50%;
                    box-shadow: 0 0 0px 4px #008c39;
                    margin-right: 16px;

                    img {
                        border-radius: 50%;
                        width: 100%;
                        height: 100%;
                        box-shadow: 0 0 0px 2px #ffffff;
                    }
                }

                .details {
                    display: flex;
                    flex-direction: column;
                    width: calc(100% - 53px);
                    // padding-left: 5px;
                    flex: 1 0 auto;

                    .name {
                        font-family: 'Inter';
                        font-style: normal;
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 18px;
                        color: black;
                        // display: flex;
                        // align-items: center;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        white-space: nowrap;
                        width: 100%;
                        display: flex;
                    }

                    .headline {
                        font-family: 'Inter';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 10px;
                        line-height: 12px;
                        color: #717171;
                        // display: flex;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        white-space: nowrap;
                        width: 100%;
                    }

                    .time {
                        font-family: 'Inter';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 10px;
                        line-height: 12px;
                        color: #717171;
                    }
                }


            }

            .connection {
                flex: 0 0 105px;

                .connect-btn {
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 14px;
                    display: flex;
                    align-items: center;
                    color: #0432A3;
                    border: 0px;
                    padding: 5px 8px;
                    background: white;
                    // border: 1px solid #0432A3;                    
                    border-radius: 5px;
                    float: right;
                    text-transform: capitalize;
                }
            }

            .clickDots {
                flex: 0 0 16px;
                display: flex;
            }
        }


        .share-article-post {

            .box {
                background: rgba(4, 50, 163, 0.05);
                border-radius: 5px;
                overflow: hidden;

                .boxe-img {
                    height: 300px;
                }

                .text {
                    padding-left: 10px;


                    span {
                        font-weight: 400;
                        line-height: 20px;
                        font-size: 10px;
                        color: #676a79;
                        display: block;
                    }

                    h6 {
                        font-weight: 600;
                        font-size: 15px;
                        line-height: 22px;
                        color: #676a79;
                        word-break: break-all;
                        cursor: pointer;
                    }
                }
            }
        }

        .share-event-post {

            .box {
                background: rgba(4, 50, 163, 0.05);
                border-radius: 5px;
                overflow: hidden;

                .btn-light {
                    border: 1px solid #0432a3;
                    color: #0432a3;
                    cursor: pointer;
                    margin-left: 10px;
                }

                .boxe-img {
                    height: 300px;
                }

                .text {
                    padding-left: 10px;

                    .btn-blue {
                        background: #008C39;
                        padding: 5px 15px;
                    }

                    p {
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 20px;
                        color: #676a79;
                    }

                    span {
                        font-weight: 400;
                        line-height: 20px;
                        font-size: 10px;
                        color: #676a79;
                        display: block;
                    }

                    h6 {
                        font-weight: 600;
                        font-size: 15px;
                        line-height: 22px;
                        color: #676a79;
                        word-break: break-all;
                        cursor: pointer;
                    }
                }
            }
        }

        .description {
            // height: 35px;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 22px;
            font-feature-settings: 'tnum' on, 'lnum' on;
            color: #676a79;
            white-space: pre-line;
            padding: .375rem .75rem;
            width: 100%;
            word-break: break-word;
            // padding: 10px;

            .description-data {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 15px;
                line-height: 20px;
                // overflow: hidden;
                word-wrap: break-word;
                // text-overflow: ellipsis;
                // display: -webkit-box;
                // -webkit-box-orient: vertical;
                // -webkit-line-clamp: 1;
                color: black;
                // overflow: hidden;
                // text-overflow: ellipsis;
                // display: -webkit-box;
                // -webkit-line-clamp: 1;
                // -webkit-box-orient: vertical;
            }

            .see-more {
                font-weight: 700;
                font-size: 16px;
                color: white;
                margin-top: 10px;
            }
        }
    }

    .carousel {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 0 1 auto;
        width: 100%;
        max-height: calc(100% - 230px);

        .carousel {
            width: 100%;
        }

        .carousel-item {
            // display: flex;
            // justify-content: center;
        }

        .single-img {
            width: 100%;
            height: 100%;
        }
    }

    .bottom-section {
        flex: 1;
        position: relative;
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
        padding: 10px 10px 0px;

        .likes {
            position: relative;
            width: fit-content;
            display: flex;
            align-items: center;
            padding-left: 5px;

            .reactionIcons {
                height: 15px;
                width: 15px;
                position: relative;
            }

            img:nth-child(1) {
                z-index: 6;
                // margin-left: 4px;
            }

            img:nth-child(2) {
                z-index: 5;
                // left: 13px;
                // left: -4px;
            }

            img:nth-child(3) {
                z-index: 4;
                // left: 22px;
                // left: -6px;
            }

            img:nth-child(4) {
                z-index: 3;
                // left: 31px;
                // left: -10px;
                display: none;
            }

            img:nth-child(5) {
                z-index: 2;
                // left: 40px;
                // left: -13px;
                display: none;
            }

            img:nth-child(6) {
                z-index: 1;
                // left: 49px;
                // left: -15px;
                display: none;
            }
        }

        .greyText {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 10px;
            color: #676A79;
        }


        .reaction-section {
            display: flex;
            padding: 15px 0px;
            justify-content: space-between;
            position: relative;
            width: 100%;

            .img {
                height: 20px;
                padding: 0px 5px;
            }

            .section {
                display: flex;
                align-items: center;
                width: 33.3%;

                .drop {

                    .box {
                        position: absolute;
                        display: flex;
                        width: fit-content;
                        right: 0px;
                        top: -100px;
                        padding: 8px;
                        z-index: 10;
                        background: #FFFFFF;
                        border: 1px solid #E9E9E9;
                        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
                        border-radius: 5px;
                        // display: flex;

                        .dropdownBox {
                            display: flex;
                            flex-direction: column;

                            li {
                                font-family: 'Inter';
                                font-style: normal;
                                font-weight: 400;
                                line-height: 20px;
                                color: #676A79;
                                font-size: 16px;
                                padding: 4px;
                            }
                        }
                    }
                }
            }

            .text {
                color: #717171;
                font-size: 14px;
            }

            .likes-container {
                display: flex;
                padding: 10px 20px;
                background: white;
                // border: 0.5px solid;
                border-radius: 35px;
                width: calc(100% - 16px);
                margin: 0px 8px;
                position: absolute;
                bottom: 45px;
                z-index: 10;
                box-shadow: 0px 4px 10px rgb(0 0 0 / 10%);
                justify-content: space-between;
                // gap: 3px;
                cursor: pointer;

                img {
                    height: 26px;
                    width: 26px;
                }
            }
        }

        .title{
            font-family: "Inter";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 12px;
            color: #717171;
        }

        .comment-box {
            display: flex;
            justify-content: space-between;
            width: 100%;
            padding: 8px 0px;
            gap: 10px;
            position: relative;



            .lines {
                position: absolute;
                left: 16px;
                bottom: 26px;
                width: 8%;
                height: calc(100% - 50px);
                border-left: 0.5px solid #A3A3A3;
                border-bottom: 0.5px solid #A3A3A3;
                border-bottom-left-radius: 8px;
                //   z-index: -1;
            }

            .comment-section {
                flex: 1;
                width: calc(100% - 42px);

                .view {
                    position: relative;
                    font-family: "Inter";
                    font-style: normal;
                    font-weight: 700;
                    font-size: 14px;
                    font-feature-settings: "tnum" on, "lnum" on;
                    color: #000000;
                    padding: 10px;
                    display: flex;
                }

                .likes-container {
                    display: flex;
                    padding: 10px 20px;
                    background: white;
                    // border: 0.5px solid;
                    border-radius: 35px;
                    width: 100%;
                    max-width: 450px;
                    position: absolute;
                    bottom: 30px;
                    z-index: 10;
                    box-shadow: 0px 4px 10px rgb(0 0 0 / 10%);
                    justify-content: space-between;
                    // gap: 3px;
                    cursor: pointer;

                    img {
                        height: 26px;
                        width: 26px;
                    }
                }
            }

            .profile-img {
                height: 32px;
                width: 32px;
                position: relative;
                background: white;

                img {
                    border-radius: 50%;
                }
            }

            .comments {
                background: #F2F2F2;
                border-radius: 0px 8px 8px 8px;
                padding: 8px 10px;
                flex: 1;
                font-family: 'Inter';
                font-style: normal;
                font-size: 14px;
                font-feature-settings: 'tnum' on, 'lnum' on;
                color: #000000;

                // width: calc(100% - 42px);
                .comment-header {
                    .pin {
                        height: 14px;
                        width: 14px;
                    }

                    .pinned {
                        font-family: 'Inter';
                        font-style: normal;
                        font-size: 14px;
                        color: #717171;
                        display: flex;
                        align-items: center;
                    }

                    .clickDots {
                        font-size: smaller;
                        color: #717171;
                    }

                    .time {
                        font-size: 12px;
                        color: #717171;
                    }
                }


                .user-name {
                    font-weight: 700;
                    line-height: 22px;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    max-width: calc(100% - 102px);
                }

                .message-text {
                    line-height: 18px;
                    padding: 6px 0px;
                    font-weight: 400;
                    word-break: break-word;

                    .blue-hashtag {
                        color: #0432A3;
                        font-weight: 500;
                    }
                }

                .comment-footer {
                    font-family: 'Inter';
                    font-style: normal;
                    font-size: 14px;
                    line-height: 26px;
                    font-weight: 700;
                    font-feature-settings: 'tnum' on, 'lnum' on;
                    position: relative;

                    .like {
                        color: #0432A3;
                    }

                    .reply {
                        color: #717171;
                    }

                    .likes-count {
                        color: #0432A3;
                        float: right;
                    }
                }
            }

            .reply-section {
                // width: 86%;
                .clickDots {
                    font-size: smaller;
                    color: #717171;
                }
                .comments {
                    width: calc(100% - 42px);

                }
            }
        }

        .load-more {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            color: #717171;
            margin-left: 42px;
            text-align: center;
        }



    }

    .comment-footer-fixed {
        display: flex;
        padding: 4px;
        position: fixed;
        bottom: 0;
        width: 100%;
        background: white;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        z-index: 101;

        .inputBox {
            width: 100%;
            position: relative;
        }

        .commentBoxIcon {
            display: flex;
            position: absolute;
            right: 15px;
            bottom: 12px;
        }

        input {
            background: #FFFFFF;
            border: 1px solid #E9E9E9;
            border-radius: 8px;
            color: #717171;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            width: 100%;
            padding: 10px 50px 10px 10px;
            // margin-top: 20px;
        }

    }

    .drawer {
        border-radius: 8px 8px 0px 0px;
        // min-height: 20vh;

        .bar {
            width: 35px;
            border-bottom: 5px solid #717171;
            margin: 18px auto;
            border-radius: 60px;
        }

        ul {
            padding-bottom: 20px !important;
        }

        li {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            // line-height: 138%;
            // letter-spacing: 0.0075em;
            font-feature-settings: 'tnum' on, 'lnum' on;
            color: #717171;
            padding: 5px 20px;
            display: flex;
            align-items: center;

            img {
                margin-right: 10px;
            }

            .pin {
                height: 16px;
                width: 16px;
            }
        }
    }

    .EZDrawer__container {
        height: unset !important;
    }
}

.comment-post-view::-webkit-scrollbar {
    // display: none;
    /* Safari and Chrome */
    // background-color: white;
    // color: white;
    width: 1px !important;
}