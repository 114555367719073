@import "../../assets/scss/variable.scss";

.advertisement {
  background: $linear;
  padding: 10px;
  color: $white;
  border-radius: 5px;
  cursor: pointer;

  h3 {
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    margin: 0px;
    display: flex;
    align-items: center;
  }
}

.min_height_ads {
  min-height: 50px;
  align-items: center;
  display: flex;
  align-content: center;
  justify-content: center;
}

.member_modal {
  .modal-content {
    // background: url(../../assets/img/member-pop.png) no-repeat;
    // background-size: 100% 100%;
    // height: 659px;

    .modal-header {
      border: none;

      .btn-close {
        color: $white;
        opacity: 5;
        background: url(../../assets/img/X.svg);
      }
    }
    .title {
      h2 {
        font-weight: 800;
        font-size: 36px;
        line-height: 40px;
        color: $white;
        margin: 0px;
      }
      p {
        color: $white;
        margin-top: 15px;
        font-weight: 400;
        font-size: 16px;
        width: 70%;
        @media (max-width: 767px) {
          width: 100%;
        }
      }
    }

    .main-box {
      display: flex;
      gap: 25px;
      margin-top: 10px;
      // width: 60%;
      @media (max-width: 991px) {
        flex-wrap: wrap;
      }
      .active-box {
        border: 0.495394px solid #ffffff !important;
      }

      .box {
        border: 0.495394px solid rgba(171, 190, 209, 0.6);
        border-radius: 3.36066px;
        border-radius: 5px;
        padding: 20px;
        width: 193.57px;
        @media (max-width: 991px) {
          width: 48%;
        }
        @media (max-width: 767px) {
          width: 100%;
        }

        p {
          color: $white;
        }

        a {
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: $white;
        }

        a:hover {
          color: $white !important;
        }

        .flex {
          display: flex;
          gap: 13px;
          align-items: center;
          margin-bottom: 15px;
        }

        h2 {
          font-weight: 600;
          font-size: 19px;
          line-height: 24px;
          color: $white;
          margin: 0px;
        }

        ul {
          margin-top: 10px;
          min-height: 250px;
          @media (max-width: 767px) {
            min-height: auto;
          }
          li {
            font-weight: 400;
            font-size: 12px;
            line-height: 13px;
            color: $white;
            display: flex;
            margin-bottom: 5px;

            &::before {
              content: url(../../assets/img/ticks.svg);
              margin-right: 10px;
            }
          }
        }

        .free {
          padding-top: 0;
          text-align: center;
          font-weight: 600;
          line-height: 24px;
          color: $white;
          font-size: 12.5141px;
          line-height: 16px;
          margin-top: 18px;
          .more {
            margin-top: 23px;
            background: #ffffff;
            border-radius: 5px;
            text-align: center;
            //height: 40px;

            span {
              line-height: 40px;
              color: $primary;
            }
          }
        }

        .moreNew {
          margin-top: 23px;
          background: #ffffff;
          border-radius: 5px;
          text-align: center;
          //height: 40px;
          line-height: 40px;
          color: rgb(4, 50, 163);
        }

        .bottom {
          .sav {
            span {
              font-size: 9.40984px;
              line-height: 13px;
              color: $primary;
            }
          }

          .price {
            text-align: center;
            span {
              font-weight: 600;
              line-height: 24px;
              color: $white;
              font-size: 12.5141px;
              line-height: 16px;
            }

            .orange {
              color: #f68a18;
              margin-left: 10px;
            }
          }

          .sav {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            color: #008c39;
          }

          .more {
            background: $primary;
            border-radius: 5px;
            text-align: center;
            height: 40px;

            a {
              line-height: 40px;
              color: #ffffff;
            }
          }
        }
      }
    }
  }
}
