.record_video {

.App {
    // display: flex;
    // justify-content: center;
    // align-items: center;
    // width: 100vw;
    // background-color: #282c34;
  
  }
  
  .Container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // min-height: 100vh;
    margin: 0 10px;
  }
  
  img {
    height: 400px;
    width: 400px;
    object-fit: cover;
  }
  
  .button-class {
    background-color: crimson;
    color: #fff;
    margin-top: 10px;
    padding: 10px 40px;
    border: none;
    border-radius: 25px;
    cursor: pointer;
  }

}