@import "../../../assets/scss/mixin.scss";
@import "../../../assets/scss/variable.scss";

.portal-dashboard {
  background-color: white;

  h2,
  h4,
  h6,
  h5 {
    color: $headfont;
  }

  .btn {
    border-radius: 0px !important;
  }

  // input[type="radio"].custom-radio {
  //   appearance: none;
  //   -webkit-appearance: none;
  //   -moz-appearance: none;
  //   width: 20px; /* Adjust the width as needed */
  //   height: 20px; /* Adjust the height as needed */
  //   border-radius: 50%;
  //   // border: 2px solid #yourBorderColor; /* Set the border color */
  //   outline: none;
  //    background-color: #F68A18; /* Set the background color */
  //   cursor: pointer;
  // }

  .portal-cover-img {
    min-height: 50vh;
    max-height: 70vh;
    position: relative;

    .img-cover {
      object-fit: cover;
      height: 100%;
      width: 100%;
      min-height: 50vh;
      max-height: 70vh;
    }

    .header-row {
      position: absolute;
      top: 44px;
      // padding-left: 99px;
      display: flex;
      max-width: 1190px;
      width: 100%;
      align-items: center;
      margin: auto;

      .authenticate {
        margin-left: auto !important;
        // padding-right: 99px;
        display: flex;
        align-items: center;

        .login {
          color: #ffffff;
          font-weight: 600;
          font-size: 14px;
          padding-right: 25px;
          cursor: pointer;
        }

        .prof {
          cursor: pointer;
          padding-left: 20px;
        }
      }
    }

    .center_banner_content {
      max-width: 900px;
      width: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .find-jobs {
      // position: absolute;
      // left: 20%;
      // top: 30%;
      // margin: 10px;
      // padding: 10px;
      margin-left: 45px;
    }

    .testtt {
      // width: 90%;
      // position: absolute;
      // top: 47%;

      .search {
        // position: absolute;
        // left: 20%;
        // top: 47%;
        // margin: 10px;
        padding: 10px;
        display: flex;
        justify-content: space-evenly;
        gap: 160px;
        // align-items: center;
        // max-width: 880px;
        width: 100%;

        input {
          background: rgba(255, 255, 255, 0.8);
          border: none;
          padding: 10px 10px 10px 40px;
          width: 160%;
          border-radius: 0%;
          line-height: 20px;
        }

        .form-select {
          margin-left: 30px;
          background-color: rgba(255, 255, 255, 0.8);
          border: none;
          padding: 5px 50px 5px 40px;
        }

        .search-icon {
          margin-left: -46px;
          width: 40px;
          height: 40px;
          // text-align: center;
          padding: 8px 8px 5px 8px;
          // position: absolute;
          // left: 123%;
          // top: 20%;
          cursor: pointer;
        }

        .dropdown {
          width: 320px;
          padding: 10px;
          text-align: left;
          max-height: 180px;
          overflow: hidden;
          overflow-y: auto;
          position: absolute;
          z-index: 1;
          background-color: #ffffff;
          border: 1px solid #d1dfff;
          border-radius: 4px;

          .item {
            cursor: pointer;
            margin-top: 10px;
          }
        }
      }
    }
  }

  .heading {
    padding-top: 30px;
    text-align: center;
  }

  .card {
    max-width: 550px;
    min-height: 285px;
    margin: 25px;
    box-shadow: 0px 2px 4px rgba(171, 190, 209, 0.6);
    border-radius: 0px !important;

    .btn {
      color: $white;
    }

    .job-title {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      // width: 470px;
    }

    .card-content {
      display: flex;
      align-items: center;
      flex-direction: row;
    }

    .overflow-ellipsis {
      // white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .job-description {
      height: 87px;
      /* Adjust the max height as needed */
      overflow-y: auto !important;
      overflow-x: auto !important;

      &::-webkit-scrollbar {
        width: 5px;
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      }

      &::-webkit-scrollbar-thumb {
        background-color: #363b99;
        border-radius: 10px;
      }
    }
  }

  input[type="checkbox"]:checked {
    background-color: $orange;
    accent-color: $orange;
  }

  .right-side {
    .recent-views {
      position: relative;
      left: 30px;
    }

    .benifit-card {
      // width: 256px;
      // height: 365px;
      // position: relative;
      // left: 30px;
      // margin-top: 30px;
      padding: 30px;
      background: linear-gradient(
        120.5deg,
        #5b2e92 0%,
        #29409c 50.49%,
        #0f91d2 100%
      );

      h2 {
        position: absolute;
        color: $white;
        top: 20px;
        left: 25px;
      }

      img.card-image {
        height: 100px;
      }

      p {
        // position: absolute;
        color: $white;
        // bottom: 142px;
        // left: 25px;
        // right: 20px;
      }

      .btn {
        // position: absolute;
        background-color: $white;
        // bottom: 55px;
        // left: 25px;
      }
    }
  }

  .job-card-carousel {
    .react-multiple-carousel__arrow--left {
      left: calc(0% + 1px) !important;
    }

    .react-multiple-carousel__arrow {
      background: grey;
    }

    .react-multiple-carousel__arrow--right {
      right: calc(0% + 1px) !important;
    }
  }

  /* Pagination.css */
  .pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    font-family: Arial, sans-serif;
  }

  .pagination-button,
  .page-number {
    background: none;
    border: 1px solid #ddd;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    text-align: center;
    color: #555;
    cursor: pointer;
    transition: color 0.3s, border-color 0.3s;
  }

  .pagination-button:disabled {
    cursor: not-allowed;
    color: #ccc;
    border-color: #eee;
  }

  .page-number.active {
    color: rgb(246, 138, 24);
    font-weight: bold;
  }

  .ellipsis {
    color: #bbb;
  }
}

@media (max-width: 767px) {
  .portal-dashboard {
    .portal-cover-img {
      .header-row {
        top: 10px;
      }

      .header-row .authenticate {
        justify-content: center;
      }

      .img-cover {
        min-height: 300px;
      }
    }
    .right-side {
      .benifit-card {
        p {
          margin-top: 47px;
        }
      }
    }
    .portal-cover-img {
      .find-jobs img {
        width: 150px;
      }

      .center_banner_content {
        top: 69%;
      }

      .testtt .search {
        flex-direction: column;
        gap: 0px;

        .search-icon {
          margin-left: 0;
        }
      }

      .testtt .search input {
        width: 100% !important;
        margin-bottom: 10px;
      }

      .autocomplete-wrapper {
        width: 100% !important;
      }
    }
  }
}

@media (max-width: 850px) {
  .portal-dashboard {
    .portal-cover-img {
      .testtt {
        .search input {
          width: 133%;
        }
      }

      .find-jobs {
        margin-left: 0;
        padding: 0 22px;
      }
    }
  }
}

@media (max-width: 1023px) {
  .portal-dashboard {
    .portal-cover-img {
      .header-row {
        padding: 0 20px;
      }

      .find-jobs img {
        width: 150px;
      }

      // .find-jobs{
      //   margin-left: 0;
      // }
    }
    .right-side {
      .benifit-card {
        img.card-image {
          height: 50px;
        }
      }
    }
  }
}
