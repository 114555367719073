@import "../../assets/scss/variable.scss";
.search-default-pop-up {
  .modal-header {
    background: $linear;

    .modal-title {
      color: $white;
    }
    .btn-close {
      color: $white;
      background: url(../../assets/img/X.svg) no-repeat center;
      opacity: 5;
    }
  }
  // background: rgba(0, 0, 0, 0.25);
  // padding-top: 10%;
  padding-bottom: 30px;
  .search-default-pop-up-inner {
    background-color: #fff;
    // max-width: 574px;
    // width: 100%;
    margin: auto;
    border-radius: 5px;
    // padding: 20px;
  }
  h3 {
    color: #01052d;
    font-size: 19px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    margin-bottom: 30px;
  }
  h4 {
    color: #01052d;
    font-size: 15px;
    font-weight: 600;
    line-height: 22.5px;
  }
  label {
    display: block;
    margin-bottom: 8px;
    color: #676a79;
    font-size: 15px;
    font-weight: 600;
    line-height: 22.5px;
  }
  .input-form {
    margin-top: 20px;
  }
  input,
  textarea {
    border-radius: 4px;
    border: 1px solid #d1dfff;
    width: 100%;
    padding: 8px 20px;
  }
  textarea {
    height: 190px;
  }
  input[type="checkbox"] {
    display: inline-block;
    padding: 0;
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
  input[type="radio"] {
    min-width: 24px;
    width: 24px;
    height: 24px;
    padding: 0;
    accent-color: $primary;
    border: 1px solid #676A79;
    background: none;
    // &:checked {
    //   background-color: #0432a3;
    // }
  }
  input[type="radio"]:checked {
    //   background: $white;
    background: url("../../assets/img/blue-circle.svg") no-repeat center;
  }

  .blue-btn-outer {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    gap: 20px;
    &.end {
      justify-content: end;
    }
  }
  .blue-btn {
    border-radius: 5px;
    background: #0432a3;
    color: #fff;
    padding: 8px 30px;
    display: inline-block;
    cursor: pointer;
    outline: 0;
  }
  .white-btn {
    border-radius: 5px;
    background: #fff;
    color: #0432a3;
    padding: 8px 30px;
    display: inline-block;
    border: 1px #0432a3 solid;
    cursor: pointer;
  }
  .blue-link {
    color: #0432a3;
    font-size: 15px;
    font-weight: 400;
    line-height: 22.5px;
    text-decoration-line: underline !important;
    display: inline-block;
  }
  .blue-clr {
    color: #0432a3;
    text-decoration: none;
  }
  p {
    color: #717171;
    font-size: 16px;
    font-weight: 400;
    line-height: 145%;
    letter-spacing: 0.269px;
  }
}

.add-resume-pop-up {
  h3 {
    text-align: left;
  }
  .resume-list-outer {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
    margin-top: 11px;
  }
  .resume-list {
    position: relative;
  }
  .eye-checkbox {
    position: absolute;
    display: flex;
    bottom: 20px;
    right: 20px;
    input[type="checkbox"] {
      margin-left: 10px;
      margin-right: 0;
    }
  }
  .lock-icon {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    color: #fff;
    font-size: 10px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    img {
      max-width: 34px;
    }
    span {
      margin-top: 5px;
    }
  }
  p {
    color: #676a79;
    font-size: 15px;
    font-weight: 400;
    line-height: 22.5px;
    margin-top: 8px;
  }
  .title {
    display: block;
    color: #676a79;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    margin-bottom: 10px;
  }
  .blue-link {
    margin-top: 14px;
  }
  .or {
    position: relative;
    margin-top: 10px;
    text-align: center;
    z-index: 1;
    &::before {
      content: "";
      clear: both;
      display: block;
      background: #d1dfff;
      width: 100%;
      height: 1px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      z-index: -1;
    }
    span {
      background-color: #fff;
      padding: 0 20px;
      display: inline-block;
      color: #01052d;
      font-size: 15px;
      font-weight: 600;
      line-height: 22.5px;
    }
  }
  .content-box {
    margin-top: 20px;
    padding-right: 20px;
    p {
      color: #676a79;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
    }
  }
  .upload-resume-list-box {
    border-radius: 5px;
    border: 1px solid #d1dfff;
    padding: 20px;
    .upload-resume-list {
      display: flex;
      align-items: center;
    }
    .img-txt {
      margin-left: 20px;
      width: 500px;
    }
    input[type="checkbox"] {
      margin-right: 20px;
    }
  }
  .upload-btn {
    border-radius: 5px;
    background: rgba(4, 50, 163, 0.05);
    color: #0432a3;
    font-size: 15px;
    font-weight: 600;
    line-height: 22.5px;
    padding: 8px 30px;
    display: inline-block;
    margin-top: 10px;
    cursor: pointer;
    label {
      margin: 0;
      cursor: pointer;
    }
    input {
      display: none;
    }
  }
  .head {
    color: #01052d;
    font-size: 15px;
    font-weight: 600;
    line-height: 22.5px;
  }
  .date {
    color: #676a79;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
}

.add-resume-pop-up.resume-cv {
  max-height: 100%;
  overflow: hidden;

  .hidden-cv {
    margin-top: 500px;
    width: 1200px;
  }
}

.application-pop-up {
  h3 {
    border-bottom: 1px #d1dfff solid;
    padding-bottom: 20px;
    margin-bottom: 0;
  }
  .img-content-column {
    display: flex;
    flex-wrap: wrap;
  }
  .img-box {
    flex-basis: 22%;
  }
  .right-box {
    flex-basis: 78%;
    padding-left: 16px;
    margin-bottom: 30px;
    p {
      color: #676a79;
      font-size: 10px;
      font-weight: 400;
      line-height: 20px;
    }
  }
  .content-box {
    p {
      margin-top: 18px;
      color: #676a79;
      font-size: 15px;
      font-weight: 400;
      line-height: 22.5px;
    }
  }
  .img-content-box {
    margin-top: 20px;
    h5 {
      margin-bottom: 5px;
      color: #676a79;
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
    }
    h4 {
      margin-bottom: 8px;
      color: #676a79;
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
    }
  }
  .explore-more-btn {
    color: #0432a3;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
}

.job-alerts-pop-up {
  .cls-btn {
    font-size: 15px;
    color: #01052d;
    cursor: pointer;
  }
  h3 {
    margin-bottom: 0;
  }
  .head {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px #d1dfff solid;
    padding-bottom: 20px;
  }
  .job-alerts-list-outer {
    display: grid;
    grid-template-columns: 90% auto;
  }
  h4 {
    color: #000;
    font-size: 19px;
    font-weight: 600;
    line-height: 24px;
  }
  h5 {
    color: #01052d;
    font-size: 15px;
    font-weight: 600;
    line-height: 22.5px;
  }
  .time {
    color: #676a79;
    font-size: 10px;
    font-weight: 400;
    line-height: 20px;
    display: block;
  }
  .filters {
    color: #676a79;
    font-size: 15px;
    font-weight: 400;
    line-height: 22.5px;
    display: block;
    margin-top: 8px;
  }
  .edit,
  .delete {
    cursor: pointer;
  }
  .edit {
    margin-right: 10px;
  }
  .delete {
    img {
      max-height: 18px;
    }
  }
  .left-list {
    margin-bottom: 25px;
  }
  .blue-btn-outer {
    margin-top: 0;
  }
  label {
    display: inline-block;
    margin-left: 20px;
    margin-bottom: 0;
    color: #676a79;
    font-size: 15px;
    font-weight: 400;
    line-height: 22.5px;
  }
  .radio-list {
    margin-top: 30px;
  }
  .radio-list-item {
    margin-top: 10px;
    display: flex;
    align-items: center;
  }
  .days {
    border-radius: 4px;
    border: 1px solid #d1dfff;
    padding: 2px 12px;
    width: 30% !important;
  }
  .days::-webkit-inner-spin-button,
  .days::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.survey-pop-up {
  .form-select {
    background: url("../../assets/img/angleDown.png") 98% 8px no-repeat;
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
  }
  h3 {
    margin-bottom: 10px;
  }
  h4 {
    margin-top: 20px;
    margin-bottom: 10px;
  }
}

.thankyou-pop-up {
  .search-default-pop-up-inner {
    padding: 30px 50px;
  }
  h2 {
    color: #01052d;
    text-align: center;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: 44px;
  }
  .head {
    p {
      color: #676a79;
      text-align: center;
      font-size: 15px;
      font-weight: 400;
      line-height: 22.5px;
      margin-top: 10px;
    }
  }
  .range-outer {
    display: grid;
    grid-template-columns: 50% 30% 20%;
    border-top: 1px #d1dfff solid;
    margin-top: 27px;
  }
  .range {
    position: relative;
    padding-top: 10px;
    &.avrg {
      &::before {
        content: "";
        width: 10px;
        height: 10px;
        background: #0432a3;
        border-radius: 50%;
        display: block;
        position: absolute;
        top: -5px;
        left: 0;
        right: 30%;
        margin: auto;
      }
    }
  }
  .pay-box {
    text-align: center;
    border-radius: 8px;
    border: 1px solid #d1dfff;
    background: #fff;
    max-width: 342px;
    width: 100%;
    margin: 60px auto;
    padding: 0 22px 18px;
    .title {
      border-radius: 50px;
      background: #f2f2f2;
      color: #676a79;
      text-align: center;
      font-size: 15px;
      font-weight: 400;
      line-height: 22.5px;
      padding: 6px 18px;
      position: relative;
      top: -10px;
    }
    h3 {
      color: #01052d;
      text-align: center;
      font-size: 30px;
      font-weight: 600;
      line-height: 44px;
      margin: 0;
    }
    p {
      color: #676a79;
      text-align: center;
      font-size: 15px;
      font-weight: 400;
      line-height: 22.5px;
    }
  }
}

@media (max-width: 767px) {
  .search-default-pop-up {
    .search-default-pop-up-inner {
      max-width: 320px;
      padding: 5px;
    }
    textarea {
      height: 100px;
    }
    label {
      font-size: 14px;
    }
    h3 {
      font-size: 16px;
    }
    .white-btn{
      padding: 8px 15px;
    }
  }
  .add-resume-pop-up {
    .resume-list-outer {
      grid-template-columns: repeat(2, 1fr);
      text-align: center;
      max-width: 300px;
      margin-left: auto;
      margin-right: auto;
    }
    p {
      font-size: 14px;
    }
  }
  .application-pop-up {
    .content-box p {
      font-size: 14px;
    }
    .right-box {
      flex-basis: 100%;
      padding-left: 0;
      margin-top: 10px;
    }
  }
  .job-alerts-pop-up {
    h4 {
      font-size: 16px;
    }
    h5 {
      font-size: 14px;
    }
    .filters {
      font-size: 12px;
    }
    .edit {
      margin-right: 6px;
    }
    .job-alerts-list-outer {
      grid-template-columns: 80% auto;
      justify-content: space-between;
    }
  }
  .survey-pop-up {
    .form-select {
      font-size: 12px;
    }
    .form-select {
      background-size: 14px;
    }
  }
  .thankyou-pop-up {
    .pay-box {
      h3 {
        font-size: 24px;
      }
      p {
        font-size: 14px;
      }
      .title {
        font-size: 12px;
      }
    }
    .range {
      font-size: 12px;
    }
  }
}



//Styling done to handle placeholder text in salary Comparison
/* Add styling to the placeholder text */
.autocomplete-wrapper input::placeholder {
  //font-weight: 400;
  color:#676A79;
}
