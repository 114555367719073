@import "../../../../assets/scss/mixin.scss";
@import "../../../../assets/scss/variable.scss";
.left-sidebar{
.sidebar-header{
    // height: 40px;
    border-bottom: 1px solid $portalborder;
    margin-bottom: 20px;
    h5{
        margin-top: 10px;
        margin-bottom: 10px;
        font-weight: 600;
        font-size: 15px;
    }
    p{
        margin-bottom: 15px;
    }
}

    .job-card{
      cursor: pointer;
        .img-icon{
            width: 40px;
            height: 40px;
            border-radius: 50%;
        }
      h5{
        font-weight: 600;
        font-size: 15px;
      }  
      .shortlist-number{
        margin-top: 5px;
        margin-bottom: 5px;
      }
    }

    .active{
        border-left: 4px solid $primary;
    }
}

.filter-header{
    .filter-option {
        display: inline-flex;
        justify-content: space-between;
        /* align-content: center; */
        align-items: baseline;
        // width: 100%;
        // padding-left: 30px;
        // padding-right: 30px;
        // border-radius: 5px;
        // box-shadow: 0px 2px 4px rgba(171, 190, 209, 0.6);
        // background: $white;
    
        .search {
          position: relative;
          &::before {
            content: "";
            background: url(../../../../assets/img/location-job.svg) no-repeat;
            position: absolute;
            top: 7px;
            left: -17px;
            width: 22px;
            height: 22px;
            z-index: 5;
            cursor: pointer;
          }
          &::after {
            content: "";
            background: none;
            position: absolute;
          }
          // input {
          //   background: rgba(238, 240, 242, 0.35);
          //   border: none;
          //   width: 100%;
          //   padding: 10px 10px 10px 40px;
          //   &::placeholder {
          //     color: $grey;
          //     @include para;
          //   }
          // }
        }
    
        ul {
          display: flex;
          justify-content: space-between;
          .active {
            .form-select {
              color: $black;
              &::after {
                background: black;
                width: 1px;
                height: 100%;
                position: absolute;
                right: 0;
                top: 0;
              }
            }
          }
          li {
            display: inline;
          }
          .upper {
            z-index: 99;
            position: relative;
          }
        //   .form-select {
        //     @include para;
        //     border: none;
        //     display: inline;
        //     position: relative;
        //     background-image: url(../../../../assets/img/drop-down.svg);
        //     background-size: auto;
        //     width: 95px;
        //   }
        .form-select{
            padding: 0.375rem 1.25rem 0.375rem 0.55rem;
            border: none;
        }
        .sort-header-select{
          padding: 0.375rem 1.75rem 0.375rem 0.55rem !important;
            border: none;
        }
        .sort-edu-select{
          // padding: 0.375rem 0.55rem 0.375rem 0.55rem !important;
            border: none;
        }
       
        }
        ul li:last-child {
          margin-right: 0;
        }
    
        .autocomplete-wrapper {
            width: 23%;
          input {
            border: none;
            // background-image: url(../../../assets/img/drop-down.svg);
            // background-size: auto;
            // background-repeat: no-repeat;
            // background-position: right 0.75rem center;
            // max-width: 130px;
            align-items: center;
            display: flex;
            padding: 0 15px;
          }
        }
    
        .autocomplete-wrapper div {
          z-index: 1;
        }
        .autocomplete-wrapper input {
          width: 100%;
          // padding: .375rem .75rem;
          top: 7px;
          left: -8px;
          padding: 0px 0.5rem 0 0.75rem;
          border-radius: 4px;
          // min-width: 190px;
        }
        .autocomplete-wrapper input::placeholder {
          text-align: center;
        }
        // .autocomplete-wrapper input:focus {
        //   border-color: #0F67FF;
        //   box-shadow: none;
        //   outline: none;
        // }
        .autocomplete-wrapper .dropdown {
          width: 100%;
          padding: 0;
          text-align: left;
          max-height: 100px;
          overflow: hidden;
          overflow-y: auto;
          position: absolute;
          z-index: 15;
          background-color: #ffffff;
          border: 1px solid #d1dfff;
          border-radius: 4px;
          box-shadow: 10px 10px 5px lightblue;
        }
        .autocomplete-wrapper .item {
          display: block;
          cursor: pointer;
          font-size: 14px;
          padding: 0.375rem 0.75rem;
        }
        .autocomplete-wrapper .item.selected-item {
          background-color: #0432a3;
          color: #fafbfc;
        }
        .autocomplete-wrapper .item:hover {
          background-color: #0432a3;
          color: #fafbfc;
          width: 100%;
        }
      }

      .filter-tabs{
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100%;
        span{
          font-size: 15px;
          font-weight: 600;
          cursor: pointer;
          height: 100%;
          padding: 6px 5px 2px 5px;
        }
        .active{
          color: $primary;
          background-color: $light-grey;
        }
      }

}