

.image_drag_drop {
    width:100%;
    height:225px;
    object-fit: cover;
}

.blue-txt {
    color: #0432a3;
}