@import "../../../assets/scss/mixin.scss";
@import "../../../assets/scss/variable.scss";

.create-talent-pool {
  .header {
    border: none;
    h5 {
      font-size: 27px;
      font-weight: 600;
      color: $dark-black;
    }
    .btn-close {
      opacity: 1;
    }
  }
  .form-label{
    color: rgba(1, 5, 45, 1) !important;
  }
  .show-color {
    width: 86px;
    height: 40px;
    border-radius: 5px;
    border: 1px solid #d1dfff;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .open-color-picker {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-left: 20px;
    margin-top: 10px;
  }
  .big-img {
    position: relative;
    .img-cover {
      position: relative;
    }
    .child-img {
      position: absolute;
      top: 64px;
      left: 188px;
    }
  }
  .wrapper-class-talent {
    .rdw-editor-toolbar {
      padding: 0px;
    }
    .rdw-inline-wrapper {
      margin-right: 150px;
    }
    .rdw-text-align-wrapper {
      margin-left: 150px;
    }
  }
}
.talent_pool_privacy{
  .rdw-editor-main{
    min-height: 540px !important;
  }
  
}

.success-popup{
    .check {
      background: url("../../../assets/img/check-mark.png") no-repeat center;
      height: 100px;
    }
  
    h4 {
      @include font-19;
      color: $black;
    }
  
    p {
      @include font-14;
      font-size: 15px;
      color: $grey;
      margin-right: 7px;
    }
  
    .ci-share {
      @include ci-share;
    }
  }
