@import "../../assets/scss/mixin.scss";
@import "../../assets/scss/variable.scss";

.setting {
  .setting-page {
    // background: url(../../assets/img/setting.png);
    padding-top: 26px;
    .main-tabs {
      display: flex;
      aside {
        width: 274px;
        background: $white;
        border-radius: 5px;
        position: relative;
        .heading {
          h1 {
            @include font-600;
            font-size: 30px;
            padding: 20px 30px;
            @media (max-width: 767px) {
              padding: 20px;
            }
          }
        }
        .nav-pills {
          .active {
            &::before {
              content: "";
              border-left: 3px solid $primary;
              height: 14.19px;
              position: absolute;
              left: 0;
              top: 14px;
            }
            background-color: $white;
            color: $primary !important;
            border-radius: 2px;
          }
          .nav-link {
            border-radius: 0;
            text-align: left;
            @include font-600;
            color: $grey;
            padding: 2px 0px 5px 25px;
            line-height: 40px;
            position: relative;
          }
        }
      }
      .vertical-tabs {
        margin-left: 26px;
        background: $white;
        border-radius: 5px;
        width: 874px;
        height: 1000px;
        .heading {
          h1 {
            padding: 20px 30px;
            color: #01052d;
            font-family: Inter;
            font-size: 19px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
            @media (max-width: 767px) {
              padding: 20px;
            }
          }
        }
        .search {
          position: relative;
          margin-right: 30px;
          margin-left: 100px;
          margin-top: 10px;
          &::before {
            content: "";
            background: url(../../assets/img/Search.svg) no-repeat;
            position: absolute;
            top: 5px;
            width: 22px;
            height: 22px;
            z-index: 5;
            cursor: pointer;
          }
          input {
            border: none;
            border-radius: 0;
            border-bottom: 2px solid $transparent-blue;
            padding: 5px 30px;
          }
        }
        .content {
          background: rgba(238, 240, 242, 0.35);
          padding: 0 30px;
          overflow-y: auto;

          min-height: calc(100vh - 160px);
          @media (max-width: 767px) {
            padding: 0 20px;
          }
          .profile-information {
            padding-top: 15px;
            input {
              border-radius: 5px;
              border: 1px solid #d1dfff;
              width: 100%;
              padding: 10px;
              margin-top: 15px;
              background-color: transparent;
            }
            h4 {
              @include font-600;
              color: $black;
            }
            ul {
              li {
                @include para;
                padding: 10px;
                cursor: pointer;
              }
              .active {
                background: $transparent-blue;
                color: $primary;
              }
            }
            .profile-box-outer {
              margin-top: 15px;
              gap: 20px;
              display: flex;
              flex-wrap: wrap;
              @media (max-width: 767px) {
                gap: 10px;
                display: grid;
                grid-template-columns: repeat(2, 1fr);
              }
            }
            .profile-box {
              border-radius: 5px;
              border: 1px solid rgb(209 223 255 / 50%);
              background: rgb(255 255 255 / 50%);
              padding: 8px 20px 15px;
              text-align: center;
              @media (max-width: 767px) {
                padding: 8px 10px 15px;
              }
              h5 {
                color: #01052d;
                font-family: Inter;
                font-size: 15px;
                font-style: normal;
                font-weight: 600;
                line-height: 22.5px;
                margin-top: 10px;
                overflow: hidden;
                text-overflow: ellipsis;
                text-wrap: nowrap;
              }
              h6 {
                color: #536985;
                font-family: Inter;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
              }
              a {
                color: #0432a3;
                font-family: Inter;
                font-size: 15px;
                font-style: normal;
                font-weight: 600;
                line-height: 22.5px;
                margin-top: 10px;
                display: inline-block;
              }
            }
            .switch-box {
              position: relative;
              display: inline-block;
              max-width: 24px;
              height: 12px;
              flex-basis: 5%;
              input {
                opacity: 0;
                width: 0;
                height: 0;
                &:checked ~ .slider {
                  background-color: #0432a3;
                  &:before {
                    transform: translateX(12px);
                  }
                }
              }
              .slider {
                position: absolute;
                cursor: pointer;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background-color: #ffffff;
                border: 1px solid #676a79;
                transition: 0.4s;
                &.round {
                  border-radius: 34px;
                  background-color: #676a79;
                }
                &.round:before {
                  border-radius: 50%;
                  background-color: #ffffff;
                }
                &:before {
                  position: absolute;
                  content: "";
                  height: 10px;
                  width: 10px;
                  left: 0;
                  bottom: 0;
                  background-color: #676a79;
                  transition: 0.4s;
                }
              }
            }
          }
        }

        .loader-content {
          min-height: calc(100vh - 160px);
        }
      }
    }
  }
}

.setting-modal-default-modal
  .modal-header:not(#membership-modal, #account-modal) {
  background: linear-gradient(
    120.5deg,
    #5b2e92 0%,
    #29409c 50.49%,
    #0f91d2 100%
  );
  height: 70px;
  h4 {
    color: white !important;
    font-weight: 600;
  }
  .btn-close {
    --bs-btn-close-color: white !important;
    background: url(../../assets/img/close-white.svg) no-repeat !important ;
    opacity: 1.5;
  }
}

.modal-body input[type="radio"]:not(.my-input-custom) {
  width: 20px;
  height: 20px;
  display: inline;
  vertical-align: top;
  margin-right: 12px;
}

.last-name-radio-list-modal .modal-body .radio-list {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.last-name-radio-list-modal .modal-body .radio-list img {
  max-width: 48px;
  margin: 0 20px;
  border-radius: 40px;
}

.no-background-color {
  background-color: transparent !important;
}
.secondary-sidebar {
  background-color: #ffffff;
  .bottom-custom {
    .links {
      margin-bottom: 10px;
      ul {
        display: flex;
        flex-wrap: wrap;
        gap: 5px;
        li {
          @include para;
          font-size: 10px;
        }
      }
    }
  }
}
.copy-right {
  p {
    @include para;
    font-size: 10px;
  }
}

.setting-bottom {
  position: absolute;
  bottom: 0;
  .bottom-custom {
    .links {
      margin-bottom: 10px;
      ul {
        display: flex;
        flex-wrap: wrap;
        gap: 5px;
        li {
          @include para;
          font-size: 10px;
        }
      }
    }
  }
}
.copy-right {
  p {
    @include para;
    font-size: 10px;
  }
}

.setting-popup {
  .modal-header {
    padding: 30px 30px 20px;
    background: linear-gradient(
      120.5deg,
      #5b2e92 0%,
      #29409c 50.49%,
      #0f91d2 100%
    );
    height: 70px;
    h4 {
      color: white !important;
    }
    .btn-close {
      --bs-btn-close-color: white !important;
      background: url(../../assets/img/close-white.svg) no-repeat !important ;
      opacity: 1.5;
    }
    h5 {
      @include large;
      color: white !important;
    }
  }
  .modal-body {
    padding: 13px 30px;
    .box {
      .eye {
        @include eye;
      }
      .profile-img {
        @include profile-125;
      }
      p {
        @include para;
        span {
          margin: 0;
          font-weight: 400;
          font-size: 14px;
          color: $blue;
        }
      }
      .d-flex {
        margin: 20px 0;
        .enable {
          @include font-600;
          color: $grey;
        }
      }
      ul {
        li {
          @include para;
          color: $grey;
          font-size: 14px;
          line-height: 30px;
        }
      }
      span {
        margin: 0;
      }
      .switch {
        position: relative;
        display: inline-block;
        width: 48px;
        height: 24px;
        input {
          opacity: 0;
          width: 0;
          height: 0;
        }
      }
      .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: $white;
        border: 1px solid $grey;
        -webkit-transition: 0.4s;
        transition: 0.4s;
        &:before {
          position: absolute;
          content: "";
          height: 16px;
          width: 16px;
          left: 3px;
          bottom: 3px;
          background-color: $grey;
          -webkit-transition: 0.4s;
          transition: 0.4s;
        }
      }
      input {
        &:checked {
          + {
            .slider {
              background-color: $primary;
              &:before {
                -webkit-transform: translateX(23px);
                -ms-transform: translateX(23px);
                transform: translateX(23px);
              }
            }
          }
        }
        &:focus {
          + {
            .slider {
              box-shadow: 0 0 1px $grey;
            }
          }
        }
      }
      .slider.round {
        border-radius: 34px;
        background-color: $grey;
        &:before {
          border-radius: 50%;
          background-color: $white;
        }
      }

      &.social-media {
        .know-more {
          display: inline;
          position: relative;
          cursor: pointer;
        }

        // .know-more:hover:after {
        //   background: #333;
        //   background: rgba(0, 0, 0, 0.8);
        //   border-radius: 5px;
        //   top: 20px;
        //   color: #fff;
        //   content: attr(data-title);
        //   left: 20%;
        //   padding: 5px 15px;
        //   position: absolute;
        //   z-index: 98;
        //   width: 350px;
        // }

        // .know-more:hover:before {
        //   border: solid;
        //   border-color: #333 transparent;
        //   border-width: 0 6px 6px 6px;
        //   bottom: -4px;
        //   content: "";
        //   left: 50%;
        //   position: absolute;
        //   z-index: 99;
        // }

        .know-more .tooltip-content {
          display: none;
          background: rgba(0, 0, 0, 0.8);
          border-radius: 5px;
          top: 22px;
          color: #fff;
          padding: 10px;
          position: absolute;
          z-index: 98;
          width: 550px;
          height: 225px;
          overflow: auto;
          left: 140px;
          transform: translateX(-50%);
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);

          ul {
            list-style-type: disc;
            padding-left: 20px !important;
            margin-top: 10px !important;
            li {
              margin-bottom: 10px;
              color: #fff;

              ul {
                list-style-type: circle;
                padding-left: 10px !important;
                li {
                  color: #f0f0f0;
                }
              }

              p {
                text-align: center;
                color: #f0f0f0;
              }
            }
          }
        }

        .know-more .tooltip-arrow {
          display: none;
          position: absolute;
          bottom: -5px;
          left: 50%;
          transform: translateX(-50%);
          width: 0;
          height: 0;
          border-left: 6px solid transparent;
          border-right: 6px solid transparent;
          border-bottom: 6px solid rgba(0, 0, 0, 0.8);
          z-index: 99;
        }

        .know-more:hover .tooltip-content,
        .know-more:hover .tooltip-arrow {
          display: block;
        }
        .social-links {
          padding: 10px;
          margin: 10px 0;
          .d-flex {
            gap: 20px;
            align-items: center;
            margin: 0;
            .icon {
              .facebook {
                @include facebook;
              }
              .instagram {
                @include instagram;
              }
              .linkdin {
                @include linkdin;
              }
              .twitter {
                @include twitter;
              }
            }
            .link {
              flex: 1 1 100%;
              span {
                max-width: 275px;
                @include small;
              }
            }
            .edit-box {
              position: relative;
              display: flex;
              gap: 10px;
              .edit {
                @include edit-font;
                right: inherit;
                top: 0;
                position: relative;
              }
              .pencil {
                @include pencil;
              }
              .tick {
                @include tick;
              }
              .delete {
                @include delete;
              }
              .close {
                @include small;
                cursor: pointer;
              }
              .embed {
                font-weight: 600;
                font-size: 14px;
                line-height: 20px;
                color: #0432a3;
                cursor: pointer;
              }
            }
            .primary {
              .switch {
                .slider {
                  border: 1px solid $primary;
                  &::before {
                    background-color: $primary;
                  }
                }
              }
            }
          }
        }
      }
    }
    .text-box {
      border: 1px solid #d1dfff;
      border-radius: 5px;
      padding: 10px;
      display: flex;
      justify-content: space-between;
      margin-bottom: 14px;
      .link {
        @include small;
      }
      .avilable {
        @include small;
        color: #008c39;
        font-size: 10px;
        cursor: pointer;
      }
      .unAvilable {
        @include small;
        color: #b62727;
        font-size: 10px;
        cursor: pointer;
      }
    }
  }
}
.create-mode {
  .modal-header {
    border: none;
    padding: 30px 30px 5px;
    width: 100%;
    @include center-text;
  }
  /* Rounded sliders */
  .switch {
    position: relative;
    display: block;
    width: 37px;
    height: 10px;
    top: 37px;
    right: 50px;
    input {
      opacity: 0;
      width: 0;
      height: 0;
    }
  }
  .slider {
    position: absolute;
    cursor: pointer;
    height: 8px;
    width: 23px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $grey;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    &:before {
      position: absolute;
      content: "";
      height: 18px;
      width: 18px;
      left: -10px;
      bottom: -5px;
      background-color: $primary;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }
  }

  .slider_disabled {
    position: absolute;
    cursor: pointer;
    height: 8px;
    width: 23px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 34px;
    background-color: $grey;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    &:before {
      position: absolute;
      content: "";
      height: 18px;
      width: 18px;
      left: -10px;
      bottom: -5px;
      border-radius: 50%;
      background-color: $grey;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }
  }
  input {
    &:checked {
      + {
        .slider {
          background-color: $primary;
          &:before {
            -webkit-transform: translateX(26px);
            -ms-transform: translateX(26px);
            transform: translateX(26px);
          }
        }
      }
    }
    &:focus {
      + {
        .slider {
          box-shadow: 0 0 1px #2196f3;
        }
      }
    }
  }
  .slider.round {
    border-radius: 34px;

    &:before {
      border-radius: 50%;
    }
  }

  .modal-body {
    h6 {
      @include font-600;
    }
    ul {
      li {
        list-style-type: disc;
        @include para;
        margin-left: 20px;
      }
    }
    .type-box {
      border: 1px solid #e9e9e9;
      border-radius: 8px;
      max-height: 140px;
      height: 140px;
      margin-bottom: 30px;
      padding: 20px;
      overflow-y: auto;
      margin-top: 15px;
      input {
        border: 1px solid #e9e9e9;
        width: 100%;
        padding: 10px;
      }
      .round {
        background: $white;
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
        border-radius: 89px;
        width: fit-content;
        padding: 10px 25px;
        @include center-text;
        @include para;
        .close {
          font-weight: 600;
          cursor: pointer;
        }
      }
    }
  }
}

.merge-account {
  .inputfields {
    padding: 0 !important;
    margin: 0 !important;
  }
  .d-flex {
    margin: 0 !important;
  }
}

@media (max-width: 991px) {
  .setting {
    .setting-page {
      .main-tabs {
        flex-wrap: wrap;
        aside {
          width: 100%;
        }
        .vertical-tabs {
          width: 100%;
          margin-top: 26px;
          margin-left: 0;
        }
        .search {
          margin-left: 30px;
          margin-bottom: 30px;
        }
        .bottom {
          position: relative;
        }
      }
    }
    .setting-bottom {
      position: relative;
      display: none;
    }
  }
}
@media(max-width:767px){
  .setting-popup{
    .modal-body{
      padding: 13px 15px;
    }
  }
}

.dark_label{
  color: #000 !important;
}

.setting-modal-default-modal .switch-box .security_switch:before{
  height: 15px !important;
  width: 15px !important;
  bottom: 2px !important;
  left : 2px !important;
}

.setting-modal-default-modal .switch-box input:checked ~ .security_switch:before{
  transform: translateX(21px) !important;
}

.setting-modal-default-modal .switch-box .security_switch.round{
  background-color: transparent !important;
}
.setting-modal-default-modal .switch-box .security_switch.round:before{
background-color: #676a79 !important;
}
.create-mode .creator_switch:before{
background-color: #fff !important;
}
.create-mode input:checked + .creator_switch{
background-color: rgba(211, 211, 211, 1) !important;

}