/* Dropdown styles */
.dropdownv2 {
  position: relative;
  display: inline-block;
  margin-right: 22px;

  .dropdown-togglev2 {
    display: flex;
    align-items: center;
    // padding: 4px;
    // background-color: #ffffff;
    // border: 1px solid #ccc;
    cursor: pointer;
    user-select: none;
    transition: background-color 0.2s ease;
    border-radius: 4px;
  }

  .dropdown-togglev2:hover {
    // background-color: #f8f8f8;
  }

  .dropdown-option-image {
    width: 35px;
    height: 35px;
    margin-right: 12px;
    border-radius: 50%;
  }

  .dropdown-option-label {
    flex-grow: 1;
    font-weight: bold;
  }

  .dropdown-caretv2 {
    display: inline-block;
    // margin-left: 12px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 7px 7px 0 7px;
    border-color: #999 transparent transparent transparent;
  }

  .dropdown-menuv2 {
    position: absolute;
    top: 100%;
    left: 0;
    margin: 0;
    padding: 12px 0;
    background-color: #fff;
    border: 1px solid #ccc;
    border-top: none;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    list-style: none;
    z-index: 1;
    opacity: 0;
    visibility: hidden;
    transform: translateY(-12px);
    transition: opacity 0.2s ease, visibility 0.2s ease, transform 0.2s ease;
    max-height: 200px;
    overflow-y: auto;
    border-radius: 4px;
  }

  .dropdown-menuv2.open {
    opacity: 1;
    visibility: visible;
  }

  .dropdown-menuv2 li {
    display: flex;
    align-items: center;
    padding: 12px 15px;
    cursor: pointer;
    transition: background-color 0.2s ease;
  }

  .dropdown-menuv2 li:hover {
    background-color: #f8f8f8;
  }

  .dropdown-menuv2 li .dropdown-option-image {
    margin-right: 12px;
  }

  .dropdown-menuv2 li .dropdown-option-label {
    flex-grow: 1;
    font-weight: bold;
  }

  /* Centering the dropdown */
  .dropdown.open .dropdown-menuv2 {
    transform: translateY(0);
  }

  /* Adjust dropdown width */
  .dropdown.open .dropdown-togglev2 {
    border-radius: 4px 4px 0 0;
    border-bottom: none;
  }

  .dropdown.open .dropdown-menuv2 {
    border-radius: 0 0 4px 4px;
    border-top: none;
  }
}
