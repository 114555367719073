.eventdetail-webview {
    background-color: #f8f8f8;
    display: flex;
    height: 100vh;
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow: scroll;
    .heading {
        h2 {
            text-transform: capitalize;
        }

        .virtual {
            .btn {
                text-transform: capitalize;
            }

            .link {
                word-break: break-word;
                display: flex;
                align-items: center;
            }
        }
        .eventBtn{
            width: -webkit-fill-available;
        }
    }

    .networks .wrapper .events .card .img-section .tag {
        padding: 5px 10px;
        text-transform: capitalize;
        font-size: 12px;
    }

    .networks .wrapper .events .card .people-pic .d-flex span {
        height: 30px;
        width: 30px;
        display: flex;
        margin-left: -18px;
        border-radius: 55%;
        background: #0432a3;
        color: #ffffff;
        text-align: center;
        // line-height: 34px !important;
        font-weight: 400;
        font-size: 10px;
        // line-height: 20px;
        justify-content: center;
        align-items: center;
    }

    .networks .wrapper .events .card .people-pic {
        display: flex;
        flex-wrap: wrap;
    }

    .card {
        height: 100%;
    }

    .header {
        text-transform: capitalize;
    }

    .technology {
        ul {
            width: 100%;
        }

        li {
            span {
                width: calc(100% - 36px);
            }

            text-transform: capitalize;
            display: flex;
            align-items: center;
            line-height: 18px !important;
            padding-bottom: 5px;
        }
    }

    .event-details {

        .wrapper .section .heading .btn-light{
            color: #0432a3 !important;
            background-color: #EAF0FF !important;
        }
        .wrapper .section .content .description-text {
            word-break: break-word;
        }

        .networks {

            .event-wrapper {
                .card-content:nth-child(2n+0) {
                    padding-left: 0.25rem;
                }

                .card-content:nth-child(2n+1) {
                    padding-right: 0.25rem;
                }
            }

            .card {

                // height: 100%;
                .img-section {
                    max-height: 250px;
                    min-height: 100px;

                    .card-img {
                        height: 100%;
                        background: linear-gradient(120.5deg, #5b2e92 0%, #29409c 50.49%, #0f91d2 100%);
                    }
                }

                .details-section {
                    display: flex;
                    flex-direction: column;
                    height: calc(100% - 205px);
                }

                .date {
                    font-size: 14px;
                    width: 100%;

                    // margin-top: auto; 
                    .calender-icon {
                        width: 20px;
                        margin-right: 4px;
                    }

                    span {
                        width: calc(100% - 24px);
                    }
                }

                .location {
                    font-size: 14px;
                    gap: 0px;
                    margin-top: 4px;
                    margin-Bottom: 4px;
                    width: 100%;

                    .icon {
                        width: 20px;
                        margin-right: 4px;
                    }

                    span {
                        width: calc(100% - 24px);
                    }
                }

                .subText {
                    font-size: 14px;
                }

                .btn {
                    padding: 8px 20px;
                    font-size: 14px;
                    width: 80% !important;
                }

                .share-icon {
                    display: flex;
                    height: 22px !important;
                    width: 20px !important;
                }

            }

            .card-img {
                .img-fluid {
                    border-radius: 5px;
                    height: 205px;
                    width: 100%;
                    // min-height: 100px;
                }
            }
        }
    }
}

@media (min-width: 575.98px) {
    .eventdetail-webview {

        .heading .eventBtn{
            width: 200px;
        }
    }
}


@media (max-width: 575.98px) {
    .eventdetail-webview {

        .networks .wrapper .events .card .img-section .tag {
            padding: 0px 5px;
            text-transform: capitalize;
            font-size: 10px;
        }

        .networks .wrapper .events .card .people-pic .d-flex span {
            height: 22px;
            width: 22px;
            display: flex;
            margin-left: -18px;
            border-radius: 55%;
            background: #0432a3;
            color: #ffffff;
            text-align: center;
            // line-height: 34px !important;
            font-weight: 400;
            font-size: 10px;
            // line-height: 20px;
            justify-content: center;
            align-items: center;
        }

        .event-details {

            .networks {
                .card {
                    height: 100%;

                    .img-section {
                        height: 105px;
                    }

                    .details-section {
                        height: calc(100% - 105px);
                    }

                    .date {
                        font-size: 12px;
                    }

                    .location {
                        font-size: 12px;
                    }

                    .subText {
                        font-size: 12px;
                    }

                    .btn {
                        padding: 8px 20px;
                        font-size: 12px;
                        width: 80% !important;
                    }

                    .share-icon {
                        display: flex;
                        height: 22px !important;
                        width: 20px !important;
                    }

                }

                .card-img {
                    .img-fluid {
                        border-radius: 5px;
                        height: 100% !important;
                        width: 100% !important;
                        // min-height: 100px;
                    }
                }
            }
        }
    }
}

.eventdetail-webview::-webkit-scrollbar {

    width: 1px !important;

}

// @media (max-width: 300px) {