@import "../../assets/scss/mixin.scss";
@import "../../assets/scss/variable.scss";
.dashboard {
  nav {
    overflow: visible;
    .search {
      position: relative;
      &::before {
        content: "";
        background: url(../../assets/img/gg_search.svg) no-repeat;
        position: absolute;
        top: 11px;
        left: 11px;
        width: 22px;
        height: 22px;
        z-index: 5;
        cursor: pointer;
      }
      input {
        background: rgba(238, 240, 242, 0.35);
        border: none;
        width: 100%;
        padding: 10px 10px 10px 40px;
        &::placeholder {
          color: $grey;
          @include para;
        }
      }
    }
    .logo-search-box {
      display: flex;
      align-items: center;
      cursor: pointer;
    }
    .prof {
      position: sticky;
      z-index: 1;
      img {
        width: 38px;
        height: 38px;
        border-radius: 5px;
        object-fit: cover;
      }
    }
    .switch {
      position: relative;
      display: inline-block;
      width: 25px;
      height: 14px;
      input {
        opacity: 0;
        width: 0;
        height: 0;
      }
    }
    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: -5px;
      right: 0;
      bottom: 0;
      background-color: $white;
      border: 1px solid $grey;
      -webkit-transition: 0.4s;
      transition: 0.4s;
      &:before {
        position: absolute;
        content: "";
        height: 9px;
        width: 9px;
        left: 2px;
        bottom: 2px;
        background-color: $grey;
        -webkit-transition: 0.4s;
        transition: 0.4s;
      }
    }
    input {
      &:checked {
        + {
          .slider {
            background-color: $primary;

            &:before {
              -webkit-transform: translateX(23px);
              -ms-transform: translateX(23px);
              transform: translateX(23px);
              left: -6px;
            }
          }
        }
      }
      &:focus {
        + {
          .slider {
            box-shadow: 0 0 1px $grey;
          }
        }
      }
    }
    .slider.round {
      border-radius: 34px;
      background-color: $grey;
      &:before {
        border-radius: 50%;
        background-color: $white;
      }
    }
    .navbar-nav {
      align-items: center;
      gap: 5px;
      li {
        a {
          display: flex;
          align-items: center;
          white-space: nowrap;
          @include font-600;
          cursor: pointer;
          @media screen and (max-width: 1100px) {
            padding-inline: 5px;
          }
        }
        .notii {
          display: flex;
          align-items: center;
          white-space: nowrap;
          @include font-600;
          cursor: pointer;
        }

        .colornav {
          color: $primary;
        }

        // .active {
        //   background: $primary !important;
        // }
        .msg {
          padding: 9px;
          border-radius: 5px;
          position: relative;
          span {
            background: #d9534f;
            color: #ffffff;
            padding: 0px 8px;
            border-radius: 50%;
            font-size: 11px;
            position: absolute;
            top: -2px;
            left: 24px;
          }
        }
        .background_grey {
          background: rgba(238, 240, 242, 0.35);
        }
        .background_blue {
          background: $primary;
        }
        .home {
          background: url(../../assets/img/home.svg) no-repeat;
          height: 16px;
          width: 25px;
          display: block;
          background-size: 14px;
        }
        .home-inactive {
          background: url(../../assets/img/home-inactive.svg) no-repeat;
          height: 16px;
          width: 25px;
          display: block;
          background-size: 14px;
        }
        .union {
          background: url(../../assets/img/Union.svg) no-repeat;
          height: 16px;
          width: 25px;
          display: block;
          background-size: 15px;
        }

        .union-active {
          background: url(../../assets/img/Union-active.svg) no-repeat;
          height: 16px;
          width: 25px;
          display: block;
          background-size: 15px;
        }

        .brief {
          background: url(../../assets/img/briffcase.svg) no-repeat;
          height: 20px;
          width: 25px;
          display: block;
          background-size: 15px;
        }

        .brief-active {
          background: url(../../assets/img/briffcase-active.svg) no-repeat;
          height: 20px;
          width: 25px;
          display: block;
          background-size: 15px;
        }
        .wallet {
          background: url(../../assets/img/wallet-money.svg) no-repeat;
          height: 20px;
          width: 25px;
          display: block;
          background-size: 18px;
        }

        .wallet-active {
          background: url(../../assets/img/wallet-money-active.svg) no-repeat;
          height: 20px;
          width: 25px;
          display: block;
          background-size: 18px;
        }
        .worker {
          background: url(../../assets/img/noble_profile_logo.png) no-repeat;
          height: 20px;
          width: 25px;
          display: block;
          background-size: 18px;
        }

        .wallet-active {
          background: url(../../assets/img/wallet-money-active.svg) no-repeat;
          height: 20px;
          width: 25px;
          display: block;
          background-size: 18px;
        }
      }
    }
    .autocomplete-wrapper {
      position: relative;
      margin-left: 5px;
      @media (max-width: 767px) {
        margin-left: 15px;
      }
    }

    // .autocomplete-wrapper input:focus {
    //   border-color: #0F67FF;
    //   box-shadow: none;
    //   outline: none;
    // }
    .autocomplete-wrapper .dropdown {
      width: 100%;
      padding: 10px;
      text-align: left;
      max-height: 300px;
      width: 350px;
      overflow: hidden;
      overflow-y: auto;
      position: absolute;
      z-index: 1;
      background-color: #ffffff;
      border: 1px solid #d1dfff;
      border-radius: 4px;
      // box-shadow: 10px 10px 5px lightblue;
    }
    .autocomplete-wrapper .item {
      display: block;
      cursor: pointer;
      font-size: 14px;
      padding: 0.375rem 0.75rem;
      word-break: break-all;
    }

    .autocomplete-wrapper .item .name-heading-drop {
      line-height: 20px;
      color: $dark-black;
      font-size: 15px;
      font-weight: 500;
    }

    .autocomplete-wrapper .item .sub-heading-drop {
      font-weight: 400;
      color: $grey;
      font-size: 10px;
      margin-top: 0px;
      // line-height: 5px;
    }

    .autocomplete-wrapper .item.selected-item {
      background-color: $light-blue-grey;
      color: $dark-black;
    }
    .autocomplete-wrapper .item:hover {
      background-color: $light-blue-grey;
      color: $dark-black;
    }
    .navbar-toggler {
      display: none;
      &:focus {
        outline: 0;
        box-shadow: none;
      }
    }
  }
}

nav {
  overflow: hidden;
}
.nav-for-mobile {
  display: none;
}

@media (max-width: 1200px) {
  .dashboard {
    nav {
      .navbar-nav {
        align-items: center;
        gap: 10px;
      }
    }
  }
}

@media (min-width: 992px) {
  .navbar-nav {
    flex-direction: row;
  }
}

@media (max-width: 991px) {
  .dashboard {
    nav {
      .navbar-toggler {
        display: block;
      }
    }
  }
  nav {
    .nav-for-desktop {
      display: none;
    }
    .navbar-toggler {
      background-color: #fff;
      z-index: 1000;
      border: 0;
      outline: 0;
      &:focus {
        outline: 0;
        box-shadow: none;
      }
      span {
        height: 4px;
        width: 20px;
        display: block;
        margin: 7px 0;
        border-radius: 20px;
        background-color: rgba(4, 50, 163, 1);
        &:nth-child(2) {
          width: 30px;
        }
      }
    }
  }

  button.navbar-toggler span {
    transition: transform 0.5s linear;
  }
  button.navbar-toggler[aria-expanded="true"] span {
    transform: rotate(40deg) translateY(12px) translateX(6px) translateY(-4px);
    transform-origin: top center;
    width: 30px;
  }

  button.navbar-toggler[aria-expanded="true"] span:nth-child(2) {
    opacity: 0;
  }

  button.navbar-toggler[aria-expanded="true"] span:last-child {
    transform: rotate(-40deg) translateY(-10px) translateX(4px) translateY(0px);
    transform-origin: top center;
  }
  .nav-for-mobile {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh !important;
    z-index: 999;
    transition: all 0.3s ease-in-out;
    box-shadow: 1px 1px 5px #8e8e8e;
    background: rgba(0, 0, 0, 0.5);
    width: 100%;
    right: 0;
    &.show {
      transition: all 0.3s ease-in-out;
      .mobileNavFrame {
        right: 0;
        transition: all 0.3s ease-in-out;
      }
    }
    & .mobileNavFrame {
      padding: 0px 10px 0 !important;
      width: 230px;
      position: absolute;
      z-index: 9999999;
      background: #fff;
      height: 100vh;
      right: -100%;
      transition: all 0.3s ease-in-out;
      & > ul {
        margin-top: 80px !important;
        overflow-y: auto;
        height: calc(100vh - 80px);
      }
    }
    li {
      margin: 10px 0;
      border-top: 1px #e9e9e9 solid;
      padding: 15px 0 5px;
      &:first-child {
        border-top: 0;
      }
      img {
        margin-right: 10px;
      }
      a {
        color: #000;
        font-size: 14px;
        font-weight: 700;
        line-height: 20px;
        display: flex;
      }
      .sub-links {
        margin-top: 15px !important;
        li {
          margin: 0;
          border-top: 0;
          padding: 0;
          a {
            color: #717171;
            font-size: 16px;
            font-weight: 400;
            line-height: 30px;
            letter-spacing: 0.269px;
          }
        }
      }
      .background_grey {
        background: rgba(238, 240, 242, 0.35);
      }
      .background_blue {
        background: $primary;
      }
      .home {
        background: url(../../assets/img/home.svg) no-repeat;
        height: 16px;
        width: 25px;
        display: block;
        background-size: 14px;
      }
      .home-inactive {
        background: url(../../assets/img/home-inactive.svg) no-repeat;
        height: 16px;
        width: 25px;
        display: block;
        background-size: 14px;
      }
      .union {
        background: url(../../assets/img/Union.svg) no-repeat;
        height: 16px;
        width: 25px;
        display: block;
        background-size: 15px;
      }

      .union-active {
        background: url(../../assets/img/Union-active.svg) no-repeat;
        height: 16px;
        width: 25px;
        display: block;
        background-size: 15px;
      }

      .brief {
        background: url(../../assets/img/briffcase.svg) no-repeat;
        height: 20px;
        width: 25px;
        display: block;
        background-size: 15px;
      }

      .brief-active {
        background: url(../../assets/img/briffcase-active.svg) no-repeat;
        height: 20px;
        width: 25px;
        display: block;
        background-size: 15px;
      }
      .wallet {
        background: url(../../assets/img/wallet-money.svg) no-repeat;
        height: 20px;
        width: 25px;
        display: block;
        background-size: 18px;
      }

      .wallet-active {
        background: url(../../assets/img/wallet-money-active.svg) no-repeat;
        height: 20px;
        width: 25px;
        display: block;
        background-size: 18px;
      }
      .worker {
        background: url(../../assets/img/noble_profile_logo.png) no-repeat;
        height: 20px;
        width: 25px;
        display: block;
        background-size: 18px;
      }

      .wallet-active {
        background: url(../../assets/img/wallet-money-active.svg) no-repeat;
        height: 20px;
        width: 25px;
        display: block;
        background-size: 18px;
      }
    }
  }
}
.nav-for-mobile .mobileNavFrame .navbar-toggler {
  background-color: #fff;
  z-index: 1000;
  border: 0;
  outline: 0;
}

.nav-for-mobile .mobileNavFrame .navbar-toggler span {
  height: 4px;
  width: 20px;
  display: block;
  margin: 7px 0;
  border-radius: 20px;
  background-color: rgb(4, 50, 163);
}

.nav-for-mobile .mobileNavFrame .navbar-toggler span:nth-child(2) {
  width: 30px;
}
@media (max-width: 475px) {
  .dashboard {
    nav {
      .navbar-brand {
        margin: 0;
      }
      .search {
        width: 158px;
      }
    }
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: 3px;
  }
}

.admin {
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 5px 24px rgb(0 0 0 / 10%);
  padding: 17px 30px;
  position: absolute;
  z-index: 1;
  right: -4px;
  top: 49px;
  // width: 117px;
}
// @media (max-width: 767px) {

//   .admin {

//     z-index: 9;
//     left: 27px;
//     top: 38px;
// }
// }
.dropdown-span {
  padding: 4px 0px;
  font-weight: 500;
  display: flex;
  gap: 12px;
  align-items: center;
}

.dropdown-span span:hover {
  color: $primary;
}

.outside-navbar {
  .login-button {
    background: $transparent-blue;
    border-radius: 23px;
    color: $primary;
    font-weight: 600;
    padding: 5px 15px;
    cursor: pointer;
  }
}
