@import "../../../../assets/scss/mixin.scss";
@import "../../../../assets/scss/variable.scss";

.appLoader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  // width:150px;
  // height:150px;
  background: transparent;
  // border:3px solid #3c3c3c;
  border-radius: 50%;
  text-align: center;
  line-height: 30px;
  z-index: 1;

  color: $primary;
  letter-spacing: 2px;
  // text-shadow:0 0 10px $primary;
  // box-shadow:0 0 20px rgba(0,0,0,.5);
  font-size: 13px;
  font-weight: 600;
}

// .appLoader:before{
//     content: ' ';
//     position:absolute;
//     top:-10px;
//     right: 46px;
//     width:40%;
//     height:40%;
//     border:4px solid rgba(0, 0, 0, 0.1);
//     // border-right:4px solid $primary;
//     border-top:4px solid $primary;
//     border-radius:50%;
//     animation:animatec 1s linear infinite;

// }

@keyframes animatec {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
