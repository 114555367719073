.comming-soon-banner-alert-container{
    background-image: url('./../../../../../../assets//img/comming-soonbanner.png');
    background-repeat: no-repeat;
    background-size: cover; /* Use cover instead of 100% */
    background-position: center;
    width: 100%;
    background-color: #ffff;
    font-family: 'Inter', sans-serif;
    padding: 100px 20px 42px 20px;
    position: relative;

    .maintenance-title{
font-size: 3rem;
font-weight: 700;
line-height: 70px;
text-align: center;
    }
    .maintenance_desc{
font-size: .93rem;
font-weight: 400;
line-height: 22.5px;
text-align: center;
max-width: 570px;
margin: 10px auto 50px auto;
    }
    .notify-input{
        margin-bottom: 119px;
    }
    .basic-info-details{
        max-width: 806px;
        padding: 0 10px;
        margin: auto;
        margin-top: 100px;
        .info-title{
font-size: 1.87rem;
font-weight: 600;
line-height: 44px;
text-align: center;
margin-bottom: 29px;
        }
        .info-desc{
font-size: .95rem;
font-weight: 400;
line-height: 22.5px;
text-align: center;
margin-bottom: 135px;

        }
    }
    .notification-content{
        text-align: center;
        .notification-text{
font-size: 19px;
font-weight: 600;
line-height: 24px;
text-align: center;
max-width: 667px;
padding: 0 15px;
margin: auto;
margin-bottom: 100px;
.life-time-membership{
    font-size: 1.18rem;
    font-style: italic;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    color: #19BCF4;
}
        }  
    }
    .input-search_container {
        position: relative;
        display: inline-block;
        max-width: 460px;
margin: auto;
    }
    .search_icon {
        position: absolute;
        top: 50%;
        left: 0px;
        transform: translateY(-50%);
        color: #0432A3;
        z-index: 1
    }
    .sidebar-search-box {
        padding:9.2px 35px 9.2px 40px; 
        border-radius: 0;
        border: none;
        border-bottom: 0.5px solid #000000;
        outline: none;
        width: 100%;
        min-width: 460px;
        box-sizing: border-box;
        font-family: var(--font-lora);
        font-size: 13px;
        font-weight: 400;
    }
    
    .search_icon-loading{
        position: absolute;
        top: 50%;
        right: 0px;
        transform: translateY(-50%);
        color: #999;
        font-size: .95rem;
font-weight: 600;
line-height: 22.5px;
text-align: right;
color: #0432A3;
cursor: pointer;
    }
    .banner-footer{
        // position: absolute;
        // bottom: 100px;
        // left: 50%;
        // transform: translateY(-50%);
        padding-bottom: 21px;

    }
}