@import "../../../assets/scss/mixin.scss";
@import "../../../assets/scss/variable.scss";

.default_sidebar {
  .left {
    background: $white;

    .profile-details {
      h6 {
        font-weight: 600;
        font-size: 19px;
        line-height: 24px;
        word-break: break-all;
        color: $dark-black;
        text-transform: capitalize;
      }

      p {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: $dark-black;
      }
    }

    .follower {
      display: flex;
      justify-content: space-around;
      margin-top: 20px;
      margin-bottom: 15px;

      .h-border {
        background: #d1dfff;
        width: 1px;
      }

      .box {
        text-align: center;

        h6 {
          font-weight: 600;
          font-size: 15px;
          line-height: 22px;
          color: $dark-black;
        }

        p {
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #676a79;
        }
      }
    }

    .starter {
      color: #0432a3;
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      text-align: end;
      text-transform: uppercase;
      font-size: 10px;
      margin-right: 7px;
      cursor: pointer;
    }

    .profile {
      height: 132.02px;
      background: $linear;
      border-radius: 5px;
      // padding: 20px;
      position: relative;

      .back-image-fluid {
        border-radius: 5px;
        height: 132.02px;
        width: 100%;
        object-fit: cover;
      }

      .img {
        border: 2px solid $white;
        border-radius: 5px;
        width: 72px;
        height: 72px;
        background: $white;
        display: flex;
        position: absolute;
        margin: 20px;
      }

      .starter {
        position: absolute;
        right: 5px;
        font-weight: 500;
        font-size: 10px;
        line-height: 20px;
        text-align: right;
        text-transform: uppercase;
        bottom: -19px;

        span {
          color: $primary;
        }
      }
    }

    .leftMenuP {
      margin-top: 20px;
    }

    .text {
      h1 {
        @include h1;
        font-size: 19px;
        word-break: break-word;
        line-height: 24px;
      }

      p {
        border: 1px solid #d1dfff;
        padding: 10px;
        @include para;
        font-size: 14px;
      }

      small {
        @include small;
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
      }
    }

    .update {
      height: 41.58px;
      background: $primary;
      color: $white;
      border-radius: 5px;
      text-align: center;
      line-height: 42px;

      a {
        color: $white;
        font-weight: 600;
        font-size: 15px;
      }
    }

    .article {
      h4 {
        @include h1;
        font-size: 14px;
        line-height: 10px;
        margin-bottom: 10px;
      }

      .box {
        display: flex;
        gap: 15px;
        margin-top: 20px;

        .img {
          height: 50px;
          width: 84px;
          border-radius: 5px;
          background: $linear;

          img {
            height: 50px;
            width: 84px;
            border-radius: 5px;
          }
        }

        .text {
          h6 {
            @include h1;
            font-size: 14px;
            line-height: 18px;
          }

          h6:hover {
            text-decoration: underline;
          }

          span {
            font-weight: 400;
            font-size: 10px;
          }
        }
      }

      span {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: $grey;
        // cursor: pointer;
      }
      // span:hover {
      //   color: $primary;
      // }
    }
    .pages {
      h4 {
        @include h1;
        font-size: 14px;
        line-height: 10px;
        margin-bottom: 10px;
      }

      .box {
        margin-top: 20px;

        .img {
          height: 80px;
          border-radius: 5px;
          background: $linear;

          img {
            height: 80px;
            width: 100%;
            border-radius: 5px;
          }
          .page-icon {
            width: 50px;
            height: 50px;
            position: relative;
            border-radius: 55%;
            bottom: 57px;
            left: 10px;
            border: 2px solid #ffffff;
            background: white;
          }
        }

        .text {
          margin-top: 5px;
          h6 {
            @include h1;
            font-size: 14px;
            line-height: 18px;
          }

          h6:hover {
            text-decoration: underline;
          }

          span {
            font-weight: 400;
            font-size: 10px;
          }
        }
      }

      span {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: $grey;
        // cursor: pointer;
      }
      // span:hover {
      //   color: $primary;
      // }
    }

    .groups {
      h4 {
        @include h1;
        font-size: 14px;
        line-height: 10px;
        margin-bottom: 10px;
      }

      ul {
        margin-top: 10px;
        margin-bottom: 0;

        li {
          @include para;
          font-size: 14px;
          line-height: 19px;
          display: flex;
          gap: 6px;
          cursor: pointer;

          &::before {
            content: url("../../../assets/img/people.svg");
          }
        }
        li:hover {
          text-decoration: underline;
        }
      }

      span {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: $grey;
        margin-left: 26px;
        cursor: pointer;
      }
      span:hover {
        color: $primary;
      }
    }

    .events {
      h4 {
        @include h1;
        font-size: 14px;
        line-height: 10px;
        margin-bottom: 10px;
      }

      ul {
        margin: 0;

        li {
          @include para;
          font-size: 14px;
          line-height: 30px;
          display: flex;
          gap: 6px;
          cursor: pointer;
        }
        li:hover {
          text-decoration: underline;
        }
      }

      span {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: $grey;
        cursor: pointer;
        margin-left: 26px;
      }
      span:hover {
        color: $primary;
      }
    }

    .follow {
      h4 {
        @include h1;
        font-size: 14px;
        line-height: 10px;
        margin-bottom: 10px;
      }

      .tag_parent {
        margin-top: 10px;
        margin-bottom: 10px;
        display: flex;
        flex-wrap: wrap;
        gap: 12px;
      }

      span {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: $grey;
        cursor: pointer;
      }
      span:hover {
        color: $primary;
      }

      .tags_text {
        font-family: "Inter";
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: $black;
        padding: 5px 10px 10px 5px;

        background-color: #e9f0f8;
        word-wrap: break-word;
      }

      ul {
        margin: 0;

        li {
          @include para;
          font-size: 14px;
          line-height: 25px;
        }
      }
    }

    .discover {
      h4 {
        @include h1;
        font-weight: 600;
        font-size: 15px;
        line-height: 22px;
        color: $primary;
      }
    }
  }
}

.sidebar-sticky-left {
  position: sticky;
}
