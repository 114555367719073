@import "../../../../assets/scss/mixin.scss";
@import "../../../../assets/scss/variable.scss";

.ad-sidebox {
  border-radius: 5px;
  background: #ffffff;
  .advertisement-side {
    border-radius: 6.744px;
    background: #ffffff;
    box-shadow: 0px 3px 10px 0px rgba(171, 190, 209, 0.6);
    .ad-image {
      background: url("../../../../assets/img/adbar.png") no-repeat;
      background-color: #000;
      background-size: cover;
      height: 204px;
      padding: 20px;
      position: relative;
      border-radius: 6.744px;
      .bottoms {
        position: absolute;
        bottom: 0;
      }

      h4 {
        width: 90%;
        @include font-14;
        color: $white;
        mix-blend-mode: exclusion;
      }
      h3 {
        width: 90%;
        padding: 0;
        background: transparent;
        border: none;
        @include font-14;
        color: $white;
        font-weight: 600;
        mix-blend-mode: exclusion;
      }

      .dots {
        margin: 0 auto;
        display: flex;
        gap: 2px;

        .dot {
          height: 3px;
          width: 3px;
          background: $white;
          border-radius: 50%;
        }
      }
    }
  }
}
