@import "../../assets/scss/mixin.scss";
@import "../../assets/scss/variable.scss";

.billing-box{
    // box-shadow:rgba(171, 190, 209, 0.6) 0px 2px 8px 0px;
    background: #ffffff;
    box-shadow: 0px 2px 4px rgba(171, 190, 209, 0.6);
    border-radius: 5px;
    .box{
        h4{
            margin-top: 15px;
            margin-bottom: 15px;
        }
    }
}

.right-price{
    padding: 0px 40px;
    @media (max-width: 767px) {
        padding: 0 15px;
    }
}

// Css Used For Implementing New Header Design

.text-center .my-3 .image-container{
    width: 100%; 
    height:auto;
    margin-left:-14px; 
}

.text-overlay{
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -50%);
    textAlign: center;
    color: white;
}
.text-overlay h1{
    color:#FFFFFF;
    font-size:30px;
    font-weight:bold;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8);
    white-space: nowrap; 
    overflow: hidden; 
    text-overflow:ellipsis;
    max-width: 100%;
}