@import "../../../../assets/scss/mixin.scss";
@import "../../../../assets/scss/variable.scss";

.people-know {
  box-shadow: 0px 2px 4px rgb(171 190 209 / 60%);
  border-radius: 5px;
  background: $white;
  margin-top: 26px;
  padding: 15px;

  h6 {
    font-weight: 600;
    font-size: 19px;
    line-height: 24px;
    margin-bottom: 24px;
    color: $dark-black;
  }

  .d-plex {
    .img {
      width: 40px;
      height: 40px;
      border-radius: 55%;
      margin-right: 18px;
      cursor: pointer;

      img {
        width: 40px;
        height: 40px;
        border-radius: 55%;
        object-fit: cover;
      }
    }

    .div {
      h6 {
        font-weight: 600;
        font-size: 15px;
        line-height: 22px;
        margin: 0px;
        cursor: pointer;
        word-break: break-all;
      }

      h6:hover {
        cursor: pointer;
        color: $primary;
        text-decoration: underline;
      }

      p {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: $grey;
        word-break: break-all;
      }
    }

    .plus {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background: rgba(4, 50, 163, 0.05);
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      span {
        color: $primary;
        font-size: 20px;
      }
    }
  }
}
