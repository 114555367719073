@import "../../../assets/scss/mixin.scss";
@import "../../../assets/scss/variable.scss";

.recruiter {
  min-height: 100%;
  // background-color: #e5e5e5;

  .main-content {
    .recruiter-bar {
      border-radius: 5px;
      box-shadow: 0px 2px 4px 0px rgba(171, 190, 209, 0.6);

      .filter-option {
        display: inline-flex;
        justify-content: space-between;
        /* align-content: center; */
        align-items: baseline;
        // width: 100%;
        // padding-left: 30px;
        // padding-right: 30px;
        // border-radius: 5px;
        // box-shadow: 0px 2px 4px rgba(171, 190, 209, 0.6);
        // background: $white;

        .search {
          position: relative;
          &::before {
            content: "";
            background: url(../../../assets/img/location-job.svg) no-repeat;
            position: absolute;
            top: 7px;
            left: -10px;
            width: 22px;
            height: 22px;
            z-index: 5;
            cursor: pointer;
          }
          &::after {
            content: "";
            background: none;
            position: absolute;
          }
          // input {
          //   background: rgba(238, 240, 242, 0.35);
          //   border: none;
          //   width: 100%;
          //   padding: 10px 10px 10px 40px;
          //   &::placeholder {
          //     color: $grey;
          //     @include para;
          //   }
          // }
        }

        ul {
          justify-content: space-between;
          .active {
            .form-select {
              color: $black;
              &::after {
                background: black;
                width: 1px;
                height: 100%;
                position: absolute;
                right: 0;
                top: 0;
              }
            }
          }
          li {
            display: inline;
          }
          li:nth-child(1) {
            width: 20%;
          }
          li:nth-child(2) {
            width: 12%;
          }
          li:nth-child(3) {
            width: 12%;
          }
          li:nth-child(4) {
            width: 20%;
          }
          li:nth-child(5) {
            width: 18%;
          }
          li:nth-child(6) {
            width: 18%;
          }
          .upper {
            z-index: 99;
            position: relative;
          }
          .form-select {
            @include para;
            border: none;
            display: inline;
            position: relative;
            background-image: url(../../../assets/img/drop-down.svg);
            background-size: auto;
            width: 95px;
          }
        }

        .autocomplete-wrapper {
          input {
            border: none;
            // background-image: url(../../../assets/img/drop-down.svg);
            // background-size: auto;
            // background-repeat: no-repeat;
            // background-position: right 0.75rem center;
            // max-width: 130px;
            align-items: center;
            display: flex;
            padding: 0 25px;
          }
        }

        .autocomplete-wrapper div {
          // width: 190px;
          z-index: 1;
        }
        .autocomplete-wrapper input {
          width: 100%;
          // padding: .375rem .75rem;
          top: 7px;
          left: -8px;
          padding: 0px 1rem 0 1.3rem;
          // min-width: 190px;
        }
        .autocomplete-wrapper input::placeholder {
          text-align: center;
        }
        // .autocomplete-wrapper input:focus {
        //   border-color: #0F67FF;
        //   box-shadow: none;
        //   outline: none;
        // }
        .autocomplete-wrapper .dropdown {
          width: 100%;
          padding: 0;
          text-align: left;
          max-height: 100px;
          overflow: hidden;
          overflow-y: auto;
          position: absolute;
          z-index: 15;
          background-color: #ffffff;
          border: 1px solid #d1dfff;
          border-radius: 4px;
        }
        .autocomplete-wrapper .item {
          display: block;
          cursor: pointer;
          font-size: 14px;
          padding: 0.375rem 0.75rem;
        }
        .autocomplete-wrapper .item.selected-item {
          background-color: #0432a3;
          color: #fafbfc;
        }
        .autocomplete-wrapper .item:hover {
          background-color: #0432a3;
          color: #fafbfc;
          width: 100%;
        }
      }
    }

    .current-company {
      input {
        width: 100% !important;
        padding: 0px !important;
      }
    }

    .shortlist {
      //padding: 36px 30px 20px 30px;
      padding: 15px 30px 20px 30px;
      border-radius: 5px;
      min-height: 110px;
      .user-checkbox {
        width: 20px;
        height: 20px;
        accent-color: $primary;
      }
      // .right-side{
      //   display: inline;
      //   float: right;
      // }
      .interview-btn {
        border: 1px solid $primary;
        margin-bottom: 3px;
        border-radius: 25px;
        color: $primary;
        padding: 5px;
        font-size: 14px;
        font-weight: 600;
        width: 180px;
        margin-left: 75px;
        text-align: center;
        cursor: pointer;
      }
      .watch-interview {
        // color: $primary;
        // float: right;
        // gap: 10px;
        // cursor: pointer;
        display: flex;
        color: #0432a3;
        font-weight: 600;
        gap: 10px;
        cursor: pointer;
        justify-content: end;
        margin-bottom: 10px;
      }
      .send {
        display: flex;
        color: $parafont;
        // margin-top: 3px;
        margin-bottom: 3px;
        font-size: 14px;
        font-weight: 600;
        float: right;
        gap: 10px;
        cursor: pointer;
      }
      .user-post {
        display: flex;
        flex-direction: row;
        gap: 15px;
        align-items: center;

        .poster-name {
          @include small;
          color: $dark-black;
          font-weight: 600;
        }
        .varify {
          background: url(../../../assets/img/big-verify.svg) no-repeat center;
          width: 15px;
          height: 15px;
          border-radius: 50%;
          cursor: pointer;
          display: inline-block;
          margin-top: 4px;
          margin-left: 6px;
        }

        .designation-name {
          @include font-14;
          max-width: 225px;
        }

        .designation-name.search {
          background: #008c391a;
          color: #008c39;
          border-radius: 20px;
          padding: 0 10px;
        }

        .designation-name.applicant {
          background: #0432a31a;
          color: #0432a3;
          border-radius: 20px;
          padding: 0 10px;
        }
      }

      .prof-img {
        width: 80px;
        height: 80px;
        border-radius: 55%;
        position: relative;

        img:not(.toplist-star) {
          border-radius: 50%;
          width: 80px;
          height: 80px;
          box-shadow: 0 0 0px 2px #ffffff;
          cursor: pointer;
        }
        .toplist-star {
          position: absolute;
          bottom: 0;
          right: 0;
        }
      }

      .job-details {
        @include font-14;
        color: $grey;
        font-weight: 600;
      }
      .skill-span {
        border: 1px solid $parafont;
        border-radius: 20px;
        padding: 5px 10px;
        margin-right: 10px;
      }
    }
  }

  .sidebar {
    .top-sidebar {
      border-radius: 5px;
      box-shadow: 0px 2px 4px 0px rgba(171, 190, 209, 0.6);

      .text-dark-blue {
        @include small;
        color: $dark-black;
        font-weight: 600;
        cursor: pointer;
        display: inline-flex;
        span {
          font-size: 12px;
          font-weight: 400;
        }
      }
    }
  }
}

.smart-interview-page {
  background: #eff2f6;
  padding-top: 30px;
  min-height: 100vh;
  .back-btn {
    cursor: pointer;
    font-weight: 600;
    img {
      margin-right: 10px;
      vertical-align: baseline;
    }
  }
  .section-smart-interview {
    .smart-interview-outer {
      border-radius: 5px;
      background: #fff;
      margin-top: 20px;
      padding: 30px;
    }
    h3 {
      color: #000;
      font-family: Inter;
      font-size: 15px;
      font-style: normal;
      font-weight: 600;
      line-height: 22.5px;
    }
    p {
      margin-top: 10px;
    }
    p,
    li {
      color: #676a79;
      font-family: Inter;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 22.5px;
    }
    ul {
      margin-top: 30px !important;
      list-style-type: disc;
      padding-left: 10px !important;
    }
    .blue-clr-btn {
      color: #0432a3;
      font-family: Inter;
      font-size: 15px;
      font-style: normal;
      font-weight: 600;
      line-height: 22.5px;
      background-color: transparent;
      border: 0;
      padding: 0;
    }
    .gray-blue-btn {
      color: #0432a3;
      font-family: Inter;
      font-size: 15px;
      font-style: normal;
      font-weight: 600;
      line-height: 22.5px;
      border-radius: 5px;
      background: rgba(4, 50, 163, 0.05);
      padding: 8px 30px;
      margin-right: 27px;
    }
  }
}

.shortlist-modal {
  .modal-header {
    background: $linear;
    padding: 24px;
    .modal-title {
      color: $white;
    }
    .btn-close {
      color: $white;
      background: url(../../../assets/img/X.svg) no-repeat center;
      opacity: 5;
    }
  }
  .shortlist-main {
    #scrollableDiv {
      height: 300px;
      overflow: auto;

      &::-webkit-scrollbar {
        width: 5px;
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      }

      &::-webkit-scrollbar-thumb {
        background-color: #363b99;
        border-radius: 10px;
      }
    }
    span {
      color: $primary;
      cursor: pointer;
      font-size: 15px;
      font-weight: 400;
    }
    .user-checkbox {
      width: 20px;
      height: 20px;
      accent-color: $primary;
    }
    .btn {
      padding: 10px 30px !important;
    }
  }
}
.shortlisted {
  // height: 100vh;
  .create {
    text-align: center;
    color: $primary;
    font-size: 20px;
    font-weight: 500;
    cursor: pointer;
  }
}

.custom-skill-placeholder::placeholder {
  /* Styles for placeholder text */
  font-weight: 500;
  font-size: 14px;
  // margin-top: 5px;
  padding-bottom: 30px;
  // color:red;
}

//add fontweight for location autoComplete Wrappper
.autocomplete-wrapper input::placeholder {
  font-weight: 500;
}
