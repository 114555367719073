.pagination {
  margin-top: 30px;
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 3rem;
  font-size: 1.rem;
  gap: 5px;
}
.previousicon {
  margin-top: 30px;
  margin-right: 10px;
  cursor: pointer;
  font-weight: 400;
  color: #bcbcbc;
  font-weight: 800;
  font-size: 7.71429px;
  line-height: 9px;
  padding-right: 15px;
}

.nexticon{
    margin-top: 30px;
    margin-left: 10px;
    cursor: pointer;
    font-weight: 400;
    color: #bcbcbc;
    font-weight: 800;
    font-size: 7.71429px;  
    line-height: 9px;
    padding-right: 15px;
  }


.pageNum {
  cursor: pointer;
  font-weight: 400;
  color: #bcbcbc;
  font-weight: 800;
 /* font-size: 12.71429px;*/  /* 7.71429px;  */
  font-size: 16px;  /* 7.71429px;  */
  line-height: 9px;
  padding-right: 15px;
}

.pageNum :first-child {
  border: 1px solid;
  display: flex;
  width: 30px;
  height: 30px;
  justify-content: center;
  border-radius: 100%;
  align-items: center;
}
.pageNum :hover {
  color: #0432a3;
}

.pagination .pageNum :hover {
  color: #0432a3;
}

.pageNum :focus {
 /* color: #0432a3; */
}
