@import "../../../../../assets/scss/mixin.scss";
@import "../../../../../assets/scss/variable.scss";

.recomendation {
  background: $white;
  padding: 30px;
  border-radius: 5px;
  @media (max-width: 767px) {
    padding: 30px 20px;
  }
  .sent-button {
    border: none;
    background: $primary;
    padding: 15px;
    @include small;
    border-radius: 20px;
    color: $white;
    line-height: 5px;
    cursor: pointer;
  }
  .head_flex_rec {
    display: flex;
    justify-content: space-between;
    .add {
      cursor: pointer;
    }
    .pencil-edit {
      cursor: pointer;
    }
  }

  .heading {
    h6 {
      @include large;
      @media (max-width: 767px) {
        font-size: 12px;
        line-height: 16px;
        padding-right: 10px;
      }
    }
  }

  .ask {
    @include font-600;
    color: $primary;
    cursor: pointer;
    @media (max-width: 767px) {
      font-size: 12px;
      line-height: 16px;
    }
  }

  .circle-blue {
    cursor: pointer;
    background: $primary;
    border-radius: 23px;
    padding: 8px 15px;
    color: $white;
    font-weight: 600;
    height: 35px;
  }
  .tag-blue {
    background: #0432a3;
    padding: 2px 8px;
    color: #ffffff;
    font-weight: 600;
    height: 24px;
    width: fit-content;
    margin-left: 12px;
  }

  .borders {
    cursor: pointer;
    background: transparent;
    border-radius: 23px;
    color: $black;
    border: 1px solid $primary;
    padding: 8px 15px;
    color: $primary;
    font-weight: 600;
    height: 35px;
  }

  .details {
    .desc {
      line-height: 25px !important;
      word-wrap: break-word;
    }
    h6 {
      cursor: pointer;
    }
  }

  .delete {
    cursor: pointer;
  }
  .pencil-edit {
    cursor: pointer;
  }
  .form-switch {
    cursor: pointer;
  }
}
@media(max-width:767px){
  .recomendation{
    .head_flex_rec {
      flex-wrap: wrap;
      .heading{
        padding-bottom:5px;
      }
    }
    .recommend-tab{
      gap:5px !important;
      flex-wrap: wrap;
    }
    .button-unfollow{
      width:auto !important;
    }
  }
}