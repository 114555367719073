@import "../../../assets/scss/variable.scss";
.edit-portal-profile {
  .header-row {
    margin-top: 50px;
    // padding-left: 60px;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    .profile-icon {
      margin-left: auto !important;
      // padding-right: 60px;
      display: flex;
      align-items: center;
      img {
        max-width: 40px;
        border-radius: 100%;
      }
    }
  }
  .container {
    .card {
      margin-top: 20px;
      height: 180px;

      .card-header {
        border: none;
        background: none;
        h5 {
          margin-top: 10px;
        }
      }
      .card-body {
        padding-top: 45px;
        p {
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          margin-bottom: 20px;
          display: inline;
          -webkit-line-clamp: 2;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }
    .main-form-div {
      border: 1px solid rgba(209, 223, 255, 1);
      padding: 15px;
      margin-top: 20px;

      .profile-image {
        background: #fff;
        border: 2px solid #fff;
        border-radius: 55%;
        flex: 0 0 14%;
        height: 100px;
        position: relative;
        top: 3px;
        left: 5px;
        width: 100px;
        .pro img {
          border-radius: 55%;
          height: 100px;
          object-fit: cover;
          width: 100px;
        }
        .edit {
          bottom: 0;
          cursor: pointer;
          position: absolute;
          right: -5px;
          bottom: 4px;
        }
      }
      .mail {
        .intro-img {
          width: 20px;
          height: 20px;
          margin-left: 50px;
          margin-top: 40px;
        }
        p {
          color: $primary;
          margin-top: 40px;
          margin-left: 10px;
          font-size: 15px;
          font-weight: 400;
          line-height: 22.5px;
        }
      }
      .edit-form {
        margin-left: 5px;
        h3 {
          color: #01052d;
          font-size: 19px;
          font-weight: 600;
          line-height: 24px;
        }
        .contact-label {
          font-size: 15px;
          font-weight: 600;
          line-height: 22px;
          color: $headfont;
          display: block;
          margin-bottom: 15px;
        }

        .form-control {
          border: 1px solid $portalborder;
        }
        .form-select {
          cursor: pointer;
          -webkit-appearance: none !important;
          -moz-appearance: none !important;
        }
        .edit-box {
          background: url("../../../assets/img/edit-orange.svg") 98% 0 no-repeat;
          background-size: auto;
          cursor: pointer;
          -webkit-appearance: none !important;
          -moz-appearance: none !important;
          background-color: #fff;
        }
        .addButton {
          color: rgba(25, 188, 244, 1);
          font-size: 15px;
          font-weight: 600;
          display: inline-block;
          margin-top: 10px;
          cursor: pointer;
        }
        .orange-clr {
          color: rgba(246, 138, 24, 1);
        }
        .contact-info {
          h6 {
            font-size: 15px;
            font-weight: 600;
            line-height: 22px;
            color: rgb(1, 5, 45);
            display: block;
            margin-bottom: 5px;
          }
          .addButton {
            margin-top: 10px;
            display: inline-block;
          }
        }
        .website-info {
          h6 {
            margin-top: 10px;
          }
          .addButton {
            margin-top: 0;
          }
        }
        .checkbox-box {
          input {
            margin-right: 10px;
            vertical-align: middle;
          }
        }
        .double-input {
          margin-top: 20px;
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 60px;
        }
      }
    }
  }
}

.edit-portal-profile {
  .container {
    .main-form-div {
      .edit-intro-first {
        .edit-form {
          margin-top: 30px;
        }
      }
    }
  }
}

.save-btn-outer {
  text-align: center;
}
.save-btn,
.close-btn {
  border-radius: 5px;
  background: #f68a18;
  color: #fff;
  font-size: 15px;
  font-weight: 600;
  line-height: 22.5px;
  display: inline-block;
  padding: 10px 30px;
  border: 0;
  outline: 0;
  margin-top: 40px;
  margin-bottom: 40px;
}
.close-btn {
  border: 1px #f68a18 solid;
  background-color: transparent;
  color: #f68a18;
  margin-right: 22px;
}

.upload-resume-section {
  p {
    color: #676a79;
    font-size: 15px;
    font-weight: 400;
    line-height: 22.5px;
    margin-top: 10px;
  }
  .drag-box {
    border: 1px #d1dfff solid;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 140px;
    color: #676a79;
    font-size: 15px;
    font-weight: 400;
    line-height: 22.5px;
    margin-top: 25px;
    img {
      margin-right: 15px;
    }
    label {
      display: inline-block !important;
      margin: 0 !important;
      text-decoration-line: underline;
      color: #676a79 !important;
      font-size: 15px !important;
      font-weight: 400 !important;
      margin-left: 5px !important;
      cursor: pointer;
    }
  }
}

.add-cover-section {
  textarea {
    height: 190px;
  }
}

@media screen and (max-width: 767px) {
  .edit-portal-profile {
    .header-row {
      padding-left: 15px;
      display: block;
      .profile-icon {
        padding-right: 15px;
      }
      span{
        margin-bottom: 20px;
        display: block;
      }
      .company_logo{
        display: flex;
      }
    }
  
    .container {
      .main-form-div {
        .mail {
          .intro-img {
            margin-left: 20px;
            margin-top: 20px;
          }
          p {
            margin-top: 20px;
          }
        }
        .profile-image {
          height: auto;
          .edit {
            bottom: 0px;
          }
          .pro {
            img {
              height: 50px;
              width: 50px;
            }
          }
        }
        .edit-form {
          .double-input {
            grid-template-columns: repeat(1, 1fr);
            gap: 0px;
          }
        }
      }
    }
  }
}
.portal-check {
  input[type="checkbox"]:checked {
    // background-color: #19BCF4 !important;
    accent-color: #19bcf4;
  }
}

.company-custom-dropdown {
  .portal-auto-complete {
    &.location {
      input {
        padding: 5px 35px !important;
      }
    }
    input {
      padding: 5px 20px !important;
      background-image: url("../../../assets/img/tpdrop.png");
      background-repeat: no-repeat;
      background-position: 98.5% center; /* Positions the background image to the left and centers vertically */
      background-size: auto;
      cursor: pointer;
      /* Additional styles to center the text vertically */
      display: flex;
      align-items: center;
    }
  }
}
.exper {
  .react-datepicker-wrapper {
    input {
      background-color: #fff;
    }
  }
}
