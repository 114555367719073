@import "../../assets/scss/mixin.scss";
@import "../../assets/scss/variable.scss";
.people {
  .wrappers {
    margin-top: 26px;
    // width: 100%;
    padding: 1px;
    border-radius: 5px;
    box-shadow: 0px 2px 4px rgba(171, 190, 209, 0.6);
    background: $white;

    input {
      border: none;
      background-image: url(../../assets/img/drop-down.svg);
      background-size: auto;
      background-repeat: no-repeat;
      background-position: right 0.75rem center;
      max-width: 130px;
      align-items: center;
      display: flex;
      padding: 0 25px;
    }
    ul {
      .active {
        .form-select {
          color: $black;
          font-weight: 600 !important;
          &::after {
            background: black;
            width: 1px;
            height: 100%;
            position: absolute;
            right: 0;
            top: 0;
          }
        }
      }
      li {
        display: inline;
      }
      .upper {
        z-index: 99;
        position: relative;
      }
      .form-select {
        @include para;
        border: none;
        display: inline;
        position: relative;
        background-image: url(../../assets/img/drop-down.svg);
        background-size: auto;
        width: 140px;
      }
    }

    .autocomplete-wrapper div {
      width: 190px;
      z-index: 1;
    }
    .autocomplete-wrapper input {
      width: 100%;
      // padding: .375rem .75rem;
      padding: 0px 2.2rem 0 1rem;
      border-radius: 4px;
      min-width: 190px;
    }
    .autocomplete-wrapper input::placeholder {
      text-align: center;
    }
    // .autocomplete-wrapper input:focus {
    //   border-color: #0F67FF;
    //   box-shadow: none;
    //   outline: none;
    // }
    .autocomplete-wrapper .dropdown {
      width: 100%;
      padding: 0;
      text-align: left;
      max-height: 100px;
      overflow: hidden;
      overflow-y: auto;
      position: absolute;
      z-index: 15;
      background-color: #ffffff;
      border: 1px solid #d1dfff;
      border-radius: 4px;
      box-shadow: 10px 10px 5px lightblue;
    }
    .autocomplete-wrapper .item {
      color: #000000;
      display: block;
      cursor: pointer;
      font-size: 14px;
      padding: 0.375rem 0.75rem;
    }
    .autocomplete-wrapper .item.selected-item {
      background-color: #0432a3;
      color: #fafbfc;
    }
    .autocomplete-wrapper .item:hover {
      background-color: #0432a3;
      color: #fafbfc;
      width: 100%;
    }
  }
  .wrapper {
    position: relative;
    background: $white;
    border-radius: 5px;
    margin-top: 10px;
    box-shadow: 0px 2px 4px rgba(171, 190, 209, 0.6);
    padding: 20px;
    .nav-tabs {
      .nav-item {
        padding: 0 28px 0 0px;
        @media (max-width: 767px) {
          padding-right: 10px;
        }
        .nav-link {
          border: none;
          padding: 0 0 9px;
          @include font-600;
          color: $grey;
          @media (max-width: 767px) {
            font-size: 12px;
          }
        }
        .active {
          border-bottom: 4px solid $primary;
          color: $primary;
          border-radius: 4px;
        }
      }
    }

    .profile-img {
      @include profile-80;

      cursor: pointer;
    }
    .details {
      h2 {
        @include font-600;
        @include center-text;
        cursor: pointer;
        word-break: break-all;
        text-transform: capitalize;
        .tick {
          @include verify-profile;
        }
      }
      p {
        @include font-14;
        color: $grey;
        span {
          font-size: 10px;
        }
      }
    }
    .btn-light {
      color: $primary;
    }
  }
  .advertisement {
    background: white;
    // height: 40%;
    width: 274px;
    height: 234px;
    margin: 13px 0 25px 0;
    @media (max-width: 767px) {
      width: 100%;
    }
  }
}

.wrappers ul li:not(.autocomplete-wrapper) select, .wrappers ul li:not(.autocomplete-wrapper) select option{
  width: 200px !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (max-width: 991px) {
  .people {
    .wrappers {
      ul {
        li {
          // display: flex;
          line-height: 42px;
        }
      }
    }
  }
}
.parent-discover-btn{
  background: $white;
  .discover-btn{
    background: $white;
    h4{
      @include h1;
      font-weight: 600;
      font-size: 15px;
      line-height: 22px;
      color: $primary;
    }
    
  }
}

.discover {
  h4 {
    @include h1;
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;
    color: $primary;
  }
}
// .selectOptionOption{
//     display: inline-block;
//     height: 3em;    
//     -webkit-appearance: none;
//     -moz-appearance: none;
// }

// .selectOptionOption option{
//     display: inline-block;
//     width: 2.5em;
//     height: 2.5em;
//     float:left;
// }

@media (max-width: 767px) {
  .people .col-lg-1.col-md-1.col-sm-1.me-3.mb-2 {
    width: 30%;
    margin-right: 0 !important;
  }
  .people .col-lg-8.col-md-8.col-sm-7.mb-3.ml-20 {
    width: 70%;
    margin-left: 0;
  }
}


.card_wrapper{
  border-radius: 5px;
  box-shadow: 0px 2px 4px rgba(171, 190, 209, 0.6);
  background: #ffffff;
}

.logo-relative{
  position: relative;
}

.result-count{
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  color: #676A79;
}


//Applied custom scrollbar for school dropdown in connections by Surajm (Start)
// .custom-scrollbar {
//   &::-webkit-scrollbar {
//     width: 5px;
//   }
//   &::-webkit-scrollbar-track {
//     box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
//   }
//   &::-webkit-scrollbar-thumb {
//     background-color: #363b99;
//     border-radius: 10px;
//   }
// }
