/* Comingsoon.scss */
#comingSoon {
  .container-unique {
    padding: 2rem;
  }

  // header {
  //   margin-bottom: 2rem;
  // }

  .header-content1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  header h1 {
    font-size: 1.5rem;
  }

  nav ul {
    list-style-type: none;
    display: flex;
  }

  nav ul li {
    margin-right: 1rem;
    cursor: pointer;
  }

  main {
    margin-bottom: 2rem;
  }

  .video-section-unique {
    margin-bottom: 2rem;
  }

  .video-section-unique video {
    width: 100%;
  }

  .details-section-unique {
    padding: 2rem;
    background-color: #f9f9f9; /* Light gray background */
    background-size: cover;
    background-position: center;
  }

  .details-container-unique {
    max-width: 800px; /* Adjust as needed */
    margin: 0 auto;
  }

  .details-section-unique h2 {
    margin-bottom: 1rem;
  }

  .card-grid-unique {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 1rem;
  }

  .clickable-card-unique {
    background-color: #f9f9f9;
    padding: 1rem;
    border-radius: 5px;
    text-align: center;
  }

  .clickable-card-unique img {
    max-width: 100px;
  }

  .feature-grid-unique {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-gap: 1rem;
  }

  .feature-card-unique {
    background-color: #f9f9f9;
    padding: 1rem;
    border-radius: 5px;
  }

  .company-logos-section-unique {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
  }

  .company-logo-unique {
    margin: 0 1rem;
  }

  .company-logo-unique img {
    max-width: 100px;
  }

  footer {
    text-align: center;
  }

  .footer-content-unique {
    font-size: 0.8rem;
  }
}
#comming-soon-container {
  font-family: "Inter", sans-serif;
  color: #000000;
  background-color: #ecf0f3;
  min-height: 100vh;
  .nav-container{
    max-width: 1360px;
    margin: auto;
    margin-top: 114px;
    margin-bottom: 25px;
    width: 100%;
    padding: 0px 20px ;
  }
  .navbar-toggler span{
    background-color: #fff;
    height: 50px;
    width: 30px;
    color: red;
  }
  .set-area-container {
    max-width: 1400px;
    margin: auto;
    padding-left:20px;
    padding-right: 20px;
  }
  .comming-soon-title {
    padding-bottom: 86px;
  }
  .nav-item a{
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 22.5px;
    text-align: left;
    color: #000000;
    
  }
  .days-count h1 .number{
font-size: 2.5rem;
font-weight: 600;
line-height: 22.5px;
text-align: center;

  }
  .days-count h1 .days{
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 22.5px;
    text-align: center;
    margin-left: 22px;
    
      }
  .comming-soon-title h1 {
    font-size: 3rem;
    font-weight: 400;
    line-height: 85px;
    letter-spacing: 0.26em;
    color: #000000;
  }
  .comming-soon-title h6 {
    font-size: 1.5rem;
    font-weight: 200;
    line-height: 22.5px;
    color: #000000;
  }
  .full-width-video {
    .video-player {
      max-height: 568px;
    }
    .video-text {
      padding-top: 100px;
      padding-bottom: 50px;
      h2 {
        font-size: 3rem;
        font-weight: 600;
        line-height: 70px;
        text-align: left;
        margin-bottom: 53px;
      }
      p {
        font-size: 1.25rem;
        font-weight: 400;
        line-height: 36px;
        text-align: left;
        margin-bottom: 62px;
      }
    }

  }
  .subscription_container {
    padding-bottom: 127px;
    p {
      font-size: 1.25rem;
      font-weight: 400;
      line-height: 36px;
      text-align: left;
    }
    div .subscription-search { 
        border: 1px solid #c2c2c2;
        background-color: #fff;
        outline: none;
        width: 565px;
        height: 76px;
        border-radius: 51px;
        border: 1px 0px 0px 0px;
        padding: 20px 33px;
    }
    div .subscription-btn{
      background-color: #0432A3;
      width: 236px;
      height: 76px;
      border-radius: 51px;
      text-transform: uppercase;
font-size: 1.5rem;
font-weight: 600;
line-height: 22.5px;
text-align: center;
color: #fff;
    }
  }
  .feature-card-content{
    padding-bottom: 150px;
  }
  .comming-soon-feature{
    background-color: #01052D;
    .set-area-container{
      h1.explore-feature-title{
        font-size: 3rem;
        font-weight: 600;
        line-height: 70px;
        text-align: center;
        color: #fff;
        padding-top: 137px;
        padding-bottom: 42px;
            }
            p.explore-feature-description-head {
        font-size: 1.25rem;
        font-weight: 400;
        line-height: 36px;
        color: #fff;
        padding-bottom: 107px;
            }
    }

  }
  .app-download-section{
    background-color: #ECF0F3;
    padding: 96px 20px 148px 20px;
  }

  @media screen and (max-width: 1284px) {
    .app-download-section{
padding-bottom: 96px;
    }
  }
  @media screen and (max-width: 768px) {
    .nav-container{
      margin-top: 40px;

    }
    .number-of-hours{
      justify-content: space-around;
    }
    .subscription_container{
      .search-section-input{
        flex-direction: column !important;
      }
 
      div .subscription-search{
        width: 100%;
      }
      div .subscription-btn{
        width: 100%;
      }
   
    }
  }
}

