.RichEditor--hide-placeholder .public-DraftEditorPlaceholder-root {
  display: none;
}
.wrapper-class {
  position: relative;
}
.create-newsletter {
  .fixHeaderBox {
    position: relative;
    top: 0px;
    transition: top 0.3s linear;
  }
  .ck-editor__top {
    top: 125px;
    transition: top 0.3s linear;
    @media screen and (max-width: 767px) {
      top: 180px;
    }
  }
  &.sticky-header {
    .fixHeaderBox {
      position: fixed;
      top: 0;
      transition: top 0.3s;
      @media screen and (max-width: 767px) {
        max-width: calc(100% - 24px);
      }
    }
  }
}
.fixHeaderBox {
  left: 0;
  right: 0;
  margin-inline: auto;
  max-width: 1166px;
  background: #fff;
  z-index: 999;
  padding: 20px;
  box-shadow: 0 0 2px #d9d9d9db;
  .btn {
    font-size: 12px !important;
    width: auto !important;
  }
  @media screen and (max-width: 1024px) {
    max-width: 992px;
  }
  @media screen and (max-width: 992px) {
    .btn {
      font-size: 12px;
    }
  }
}
.create-newsletter {
  .preview-button{
    margin:7px 10px 0 0;
  }
  .rdw-editor-toolbar {
    position: absolute;
    top: 10px;
    width: fit-content;
  }
  .rdw-editor-wrapper {
    display: flex;
    justify-content: center;
  }
  .rdw-editor-main {
    width: 100%;
    border: none !important;
  }
}

.image-upload {
  margin-top: 20px;
  background: white !important;
}
.image-upload .background_color {
  background-color: #eef0f259 !important;
}
.editor-class {
  border: aliceblue;
}
.dropzone,
.article-imagebox-banner {
  padding-left: 159px;
  padding-right: 159px;
}

.input-type {
  .input-type::placeholder {
    font: 30px;
  }
}
.rdw-editor-wrapper .article-editor {
  .create-newsletter .rdw-editor-toolbar {
    margin-top: 20px !important;
    margin-bottom: 0px !important;
  }
}
.create-article-container {
  max-width: 1190px;
  margin: auto;
  height: 100vh;
  .editor-content-area {
    padding-left: 159px;
    padding-right: 159px;
  }
}
.create-article-page {
  .create-newsletter {
    .wrapper-class {
      .user-profile-details {
        .article-user-profile {
          width: 48px;
          height: 48px;
          border-radius: 50px;
          object-fit: cover;
        }

        .user-name {
          font-family: Inter;
          font-size: 15px;
          font-weight: 600;
          line-height: 16px;
          color: #000;
        }

        .user-tag {
          font-family: Inter;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          color: #676a79;
        }
      }

      .next-to-article {
        background: #0432a3;
        width: 168px;
        height: 40px;
        padding: 8.5px 30px 8.5px 30px;
        border-radius: 5px;
        color: #fff;
        font-family: Inter;
        font-size: 15px;
        font-weight: 600;
        line-height: 22.5px;
      }
    }
  }
}
// ck editor
.ck.ck-editor__top.ck-reset_all {
  position: fixed;
  left: 0;
  right: 0;
  max-width: 730px;
  margin-inline: auto;
  z-index: 999;
  @media screen and (max-width: 1200px) {
    max-width: 580px;
  }
  @media screen and (max-width: 992px) {
    max-width: 340px;
  }
  @media screen and (max-width: 767px) {
    max-width: calc(100% - 24px);
  }
}

.ck.ck-editor__top .ck-sticky-panel .ck-sticky-panel__content {
  border: none !important;
}
.article-imagebox {
  width: 100%;
  display: block;
  margin-inline: auto;
  margin-block-start: 50px;
  padding-inline: 160px;

  .dropzone {
    padding-inline: 0px !important;
    .image-upload {
      background: rgba(238, 240, 242, 0.35) !important;
      padding: 20px 10px !important;
    }
  }
  .image_drag_drop {
    width: auto;
    height: 100%;
  }
}

.ck.ck-editor__main > .ck-editor__editable:not(.ck-focused) {
  border-color: #fff !important;
}
.ck.ck-editor__top.ck-reset_all .ck.ck-sticky-panel .ck.ck-toolbar {
  background: #f3f3f3;
}
.writenTextBox {
  .input-type {
    color: rgb(26, 26, 26) !important;
    font-size: 36px;
    font-weight: 600;
    font-family: Inter, sans-serif;
    &::placeholder {
      font-size: 36px;
      font-weight: 600;
      color: rgba(121, 116, 116, 1) !important;
      font-family: Inter, sans-serif;
    }
    &::-moz-placeholder {
      font-size: 36px;
      font-weight: 600;
      color: rgba(121, 116, 116, 1) !important;
      font-family: Inter, sans-serif;
    }
    &::-webkit-input-placeholder {
      font-size: 36px;
      font-weight: 600;
      color: rgba(121, 116, 116, 1) !important;
      font-family: Inter, sans-serif;
    }
  }
}
@media screen and (max-width: 767px) {
  .create-article-container {
    .editor-content-area,
    .dropzone {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  .create-newsletter {
    .article-editor {
      .rdw-editor-toolbar {
        margin-top: 75px !important;
      }
    }
    .wrapper-class .image-upload {
      margin-top: 0;
      padding: 30px !important;
    }
    .preview-button{
      margin: 0 ;
    }
  }
  .writenTextBox {
    padding-inline: 0 !important;
    padding-top: 30px;
    padding-bottom: 20px;
    .input-type{
      padding:10px 0 0 0;
      margin-top: 45px;
    }
    .ck.ck-content{
      padding:0;
    }
    .ck.ck-editor__top.ck-reset_all {
      position: relative !important;
      top: -110px !important;
      margin: 0;
      max-width: 100%;
      z-index: 1;
    }
  }
  .article-imagebox {
    padding-inline: 0;
    font-size: 13px !important;
    margin-block-start: 15px;
    .dropzone {
      padding-inline: 0px !important;
      img {
        max-width: 30px;
        margin-bottom: 10px;
      }
    }
  }
  .fixHeaderBox {
    .next-button-row{
      background-color: #fff;
      bottom: 0;
      width: 100%;
      left: 0;
      z-index: 99;
      position: fixed !important;
      padding: 10px;
      position:fixed !important;
      .btn.next-button{
        width:100%;
      }
    }

  }
  .create-newsletter{
    &.sticky-header{ 
      .wrapper-class {
        padding-bottom: 40px !important;
      }   
    }
  }
}
