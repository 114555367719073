@import '../../assets/scss/mixin.scss';
@import '../../assets/scss/variable.scss';

.application-nobel {
    // height: 100vh;
    .top {
        display: flex;
        gap: 15px;
        @include font-14;
        color: $grey;
       
    }

    .dropdown {
        @media (max-width:767px) {
            font-size: 12px;
        }
        .asc-select{
            padding: 0.375rem 1.55rem 0.375rem 0.5rem !important;
        }
    }
   

    .input {
        ul {
            border: none;

            li {
                font-weight: 600;
                font-size: 15px;
                line-height: 22px;
                color: #676A79;
                cursor: pointer;

                @media (max-width:767px) {
                    font-size: 12px;
                }
            }
        }
    }

    .context {
        .profile-img {
            @include profile-64;
        }

        .header {
            h3 {
                font-size: 19px;
                margin-bottom: 10px;

                @media (max-width:767px) {
                    font-size: 14px;
                }
            }

            p {
                margin-bottom: 5px;
            }
        }
    }

    .header {
        h2 {
            @include font-600;
        }

        p {
            @include small;
            color: $grey;
            padding-right: 2px;

            @media (max-width:767px) {
                font-size: 12px;
                padding-right: 10px;
            }
        }

        a {
            color: $primary;
            font-weight: 600;
            display: inline-block;

            @media (max-width:767px) {
                margin-top: 5px;
                font-size: 12px;
            }
        }
        .user-prof{
            color: $primary;
            font-weight: 600;
            display: inline-block;
            cursor: pointer;

            @media (max-width:767px) {
                margin-top: 5px;
                font-size: 12px;
            }
        }
        .promote{
            color: $primary;
            font-weight: 600;
            display: inline-block;
            cursor: pointer;
        }
    }

    .grey {
        background: $light-grey;
    }

    .close {
        background: #FFFFFF;
        box-shadow: 0px 6px 12px rgb(171 190 209 / 30%);
        height: 32px;
        width: 32px;
        display: grid;
        place-content: center;
        border-radius: 50%;
        position: absolute;
        right: 5px;
        top: 60px;
        cursor: pointer;
    }

    .shopping {
        background: #FFFFFF;
        box-shadow: 0px 6px 12px rgb(171 190 209 / 30%);
        height: 32px;
        width: 32px;
        display: grid;
        place-content: center;
        border-radius: 50%;
        position: absolute;
        right: 5px;
        top: 20px;
        cursor: pointer;
    }

    .next {
        position: absolute;
        right: 0px;

        a {
            font-size: 14px;
            font-weight: 400;
        }
    }

    .resume-box {
        button {
            background: $transparent-blue;
            border: none;
            height: 40px;
            color: $primary;
            @include font-600;
        }
    }

    .profile-img {
        @include profile-48;
    }

    .details {
        @media (max-width:767px) {
            margin-top: 10px;
        }

        h3 {
            @include font-600;
            color: $black;

            @media (max-width:767px) {
                font-size: 12px;
            }
        }

        h6 {
            @include font-14;
            color: $black;
        }

        p {
            @include font-14;

            @media (max-width:767px) {
                font-size: 12px;
            }
        }
    }
   
}

.applicationnobal_detail {
    .autocomplete-wrapper {
        input {
            border: none;
            // background-image: url(../../../assets/img/drop-down.svg);
            // background-size: auto;
            // background-repeat: no-repeat;
            // background-position: right 0.75rem center;
            // max-width: 130px;
            align-items: center;
            display: flex;
            padding: 0 25px;
        }
    }

    .autocomplete-wrapper div {
        // width: 190px;
        z-index: 1;
    }

    .autocomplete-wrapper input {
        width: 100%;
        // padding: .375rem .75rem;
        top: 7px;
        left: -8px;
        padding: 0px 0px 0 1.5rem;
        border-radius: 4px;
        // min-width: 190px;
    }

    .autocomplete-wrapper input::placeholder {
        text-align: center;
    }

    .autocomplete-wrapper .dropdown {
        width: 100%;
        padding: 0;
        text-align: left;
        max-height: 100px;
        overflow: hidden;
        overflow-y: auto;
        position: absolute;
        z-index: 15;
        background-color: #ffffff;
        border: 1px solid #d1dfff;
        border-radius: 4px;
        box-shadow: 10px 10px 5px lightblue;
    }

    .autocomplete-wrapper .item {
        display: block;
        cursor: pointer;
        font-size: 14px;
        padding: 0.375rem 0.75rem;
    }

    .autocomplete-wrapper .item.selected-item {
        background-color: #0432a3;
        color: #fafbfc;
    }

    .autocomplete-wrapper .item:hover {
        background-color: #0432a3;
        color: #fafbfc;
        width: 100%;
    }
   
}

//   /assets/img/location-job.svg
.application-search {
    .search {
        position: relative;

        // &::before {
        //     content: "";
        //     background: url(../../assets/img/location-job.svg) no-repeat;
        //     position: absolute;
        //     top: 7px;
        //     left: -10px;
        //     width: 22px;
        //     height: 22px;
        //     z-index: 5;
        //     cursor: pointer;
        // }

        // &::after {
        //     content: "";
        //     background: none;
        //     position: absolute;
        // }

        // input {
        //   background: rgba(238, 240, 242, 0.35);
        //   border: none;
        //   width: 100%;
        //   padding: 10px 10px 10px 40px;
        //   &::placeholder {
        //     color: $grey;
        //     @include para;
        //   }
        // }
    }
}