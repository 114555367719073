.comming-soon-footer{
    padding: 102px 0 0px 0;
    font-family: 'Inter',sans-serif;
    background-color: #fff;

    .comming-soon-footer-container{
        max-width: 927px;
        margin: auto;
        color: #676A79;
    }
    .footer-list-content{
        max-width: 927px;
        margin: 71px auto 134px auto;
    } .footer-list-content p{
        color: #676A79;
font-size: 1rem;
font-weight: 400;
line-height: 32px;
letter-spacing: 0.10000000149011612px;
text-align: center;
    }
    .footer-list-content p.before-dot::before {
        content: '';
        display: inline-block;
        width: 2px;
        height: 2px;
        border-radius: 50%; /* Makes it a circle */
        margin-right: 8px; /* Adjust as needed */
        margin-bottom: 5px; /* Adjust as needed */
        background-color: #676A79;
    }
    
    .cursor-pointer{
        cursor: pointer;
    };
   
}