@import "../../assets/scss/mixin.scss";
@import "../../assets/scss/variable.scss";
@import "src/assets/styles/colors";

.blog {
  .bg {
    background-color: $color-3;
    background-size: auto;
    min-height: 340px;
    color: $color-1;
    display: flex;
    // align-items: center;
    position: relative;
  }

  .bg-image {
    width: 115%;
    // margin-top: 60px;
    // margin-left: 60px;
    background-size: auto;
    background-position: bottom;
    mix-blend-mode: overlay;
  }

  .margin-top {
    margin-top: 35px;
  }

  @media (max-width: 1440px) {
    .bg-image {
      margin-top: 53px;
      // width: 100%;
    }
  }

  @media (max-width: 1000px) {
    .margin-top {
      margin-top: 0px;
    }
  }

  @media (max-width: 767px) {
    .bg-image {
      max-width: 200px;
      width: 100%;
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .mt-55 {
    margin-top: 117px;
    @media (max-width: 767px) {
      margin-top: 80px;
    }
    .filter {
      display: flex;
      justify-content: right;
      gap: 25px;

      select:focus {
        outline: none !important;
      }

      .category {
        border: none !important;
        @include small;
        font-weight: 600;
        color: $grey;
        display: flex;
        cursor: pointer;

        &::after {
          content: "";
          // @include border;
          margin-top: 8px;
          margin-left: 5px;
        }
      }

      .sort {
        @include small;
        font-weight: 600;
        color: $grey;
        display: flex;
        cursor: pointer;

        &::after {
          content: "";
          // @include border;
          margin-top: 8px;
          margin-left: 5px;
        }
      }
    }

    .wrapper {
      margin: 30px 0px;

      .thumbnail {
        box-shadow: 0px 8px 16px rgba(171, 190, 209, 0.4);
        border-radius: 5px;
        overflow: hidden;

        .img {
          height: 300.24px;
          background: $grey;
        }

        .caption {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .left {
            display: flex;
            align-items: center;
            gap: 15px;

            .profile-image {
              height: 32.53px;
              width: 32.53px;
              border-radius: 55%;

              img {
                height: 32.53px;
                width: 32.53px;
              }
            }
          }

          .text {
            span {
              @include small;
            }
          }

          .language {
            span {
              @include small;
            }
          }
        }

        .dates {
          position: relative;
          margin: 15px 0px;

          &::before {
            content: url(../../assets/img/calenda.svg);
            position: absolute;
            display: inline;
            top: 3px;
            left: 0px;
          }

          span {
            @include small;
            font-size: 14px;
            color: $grey;
            margin-left: 24px;
          }
        }

        .text {
          h6 {
            @include h1;
            font-size: 19px;
            line-height: 24px;
          }
        }

        .main-text {
          p {
            @include small;
            font-size: 12px;
            color: $grey;
          }
        }

        .read {
          margin-top: 8px;

          a {
            color: $primary;
            font-weight: 800;
            font-size: 12px;
          }
        }
      }
    }

    // .pagination {
    //   .page-item {
    //     &:first-child {
    //       .page-link {
    //         border: 1px solid #bcbcbc;
    //         border-radius: 42.8571px;
    //         margin: 0px 10px;
    //         color: $grey;
    //       }
    //     }
    //     &:last-child {
    //       .page-link {
    //         border: 1px solid #bcbcbc;
    //         border-radius: 42.8571px;
    //         margin: 0px 10px;
    //         color: $grey;
    //       }
    //     }
    //     a {
    //       border: none;
    //       font-weight: 800;
    //       font-size: 7.71429px;
    //       color: $grey;
    //       padding: 5px 8px;
    //       margin: 0px 8px;
    //     }
    //   }
    // }
  }
}

.blog-details {
  .react-multi-carousel-item {
    width: 375px !important;
  }

  .react-multi-carousel-track {
    gap: 20px;
  }
  margin-top: 7%;
  @media (max-width: 767px) {
    margin-top: 70px;
  }
  h2 {
    @include h1;
    font-size: 36px;
    margin-bottom: 20px;
    @media (max-width: 767px) {
      line-height: 30px;
      font-size: 20px;
    }
  }
  .img-fluid {
    width: 100%;
    height: 415px;
  }
  .pro {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-top: 10px;

    .profile-image {
      height: 32.53px;
      width: 32.53px;
      border-radius: 55%;
      img {
        height: 32.53px;
        width: 32.53px;
        border-radius: 55%;
      }
    }

    .dates {
      position: relative;
      margin: 10px 0px;
      overflow: hidden;
      // text-overflow: ellipsis;
      // display: -webkit-box;
      // -webkit-line-clamp: 1;
      // -webkit-box-orient: vertical;
      &::before {
        content: url(../../assets/img/calenda.svg);
        position: absolute;
        display: inline;
        top: 3px;
        left: 0px;
      }

      span {
        @include small;
        font-size: 14px;
        color: $grey;
        margin-left: 24px;
      }
    }
  }

  .content {
    p {
      @include small;
      color: $grey;
    }

    .text-index {
      text-indent: 150px;
    }

    .img {
      height: 415px;
    }

    .view {
      // display: inline;
      background: rgba(4, 50, 163, 0.05);
      // padding: 10px 18px;
      border-radius: 5px;
      display: inline-flex;
      padding: 8.5px 30px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      button {
        border: 0;
        font-weight: 600;
        font-size: 15px;
        background: none;
        line-height: 22px;
        color: $primary;
      }
    }
  }
}

//Added New Css for Blog Header by Suraj
.blog{
   .bgNew{
    background-color: $color-3;
    background-size: auto;
    min-height: 379.7px;
    color: $color-1;
    display: flex;
    // align-items: center;
    position: relative;
   }

}