@import "../../../../assets/scss/mixin.scss";
@import "../../../../assets/scss/variable.scss";

.vote-percent{
    .progress-bar {
      background-color: #b0c4de;
      color: $white;
      text-align: left;
      overflow: visible;
      color: $dark-black;
      padding-left: 8px;
      font-size: 14px;
      font-weight: 600;
      text-transform: capitalize;
      }
    .progress {
       height: 2.2rem !important;
    }

    .text-end {
      font-size: 14px;
      font-weight: 600;
      line-height: 22px;
      color: $dark-black;
      right: 10px;
      top: 29px;
      position: relative;
    }
}

.pollBoxContainer {
  border: 1px solid $border;
  padding: 20px 20px;
  margin: 20px 0px 20px 0px;
  border-radius: 5px;
}


.btn-poll:hover {
  color: $white;
  background-color: $primary;
}

.btn-poll {
  font-weight: 600;
  font-size: 14px;
  padding: 10px 30px;
  border-radius: 5px;
  border: 1px solid $primary;
  color: $primary;
  width: 100%;
  margin: auto;
  background-color: transparent;
  box-shadow:  1px 1px $border;
}

.view-poll-result{
    .modal-title {
    color: #01052d;
    font-size: 19px;
    font-weight: 600;
    line-height: 44px;
}
}
.viewPollmodal-body{
.nav-tabs {
  .nav-insight-item {
    padding: 0 30px 0 30px !important;
  }
  .nav-item {
    padding: 0 20px 0 0px;

    .nav-link {
      border: none;
      padding: 0 0 9px;
      @include font-600;
      color: $grey;
    }

    .active {
      border-bottom: 4px solid $primary;
      color: $primary;
      border-radius: 4px;
    }
  }
}
.viewPolldiv{
  h6 {
    @include h1;
    font-size: 15px;
    line-height: 18px;
    margin: 0px;
    // margin-bottom: 5px;
    overflow: hidden;
    max-width: 10rem;
    cursor: pointer;
  }

  h6:hover {
    text-decoration: underline;
    color:$primary
  }
}

}
