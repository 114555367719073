@import "src/assets/styles/colors";
.login.login-header {
  @media screen and (max-width: 991px) {
    position: sticky;
    top: 0;
    z-index: 9999;
  }
}
.login-header .navbar {
  padding-top: 76px;
  padding-bottom: 15px;
  @media screen and (max-width: 1100px) {
    padding-top: 25px;
  }
  @media screen and (max-width: 991px) {
    padding-top: 15px;
  }
}

.navbar-brand {
  .navbar-brand-image {
    width: auto;
    max-width: 200px;
  }
}

.navbar-light .navbar-nav .nav-link {
  font-weight: 600;
  font-size: 15px;
  line-height: 1.5;
  color: $color-1;
  display: flex;
  gap: 10px;
}
.login .navbar-light .navbar-nav .nav-link {
  padding: 0px;
}
.login .navbar-light .navbar-nav .nav-link.hyperLink {
  padding: 5px 12px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  &:hover {
    background: rgb(3 31 98 / 59%);
    box-shadow: 0 2px 4px rgb(0 0 0 / 20%);
  }
}
.navbar-nav {
  gap: 50px;
  padding-top: 5px;
}

.navbar__link-icon {
  background-size: 15px 20px;
  display: inline-block;
  width: 20px;
  height: 20px;
}

@media (max-width: 991px) {
  .navbar__link-icon {
    width: 48px;
  }
}
