@import "../../assets/scss/mixin.scss";
@import "../../assets/scss/variable.scss";
@import "src/assets/styles/colors";

.explore {
  .bg {
    background-color: $color-3;
    background-size: auto;
    min-height: 340px;
    color: $color-1;
    display: flex;
    align-items: center;
    position: relative;
  }

  .bg-image {
    background-size: auto;
    width: 100%;

    background-position: bottom;
    margin-top: 65px;
    mix-blend-mode: overlay;
  }

  .margin-top {
    margin-top: 35px;
  }

  @media (max-width: 1440px) {
    .bg-image {
      background-size: auto;
      width: 100%;
      background-position: bottom;
      mix-blend-mode: overlay;
    }
  }

  @media (max-width: 1000px) {
    .margin-top {
      margin-top: 0px;
    }
  }
  @media (max-width: 767px) {
    .bg-image {
      max-width: 200px;
      width: 100%;
      display: block;
      margin-top: 30px;
      margin-left: auto;
      margin-right: auto;
    }
  }
  .links {
    ul {
      display: flex;
      gap: 15px;
      justify-content: center;
      flex-wrap: wrap;
      @media (max-width: 767px) {
        gap: 5px;
      }
      li {
        border: 1px solid $grey;
        border-radius: 25px;
        padding: 10px 16px;
        @include explore-font;
        line-height: 15px;
        color: $grey;
        cursor: pointer;
        @media (max-width: 767px) {
          font-size: 16px;
          padding: 8px 12px;
        }
      }

      .active {
        background-color: $primary;
        color: $white;
        cursor: pointer;
        border: 1px solid $transparent-blue;
      }

      li:hover {
        border: 1px solid $transparent-blue;
        background-color: $primary;
        color: $white;
        cursor: pointer;
      }
    }
  }
  .wrapper {
    .flex {
      display: flex;
      align-items: center;
      justify-content: space-between;
      h3 {
        @include h1;
        @media (max-width: 767px) {
          font-size: 24px;
          line-height: 34px;
        }
      }
      .show {
        cursor: pointer;

        a {
          @include show;
          span {
            margin-left: 16px;
          }
        }
      }
    }
    .links {
      margin-bottom: 40px;
      margin-top: 16px;
      ul {
        justify-content: left;
      }
    }
    .thumbnail {
      cursor: pointer;
      border: 0.2px solid #d1dfff;
      border-radius: 5px;
      margin-bottom: 19px;
      min-height: 490px;

      img {
        min-height: 280px;
      }

      .main-texts {
        p {
          @include para;
          margin-top: 17px;
          -webkit-line-clamp: 5;
          -webkit-box-orient: vertical;
          word-wrap: break-word;
          display: -webkit-box;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .comments {
        margin-top: 22px;
        margin-bottom: 18px;
        span {
          @include para;
          font-size: 14px;
        }
      }
    }
  }
}

.show-all {
  font-weight: bold;
  margin-right: 10px;
}

//Added New Css for Blog Header by Suraj
.explore{
  .bgNew{
    background-color: $color-3;
    background-size: auto;
    min-height: 379.7px;
    color: $color-1;
    display: flex;
    align-items: center;
    position: relative;
  }
}