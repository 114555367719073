@import "../../assets/scss/mixin.scss";
@import "../../assets/scss/variable.scss";

.leads {
  min-height: 100vh;
  // background-color: #e5e5e5;
  .container {
    .main-box {
      border-radius: 5px;
      h2 {
        @include h2;
      }

      .heading-row {
        justify-content: space-between;
        display: flex;
        padding: 30px;
        @media (max-width: 767px) {
          padding: 20px;
        }
        .head {
          font-size: 19px;
          font-weight: 600;
          color: $dark-black;
        }
      }

      .group-bar {
        background-color: #e8edf2;
        padding: 15px;
        border-radius: 5px;
        display: flex;

        .group-title {
          font-size: 15px;
          font-style: normal;
          font-weight: 400;
          color: $dark-black;
        }

        .group-header {
          font-weight: bold;
        }

        .input-group-add {
          width: 115px;
          border: none;
          background: transparent;
          margin-left: 10px;
          cursor: pointer;
          font-size: 15px;
          font-style: normal;
          font-weight: 400;
          color: $dark-black;
          border: 1px solid #676a79;
          box-shadow: 0px 0px 0px 0px rgba(0,0,0,0.5);
          padding: 0px 10px;
        }
        .add-group-icon {
          background: rgba(4, 50, 163, 0.1);
          height: 24px;
          width: 24px;
          cursor: pointer;
          margin-left: 5px;
          margin-right: 7px;
        }
        .groups {
          border-radius: 4px;
          padding: 10px;
          gap: 16px;
          background: rgba(4, 50, 163, 0.1);
          height: 24px;
          // width: 105px;
          margin-right: 5px;
          display: flex;
          align-items: center;
          img{
            float: inline-end;
            width: 16px;
            height: 16px;
          }
        .group-names {
          //  margin-left: 10px;
        }
      }
      }

      .wrapperLead {
        display: flex;
        justify-content: space-between;
        /* align-content: center; */
        align-items: baseline;
        // width: 100%;
        padding-left: 30px;
        padding-right: 30px;
        // border-radius: 5px;
        // box-shadow: 0px 2px 4px rgba(171, 190, 209, 0.6);
        // background: $white;
        @media (max-width: 767px) {
          padding-left: 10px;
          padding-right: 10px;
          flex-wrap: wrap;
        }
        .btn-blue {
          @media (max-width: 767px) {
            display: flex;
          }
        }
        .search {
          position: relative;
          display: inline-flex;
          @media (max-width: 767px) {
            margin: 10px 0;
          }
          input {
            background: rgba(238, 240, 242, 0.35);
            border: none;
            width: 100%;
            padding: 5px 10px 5px 40px;
          }
        }
        .search::before {
          content: "";
          background: url(../../assets/img/gg_search.svg) no-repeat;
          position: absolute;
          top: 7px;
          left: 11px;
          width: 22px;
          height: 22px;
          z-index: 5;
          cursor: pointer;
        }

        ul {
          display: flex;
          @media (max-width: 767px) {
            flex-wrap: wrap;
          }
          .active {
            .form-select {
              color: $black;
              &::after {
                background: black;
                width: 1px;
                height: 100%;
                position: absolute;
                right: 0;
                top: 0;
              }
            }
          }
          // li {
          //   display: inline;
          // }
          .upper {
            z-index: 99;
            position: relative;
          }
          .form-select {
            @include para;
            border: none;
            display: inline;
            position: relative;
            background-image: url(../../assets/img/drop-down.svg);
            background-size: auto;
            width: 95px;
          }
        }

        .autocomplete-wrapper {
          align-items: center;
          display: flex;
          input {
            border: none;
            background-image: url(../../assets/img/drop-down.svg);
            background-size: auto;
            background-repeat: no-repeat;
            background-position: right 0.75rem center;
            max-width: 180px;
            align-items: center;
            display: flex;
            padding: 0 25px;
          }
        }

        .autocomplete-wrapper div {
          // width: 190px;
          // z-index: 1;
        }
        .autocomplete-wrapper input {
          width: 100%;
          // padding: .375rem .75rem;
          padding: 0px 1.5rem 0 1rem;
          border-radius: 4px;
          color: black;
          // min-width: 190px;
        }
        .autocomplete-wrapper input::placeholder {
          text-align: left;
          color: black;
        }
        // .autocomplete-wrapper input:focus {
        //   border-color: #0F67FF;
        //   box-shadow: none;
        //   outline: none;
        // }
        .autocomplete-wrapper .dropdown {
          width: 100%;
          padding: 0;
          text-align: left;
          max-height: 100px;
          overflow: hidden;
          overflow-y: auto;
          position: absolute;
          z-index: 15;
          background-color: #ffffff;
          border: 1px solid #d1dfff;
          border-radius: 4px;
          box-shadow: 10px 10px 5px lightblue;
        }
        .autocomplete-wrapper .item {
          display: block;
          cursor: pointer;
          font-size: 14px;
          padding: 0.375rem 0.75rem;
        }
        .autocomplete-wrapper .item.selected-item {
          background-color: #0432a3;
          color: #fafbfc;
        }
        .autocomplete-wrapper .item:hover {
          background-color: #0432a3;
          color: #fafbfc;
          width: 100%;
        }
      }

      .table_data {
        margin: 30px;
        @media (max-width: 767px) {
          margin: 10px;
        }

        .table_head {
          color: $dark-black;
          font-size: 15px;
          padding-top: 20px;
          padding-bottom: 20px;
          font-weight: 600;
        }

        .table_cell {
          color: $grey;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          padding-top: 20px;
          padding-bottom: 20px;
          vertical-align: middle;

          span {
            text-transform: capitalize;
            display: flex;
            justify-content: center;
          }

          .edit-input {
            border: 1px solid $border;
            padding: 10px 2px;
            border-radius: 5px;
          }

          input:disabled {
            background-color: transparent;
          }

          .image_circle {
            width: 25px;
            height: 25px;
            background: #676a79;
            border-radius: 50%;
            margin-top: -10px;

            img {
              width: 25px;
              height: 25px;
              border-radius: 50%;
            }
          }
        }

        .lead_status {
          color: $primary;
          cursor: pointer;
        }

        .schedule-table {
          // color: $primary;
          font-size: 10px;
        }

        .call_action {
          color: $primary;
          cursor: pointer;
        }

        .qualified {
          color: #008c39;
          background-color: #effff1;
          border-radius: 18px;
          padding: 5px 20px;
          cursor: pointer;
        }

        .unqualified {
          color: #ff3642;
          background-color: #fff2f3;
          border-radius: 18px;
          padding: 5px 20px 5px 20px;
          cursor: pointer;
        }

        .scheduled {
          color: #19bcf4;
          background-color: rgba(0, 140, 57, 0.1);
          border-radius: 18px;
          padding: 5px 20px 5px 20px;
          cursor: pointer;
        }

        .progres {
          color: #f68a18;
          background-color: #fef3e8;
          border-radius: 18px;
          padding: 5px 8px 5px 12px;
          cursor: pointer;
        }
        .pending {
          color: #ffcc00;
          background-color: #fff9da;
          border-radius: 18px;
          padding: 5px 20px 5px 20px;
          cursor: pointer;
        }

        .center_align {
          text-align: center;
        }
      }
    }

    .bg-white {
      background-color: $white;
    }
  }
}
