@import "../../../assets/scss/mixin.scss";
@import "../../../assets/scss/variable.scss";
@import "../../../assets/styles/colors.scss";

.savebutton {
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.report-modal {
  .modal-header {
    padding: 20px !important;
    border-bottom: 0px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    color: #000000;
  }

  label {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #717171;
  }
  .other-reson {
    input {
      margin: 10px;
      width: 100%;
      padding: 0.375rem 0.75rem;
      border-radius: 4px;
      border: 1px solid $border;
    }
  }

  .modal-body {
    padding: 0px 20px 20px !important;
  }

  [type="radio"] {
    background-color: white;
    accent-color: #0432a3;
  }
  //   input[type='radio'] {
  //     accent-color: #232323;
  // }

  //   .form-check-input:checked[type=radio] {
  //     background-color: #0432a3;
  //   }
}

// .popup{
//   width: 500px;
// }
.profile {
  // background-color: $starter-color;

  .savebutton {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .back {
    // background: url(../../../assets/img/background.png) no-repeat;
    // background-size: cover;

    //**The above background will work only for the Nobel and Scout membership.

    .edit-box {
      background: $white;
      border-radius: 5px;
      box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.1);

      .profile-bg {
        height: 213px;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        background: $linear;
        position: relative;
        border-radius: 5px;

        .profile-bg-img {
          height: auto !important;
        }

        .edit {
          position: absolute;
          right: 10px;
          top: 10px;
          cursor: pointer;
        }

        .nobel {
          position: absolute;
          bottom: -27px;
          right: 5px;
          @include small;
          color: $primary;
        }
      }

      .left {
        .details {
          display: flex;
          gap: 19px;

          .profile-image {
            width: 100px;
            height: 100px;
            position: relative;
            border-radius: 55%;
            top: -14px;
            border: 2px solid #ffffff;
            background: white;
            flex: 0 0 14%;

            .pro {
              img {
                width: 100px;
                height: 100px;
                object-fit: cover;
                border-radius: 55%;
              }
            }

            .edit {
              position: absolute;
              bottom: 0;
              right: 5px;
              cursor: pointer;
            }
          }

          @media (max-width: 1201px) {
            .profile-image {
              flex: 0 11%;
            }
          }

          .mt {
            margin-top: 30px;

            .top {
              display: flex;
              // align-items: baseline;
              align-items: center;
              gap: 10px;

              .name {
                width: fit-content;
                max-width: 75%;
                word-break: break-all;

                h2 {
                  @include h1;
                  color: $headfont;
                  font-size: 19px;
                  line-height: 24px;
                  margin-bottom: 0;
                  text-transform: capitalize;
                }

                @media (max-width: 400px) {
                  h2 {
                    @include h1;
                    font-size: 18px;
                    line-height: 24px;
                    margin-bottom: 0;
                  }
                }

                @media (max-width: 360px) {
                  h2 {
                    @include h1;
                    font-size: 14px;
                    line-height: 24px;
                    margin-bottom: 0;
                  }
                }
              }

              .varify {
                @include tick-varify;

                .checkMark {
                  @include checkmark;
                }
              }

              .hiring {
                @include hiring;
              }
              .seeking {
                @include seeking;
              }
              .details-img {
                img {
                  max-width: 15px;
                }
              }
            }

            .location {
              background: url(../../../assets/img/location.svg) no-repeat center;
              cursor: pointer;
              display: inline-block;
              height: 20px;
              width: 13px;
              margin-right: 5px;
              @media (max-width: 767px) {
                background-size: contain;
                width: 25px;
                height: 20px;
              }
            }

            .work {
              flex: 0 0 35%;
              word-break: break-word;

              span {
                @include small;
                color: $grey;
              }
            }

            .where {
              display: flex;

              .link {
                @include small;
                color: $primary;
                // margin-left: 20px;
                // cursor: pointer;
                // text-decoration: underline;
                @media (max-width: 767px) {
                  font-size: 10px;
                  line-height: 16px;
                  flex-basis: 50%;
                }
                img {
                  @media (max-width: 767px) {
                    max-width: 15px;
                  }
                }
              }

              span {
                @include small;
                color: $grey;
                margin-right: 20px;
                // word-break: break-all;
                word-break: break-word;

                @media (max-width: 767px) {
                  margin-right: 10px;
                  font-size: 12px;
                  line-height: 16px;
                }
              }
              // .location-span{
              //   word-spacing: -2.89px;;
              // }

              .mutual-connection {
                display: flex;
                align-items: center;
                margin-top: 14px;
                margin-left: 35px;

                .mutual-connection:hover {
                  color: $primary;
                  text-decoration: underline;
                  cursor: pointer;
                }

                .list_img {
                  display: inline-block;
                  margin-left: -35px;
                  width: 36px;
                  height: 36px;
                  border-radius: 55%;
                  background: #0432a3;
                  color: #ffffff;
                  text-align: center;
                }
              }
            }
          }

          .company {
            @include center-text;
            //    flex: 0 0 35%;
            word-break: break-word;
            @media (max-width: 767px) {
              gap: 5px;
            }
            .circle {
              @include profile-circle;
              img {
                width: 100%;
                height: 100%;
              }
            }

            h6 {
              @include small;
              color: $grey;
              font-weight: 600;
              margin-bottom: 0;
              @media (max-width: 767px) {
                font-size: 12px;
              }
            }
          }

          .univer {
            @include center-text;
            // flex: 0 0 35%;
            word-break: break-word;
            @media (max-width: 767px) {
              gap: 5px;
            }
            .circle {
              @include profile-circle;
              img {
                width: 100%;
                height: 100%;
              }
            }

            h6 {
              @include small;
              color: $grey;
              font-weight: 600;
              margin-bottom: 0;
              @media (max-width: 767px) {
                font-size: 12px;
              }
            }
          }
        }
      }

      .right {
        margin-top: 40px;
        @media (max-width: 767px) {
          margin-top: 20px;
        }
        .progres {
          @include center-text;
          margin-bottom: 10px;
          flex-wrap: wrap;
          gap: 10px;

          .first {
            span {
              @include small;
            }

            .progress {
              width: 176px;
              height: 8px;

              .progress-bar {
                background: $linear;
              }
            }
          }

          .second {
            span {
              @include small;
            }

            .progress {
              width: 176px;
              height: 8px;

              .progress-bar {
                background: $linear;
              }
            }
          }
        }

        .follow {
          @include center-text;
          gap: 20px;
          margin-top: 12px;
          flex-wrap: wrap;

          span {
            &:nth-child(1) {
              @include font-600;
              color: $headfont;
            }

            &:nth-child(2) {
              @include small;
              color: $primary;
              margin-left: 6px;
              @media (max-width: 767px) {
                font-size: 12px;
                line-height: 28px;
                margin-left: 5px;
              }
            }
          }
          .member-color {
            color: $grey !important;
          }

          .member-view {
            display: flex;
            justify-content: center;
          }
        }

        .h-border {
          background: #d1dfff;
          width: 1px;
          height: 40px;
        }
      }

      .bottom {
        @include center-text;
        margin-top: 30px;

        .center {
          @include center-text;
          width: 100%;

          .activity {
            ul {
              display: flex;
              gap: 25px;

              .active-color {
                color: $primary;
              }

              li {
                @include font-600;
                color: $grey;
                cursor: pointer;
              }
            }
          }

          .add {
            margin-left: 40px;
            cursor: pointer;

            span {
              @include font-600;
              color: $primary;
            }
          }
        }

        .member {
          width: 50%;
          justify-content: flex-end;
          @include center-text;

          .view {
            a {
              @include font-600;
              color: $grey;
              cursor: pointer;
              @media (max-width: 767px) {
                font-size: 14px;
              }
            }
          }

          .border-btn {
            border: 1px solid #0432a3;
          }

          .btn {
            color: $primary;
          }

          .connect-img {
            margin-right: 6px;
            width: 12px;
            margin-top: -2px;
          }

          .edit {
            background: $transparent-blue;
            border-radius: 23px;
            padding: 5px 15px;
            color: $primary;
            font-weight: 600;

            a {
              @include font-600;
              color: $primary;
            }
          }

          .edit:hover {
            cursor: pointer;
          }

          .dots {
            background: $transparent-blue;
            @include center-text;
            gap: 1px;
            justify-content: center;
            height: 33px;
            width: 33px;
            border-radius: 5px;
            cursor: pointer;

            span {
              font-size: 13px;
              color: $black;
            }
          }

          .drop {
            position: relative;

            .box {
              position: absolute;
              left: -140px;
              top: 13px;
              background: #ffffff;
              border-radius: 5px;
              padding: 17px 20px;
              z-index: 9;
              box-shadow: 0 5px 24px rgb(0 0 0 / 10%);

              ul {
                li {
                  @include para;
                  display: flex;
                  align-items: baseline;
                  gap: 15px;
                  line-height: 34px;
                  cursor: pointer;
                }
                .connect-status {
                  @include para;
                  display: flex;
                  align-items: baseline;
                  gap: 15px;
                  line-height: 34px;
                  cursor: pointer;
                }
              }
            }
          }
        }
      }
    }

    .profile-about {
      .about {
        position: relative;
        margin-bottom: 30px;

        h3 {
          @include font-600;
          font-size: 19px;
          color: $black;
          margin-bottom: 20px;
        }

        .edit {
          @include edit-font;
          cursor: pointer;
        }

        p {
          @include para;
          word-break: break-all;
          @media (max-width: 767px) {
            font-size: 14px;
          }
        }
      }
      // .about-extra-space {
      //   margin-bottom: 90px !important;
      // }

      .resume {
        font-weight: 400;
        font-size: 15px;
        line-height: 22px;
        color: #676a79;
        word-break: break-all;
        margin-top: 30px;
        margin-bottom: 30px;
      }

      .flex {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin: 30px 0;
        @media (max-width: 767px) {
          margin: 10px 0;
        }
        .column {
          display: flex;
          gap: 30px;

          .content {
            h6 {
              @include font-600;
              margin: 0;
              cursor: pointer;
            }

            p {
              @include small;
              font-size: 10px;
            }
          }
        }
      }

      .experience {
        .flexs {
          @include center-text;
          justify-content: space-between;

          .heading {
            margin: 0;
          }
        }
      }
      .wp-details {
        .job-types {
          display: flex;
          gap: 150px;
          .radio {
            display: flex;
            gap: 5px;
            input {
              accent-color: $primary;
              height: 20px;
              width: 20px;
            }
          }
        }
      }

      .heading {
        @include center-text;
        justify-content: space-between;
        margin-bottom: 30px;

        h6 {
          @include font-600;
          font-size: 19px;
          color: $black;
          margin: 0;
        }
      }

      .add {
        background: none;
        border: none;
        @include font-600;
        color: $primary;
        cursor: pointer;
      }

      .eperience-details {
        .flex {
          display: flex;
          gap: 13px;
          justify-content: unset;

          .exper-pic {
            height: 50px;
            width: 50px;

            img {
              @include profile-50;
            }
          }

          .details {
            width: 66%;
            word-break: break-word;
            h6 {
              text-transform: capitalize;
            }
            span {
              @include para;
              color: $grey;
            }
          }
        }

        .designation {
          position: relative;

          .right-align {
            margin-left: 64px;
            position: relative;

            .details {
              margin-bottom: 20px;
              word-break: break-word;

              .hr {
                &::before {
                  content: "";
                  height: 115%;
                  background: #d1dfff;
                  position: absolute;
                  left: -40px;
                  top: 12px;
                  width: 1.5px;
                }
              }

              @media (max-width: 600px) {
                .hr {
                  &::before {
                    height: 110%;
                  }
                }
              }

              @media (max-width: 400px) {
                .hr {
                  &::before {
                    height: 105%;
                  }
                }
              }

              span {
                @include para;
                color: $grey;
                display: block;
                @media (max-width: 767px) {
                  font-size: 12px;
                  line-height: 20px;
                }

                strong {
                  &::before {
                    content: "";
                    width: 10px;
                    height: 10px;
                    background: #d1dfff;
                    display: inline-block;
                    border-radius: 5px;
                    position: absolute;
                    left: -44px;
                    top: 5px;
                  }
                }

                .brief {
                  display: inline;
                  margin-left: 5px;
                }
              }

              .edit {
                @include edit-font;
                cursor: pointer;
              }

              p {
                @include para;
                color: $grey;
                margin: 10px 0;
                @media (max-width: 767px) {
                  font-size: 12px;
                  line-height: 20px;
                }
              }
            }
          }
        }

        .education {
          .heading {
            @include center-text;
            justify-content: space-between;
          }

          .mr {
            .edu {
              display: flex;
              gap: 18px;
              position: relative;

              .degree-pic {
                width: 50px;
                height: 50px;

                img {
                  @include profile-50;
                }
              }

              .details {
                width: 66%;
                word-break: break-word;

                span {
                  @include para;
                  color: $grey;
                }
              }
            }

            .edit {
              @include edit-font;
              cursor: pointer;
            }

            p {
              margin-left: 67px;
              margin-top: 20px;
              margin-bottom: 20px;
              @include para;
              word-break: break-all;
            }
            .show-media {
              margin-left: 39px;
              margin-top: 20px;
              margin-bottom: 20px;
              word-break: break-word;
            }
          }
        }

        .honors {
          .detai {
            .colum {
              position: relative;

              h6 {
                @include small;
                margin-bottom: 0;
                word-break: break-word;
                text-transform: capitalize;
              }

              span {
                @include para;
                display: block;
                word-break: break-word;
              }

              .edit {
                @include edit-font;
                cursor: pointer;
              }
            }
          }
        }

        .skills {
          .develop {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 20px 0px;

            span {
              @include para;
              word-break: break-word;
            }

            .right-align {
              @include center-text;
              align-items: unset;

              .edit {
                @include edit-font;
                position: relative;
                cursor: pointer;
              }

              .delete {
                cursor: pointer;
              }
            }
          }

          .show {
            @include font-600;
            color: $grey;
            margin-top: 10px;
            display: block;
            cursor: pointer;
            display: inline-block;
          }
        }

        .license {
          .details {
            position: relative;

            h6 {
              @include small;
              color: $black;
              margin: 0;
              word-break: break-word;
            }

            span {
              @include para;
              display: block;
              word-break: break-word;
            }

            .edit {
              @include edit-font;
              cursor: pointer;
            }
          }
        }

        .language {
          .details {
            position: relative;
            margin-bottom: 20px;
            margin-top: 20px;

            h6 {
              @include small;
              margin-bottom: 0;
              word-break: break-word;
            }

            span {
              @include para;
              display: block;
            }

            .edit {
              @include edit-font;
              cursor: pointer;
            }
          }
        }

        .course {
          .details {
            position: relative;
            margin-bottom: 20px;
            margin-top: 20px;

            h6 {
              @include small;
              margin-bottom: 0;
            }

            span {
              @include para;
              display: block;
              word-wrap: break-word;
            }

            .edit {
              @include edit-font;
              cursor: pointer;
            }
          }
        }
      }
    }

    .sidebar {
      .box {
        background: $white;
        border-radius: 5px;

        .intro {
          padding: 20px;

          .heading {
            display: flex;
            justify-content: space-between;

            h5 {
              @include font-600;
            }

            .delete {
              cursor: pointer;
            }
          }

          .img {
            height: 131.56px;
          }
        }

        .badg {
          padding: 20px;

          ul {
            position: relative;
            li {
              @include small;
              line-height: 50px;
              i {
                margin-right: 15px;
              }

              span {
                background: url(../../../assets/img/info.svg) no-repeat center;
                width: 20px;
                height: 20px;
                display: inline-block;
                position: relative;
                top: 5px;
              }
            }
            .badge-modal {
              position: absolute;
              border-radius: 5px;
              background: #fff;
              padding: 20px;
              width: 100%;
              box-shadow: 0px 0px 12px #c4c4c4;
              z-index: 1;
              .title {
                color: #676a79;
                font-family: Inter;
                font-size: 15px;
                font-style: normal;
                font-weight: 600;
                line-height: 22.5px;
                &.inactive {
                  font-size: 15px;
                  font-weight: 600;
                  margin-top: 10px;
                }
              }
              p {
                color: #676a79;
                font-family: Inter;
                font-size: 10px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
                &.inactive {
                  font-size: 10px;
                  font-weight: 400;
                }
              }
              .inactive {
                color: #676a79;
                font-family: Inter;
                font-style: normal;
                line-height: 22.5px;
                opacity: 0.4;
              }
              button {
                position: absolute;
                background: transparent;
                right: 10px;
                top: 10px;
              }
            }
          }
        }

        .padding {
          padding: 20px;

          h6 {
            @include font-600;
            color: $black;
            position: relative;

            .edit {
              position: absolute;
              right: 5px;
              top: 5px;
              cursor: pointer;
            }
          }

          .flex {
            @include center-text;
            position: relative;

            span {
              @include font-600;
              color: $grey;
            }

            .active {
              @include font-600;
              color: $primary;
            }

            .close {
              @include font-600;
              color: $grey;
              position: absolute;
              right: 0;
              cursor: pointer;
            }
          }
        }
      }
    }
  }

  .bottom {
    bottom: 0;
    margin-bottom: 23px;

    .links {
      margin-bottom: 10px;

      ul {
        display: flex;
        flex-wrap: wrap;
        gap: 5px;

        li {
          @include para;
          font-size: 10px;
        }
      }
    }

    .copy-right {
      p {
        @include para;
        font-size: 10px;
      }
    }
  }
}

.edit-profile {
  .popup {
    .profile-img {
      position: relative;
      width: 80px;
      @media (max-width: 767px) {
        top: 70px;
      }

      .img {
        @include profile-64;
        width: 80px;
        height: 80px;
        border-radius: 55%;
      }

      .edit {
        @include pencil;
        width: 25px;
        height: 25px;
        cursor: pointer;
        bottom: 0px;
        top: 50px;
        right: 0px;
        background-size: 25px 25px;
      }
    }
  }
}

.people-view {
  padding-top: 26px;

  .wrappers {
    background: #ffffff;
    /* shaddow2px */

    box-shadow: 0px 2px 4px rgba(171, 190, 209, 0.6);
    border-radius: 5px;

    h2 {
      @include font-19;
      word-break: break-word;
    }

    p {
      @include para;
      color: $grey;
    }

    .outline {
      border: 1px solid $primary;
      border-radius: 20px;
      padding: 8px 18px;
      word-break: break-all;
      white-space: normal;
      @media (max-width: 767px) {
        word-break: break-word;
      }

      p {
        color: $primary;
        @include font-600;
      }
    }

    .blue-button {
      background-color: $primary;
      border-radius: 20px;
      padding: 8px 18px;

      p {
        color: $white;
        @include font-600;
      }
    }

    .msg {
      background: url(../../../assets/img/bx_message-detail.svg) no-repeat
        center;
      width: 18px;
      height: 17px;
      display: inline-block;
      margin-right: 11px;
    }

    .conne {
      background: url(../../../assets/img/connectIcon.svg) no-repeat center;
      width: 18px;
      height: 17px;
      display: inline-block;
      margin-right: 11px;
      background-size: contain;
    }

    .union {
      background: url(../../../assets/img/Union-blue.svg) no-repeat center;
      width: 18px;
      height: 17px;
      display: inline-block;
      margin-right: 11px;
    }

    .btn-light {
      color: $primary;
      display: flex;
      align-items: center;
    }
  }
}

.sidebar-refer {
  .friend {
    height: 165px;
    position: relative;

    .refer {
      position: absolute;
      bottom: 12px;
      right: 1px;
      color: $primary;
      @include font-14;
      font-size: 15px;
      line-height: 7px;

      //   position: absolute;
      // bottom: 12px;
      // right: -21px;
      // color: #0432a3;
      // font-weight: 400;
      // font-size: 14px;
      // line-height: 24px;
      // font-size: 16px;
    }
  }

  .content-text {
    p {
      @include font-14;
      color: $grey;
    }
  }
}

@media (max-width: 670px) {
  .listMessage_Button {
    width: 8rem;
  }
}

@media (max-width: 991px) {
  .profile {
    .back {
      .profile-about {
        .eperience-details {
          .designation {
            .right-align {
              .details {
                .edit {
                  top: -21px;
                }
              }
            }
          }
        }

        .heading {
          h6 {
            font-size: 14px;
          }
        }

        .add {
          font-size: 14px;
        }
      }

      .edit-box {
        .left {
          .details {
            display: block;

            .mt {
              margin: 0;

              // .top {
              //   display: grid;
              // }
            }
          }
        }

        .bottom {
          flex-wrap: wrap;

          .center {
            display: block;

            .add {
              margin: 24px 0;
            }

            .activity {
              ul {
                display: block;

                li {
                  line-height: 32px;
                }
              }
            }
          }

          .member {
            width: auto;
          }
        }
      }
    }
  }
}
.likes-comment {
  display: flex;
  gap: 10px;
  // margin-left: 50px;
}

.comments {
  font-size: large;
  color: white;
}
.likes {
  font-size: large;
  color: white;
}

.fix-width {
  width: 550px;
}

@media (max-width: 600px) {
  .fix-width {
    width: auto !important;
  }
}

.popup {
  .add-expriece {
    .company-custom-dropdown {
      .autocomplete-wrapper {
        position: relative;
      }
      .autocomplete-wrapper input {
        // margin-top: 1.5rem;
        border: 1px solid #d1dfff;
        width: 100%;
        // padding: 0.375rem 0.75rem;
        padding: 5px 35px;
        border-radius: 4px;
        padding-right: 50px;
      }
      // .autocomplete-wrapper input:focus {
      //   border-color: #0F67FF;
      //   box-shadow: none;
      //   outline: none;
      // }
      .autocomplete-wrapper .dropdown {
        width: 100%;
        padding: 0;
        text-align: left;
        max-height: 200px;
        overflow: hidden;
        overflow-y: auto;
        position: absolute;
        z-index: 1;
        background-color: #ffffff;
        border: 1px solid #d1dfff;
        border-radius: 4px;
        // box-shadow: 10px 10px 5px lightblue;
      }

      .autocomplete-wrapper .item .name-heading-drop {
        line-height: 20px;
        color: $dark-black;
        font-size: 15px;
        font-weight: 400;
      }

      .autocomplete-wrapper .item .sub-heading-drop {
        font-weight: 400;
        color: $grey;
        font-size: 11px;
        margin-top: 0px;
        // line-height: 5px;
      }

      .autocomplete-wrapper .item {
        display: block;
        cursor: pointer;
        font-size: 14px;
        padding: 0.375rem 0.75rem;
      }
      .autocomplete-wrapper .item.selected-item {
        background-color: $light-blue-grey;
        color: $dark-black;
      }
      .autocomplete-wrapper .item:hover {
        background-color: $light-blue-grey;
        color: $dark-black;
      }
      .fake-input {
        position: relative;
        // width: 240px;
        border: 1px solid #d1dfff;
        border-radius: 4px;
        color: #676a79;
        font-size: 1rem;
      }
      .fake-input input {
        border: none;
        background-color: #fff;
        display: block;
        width: 100%;
        box-sizing: border-box;
      }
      .fake-input img {
        position: absolute;
        top: 0.5px;
        height: 30px;
        width: 30px;
        object-fit: cover;
        padding: 3px;

        // right: 5px;
      }
    }
    .modal-header {
      // border: none;
      // background: linear-gradient(
      //   120.5deg,
      //   #5b2e92 0%,
      //   #29409c 50.49%,
      //   #0f91d2 100%
      // );
      h5 {
        @include h1;
        font-size: 19px;
        color: $white;
      }
      // padding-bottom: 0px;
    }

    .modal-body {
      form {
        .add-media {
          span {
            font-size: 15px;
            background: none;
            border: none;
            @include font-600;
            color: $primary;
            cursor: pointer;
          }
        }

        .box {
          height: 35px;
          background: rgba(4, 50, 163, 0.1);
          border-radius: 5px;
          @include center-text;
          padding: 0 20px;
          margin-bottom: 25px;
          justify-content: space-between;

          h6 {
            @include para;
            margin-bottom: 0;
          }

          .form-switch {
            float: right;
          }
        }

        label {
          @include small;
          color: #000;
          margin-bottom: 8px;
          margin-right: 10px;
        }
        select {
          margin-bottom: 10px;
        }

        .title {
          margin-bottom: 20px;
          position: relative;

          .autocomplete-wrapper {
            position: relative;
          }

          .autocomplete-wrapper input {
            border: 1px solid #d1dfff;
            width: 100%;
            padding: 0.375rem 0.75rem;
            border-radius: 4px;
          }

          // .autocomplete-wrapper input:focus {
          //   border-color: #0F67FF;
          //   box-shadow: none;
          //   outline: none;
          // }
          .autocomplete-wrapper .dropdown {
            width: 100%;
            padding: 0;
            text-align: left;
            max-height: 100px;
            overflow: hidden;
            overflow-y: auto;
            position: absolute;
            z-index: 1;
            background-color: #ffffff;
            border: 1px solid #d1dfff;
            border-radius: 4px;
            // box-shadow: 10px 10px 5px lightblue;
          }

          .autocomplete-wrapper .item {
            display: block;
            cursor: pointer;
            font-size: 14px;
            padding: 0.375rem 0.75rem;
          }

          .autocomplete-wrapper .item.selected-item {
            background-color: #0432a3;
            color: #fafbfc;
          }

          .autocomplete-wrapper .item:hover {
            background-color: #0432a3;
            color: #fafbfc;
          }

          .icon {
            position: relative;

            input {
              padding: 5px 35px;
            }

            .icon-img {
              position: absolute;
              top: 5px;
              left: 5px;

              img {
                width: 66%;
              }
            }
          }

          input {
            border: 1px solid #d1dfff;
            border-radius: 4px;
            color: $grey;
          }

          .cale {
            @include blue-calender;
          }

          .autocomplete-wrapper {
            position: relative;
          }

          .autocomplete-wrapper input {
            border: 1px solid #d1dfff;
            width: 100%;
            padding: 0.375rem 0.75rem;
            border-radius: 4px;
          }

          .autocomplete-wrapper .dropdown {
            width: 100%;
            padding: 0;
            text-align: left;
            max-height: 100px;
            overflow: hidden;
            overflow-y: auto;
            position: absolute;
            z-index: 1;
            background-color: #ffffff;
            border: 1px solid #d1dfff;
            border-radius: 4px;
            // box-shadow: 10px 10px 5px lightblue;
          }

          .autocomplete-wrapper .item {
            display: block;
            cursor: pointer;
            font-size: 14px;
            padding: 0.375rem 0.75rem;
          }

          .autocomplete-wrapper .item.selected-item {
            background-color: #0432a3;
            color: #fafbfc;
          }

          .autocomplete-wrapper .item:hover {
            background-color: #0432a3;
            color: #fafbfc;
          }
        }

        .show-media {
          background-color: #dfd6d630;
          margin-top: 20px;
          margin-bottom: 20px;
          display: flex;
          .show-media-image {
            position: relative;
            .main-image {
              object-fit: cover;
              width: -webkit-fill-available;
            }
            .edit {
              position: absolute;
              bottom: 0;
              right: 5px;
              cursor: pointer;
            }
          }
        }

        .check {
          @include center-text;
          margin-bottom: 20px;

          input {
            @include tick;
          }

          span {
            @include para;
            margin: 0;
          }
        }

        .words {
          text-align: right;
          @include para;
          font-size: 12px;
        }

        .left {
          text-align: right;
          @include para;
          font-size: 12px;
        }
      }
    }
  }
}

.add-media-modal {
  .modal-header {
    // border: none;
    // background: linear-gradient(
    //   120.5deg,
    //   #5b2e92 0%,
    //   #29409c 50.49%,
    //   #0f91d2 100%
    // );
    h5 {
      @include h1;
      font-size: 19px;
      color: $white;
    }

    // padding-bottom: 0px;
    // padding-left: 30px;
    // padding-right: 30px;
  }

  .modal-body {
    padding-top: 15px;
    overflow-x: hidden;
    form {
      label {
        @include small;
        color: $grey;
        margin-bottom: 8px;
        margin-right: 10px;
      }

      .title {
        margin-bottom: 20px;
        position: relative;
        // .icon {
        //   position: relative;

        //   input {
        //     padding: 5px 35px;
        //   }

        //   // .icon-img {
        //   //   position: absolute;
        //   //   top: 5px;
        //   //   left: 5px;

        //   //   img {
        //   //     width: 66%;
        //   //   }
        //   // }
        // }

        input {
          border: 1px solid #d1dfff;
          border-radius: 4px;
          color: $grey;
        }

        // .cale {
        //   @include blue-calender;
        // }
      }

      .media-img {
        img {
          object-fit: cover;
          width: -webkit-fill-available;
        }
      }
    }
  }
}

.contact-info {
  // .modal-content {
  //   border: none;
  //   border-radius: 5px;
  // }

  .modal-header {
    // border: none;
    // padding: 30px 30px 10px 30px;
    .modal-title {
      @include font-600;
      // color: $dark-black;
      font-size: 19px;
    }

    .btn-close {
      height: 12px;
      width: 12px;
    }
  }

  .modal-body {
    .sub-heading-contact {
      font-size: 15px;
      background: none;
      border: none;
      font-weight: 600;
      line-height: 22px;
      color: #0432a3;
      cursor: pointer;
      word-break: break-all;
    }

    p {
      color: $dark-black;
    }

    span {
      font-size: 15px;
      margin: 0;
      line-height: 34px;
    }

    .title {
      margin-bottom: 20px;

      label {
        @include para;
        color: #000;
        margin-bottom: 8px;
      }

      .autocomplete-wrapper {
        position: relative;
      }

      .autocomplete-wrapper input {
        border: 1px solid #d1dfff;
        width: 100%;
        padding: 0.375rem 0.75rem;
        border-radius: 4px;
      }

      // .autocomplete-wrapper input:focus {
      //   border-color: #0F67FF;
      //   box-shadow: none;
      //   outline: none;
      // }
      .autocomplete-wrapper .dropdown {
        width: 100%;
        padding: 0;
        text-align: left;
        max-height: 100px;
        overflow: hidden;
        overflow-y: auto;
        position: absolute;
        z-index: 1;
        background-color: #ffffff;
        border: 1px solid #d1dfff;
        border-radius: 4px;
        // box-shadow: 10px 10px 5px lightblue;
      }

      .autocomplete-wrapper .item {
        display: block;
        cursor: pointer;
        font-size: 14px;
        padding: 0.375rem 0.75rem;
      }

      .autocomplete-wrapper .item.selected-item {
        background-color: #0432a3;
        color: #fafbfc;
      }

      .autocomplete-wrapper .item:hover {
        background-color: #0432a3;
        color: #fafbfc;
      }
    }
  }
}

.edit-textarea {
  width: 100%;
  height: 200px;
  border: 1px solid #d1dfff;
  padding: 10px;
  margin-top: 10px;
}

.popup {
  .modal-body {
    padding: 16px !important;
    .react-datepicker-wrapper {
      margin-bottom: 10px;
    }
    .video-buttons-row{
      gap:10px !important;
    }
  }
}

.add-section {
  .modal-header {
    // padding: 30px;
    // background: linear-gradient(
    //   120.5deg,
    //   #5b2e92 0%,
    //   #29409c 50.49%,
    //   #0f91d2 100%
    // );
    h5 {
      @include font-600;
      color: $white;
      font-size: 19px;
      &.modal-title {
        h5 {
          font-size: 30px;
        }
      }
    }
    .btn-close {
      --bs-btn-close-color: $white !important;
    }
  }

  .modal-body {
    padding: 0px 30px;

    ul {
      li {
        @include font-600;
        line-height: 60px;
        color: $grey;
        cursor: pointer;
      }
    }
  }
}

.edit-about {
  .modal-body {
    @include para;

    .edit-textarea {
      @include para;
    }

    .left {
      text-align: right;
      @include para;
      font-size: 12px;
    }
  }
}

.add-skill {
  .modal-header {
    // border: none;
    // background: linear-gradient(
    //   120.5deg,
    //   #5b2e92 0%,
    //   #29409c 50.49%,
    //   #0f91d2 100%
    // );
    h5 {
      @include font-600;
      font-size: 19px;
      color: $white;
      word-break: break-all;
    }
  }

  .modal-body {
    form {
      .title {
        label {
          @include para;
        }
      }

      .head {
        @include font-600;
        margin: 20px 0;
        color: $black;
        color: $dark-black;
      }

      .exper {
        @include small;
        margin-bottom: 8px;
        color: $dark-black;
        font-weight: 500;
      }

      .options {
        @include small;
        color: $grey;
        word-break: "break-all";
        margin-left: 10px;
      }

      .add-media {
        text-align: center;
      }

      .right-align-button {
        display: flex;
        justify-content: space-between;
      }

      .autocomplete-wrapper {
        position: relative;
      }

      .autocomplete-wrapper input {
        border: 1px solid #d1dfff;
        width: 100%;
        padding: 0.375rem 0.75rem;
        border-radius: 4px;
      }

      .autocomplete-wrapper .dropdown {
        width: 100%;
        padding: 0;
        text-align: left;
        max-height: 100px;
        overflow: hidden;
        overflow-y: auto;
        position: absolute;
        z-index: 1;
        background-color: #ffffff;
        border: 1px solid #d1dfff;
        border-radius: 4px;
        // box-shadow: 10px 10px 5px lightblue;
      }

      .autocomplete-wrapper .item {
        display: block;
        cursor: pointer;
        font-size: 14px;
        padding: 0.375rem 0.75rem;
      }

      .autocomplete-wrapper .item.selected-item {
        background-color: #0432a3;
        color: #fafbfc;
      }

      .autocomplete-wrapper .item:hover {
        background-color: #0432a3;
        color: #fafbfc;
      }
    }
  }
}

.social-media {
  .social {
    background: #ffffff;
    border-radius: 5px;

    .social-tabs {
      cursor: pointer;
      .icon {
        .facebook {
          @include facebook;
        }
        .instagram {
          @include instagram;
        }
        .linkdin {
          @include linkdin;
        }
        .twitter {
          @include twitter;
        }
      }
    }

    .share {
      @include share;
    }

    .main-box {
      gap: 10px;
      display: flex;
      flex-direction: column;
    }
  }
}

.checkExpiry {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.checkExpiry input {
  width: 20px;
  height: 20px;
  accent-color: #0432a3;
}

.contact-info-popup {
  .modal-header {
    //   background: linear-gradient(
    //     120.5deg,
    //     #5b2e92 0%,
    //     #29409c 50.49%,
    //     #0f91d2 100%
    //   );
    h5 {
      @include large;
      text-transform: capitalize;
      word-break: break-all;
      color: $white;
    }
  }

  .modal-body {
    .box {
      .heading {
        @include small;
      }

      .edit {
        cursor: pointer;
      }

      .details {
        margin-top: 30px;

        .sub-box {
          display: flex;
          gap: 10px;
          margin-bottom: 15px;

          .text {
            h6 {
              @include font-600;
              color: $grey;
              margin: 0;
            }

            p {
              @include para;
              font-size: 14px;
              word-break: break-all;
            }
          }
        }
      }
    }
  }
}

.profile-stats-text {
  font-family: "Inter";
  font-size: 12px;
  color: $color-3;
}

.profile-option-buttons {
  border: 2px solid $color-3;
  display: flex;
  width: 43px;
  height: 43px;
  border-radius: 7px;
  margin-left: 10px;
  align-items: center;
  justify-content: center;
}

// .contact-info-header {
//   font-family: "Inter";
//   font-style: normal;
//   font-weight: 600;
//   font-size: 19px;
//   line-height: 24px;
//   word-break: break-all;
//   cursor: pointer;
// }

.profile-img {
  @include profile-80;

  cursor: pointer;
}

.profile-img_rec {
  height: 48px;
  width: 48px;
  object-fit: cover;
  border-radius: 55%;
  cursor: pointer;
}

.details {
  h2 {
    @include font-600;

    cursor: pointer;
  }

  p {
    @include font-14;
    color: $grey;
  }

  .read_more_rec {
    color: $primary;
    cursor: pointer;
  }
}

.box-cover {
  background-color: $white;
  border-radius: 5px;
}

.round {
  padding: 5px 11px;
  border-radius: 12px;
  color: #0432a3;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.round:hover {
  background: #0432a3;
  color: #ffffff;
  padding: 0px 11px;
  border-radius: 12px;
  cursor: pointer;
}

.btn-active {
  background: #0432a3;
  color: #ffffff;
  padding: 5px 11px;
  border-radius: 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.position-popup {
  .profile-img {
    @include profile-50;
  }

  h6 {
    @include font-600;
  }

  p {
    @include para;
  }

  .text-blue {
    color: #0432a3 !important;
  }

  a {
    color: inherit;
  }

  .verify {
    @include verify-profile;
  }

  .edit {
    @include edit-font;
    position: relative;
  }
}

//add styling for placeholder attribute in Add Experience Modal
.title-placeholder-text::placeholder {
  font-size: 16px;
}

.company-name::placeholder {
  font-size: 16px;
  font-weight: none;
}

.location-placeholder input::placeholder {
  /* Your placeholder styling here */
  font-size: 16px;
}

.startDate-placeholder::placeholder {
  /* Your placeholder styling here */
  font-size: 16px;
}
.company_auto_drop {
  .dropdown {
    scrollbar-width: thin;
  }
}

.input-drop::-webkit-scrollbar {
  width: 6px; /* width of the scrollbar */
}

.input-drop::-webkit-scrollbar-thumb {
  background-color: #363b99; /* color of the scrollbar thumb */
}

// .autocomplete-wrapper .dropdown::-webkit-scrollbar-thumb {
//   background-color: #999; /* color of the scrollbar thumb */
//   border-radius: 3px; /* rounded corners for the thumb */
// }

.user-profile-name {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}


.tooltip-container {
  position: relative;
  display: inline-block;
}

.custom-tooltip {
  visibility: hidden;
  width: 200px;
  background-color: #6e6e6e; /* Gray background */
  color: white;
  text-align: center;
  border-radius: 8px;
  padding: 10px;
  position: absolute;
  z-index: 1;
  bottom: 125%; /* Position above the trigger */
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;
  p{
    color : #fff
  }
}

.custom-tooltip::after {
  content: "";
  position: absolute;
  top: 100%; /* At the bottom of the tooltip */
  left: 50%;
  margin-left: -10px;
  border-width: 10px;
  border-style: solid;
  border-color: #6e6e6e transparent transparent transparent; /* Arrow */
}

.tooltip-trigger:hover + .custom-tooltip {
  visibility: visible;
  opacity: 1;
}

@media(max-width:767px){
  .profile .back {
    overflow-x:hidden;
    .edit-box{
      .left .details .mt .top{
        gap: 7px;
      }
      .activity ul {
        display: block;
        white-space: nowrap;
        overflow-x: auto;
        li{
          display: inline-block;
          vertical-align: top;
          padding:5px 8px;
        }
      }
      .bottom{
        .member {
          gap: 5px;
        }
      }
    }
    .flex-wrap-social{
      flex-wrap: wrap;
      padding-bottom:5px;
    }
    .profile-about{
      padding: 30px 15px 30px 15px;
      .eperience-details {
        .education {
          .heading{
            margin-bottom:20px;
          }
          .mr p{
            margin-top:10px;
            margin-bottom:10px;
          }
        }
        .designation .right-align .details{
          width: 100%;
          padding-right: 30px;
          margin-bottom: 5px;
         .show-media {
            .ms-3 {
              margin-left: 0 !important;
            }
          }
        }
      }
      .heading{
        margin-bottom:5px;
      }
      .flex .column{
        gap:12px;
      }
    }
    .tab-flex-wrap {
      flex-wrap: wrap;
    }

  }
  .add-section .modal-body{
    padding:15px;
  }
}
@media(max-width:359px){
  .profile{
    .back {
      .profile-about{
        .eperience-details{
          .designation{
            .right-align{
              .details{
                span {
                  font-size: 9px;
                }
              }
            }
          }
          .education .mr{
            .edu .details span{
              font-size: 11px;
            }
            p{
              margin-top: 1px;
              margin-bottom: 5px;
              font-size: 11px;
            }
          }
        }
      }
    }
    h6 {
      font-size: 13px;
    }
  }
}
