@import "../../../assets/scss/mixin.scss";
@import "../../../assets/scss/variable.scss";

.login-portal {
  display: flex;
  .form-input {
    .form-control{
      padding-left: 10px !important;
    }
  }
  .left {
    height: auto !important;
    background: #0432a312;
    .center {
      display: flex;
      margin: auto;
      width: 80%;
      position: relative;
    }
  }
  .left-forgot {
 //   height: auto !important;
    background: #0432a312;
    .center {
      display: flex;
      margin: auto;
      width: 80%;
      position: relative;
    }
  }
  .right {
    .login-form {
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 100vh; 
      overflow: hidden;
      .auth-form {
        p {
          font-family: Inter;
          font-size: 15px;
          font-weight: 400;
          line-height: 23px;
          letter-spacing: 0em;
          color: #757575;
        }
        .form-heading {
          font-size: 30px;
          font-weight: 600;
          line-height: 44px;
          letter-spacing: 0em;
          color: #000;
          margin-bottom: 10px;

        }
        .sub-heading{
            color: $primary;
        }
        .nobalaccount{
          cursor: pointer;
        }
      }
    }
  }
}


@media screen and (max-width: 767px) {
  .login-portal {
    height: auto;
    flex-direction: column;
    .left,
    .right {
      width: 100%;
    }
    .right {
      display: block;
      padding: 0;
      .login-form {
        display: block;
      }
    }
    .auth-form {
      float: none;
      width: auto;
      img.mb-3 {
        width: 100%;
      }
    }
  }
}

.img-prop{
  right : 10px !important;
  left: unset !important;
  top: 10px !important;
    width: 18px;
    height: 18px;
}

.portal-login-form{
  .form-input {
    .form-control{
      padding-left: 10px !important;
    }
  }
}