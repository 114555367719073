@import "../../assets/styles/colors";
@import "../../assets/scss/mixin.scss";

.aboutWebView {
  .about .bg {
    // url(../img/about.jpg)

    @media screen and (max-width: 1080px) {
      background-image: url(../../assets/img/about.png);
      background-color: #0432a3;
      background-size: 100px;
      background-repeat: no-repeat;
      background-position-x: 90%;
      background-position-y: 20%;
    }
    @media (max-width: 760px) {
      background-image: none !important;
    }
  }
  .mobile-res {
    @media (max-width: 760px) {
      font-size: revert !important;
      line-height: normal;
    }
  }
  .contextWebView .backgroundWebView {
    padding: 50px 0px;
    background: url(../../assets/img/bgimage.jpg);
  }
}

.OurJourneyBg {
  background: url(../../assets/img/about-bg.png) no-repeat !important;
  -webkit-background-size: cover !important;
  background-size: cover !important;
  background-position: top !important;
  padding-bottom: 30px !important;
  @media screen and (max-width: 767px) {
    padding-bottom: 0px !important;
  }
  p {
    @include para;
  }
}

.section-heading {
  color: $color-2 !important;
  text-align: left;
  @include h2;
  line-height: 1.5;
  margin-bottom: 40px;
  @media screen and (max-width: 1200px) {
    font-size: 28px;
    margin-bottom: 30px;
  }
  @media screen and (max-width: 992px) {
    font-size: 26px;
    margin-bottom: 28px;
  }
  @media screen and (max-width: 768px) {
    // font-size: 24px;
    font-size: 18px;
    margin-bottom: 28px;
  }
}
.sub-heading {
  color: $dark-black;
  font-size: 19px;
  font-weight: 600;
  margin-bottom: 20px;
}

.accomplished {
  margin-block: 80px 60px;

  @media screen and (max-width: 992px) {
    margin-block: 60px;
  }
  @media screen and (max-width: 768px) {
    margin-block: 25px;
  }
}
.accomplished-list {
  ul {
    border: 1px solid $border;
    border-radius: 20px;
    -moz-border-radius: 20px;
    -webkit-border-radius: 20px;
    display: flex;
    flex-flow: row wrap;
    overflow: hidden;
    li {
      width: 50%;
      padding: 36px 10px 36px 50px;
      background: $white;
      .section-heading {
        margin-bottom: 0px;
      }
      &:nth-child(2) {
        border-top: 0;
      }
      &:nth-child(even) {
        border-left: 1px solid $border;
      }
    }
    li + li {
      border-top: 1px solid $border;
    }
    .plus-icon {
      color: $primary;
    }
  }
}

.missionVission {
  padding-block: 40px;
  .section-heading {
    margin-bottom: 50px;
  }
  p {
    @include para;
  }
  @media screen and (max-width: 767px) {
    padding-block: 0;
  }
}

.nobelPageTimeLineBox {
  padding-block: 40px;
  @media screen and (max-width: 767px) {
    padding-block: 20px;
  }
  .nobelPageTimeLine {
    border-top: 1px solid $border;
    border-bottom: 1px solid $border;
    @media screen and (max-width: 767px) {
      padding-inline: 20px;
      border: none;
    }
    .slick-prev,
    .slick-next {
      left: auto;
      right: 0;
      top: -80px;
      height: 24px;
      width: 24px;
      z-index: 99;
      @media screen and (max-width: 767px) {
        top: 50%;
        right: -24px;
        height: 20px;
        width: 20px;
      }
    }
    .slick-prev {
      right: 45px;
      @media screen and (max-width: 767px) {
        right: auto;
        left: -24px;
      }
    }
    .slick-prev:before,
    .slick-next:before {
      content: "";
      height: 24px;
      width: 24px;
      background: url(../../assets/img/slider-arrow.png) no-repeat;
      background-size: contain;
      background-position: center;
      display: inline-block;
    }
    .slick-prev:before {
      transform: rotate(180deg);
    }
    .slick-list {
      .slick-slide.slick-active {
        .companysestainBox {
          border-left: 1px solid $border;
          @media screen and (max-width: 767px) {
            border: 1px solid $border;
            border-radius: 5px;
          }
        }
      }
    }
    .companysestainBox {
      padding: 32px 45px;

      h3 {
        @include h2;
        color: $primary;
        margin-bottom: 12px;
      }
      p {
        @include para;
      }
    }
  }
}
@media (max-width: 767px) {
  .single-column-res {
    column-count: 1 !important;
  }
  .missionVission .section-heading {
    margin-bottom: 30px;
  }
}
